import React, { useState, useEffect } from "react";
import { makeStyles, Typography, Avatar } from "@material-ui/core";
import { Grid } from "@mui/material";
import { images } from "../../../../assets";
import {
  useGetUserByIdQuery,
  useGetUserRoleByIdQuery,
  // useGetUserRolesQuery,
} from "../../../../features/api/apiSlice";
import { BASE_FILE_URL } from "../../../../constants/apiTags";
import { colorTags } from "../../../../constants/variants";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(9),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(0),
    background: "#fff",
    marginBottom: theme.spacing(8),
  },
  formContainer: {
    width: "100%",
    padding: theme.spacing(0),
    // boxShadow:
    //   "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
    // boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 2px 0px",
    boxShadow:
      "rgb(204, 219, 232) 6px 6px 9px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset",
    borderRadius: theme.spacing(1),
    background: "#fff",
    display: "flex",
  },
  avatarContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing(5),
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    cursor: "pointer",
  },
  imageInput: {
    display: "none",
  },
  profileInfo: {
    marginBottom: theme.spacing(5),
  },
  profileItem: {
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  profileLabel: {
    fontWeight: "bold",
    color: theme.palette.text.secondary,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    bottom: "10px",
    left: "20px",
  },
  imageSection: {
    flex: "0 0 15%", // Fix width to 20%
    backgroundImage: `url(${images.ProfilePagePlaceholder})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    // borderRadius: theme.spacing(1),
  },
  gridSection: {
    flex: "0 0 80%", // Fix width to 80%
    padding: "5%",
  },
  button11: {
    touchAction: "manipulation",
    position: "relative",
    padding: 0,
    width: "100px",
    height: "100px",
    border: "2px solid #888888",
    outline: "none",
    backgroundColor: "#f4f5f6",
    borderRadius: "20px",
    boxShadow:
      "-3px -10px 17.5px #ffffff, -3px -5px 7.5px #ffffff, -10px 0px 15px #ffffff, 3px 10px 12.5px rgba(0, 0, 0, 0.2)",
    transition: "0.13s ease-in-out",
    cursor: "pointer",
    "&:active": {
      boxShadow: "none",
    },
    "&:active $button11Content": {
      boxShadow: "none",
    },
    "&:active $button11Content $button11Text, &:active $button11Content $button11Icon":
      {
        transform: "translate3d(0px, 0px, 0px)",
      },
  },
  button11Content: {
    position: "relative",
    display: "grid",
    padding: "10px",
    width: "100%",
    height: "100%",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    gridTemplateRows: "1fr 1fr",
    boxShadow: "inset 0px -4px 0px #dddddd, 0px -4px 0px #f4f5f6",
    borderRadius: "20px",
    transition: "0.13s ease-in-out",
    zIndex: 1,
  },
  button11Icon: {
    position: "relative",
    display: "flex",
    transform: "translate3d(0px, -2px, 0px)",
    gridColumn: 4,
    alignSelf: "start",
    justifySelf: "end",
    width: "16px",
    height: "16px",
    transition: "0.13s ease-in-out",
    "& svg": {
      width: "16px",
      height: "16px",
      fill: "#aaaaaa",
    },
  },
  button11Text: {
    position: "relative",
    transform: "translate3d(0px, -2px, 0px)",
    margin: 0,
    alignSelf: "end",
    gridColumn: "1/5",
    gridRow: 2,
    textAlign: "center",
    fontSize: "16px",
    backgroundColor: "#3333",
    color: "#000",
    textShadow: "2px 2px 3px rgba(255, 255, 255, 0.1)",
    "-webkit-background-clip": "text",
    "-moz-background-clip": "text",
    backgroundClip: "text",
    transition: "0.13s ease-in-out",
  },
}));

const dummyData = {
  name: "John Doe",
  email: "john.doe@example.com",
  phone: "123-456-7890",
  bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  facebook: "john.doe.fb",
  twitter: "john.doe.tw",
  linkedin: "john.doe.linkedin",
  industry: "Technology",
  exports: "Software",
  destinations: "USA",
  activeSince: new Date("2020-01-01"),
  profilePicture: null,
  status: "Active",
  role: "Admin",
};

const UserDetail = () => {
  const [token, setToken] = useState(localStorage.getItem("authToken"));
  const user = JSON.parse(localStorage.getItem("user"));
  const [avatar, setAvatar] = useState([]);

  const params = useParams();
  const navigate = useNavigate();
  const {
    data: profileData,
    isLoading: isLoadingProfileData,
    isError: isErrorProfileData,
    error: errorProfileData,
  } = useGetUserByIdQuery({ id: params?.userIdentifier * 1 });

  const {
    data: roleData,
    isLoading: isLoadingRoleData,
    isError: isErrorRoleData,
    error: errorRoleData,
  } = useGetUserRoleByIdQuery({ id: profileData && profileData?.role_id });

  // useEffect(() => {
  //   if (profileData) console.log(profileData);
  // }, [profileData]);

  const classes = useStyles();
  const [userData, setUserData] = useState(dummyData);
  const [profilePicturePreview, setProfilePicturePreview] = useState(
    dummyData.profilePicture
  );

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    setAvatar(file);

    setUserData((prevUserData) => ({
      ...prevUserData,
      profilePicture: file,
    }));

    const reader = new FileReader();
    reader.onloadend = () => {
      setProfilePicturePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleEditProfile = () => {
    // window.open(`/dashboard/edit-profile/${userData.id}`, "_blank");
    navigate(`/dashboard/edit-profile`);
  };

  return (
    <div style={{ margin: "5%" }}>
      <div className={classes.root}>
        <div className={classes.formContainer}>
          <div className={classes.gridSection}>
            <Grid container spacing={3}>
              {/* Left Section */}
              <Grid item xs={12} sm={6} md={4} style={{ position: "relative" }}>
                <div className={classes.avatarContainer}>
                  <label htmlFor="image-upload">
                    {profilePicturePreview ? (
                      <Avatar
                        alt={profileData?.fullName}
                        src={profilePicturePreview}
                        className={classes.avatar}
                      />
                    ) : (
                      <Avatar
                        alt={profileData?.fullName}
                        src={
                          profileData?.avatar
                            ? BASE_FILE_URL + profileData?.avatar
                            : userData.profilePicture
                        }
                        className={classes.avatar}
                      />
                    )}
                    <input
                      type="file"
                      id="image-upload"
                      accept="image/*"
                      className={classes.imageInput}
                      onChange={handleImageChange}
                    />
                  </label>
                </div>
                {profileData?.user_id === user?.user_id && (
                  <div className={classes.buttonContainer}>
                    <button
                      className={classes.button11}
                      onClick={handleEditProfile}>
                      <div className={classes.button11Content}>
                        <div className={classes.button11Icon}>
                          <svg
                            version="1.1"
                            id="Capa_1"
                            xmlns="https://www.w3.org/2000/svg"
                            xmlnsXlink="https://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            width="80px"
                            height="80px"
                            viewBox="0 0 80 80"
                            style={{ enableBackground: "new 0 0 80 80" }}
                            xmlSpace="preserve">
                            <g>
                              <path
                                d="M64,48L64,48h-8V32h8c8.836,0,16-7.164,16-16S72.836,0,64,0c-8.837,0-16,7.164-16,16v8H32v-8
                               c0-8.836-7.164-16-16-16S0,7.164,0,16s7.164,16,16,16h8v16h-8l0,0l0,0C7.164,48,0,55.164,0,64s7.164,16,16,16
                               c8.837,0,16-7.164,16-16l0,0v-8h16v7.98c0,0.008-0.001,0.014-0.001,0.02c0,8.836,7.164,16,16,16s16-7.164,16-16
                               S72.836,48.002,64,48z M64,8c4.418,0,8,3.582,8,8s-3.582,8-8,8h-8v-8C56,11.582,59.582,8,64,8z M8,16
                               c0-4.418,3.582-8,8-8s8,3.582,8,8v8h-8C11.582,24,8,20.417,8,16z M16,72c-4.418,0-8-3.582-8-8s3.582-8,8-8l0,0h8v8
                               C24,68.418,20.418,72,16,72z M32,48V32h16v16H32z M64,72c-4.418,0-8-3.582-8-8l0,0v-8h7.999c4.418,0,8,3.582,8,8
                               S68.418,72,64,72z"
                              />
                            </g>
                          </svg>
                        </div>
                        <p className={classes.button11Text}>Edit Profile</p>
                      </div>
                    </button>
                  </div>
                )}
              </Grid>

              {/* Right Section */}
              <Grid item xs={12} sm={6} md={8}>
                <Typography variant="h5" className={classes.profileInfo}>
                  Profile Information
                </Typography>
                <div className={classes.profileItem}>
                  <Typography variant="body1" className={classes.profileLabel}>
                    Full Name:
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ color: colorTags.TERTIARY }}>
                    {profileData?.fullName}
                  </Typography>
                </div>
                <div className={classes.profileItem}>
                  <Typography variant="body1" className={classes.profileLabel}>
                    Email:
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ color: colorTags.TERTIARY }}>
                    {profileData?.email}
                  </Typography>
                </div>
                {/* <div className={classes.profileItem}>
                  <Typography variant="body1" className={classes.profileLabel}>
                    Username:
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ color: colorTags.TERTIARY }}>
                    {profileData?.user_name}
                  </Typography>
                </div> */}
                <div className={classes.profileItem}>
                  <Typography variant="body1" className={classes.profileLabel}>
                    Phone:
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ color: colorTags.TERTIARY }}>
                    {profileData?.phone}
                  </Typography>
                </div>
                {/* <div className={classes.profileItem}>
                  <Typography variant="body2" className={classes.profileLabel}>
                    Address:
                  </Typography>
                  <Typography variant="body1">{userData.address}</Typography>
                </div> */}
                <div className={classes.profileItem}>
                  <Typography variant="body1" className={classes.profileLabel}>
                    Role:
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ color: colorTags.TERTIARY }}>
                    {roleData?.roleName}
                  </Typography>
                </div>
                {/* <div className={classes.profileItem}>
                  <Typography variant="body2" className={classes.profileLabel}>
                    Department:
                  </Typography>
                  <Typography variant="body1">{userData.department}</Typography>
                </div> */}
                <div className={classes.profileItem}>
                  <Typography variant="body2" className={classes.profileLabel}>
                    Status:
                  </Typography>
                  <Typography variant="body1">{profileData?.status}</Typography>
                </div>
                {/* <div className={classes.profileItem}>
                  <Typography variant="body2" className={classes.profileLabel}>
                    Added On:
                  </Typography>
                  <Typography variant="body1">
                    {new Date(userData.activeSince).toLocaleDateString("en-US")}
                  </Typography>
                </div> */}
              </Grid>
            </Grid>
          </div>
          <div className={classes.imageSection}></div>
        </div>
      </div>
    </div>
  );
};

export default UserDetail;
