import React, { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Edit as EditIcon, Add as AddIcon } from "@mui/icons-material";
import { SimpleCard } from "../../../../components";
import { colorTags } from "../../../../constants/variants";
import GridData from "../../../../components/GridData";
import { useNavigate } from "react-router-dom";
import AddCategory from "./AddCategory";
import EditCategory from "./EditCategory";
import DeleteDialog from "../../../../components/DeleteDialog";
import {
  useDeleteCategoryRequestMutation,
  useGetCategoriesQuery,
} from "../../../../features/api/apiSlice";

const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
  },
}));

// const categoriesData = [
//   {
//     id: 1,
//     name: "Business",
//     nameAm: "ንግድ",
//     subCategories: [
//       { id: 1, name: "Finance", nameAm: "ፋይናንስ" },
//       { id: 2, name: "Marketing", nameAm: "ማርኬቲንግ" },
//     ],
//   },
//   {
//     id: 2,
//     name: "Technology",
//     nameAm: "ቴክኖሎጂ",
//     subCategories: [
//       { id: 1, name: "Software", nameAm: "ሶፍትዌር" },
//       { id: 2, name: "Hardware", nameAm: "ሃርድዌር" },
//     ],
//   },
//   {
//     id: 3,
//     name: "Health",
//     nameAm: "ጤና",
//     subCategories: [
//       { id: 1, name: "Nutrition", nameAm: "ምግብ" },
//       { id: 2, name: "Exercise", nameAm: "እንቅስቃሴ" },
//     ],
//   },
// ];

export default function CategoriesPage() {
  const navigate = useNavigate();
  const [token, setToken] = useState(localStorage.getItem("authToken"));

  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => setModalOpen(false);
  const handleModalOpen = () => setModalOpen(true);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const {
    data: categoriesData,
    isLoading: isLoadingCategoriesData,
    isError: isErrorCategoriesData,
    error: errorCategoriesData,
  } = useGetCategoriesQuery();

  const columns = [
    {
      field: "id",
      headerName: "No.",
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "name",
      headerName: "Category Name",
      flex: 2,
    },
    {
      field: "nameAm",
      headerName: "Amharic Name",
      flex: 2,
    },
    {
      field: "subCategories",
      headerName: "Subcategories",
      flex: 3,
      renderCell: (params) => (
        <ul>
          {params.row.subcategories?.map((sub) => (
            <li key={sub.id}>
              {sub.name ?? ""} [{sub.nameAm ?? ""}]
            </li>
          ))}
        </ul>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: 1,
      align: "left",
      renderCell: (params) => {
        return (
          <div className="flex">
            <IconButton
              onClick={() => {
                setEditModalOpen(true);
                setSelectedRow(params.row);
              }}>
              <EditIcon color="primary" />
            </IconButton>
            <DeleteDialog
              api={useDeleteCategoryRequestMutation}
              dialogLabel="Remove Category"
              id={params.row.id}
              name={`Category ${params.row.id}`}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div style={{ marginLeft: "5%", paddingTop: "5%", width: "90%" }}>
      {modalOpen ? (
        <Button
          variant="contained"
          style={{
            marginBottom: "1%",
            marginLeft: "88%",
            backgroundColor: colorTags.SECONDARY,
          }}
          onClick={handleModalClose}>
          Close
        </Button>
      ) : (
        <Button
          variant="contained"
          style={{
            marginBottom: "1%",
            marginLeft: "88%",
            backgroundColor: colorTags.TERTIARY,
          }}
          onClick={handleModalOpen}>
          <AddIcon style={{ marginRight: "5px" }} />
          Category
        </Button>
      )}

      <AddCategory
        open={modalOpen}
        onClose={handleModalClose}
        title="Add Category"
        content="This is the modal content."
        actionText="Close"
      />

      <EditCategory
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        title="Edit Category"
        content="This is the modal content."
        actionText="Close"
        categoryData={selectedRow}
      />

      <SimpleCard
        title="Categories"
        style={{
          marginTop: "2%",
        }}>
        <Box width="100%" overflow="auto">
          <GridData
            rows={categoriesData ?? []}
            columns={columns}
            initialState={{
              pagination: {
                pageSize: 5,
              },
            }}
          />
        </Box>
      </SimpleCard>
    </div>
  );
}
