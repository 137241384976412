import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  useMediaQuery,
  useTheme,
  Tooltip,
  Snackbar,
} from "@material-ui/core";
import { INTERNAL_LINK_URL_CPANEL } from "../../../../constants/apiTags";
import copy from "clipboard-copy";

import { images } from "../../../../assets/index";

const ShareDialog = ({ open, onClose, news }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isCopied, setIsCopied] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const copyToClipboard = async (value) => {
    try {
      await copy(value);
      return true;
    } catch (error) {
      console.error("Copy failed: ", error);
      return false;
    }
  };

  const handleCopyLink = async () => {
    const isCopied = await copyToClipboard(
      INTERNAL_LINK_URL_CPANEL + "news-detail/" + news?.id
    );
    if (isCopied) {
      setIsCopied(true);
      setOpenSnackbar(true);

      setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    }
  };

  const shareNews = (platform) => {
    const newsLink = INTERNAL_LINK_URL_CPANEL + "news-detail/" + news?.id;

    if (platform === "facebook") {
      const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        newsLink
      )}`;
      window.open(facebookShareUrl, "_blank");
    } else if (platform === "twitter") {
      const twitterShareUrl = `https://x.com/intent/tweet?text=${encodeURIComponent(
        "Check out this news: " + news.title
      )}&url=${encodeURIComponent(newsLink)}`;
      window.open(twitterShareUrl, "_blank");
    } else if (platform === "telegram") {
      const telegramShareUrl = `https://t.me/share/url?url=${encodeURIComponent(
        newsLink
      )}&text=${encodeURIComponent(news.title)}`;
      window.open(telegramShareUrl, "_blank");
    } else if (platform === "whatsapp") {
      const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
        "Check out this news: " + news.title + " " + newsLink
      )}`;
      window.open(whatsappShareUrl, "_blank");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      // fullScreen={isMobile}
      PaperProps={{
        style: {
          background: "rgba(255, 255, 255, 1)",
          backdropFilter: "blur(10px)",
          padding: "20px",
          paddingBottom: "10px",
          borderRadius: "20px",
        },
      }}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.01)",
        },
      }}>
      <DialogTitle style={{ fontSize: "1rem", color: "#000" }}>
        Share Story
      </DialogTitle>
      <DialogContent
        style={{
          background: "transparent",
          padding: "5px 30px",
        }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            background: "transparent",
          }}>
          <Tooltip title="Share on Facebook">
            <IconButton onClick={() => shareNews("facebook")}>
              <img
                src={images.FacebookIconPlaceholder}
                alt="Facebook"
                style={{ width: 24, height: 24 }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Share on X.com">
            <IconButton onClick={() => shareNews("twitter")}>
              <img
                src={images.TwitterXIconPlaceholder}
                alt="X.com"
                style={{ width: 24, height: 24 }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Share on Telegram">
            <IconButton onClick={() => shareNews("telegram")}>
              <img
                src={images.TelegramIconPlaceholder}
                alt="Telegram"
                style={{ width: 24, height: 24 }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Share on WhatsApp">
            <IconButton onClick={() => shareNews("whatsapp")}>
              <img
                src={images.WhatsAppIconPlaceholder}
                alt="WhatsApp"
                style={{ width: 28, height: 28 }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Copy Link">
            <IconButton onClick={handleCopyLink}>
              <img
                src={images.CopyIconPlaceholder}
                alt="Copy"
                style={{ width: 24, height: 24 }}
              />
            </IconButton>
          </Tooltip>
        </div>
      </DialogContent>
      <DialogActions style={{ justifyContent: "flex-end", padding: "10px" }}>
        <Button
          onClick={onClose}
          color="error"
          style={{
            textTransform: "none",
            fontSize: "1rem",
            color: "#bb0000",
          }}>
          Cancel
        </Button>
      </DialogActions>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        message="Link copied to clipboard"
      />
    </Dialog>
  );
};

export default ShareDialog;
