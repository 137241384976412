import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Fab from "@mui/material/Fab";

const useStyles = makeStyles((theme) => ({
  chatContainer: {
    position: "fixed",
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    zIndex: 9999,
  },

  expandIcon: {
    marginLeft: "auto",
  },
  backToTop: {
    backgroundColor: "#fff",
    color: "#45474B",
    "&:hover": {
      backgroundColor: "#45474B",
      color: "#fff",
    },
  },
}));

const BackToTop = () => {
  const classes = useStyles();
  const [showBackToTop, setShowBackToTop] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (currentScrollY > 200) {
      setShowBackToTop(true);
    } else {
      setShowBackToTop(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className={classes.chatContainer}>
      {showBackToTop && (
        <div>
          <Fab
            sx={{
              backgroundColor: "#fff",
              color: "#45474B",
              "&:hover": {
                backgroundColor: "#45474B",
                color: "#fff",
              },
            }}
            size="small"
            className={classes.backToTop}
            onClick={scrollToTop}>
            <KeyboardArrowUpIcon />
          </Fab>
        </div>
      )}
    </div>
  );
};

export default BackToTop;
