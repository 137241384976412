import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { LoadingButton } from "@mui/lab";
import { useSetPasswordMutation } from "../features/api/apiSlice";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    minWidth: "400px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "300px",
    },
  },
  inputField: {
    marginBottom: theme.spacing(2),
  },
  passwordPolicy: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
  },
  policyItem: {
    fontSize: "0.8rem",
    marginBottom: theme.spacing(1),
  },
  fulfilled: {
    color: "#006738",
  },
  notFulfilled: {
    color: "#FF0000",
  },
}));

const ChangePasswordModal = ({ open, onClose, data, onChangePassword }) => {
  const classes = useStyles();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [passwordErrors, setPasswordErrors] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("authToken"));
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;
  const [
    updatePassword,
    { isLoading: isLoadingUpdate, isError: isErrorUpload },
  ] = useSetPasswordMutation();

  const [email, setEmail] = useState("");
  const [id, setId] = useState("");

  useEffect(() => {
    // Set initial values for email and id
    if (user && Number(user.user_id) === Number(data?.user_id)) {
      setEmail(user.email);
      setId(user.user_id);
    } else {
      onClose();
    }
  }, [user, data]);

  const handleNewPasswordChange = (event) => {
    const newPasswordValue = event.target.value;
    setNewPassword(newPasswordValue);
    validatePassword(newPasswordValue);
  };

  const validatePassword = (password) => {
    const passwordErrors = [];

    // Check each policy individually
    if (password.length >= 8) {
      passwordErrors.push({
        policy: "At least 6 characters long",
        fulfilled: true,
      });
    } else {
      passwordErrors.push({
        policy: "At least 6 characters long",
        fulfilled: false,
      });
    }

    if (/[a-z]/.test(password)) {
      passwordErrors.push({
        policy: "Contains at least one lowercase letter",
        fulfilled: true,
      });
    } else {
      passwordErrors.push({
        policy: "Contains at least one lowercase letter",
        fulfilled: false,
      });
    }

    if (/[A-Z]/.test(password)) {
      passwordErrors.push({
        policy: "Contains at least one uppercase letter",
        fulfilled: true,
      });
    } else {
      passwordErrors.push({
        policy: "Contains at least one uppercase letter",
        fulfilled: false,
      });
    }

    if (/\d/.test(password)) {
      passwordErrors.push({
        policy: "Contains at least one digit",
        fulfilled: true,
      });
    } else {
      passwordErrors.push({
        policy: "Contains at least one digit",
        fulfilled: false,
      });
    }

    if (/[!@#$%^&*?]/.test(password)) {
      passwordErrors.push({
        policy: "Contains at least one special character from @$!%*?&",
        fulfilled: true,
      });
    } else {
      passwordErrors.push({
        policy: "Contains at least one special character from @$!%*?&",
        fulfilled: false,
      });
    }

    setPasswordErrors(passwordErrors);
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    if (
      newPassword === confirmNewPassword &&
      passwordErrors.every((error) => error.fulfilled)
    ) {
      const updateData = {
        userId: user?.user_id,
        newPassword: newPassword,
        currentPassword: currentPassword,
        email: email,
        userType: user?.user_role === "Member" ? "member" : "user",
      };

      try {
        const response = await updatePassword(updateData);
        if (response.data !== "Invalid Credentials") {
          toast("Password changed successfully");
        }
      } catch (error) {
        toast("Password change failed");
      }

      setCurrentPassword("");
      setNewPassword("");
      setConfirmNewPassword("");
      setPasswordErrors([]);
      onClose();
    } else {
      toast(
        "New password and confirmation do not match or password policies are not fulfilled"
      );
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Change Password</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <TextField
          type="password"
          label="Current Password"
          maxLength="20"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          fullWidth
          className={classes.inputField}
        />

        <TextField
          type="password"
          label="New Password"
          value={newPassword}
          maxLength="20"
          onChange={handleNewPasswordChange}
          fullWidth
          className={classes.inputField}
        />

        <TextField
          type="password"
          maxLength="20"
          label="Confirm New Password"
          value={confirmNewPassword}
          onChange={(e) => setConfirmNewPassword(e.target.value)}
          fullWidth
          className={classes.inputField}
        />
        <div className={classes.passwordPolicy}>
          {passwordErrors.map((error, index) => (
            <Typography
              key={index}
              className={`${classes.policyItem} ${
                error.fulfilled ? classes.fulfilled : classes.notFulfilled
              }`}>
              {error.policy}
            </Typography>
          ))}
        </div>
        <LoadingButton
          loading={isLoadingUpdate}
          variant="contained"
          color="success"
          onClick={handleChangePassword}
          style={{ margin: "10px 0px" }}>
          Change Password
        </LoadingButton>
      </DialogContent>
    </Dialog>
  );
};

export default ChangePasswordModal;
