import React, { useState, useEffect } from "react";
import {
  Stack,
  styled,
  Link,
  Grid,
  Avatar,
  InputAdornment,
  FormControl,
  InputLabel,
  Button,
  MenuItem,
  Select,
  Fade,
  Divider,
  FormHelperText,
  Box,
  Chip,
  Autocomplete,
  TextareaAutosize,
  Switch,
} from "@mui/material";
import {
  TextField,
  Typography,
  makeStyles,
  Container,
  Modal,
  Backdrop,
} from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import { colorTags } from "../../../../constants/variants";
import { useStyles } from "../../../../App";
import {
  useGetNewsQuery,
  useUpdateArticlesRequestMutation,
  useUploadFileMutation,
  useGetCategoriesQuery,
  useGetAuthorsQuery,
} from "../../../../features/api/apiSlice";
import { toast } from "react-toastify";
import {
  FILE_DOWNLOAD_URL,
  FILE_LINK_URL,
} from "../../../../constants/apiTags";

const StyledButton = styled(Button)({
  margin: "10px",
  padding: "10px 25px",
  textAlign: "center",
  textTransform: "uppercase",
  transition: "0.5s",
  backgroundSize: "200% auto",
  color: "#F4EAD5",
  borderRadius: "5px",
  display: "block",
  border: "0px",
  fontWeight: 700,
  boxShadow: "0px 0px 14px -7px #006737",
  backgroundImage:
    "linear-gradient(45deg, #006737 0%, #285430  71%, #006737  100%)",
  cursor: "pointer",
  userSelect: "none",
  WebkitUserSelect: "none",
  touchAction: "manipulation",
  "&:hover": {
    backgroundPosition: "right center",
    color: "#fff",
    textDecoration: "none",
  },
  "&:active": {
    transform: "scale(0.95)",
  },
});

const useStyless = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    backgroundColor: "#fff",
    borderRadius: "5px",
    padding: theme.spacing(2),
    boxShadow:
      "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(255,255,255, 0.25) 0px 50px 100px -20px, rgba(255,255,255, 0.3) 0px 30px 60px -30px",
    maxWidth: "80%",
    maxHeight: "97%",
    overflow: "auto",
    outline: "none",
  },
  modalTitle: {
    textAlign: "left",
    marginBottom: theme.spacing(2),
  },
  modalBody: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
  },
  inputFields: {
    flex: "0 0 60%",
    paddingRight: theme.spacing(2),
  },
  settings: {
    flex: "0 0 40%",
    paddingLeft: theme.spacing(2),
  },
  inputField: {
    marginBottom: theme.spacing(2),
  },
  modalActions: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#03AED2",
      },
      "&:hover fieldset": {
        borderColor: "#03AED2",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#03AED2",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#03AED2",
    },
  },
  imagePreview: {
    width: "100%",
    height: "auto",
    maxHeight: "100px",
    maxWidth: "100px",
    marginBottom: "15px",
    cursor: "pointer",
  },
  imagePreviewPlaceholder: {
    width: "100%",
    height: "200px",
    backgroundColor: "#f0f0f0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  tagsContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  tagInputContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}));
const categories = [
  "editorial",
  "economy",
  "agriculture",
  "industry",
  "service",
  "opinions",
  "reports",
  "green-economy",
  "business",
  "climate-change",
  "technology",
  "inclusive-economies",
  "entrepreneurship",
  "wholesale-and-retail-trade",
  "export-import",
  "transport-and-logistics",
  "agribusiness",
  "manufacturing",
  "construction",
  "banking-and-finance",
  "tourism-and-hospitality",
  "companies",
  "education",
  "energy",
  "fisheries-and-aquaculture",
  "health",
  "insurance",
  "features-and-advocacy",
];
const EditArticle = ({ data, open, onClose, onSubmit }) => {
  const classes = useStyless();
  const dropzoneClass = useStyles();
  const [formData, setFormData] = useState({
    title: "",
    subtitle: "",
    paragraph1: "",
    paragraph2: "",
    paragraph3: "",
    // youtubeLink: "",
    language: "en",
    category: "",
    subcategory: "",
  });
  const [image, setImage] = useState(null);
  const [imageToUpload, setImageToUpload] = useState([]);

  const [additionalImage, setAdditionalImage] = useState(null);
  const [additionalImageToUpload, setAdditionalImageToUpload] = useState([]);
  const [additionalImage2, setAdditionalImage2] = useState(null);
  const [additionalImage2ToUpload, setAdditionalImage2ToUpload] = useState([]);
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState("");
  const [token, setToken] = useState(localStorage.getItem("authToken"));
  const [authorID, setAuthorID] = useState();
  const [isFeature, setIsFeature] = useState(null);
  const [youtubeLink, setYoutubeLink] = useState();
  const [iframeSrc, setIframeSrc] = useState("");

  // const authorsData = [
  //   {
  //     id: 1,
  //     name: "John Doe",
  //     email: "john.doe@example.com",
  //     avatar: "images.Avatar",
  //     status: "active",
  //     createdAt: "2023-06-01",
  //     updatedAt: "2023-06-01",
  //   },
  //   {
  //     id: 2,
  //     name: "Jane Smith",
  //     email: "jane.smith@example.com",
  //     avatar: " images.Avatar",
  //     status: "inactive",
  //     createdAt: "2023-06-02",
  //     updatedAt: "2023-06-02",
  //   },
  //   {
  //     id: 3,
  //     name: "Alice Johnson",
  //     email: "alice.johnson@example.com",
  //     avatar: "images.Avatar",
  //     status: "active",
  //     createdAt: "2023-06-03",
  //     updatedAt: "2023-06-03",
  //   },
  //   // More authors...
  // ];

  // const categoriesDataq = [
  //   {
  //     id: 1,
  //     name: "Business",
  //     nameAm: "ንግድ",
  //     subCategories: [
  //       { id: 1, name: "Finance", nameAm: "ፋይናንስ" },
  //       { id: 2, name: "Marketing", nameAm: "ማርኬቲንግ" },
  //     ],
  //   },
  //   {
  //     id: 2,
  //     name: "Technology",
  //     nameAm: "ቴክኖሎጂ",
  //     subCategories: [
  //       { id: 1, name: "Software", nameAm: "ሶፍትዌር" },
  //       { id: 2, name: "Hardware", nameAm: "ሃርድዌር" },
  //     ],
  //   },
  //   {
  //     id: 3,
  //     name: "Health",
  //     nameAm: "ጤና",
  //     subCategories: [
  //       { id: 1, name: "Nutrition", nameAm: "ምግብ" },
  //       { id: 2, name: "Exercise", nameAm: "እንቅስቃሴ" },
  //     ],
  //   },
  // ];
  const [categories, setCategories] = useState([]);

  const {
    data: categoriesData,
    isLoading: isLoadingCategories,
    isError: isErrorCategories,
    error: errorCategoriesData,
  } = useGetCategoriesQuery();

  useEffect(() => {
    if (categoriesData) {
      const allData = [];

      categoriesData?.forEach((category) => {
        // Add the category itself
        allData.push({
          id: category.id,
          name: category.name,
          nameAm: category.nameAm,
        });

        // Add each subCategory within the category
        category?.subcategories?.forEach((subCategory) => {
          allData.push({
            id: subCategory.id,
            name: subCategory.name,
            nameAm: subCategory.nameAm,
          });
        });
      });

      setCategories(allData);
      console.log(categories);
    }
  }, [categoriesData]);

  const {
    data: authorsData,
    isLoading: isLoadingAuthorsData,
    isError: isErrorAuthorsData,
    error: errorAuthorsData,
  } = useGetAuthorsQuery();

  const handleCategoryChange = (event, value) => {
    setFormData((prev) => ({ ...prev, category: value, subcategory: "" }));
  };

  // const handleSubcategoryChange = (event, value) => {
  //   setFormData((prev) => ({ ...prev, subcategory: value }));
  // };

  // const getSubcategories = () => {
  //   const selectedCategory = categoriesData?.find(
  //     (cat) => cat?.name === formData?.category
  //   );
  //   return selectedCategory ? selectedCategory?.subcategories ?? [] : [];
  // };
  const [
    updateArticles,
    {
      isLoading: updateArticlesRequestLoading,
      isError: updateArticlesRequestError,
    },
  ] = useUpdateArticlesRequestMutation();

  useEffect(() => {
    if (data) {
      setFormData({
        title: data.title,
        subtitle: data.subtitle,
        paragraph1: data.paragraph1,
        paragraph2: data.paragraph2,
        paragraph3: data.paragraph3,
        language: data.language,
        category: data.category,

        subcategory: "General",
        tags: data.tags,
      });
      setImage(data.coverImage);
      setAdditionalImage(data.additionalImage);

      setYoutubeLink(data.youtubeLink);
      setAuthorID(data.authorID);
      setIsFeature(data.isFeature);
      // setIframeSrc(data.youtubeLink);
    }
  }, [data]);

  const uploadImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("files", file);

      const response = await fetch(FILE_LINK_URL, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        throw new Error("Image upload failed");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  };

  const handleTagAdd = () => {
    if (tagInput.trim() !== "") {
      setTags((prevTags) => [...prevTags, tagInput.trim()]);
      setTagInput("");
    }
  };

  const handleTagDelete = (tagToDelete) => {
    setTags((prevTags) => prevTags.filter((tag) => tag !== tagToDelete));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const getYoutubeVideoId = (url) => {
    const regExp =
      /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/;
    const match = url.match(regExp);
    return match ? match[1] : null;
  };
  const handleYoutubeLinkChange = (e) => {
    // getYoutubeVideoId(e.target.value)
    const videoId = getYoutubeVideoId(e.target.value);
    setIframeSrc(videoId ? `https://www.youtube.com/embed/${videoId}` : "");
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setImage(reader.result);
      reader.readAsDataURL(file);
      setImageToUpload(file);
    }
  };

  const handleAdditionalImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = () => setAdditionalImage(reader.result);
      reader.readAsDataURL(file);
      setAdditionalImageToUpload(file);
    }
  };
  const handleAdditionalImage2Change = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = () => setAdditionalImage2(reader.result);
      reader.readAsDataURL(file);
      setAdditionalImage2ToUpload(file);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();

    const updateData = {
      id: data.id,
      title: formData.title,
      subtitle: formData.subtitle,
      paragraph1: formData.paragraph1,
      paragraph2: formData.paragraph2,
      paragraph3: formData.paragraph3,
      templateId: data?.templateId,
      // templateId: 6,
      youtubeLink: iframeSrc ?? youtubeLink,
      language: formData.language,
      generalCategory: formData.category,
      specificCategory: formData.subcategory,
      authorID,

      coverImage: data?.coverImage,
      additionalImages: data?.additionalImages,
      tags,
    };

    if (image) {
      try {
        const imageResponse = await uploadImage(imageToUpload);
        updateData.coverImage = imageResponse.filenames[0] ?? data?.coverImage;
      } catch (error) {
        toast.error("Error uploading cover image");
        // return;
      }
    }

    if (additionalImage) {
      try {
        const additionalImageResponse = await uploadImage(
          additionalImageToUpload
        );
        updateData.additionalImages[0] =
          additionalImageResponse?.filenames[0] ?? data.additionalImages[0];
      } catch (error) {
        toast.error("Error uploading additional image");
        // return;
      }
    }
    if (additionalImage2) {
      try {
        const additionalImage2Response = await uploadImage(
          additionalImage2ToUpload
        );
        updateData.additionalImages[1] =
          additionalImage2Response?.filenames[0] ?? data.additionalImages[1];
      } catch (error) {
        toast.error("Error uploading second additional image");
        // return;
      }
    }

    await updateArticles(updateData).then((res) => {
      if (res.data && res.data.message != "Unauthorized") {
        toast.success("Article Updated Successfully");
      } else if (
        res?.data?.message === "Unauthorized" ||
        res?.data.message === "Forbidden"
      ) {
        toast.error("Error Updating Article");
      }
    });

    onClose();
  };

  // const handleTagAdd = () => {
  //   setFormData((prev) => ({
  //     ...prev,
  //     tags: [...prev.tags, prev.tagInput],
  //     tagInput: "",
  //   }));
  // };

  // const handleTagDelete = (tagToDelete) => {
  //   setFormData((prev) => ({
  //     ...prev,
  //     tags: prev.tags.filter((tag) => tag !== tagToDelete),
  //   }));
  // };

  const renderConditionalFields = () => {
    const templateId = data.templateId;
    const fields = [];

    fields.push(
      <TextField
        fullWidth
        label="Title"
        variant="outlined"
        className={classes.inputField}
        InputProps={{ style: { fontSize: "20px" } }}
        name="title"
        value={formData.title}
        onChange={handleChange}
      />
    );

    fields.push(
      <TextField
        fullWidth
        label="Subtitle"
        variant="outlined"
        className={classes.inputField}
        InputProps={{ style: { fontSize: "14px" } }}
        name="subtitle"
        value={formData.subtitle}
        onChange={handleChange}
      />
    );

    fields.push(
      <Box
        className={
          image ? classes.imagePreview : classes.imagePreviewPlaceholder
        }
        onClick={() => document.getElementById("upload-image-input").click()}>
        {image ? (
          <img src={image} alt="Preview" className={classes.imagePreview} />
        ) : (
          <Typography variant="subtitle1" color="textSecondary">
            Click to upload cover image/Thumbnail
          </Typography>
        )}
      </Box>
    );

    fields.push(
      <input
        type="file"
        hidden
        id="upload-image-input"
        onChange={handleImageChange}
      />
    );

    fields.push(
      <TextField
        fullWidth
        label="Paragraph 1"
        variant="outlined"
        multiline
        rows={5}
        className={classes.inputField}
        InputProps={{ style: { fontSize: "13px" } }}
        name="paragraph1"
        value={formData.paragraph1}
        onChange={handleChange}
      />
    );

    if (templateId !== 1 && templateId !== 5) {
      fields.push(
        <Box
          className={
            additionalImage
              ? classes.imagePreview
              : classes.imagePreviewPlaceholder
          }
          onClick={() =>
            document.getElementById("upload-additional-image-input").click()
          }>
          {additionalImage ? (
            <img
              src={additionalImage}
              alt="Preview"
              className={classes.imagePreview}
            />
          ) : (
            <Typography variant="subtitle1" color="textSecondary">
              Additional Image
            </Typography>
          )}
        </Box>
      );

      fields.push(
        <input
          type="file"
          hidden
          id="upload-additional-image-input"
          onChange={handleAdditionalImageChange}
        />
      );
    }

    if (templateId === 4 || templateId === 6) {
      fields.push(
        <Box
          className={
            additionalImage2
              ? classes.imagePreview
              : classes.imagePreviewPlaceholder
          }
          onClick={() =>
            document.getElementById("upload-additional-image-input2").click()
          }>
          {additionalImage2 ? (
            <img
              src={additionalImage2}
              alt="Preview"
              className={classes.imagePreview}
            />
          ) : (
            <Typography variant="subtitle1" color="textSecondary">
              Additional Image
            </Typography>
          )}
        </Box>
      );

      fields.push(
        <input
          type="file"
          hidden
          id="upload-additional-image-input2"
          onChange={handleAdditionalImage2Change}
        />
      );
    }

    fields.push(
      <TextField
        fullWidth
        label="Paragraph 2"
        variant="outlined"
        multiline
        rows={6}
        className={classes.inputField}
        InputProps={{ style: { fontSize: "13px" } }}
        name="paragraph2"
        value={formData.paragraph2}
        onChange={handleChange}
      />
    );

    fields.push(
      <TextField
        fullWidth
        label="Paragraph 3"
        variant="outlined"
        multiline
        rows={7}
        className={classes.inputField}
        InputProps={{ style: { fontSize: "13px" } }}
        name="paragraph3"
        value={formData.paragraph3}
        onChange={handleChange}
      />
    );

    if (templateId !== 1 && templateId !== 2 && templateId !== 4) {
      fields.push(
        <TextField
          fullWidth
          label="YouTube Link"
          variant="outlined"
          className={classes.inputField}
          InputProps={{ style: { color: "blue", fontSize: "14px" } }}
          name="youtubeLink"
          value={iframeSrc}
          onChange={handleYoutubeLinkChange}
        />
      );

      fields.push(
        iframeSrc && (
          <Box className={classes.iframeContainer}>
            <iframe
              width="100%"
              height="100%"
              src={iframeSrc}
              title="YouTube Video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Box>
        )
      );
    }

    return fields;
  };

  const renderSettings = () => (
    <>
      <Typography variant="subtitle1" color="textSecondary">
        Other Settings
      </Typography>

      <TextField
        fullWidth
        label="Language"
        variant="outlined"
        className={classes.inputContainer}
        select
        name="language"
        value={formData.language}
        style={{ marginTop: "15px" }}
        onChange={handleChange}>
        <MenuItem value="en">English</MenuItem>
        <MenuItem value="am">አማርኛ</MenuItem>
      </TextField>

      <FormControl
        variant="outlined"
        fullWidth
        style={{
          marginTop: "15px",
        }}>
        <InputLabel id="author-label">Author</InputLabel>
        <Select
          labelId="author-label"
          value={authorID}
          onChange={(e) => setAuthorID(e.target.value)}
          label="Author">
          {authorsData?.map((author) => (
            <MenuItem key={author?.id} value={author?.id}>
              {author?.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {categoriesData && (
        <Autocomplete
          style={{
            margin: "15px 0px",
          }}
          options={categories?.map((category) => category?.name)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Category"
              variant="outlined"
              className={classes.inputContainer}
            />
          )}
          value={formData.category}
          onChange={handleCategoryChange}
        />
      )}
      {/* <Autocomplete
        style={{}}
        options={getSubcategories().map((subcat) => subcat?.name)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Subcategory"
            variant="outlined"
            className={classes.inputContainer}
          />
        )}
        value={formData.subcategory}
        onChange={handleSubcategoryChange}
        disabled={!formData.category}
      /> */}

      <Box
        className={classes.item}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          marginTop: "10px",
        }}>
        <Typography>is Feature?</Typography>
        <Switch
          checked={isFeature}
          onChange={() => setIsFeature(!isFeature)}
          color="success"
          style={{
            color: colorTags.TERTIARY,
          }}
        />
      </Box>
      <Box className={classes.tagsContainer}>
        {tags?.map((tag, index) => (
          <Chip
            key={index}
            label={tag}
            onDelete={() => handleTagDelete(tag)}
            color="primary"
            style={{
              color: "#000",
              backgroundColor: "#aaaa",
              marginTop: "15px",
              // border: `1px solid ${colorTags.TERTIARY}`,
              border: `1px solid #000`,
            }}
          />
        ))}
      </Box>
      <Box className={classes.tagInputContainer}>
        <TextField
          fullWidth
          label="Tags"
          variant="outlined"
          className={classes.inputContainer}
          value={tagInput}
          onChange={(e) => setTagInput(e.target.value)}
        />
        <Button
          variant="text"
          color="primary"
          onClick={handleTagAdd}
          style={{
            height: "56px",
            borderRadius: "5px",
          }}>
          Add
        </Button>
      </Box>
    </>
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={open}>
        <Container maxWidth="md" className={classes.modalContent}>
          <Typography variant="h6" align="center" gutterBottom>
            Edit Article
          </Typography>
          <form onSubmit={handleSave}>
            <div className={classes.modalBody}>
              <div className={classes.inputFields}>
                {categoriesData ? (
                  renderConditionalFields()
                ) : (
                  <div>Loading Items ...</div>
                )}
              </div>
              <div className={classes.settings}>
                {categoriesData ? (
                  renderSettings()
                ) : (
                  <div>Loading Items ...</div>
                )}
              </div>
            </div>
            <div className={classes.modalActions}>
              <Button
                variant="contained"
                // color="primary"
                style={{
                  backgroundColor: colorTags.TERTIARY,
                  marginRight: "15px",
                }}
                onClick={handleSave}>
                Save
              </Button>
              <Button variant="outlined" color="error" onClick={onClose}>
                Cancel
              </Button>
            </div>
          </form>
        </Container>
      </Fade>
    </Modal>
  );
};

export default EditArticle;
