import { Card } from "@mui/material";
import { Box, styled } from "@mui/system";
// import { useTranslation } from "react-i18next";
// import { Trans, withTranslation } from "react-i18next";
// import { isMobile } from "react-device-detect";
import {
  colorTags,
  primaryColor,
  secondaryColor,
  accentColor,
} from "../constants/variants";

const CardRoot = styled(Card)(() => ({
  height: "100%",
  padding: "20px 24px",
  marginTop: "0%",
  // boxShadow:
  //   "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
  // boxShadow:
  //   "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
  boxShadow:
    "rgba(104, 210, 232, 0.4) 0px 0px 0px 2px, rgba(104, 210, 232, 0.65) 0px 4px 6px -1px, rgba(104, 210, 232, 0.08) 0px 1px 0px inset",
}));

const CardTitle = styled("div")(({ subtitle }) => ({
  fontSize: "1.2rem",
  marginLeft: "43%",
  fontWeight: "400",
  textTransform: "capitalize",
  marginBottom: !subtitle && "16px",
}));

const SimpleCard = ({ children, title, subtitle, icon }) => {
  // const { t, i18n } = useTranslation();

  // if (isMobile)
  //   return (
  //     <CardRoot elevation={6}>
  //       <CardTitle subtitle={subtitle} style={{ marginLeft: "25%" }}>
  //         {title}
  //       </CardTitle>
  //       {subtitle && <Box sx={{ mb: 2 }}>{subtitle}</Box>}
  //       {children}
  //     </CardRoot>
  //   );
  // else
  return (
    <CardRoot elevation={6}>
      <CardTitle subtitle={subtitle} style={{ color: colorTags.PRIMARY }}>
        {title}
      </CardTitle>
      {subtitle && <Box sx={{ mb: 2 }}>{subtitle}</Box>}
      {children}
    </CardRoot>
  );
};

export default SimpleCard;
