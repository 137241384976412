import React, { useState, useEffect } from "react";
import {
  Paper,
  Typography,
  makeStyles,
  Grid,
  IconButton,
  Divider,
} from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import { images } from "../../../assets";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
    boxShadow: "none",
    position: "relative",
    overflow: "hidden",
    width: "100%",
  },
  eventCardContainer: {
    position: "relative",
    width: "100%",
    padding: theme.spacing(4),

    height: "400px",
    overflow: "hidden",
  },
  eventCard: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    width: "100%",
    height: "95%",
    margin: "10px",
    padding: theme.spacing(0),
    // boxShadow: "rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px",
    boxShadow:
      "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",
  },
  eventImage: {
    width: "70%",
    height: "100%",
    objectFit: "cover",
    marginRight: theme.spacing(2),
  },
  eventContent: {
    width: "30%",
    display: "flex",
    flexDirection: "column",
    paddingLeft: "3%",
    paddingTop: "2%",
    // backgroundColor: "red",
    // justifyContent: "center",
    justifyContent: "flex-start",
  },
  eventTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
    textAlign: "left",
    fontSize: "1.5rem",
  },
  eventDescription: {
    marginBottom: theme.spacing(1),
    textAlign: "left",
    fontSize: "1.2rem",
  },
  eventLocation: {
    marginBottom: theme.spacing(1),
    textAlign: "left",
    fontSize: "1.2rem",
  },
  eventDate: {
    color: theme.palette.text.secondary,
    textAlign: "left",
    fontSize: "1.2rem",
  },
  navigationButtons: {
    position: "absolute",
    // top: "50%",
    transform: "translateY(-50%)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: "50%",
    zIndex: 1000,
  },
  arrowIcon: {
    fontSize: "2rem",
  },
}));

const Events = () => {
  const classes = useStyles();
  const [events, setEvents] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    // Fetch events data from API or set it from local data
    const fetchedEvents = [
      {
        id: 1,
        title: "Event 1",
        description: "Description of Event 1",
        location: "Location 1",
        date: "2024-06-20",
        imageUrl: images.EventPagePlaceholder,
      },
      {
        id: 2,
        title: "Event 2",
        description: "Description of Event 2",
        location: "Location 2",
        date: "2024-06-25",
        imageUrl: images.EventPagePlaceholder2,
      },
      {
        id: 3,
        title: "Event 3",
        description: "Description of Event 3",
        location: "Location 3",
        date: "2024-07-01",
        imageUrl: images.EventPagePlaceholder3,
      },
    ];
    setEvents(fetchedEvents);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === events.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000); // Auto switch interval
    return () => clearInterval(interval);
  }, [events.length]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === events.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? events.length - 1 : prevIndex - 1
    );
  };

  return (
    <Paper className={classes.root}>
      <IconButton
        onClick={handlePrev}
        className={`${classes.navigationButtons} ${classes.arrowIcon}`}
        style={{ right: "120px", bottom: "50px" }}>
        <ArrowBackIos />
      </IconButton>
      <IconButton
        onClick={handleNext}
        className={`${classes.navigationButtons} ${classes.arrowIcon}`}
        style={{ right: "60px", bottom: "50px" }}>
        <ArrowForwardIos />
      </IconButton>
      <Divider style={{ marginBottom: "10px" }} />
      <Typography variant="h6" className={classes.eventTitle}>
        Events
      </Typography>
      <div className={classes.eventCardContainer}>
        <div className={classes.eventCard}>
          <img
            src={events[currentIndex]?.imageUrl}
            alt="Event"
            className={classes.eventImage}
          />
          <div className={classes.eventContent}>
            <Typography variant="h6" className={classes.eventTitle}>
              {events[currentIndex]?.title}
            </Typography>
            <Typography className={classes.eventDescription}>
              {events[currentIndex]?.description}
            </Typography>
            <Typography className={classes.eventLocation}>
              {events[currentIndex]?.location}
            </Typography>
            <Typography className={classes.eventDate}>
              {events[currentIndex]?.date}
            </Typography>
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default Events;
