import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Chip, Grid, Typography, Link } from "@material-ui/core";
import {
  Facebook,
  YouTube,
  Telegram,
  Twitter,
  LinkedIn,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: "#F3EEEA",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    backdropFilter: "blur(10px)",
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(15),
    color: "#423F3E",
  },
  overlay: {
    content: '""',
    width: "100%",
    height: "100%",
    backdropFilter: "blur(10px)",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: -1,
    background: "rgba(0, 0, 0, 0.0)",
  },
  tagCard: {
    width: "100%",
    height: "80px",
    border: "1px solid #D0D4CA",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: theme.spacing(5),
    marginBottom: theme.spacing(0),
    marginTop: "20px",
  },
  chip: {
    margin: theme.spacing(0.5),
    border: "1px solid grey",
    color: "black",
    backgroundColor: "white",
    fontStyle: "italic",
  },
  socialCard: {
    width: "50%",
    height: "80px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    margin: theme.spacing(0, "auto", 3),
    marginTop: "-40px",
    marginBottom: "0px",
    zIndex: 1,
    position: "relative",
    backgroundColor: "#000",
    borderRadius: "8px",
    padding: theme.spacing(0, 2),
  },
  socialIcon: {
    color: "#fff",
    margin: theme.spacing(0, 1),
    cursor: "pointer",
    transition: "color 0.3s",
    "&:hover": {
      color: "#028391",
    },
  },
  socialIconYoutube: {
    color: "#fff",
    margin: theme.spacing(0, 1),
    cursor: "pointer",
    transition: "color 0.3s",
    "&:hover": {
      color: "#ff0000",
    },
  },
  contactInfo: {
    color: "#fff",
  },
  miniFooter: {
    backgroundColor: "#b1b1b1",
    padding: theme.spacing(1),
    color: "#000",
    height: "80px",
    marginTop: "-40px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    borderRadius: "0px",
  },
  links: {
    display: "flex",
    alignItems: "center",
  },
  link: {
    margin: theme.spacing(0, 1),
    color: "#000",
  },
}));

const Footer = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <>
      <Card className={classes.tagCard}>
        {["#Economy", "#AI", "#Election2024", "#Ethiopia", "#Industry"].map(
          (tag, index) => (
            <Chip key={index} label={tag} className={classes.chip} />
          )
        )}
      </Card>

      <footer className={classes.footer}>
        <div className={classes.overlay}></div>
        <Grid container spacing={3}>
          <Grid item xs={10} sm={4} md={2}>
            <Typography variant="h6" className={classes.company}>
              ACBN-Digital
            </Typography>
            <Typography variant="body2" className={classes.aboutUsText}>
              {t(
                "Empowering Ethiopia with insightful news, stories, information. Your trusted source for comprehensive media coverage."
              )}
            </Typography>
          </Grid>

          <Grid
            item
            xs={10}
            sm={4}
            md={2}
            style={{ borderLeft: "1px solid #343A40" }}>
            <Typography variant="h6">{t("Editorial")}</Typography>
            <ul className={classes.contentLinksList}>
              <li>
                <Link href="/news-category/economy" color="inherit">
                  {t("Economy")}
                </Link>
                <ul className={classes.contentLinksList}>
                  <li>
                    <Link href="/news-category/agriculture" color="inherit">
                      {t("Agriculture")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/news-category/industry" color="inherit">
                      {t("Industry")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/news-category/service" color="inherit">
                      {t("Service")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/news-category/green-economy" color="inherit">
                      {t("Green Economy")}
                    </Link>
                    <ul className={classes.contentLinksList}>
                      <li>
                        <Link
                          href="/news-category/climate-change"
                          color="inherit">
                          {t("Climate Change")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          href="/news-category/technology-impact"
                          color="inherit">
                          {t("Technology")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          href="/news-category/inclusive-economies"
                          color="inherit">
                          {t("Inclusive Economies")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </Grid>

          <Grid
            item
            xs={10}
            sm={4}
            md={2}
            style={{ borderLeft: "1px solid #343A40" }}>
            <Typography variant="h6">{t("Business News")}</Typography>
            <ul className={classes.contentLinksList}>
              <li>
                <Link href="/news-category/entrepreneurship" color="inherit">
                  {t("Entrepreneurship")}
                </Link>
              </li>
              <li>
                <Link href="/news-category/wholesale-retail" color="inherit">
                  {t("Wholesale and Retail Trade")}
                </Link>
              </li>
              <li>
                <Link href="/news-category/export-import" color="inherit">
                  {t("Export-Import")}
                </Link>
              </li>
              <li>
                <Link href="/news-category/transport-logistics" color="inherit">
                  {t("Transport and Logistics")}
                </Link>
              </li>
              <li>
                <Link href="/news-category/agribusiness" color="inherit">
                  {t("Agribusiness")}
                </Link>
              </li>
              <li>
                <Link href="/news-category/manufacturing" color="inherit">
                  {t("Manufacturing")}
                </Link>
              </li>
              <li>
                <Link href="/news-category/construction" color="inherit">
                  {t("Construction")}
                </Link>
              </li>
              <li>
                <Link href="/news-category/banking-finance" color="inherit">
                  {t("Banking and Finance")}
                </Link>
              </li>
              <li>
                <Link href="/news-category/tourism-hospitality" color="inherit">
                  {t("Tourism and Hospitality")}
                </Link>
              </li>
              <li>
                <Link href="/news-category/companies" color="inherit">
                  {t("Companies")}
                </Link>
              </li>
              <li>
                <Link href="/news-category/education" color="inherit">
                  {t("Education")}
                </Link>
              </li>
              <li>
                <Link href="/news-category/energy" color="inherit">
                  {t("Energy")}
                </Link>
              </li>
              <li>
                <Link
                  href="/news-category/fisheries-aquaculture"
                  color="inherit">
                  {t("Fisheries and Aquaculture")}
                </Link>
              </li>
              <li>
                <Link href="/news-category/health" color="inherit">
                  {t("Health")}
                </Link>
              </li>
              <li>
                <Link href="/news-category/insurance" color="inherit">
                  {t("Insurance")}
                </Link>
              </li>
            </ul>
          </Grid>

          <Grid
            item
            xs={10}
            sm={4}
            md={2}
            style={{ borderLeft: "1px solid #343A40" }}>
            <Typography variant="h6">{t("Features and Advocacy")}</Typography>
            <ul className={classes.contentLinksList}>
              <li>
                <Link href="/news-category/opinions" color="inherit">
                  {t("Opinions")}
                </Link>
              </li>
              <li>
                <Link href="/news-category/reports" color="inherit">
                  {t("Reports")}
                </Link>
              </li>
              <li>
                <Link href="/news-category/addis-chamber-tv" color="inherit">
                  {t("Addis Chamber TV")}
                </Link>
              </li>
              <li>
                <Link
                  href="/news-category/addis-chamber-podcast"
                  color="inherit">
                  {t("Addis Chamber Podcast")}
                </Link>
              </li>
              <li>
                <Link href="/news-category/events" color="inherit">
                  {t("Events")}
                </Link>
              </li>
            </ul>
          </Grid>
        </Grid>
      </footer>
      <Card className={classes.socialCard}>
        <div>
          <a
            href="https://www.facebook.com"
            target="_blank"
            rel="noopener noreferrer">
            <Facebook className={classes.socialIcon} />
          </a>
          <a
            href="https://www.youtube.com"
            target="_blank"
            rel="noopener noreferrer">
            <YouTube className={classes.socialIconYoutube} />
          </a>
          <a
            href="https://telegram.org"
            target="_blank"
            rel="noopener noreferrer">
            <Telegram className={classes.socialIcon} />
          </a>
          <a
            href="https://www.twitter.com"
            target="_blank"
            rel="noopener noreferrer">
            <Twitter className={classes.socialIcon} />
          </a>
          <a
            href="https://www.linkedin.com"
            target="_blank"
            rel="noopener noreferrer">
            <LinkedIn className={classes.socialIcon} />
          </a>
        </div>
        <div className={classes.contactInfo}>
          <Typography variant="body2">
            {t("Email")}: info@acbn-digital.com
          </Typography>
          <Typography variant="body2">
            {t("Phone")}: +251-123-456-789
          </Typography>
        </div>
      </Card>
      <Card className={classes.miniFooter}>
        <div>
          <Typography variant="body2">
            <Link href="http://www.arcict.net" target="_blank" color="inherit">
              © AR C ICT Technologies
            </Link>
          </Typography>
        </div>
        <div>
          <Typography variant="body2">
            Addis Ababa Chamber of Commerce and Sectoral Associations
          </Typography>
        </div>
        <div className={classes.links}>
          <Link href="/privacy-policy" color="inherit" className={classes.link}>
            {t("Privacy Policy")}
          </Link>
          <Link href="/contact-us" color="inherit" className={classes.link}>
            {t("Contact")}
          </Link>
        </div>
      </Card>
    </>
  );
};

export default Footer;
