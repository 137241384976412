import React, { useState } from "react";
import {
  DataGrid,
  GridToolbarQuickFilter,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles";

// Custom styles using makeStyles from @mui/styles
const useStyles = makeStyles((theme) => ({
  dataGrid: {
    "& .MuiDataGrid-root": {
      border: "none",
      fontFamily: "'Roboto', sans-serif",
    },
    "& .MuiDataGrid-cell": {
      color: "#333",
      fontSize: "0.875rem",
    },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#f5f5f5",
      color: "#333",
      fontSize: "1rem",
      fontWeight: "bold",
    },
    "& .MuiDataGrid-footerContainer": {
      backgroundColor: "#f5f5f5",
    },
    "& .MuiDataGrid-toolbarContainer": {
      backgroundColor: "#fafafa",
      padding: theme.spacing(1),
      justifyContent: "space-between",
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
  },
  quickFilter: {
    margin: theme.spacing(1),
  },
}));

function QuickSearchToolbar() {
  const classes = useStyles();
  return (
    <GridToolbarContainer className={classes.quickFilter}>
      <GridToolbarQuickFilter />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default function GridData({ rows, columns, ...rest }) {
  const classes = useStyles();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });

  return (
    <Box sx={{ width: "100%" }} className={classes.dataGrid}>
      <DataGrid
        autoHeight
        rows={rows}
        columns={columns}
        disableDensitySelector
        components={{
          Toolbar: QuickSearchToolbar,
        }}
        componentsProps={{
          filterPanel: {
            disableAddFilterButton: true,
            disableRemoveAllButton: true,
          },
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        initialState={{
          pagination: {
            pageSize: 10,
          },
          filter: {
            items: [],
          },
        }}
        rowsPerPageOptions={[5, 10, 25]}
        filterModel={{
          items: [],
        }}
        {...rest}
      />
    </Box>
  );
}
