import {  useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { useEffect } from "react";
// import Box from "@mui/material/Box";
// import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
// import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route,Router, Routes, Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { initializeApp } from "firebase/app";
// import { Stack } from "@mui/system";
// import NavBar from "./components/layouts/NavBar";
// import SideBar from "./components/layouts/SideBar";
// import Loading from "./components/Loading";
// import { checkToken, getUserById } from "./features/auth/authSlice";
// import { ProtectedRoute } from "./helper/protectedRoute";

// import FirstTimeLoginPage from "./pages/LoginPage/FirstTimeLoginPage";
// import LoginPage from "./pages/LoginPage/LoginPage";
// import LogsList from "./pages/LogsPage/LogsList";
// import MessagePage from "./pages/MessagePage/MessagePage";
// import NotificationList from "./pages/NotificationPage/NotificationList";
// import ProfilePage from "./pages/Profile/ProfilePage";


// import AboutUs from "./pages/Home/AboutUs/AboutUs"
// import Services from "./pages/Home/Services/ServicesPage"
// import Repository from "./pages/Home/Repository/Repository"
// Dashboard Pages
import DashboardLayout from "./components/layouts/DashboardLayout";
import DashboardPage from "./pages/DashboardPage/DashboardPage";
// import ProductPage from "./pages/DashboardPage/Export/Products/ProductsPage"
// import  DestinationsPage from "./pages/DashboardPage/Export/Destinations/DestinationsPage"
// import ProceduresPage from "./pages/DashboardPage/Export/Procedures/ProceduresPage"
// import InstitutionsPage from "./pages/DashboardPage/Export/Institutions/InstitutionsPage"
// import DocumentsPage from "./pages/DashboardPage/Export/Documents/DocumentsPage";

import NewsPage from "./pages/DashboardPage/Posts/News/NewsPage";
// import AddNews from "./pages/DashboardPage/Posts/News/AddNews";

import PublicationsPage from "./pages/DashboardPage/Posts/Publications/PublicationsPage";
import  EventsPage from "./pages/DashboardPage/Posts/Events/EventsPage";
// import CustomImages from "./pages/DashboardPage/CustomImages/ImagesDash"

// import ProductDetail from "./pages/DashboardPage/Export/Products/ProductDetail";
// import ProceduresPageDash from "./pages/DashboardPage/Export/Procedures/ProceduresPage";
// import ProceduresList from "./pages/DashboardPage/Export/Procedures/ProceduresList";
// import AddProcedure from "./pages/DashboardPage/Export/Procedures/AddProcedure";

// import UserDetail from "./pages/DashboardPage/Profile/ProfilePage";
import UserEdit from "./pages/DashboardPage/Profile/EditProfile";



// import ExportPage from "./pages/Home/Export/ExportPage";


// import ChatPage from "./pages/DashboardPage/Chat/ChatPage";

// import SponsorsPage from './pages/DashboardPage/Sponsors/SponsorsPage';
// import MembersPage from './pages/DashboardPage/Members/Members';
// import ProfilePage from './pages/Home/Profile/ProfilePage'

import UserDetail from "./pages/DashboardPage/Settings/Users/UserDetail";
import UsersPage from "./pages/DashboardPage/Settings/Users/UsersPage";
import UserRolesPage from "./pages/DashboardPage/Settings/UserRoles/UserRolesPage";
import AuthorsPage from "./pages/DashboardPage/Settings/Authors/AuthorsPage";
import CategoriessPage from "./pages/DashboardPage/Settings/Categories/CatagoriesPage";
import YouTubePlaylistPage from "./pages/DashboardPage/SocialsDashboard/YouTubePlaylist/YouTubePlaylistPage";
// import NotificationsPage from "./pages/DashboardPage/Settings/Notifications/Notifications";

import LogsPage from "./pages/DashboardPage/Logs/LogsPage";
// import FlagsPage from "./pages/DashboardPage/Reports/FlagsPage";

import DashProfilePage from "./pages/DashboardPage/Profile/ProfilePage";
// import FaqDash from "./pages/DashboardPage/FAQ/FaqPage";
// import LinksDash from "./pages/DashboardPage/Export/Links/LinksDash"




import NotificationsDash from "./pages/NotificationPage/NotificationList";


import Login from "./pages/Sessions/Login";
// import SignUp from "./pages/Sessions/Signup";
import ForgotPassword from "./pages/Sessions/ForgotPassword";
import PasswordChange from "./pages/Sessions/NewPassword";
import Authentication from "./pages/Sessions/AuthenticationPage";
import VerifyCode from "./pages/Sessions/VerifyCode";



// import { AuthProvider } from "./features/auth/authContext";
// import Procedures from "./pages/Home/Procedures/ProceduresPage";
// import FAQPage from "./pages/Home/FAQ/FaqPage";
// import LogisticsPage from "./pages/Home/Logistics/LogisticsPage";

// import PostsNews from "./pages/Home/NewsBlogs/NewsPage"
// import PostsPublications from "./pages/Home/NewsBlogs/PublicationsPage"
// import PostsEvents from "./pages/Home/NewsBlogs/EventsPage"

// import Chat from "./pages/Home/Chat/Chat"
// import MembersPageUser from "./pages/Home/Members/MembersPage"
// import ResourcesPage from "./pages/Home/Resources/ResourcesPage"



// import PostsPage from "./pages/Home/Posts/PostsPage";
// import PostsPage from "./pages/Home/Posts/PostsPage";
// import PostsPage2 from "./pages/Home/Posts/PostsPage2";
// import Sponsorship from "./pages/Home/Sponsors/RequestSponsorship"
// import DestinationPage from "./pages/Home/Destinations/DestinationPage";
// import Notifications from "./pages/NotificationPage/Notifications";
// import TOA from "./components/TermsOfAgreement"
// import CreateProfilePage from "./pages/Home/Profile/CreateProfilePage";
// import EditProfilePage from "./pages/Home/Profile/EditProfilePage";
// import WomenInExport from "./pages/Home/WomenInExport/WomenInExport";
import Error404 from "./pages/Home/404";

// import SearchResultsPage from "./pages/Home/Search/SearchResultsPage"
// import SearchResultsRepo from "./pages/Home/Search/Repository"


// import { UserProvider } from "./features/auth2/UserContext";
// import {Switch } from 'react-router-dom';
// import CountDown from './components/CountDown'



// new links arthy
// import NewArticle from './pages/DashboardPage/Posts/News/AddNews'
// import ArticleTemplates from './pages/DashboardPage/Posts/News/ArticleTemplates'

// import SponsorsPageDash from './pages/DashboardPage/Posts/Sponsors/SponsorsPage'
// import SocialsDashboard from './pages/DashboardPage/SocialsDashboard/SocialsDash'

// import CategoryPage from './pages/Home/NewsComponents/CategoryPages/CategoryPage'
// import ArticleDetailPage from './pages/Home/NewsComponents/NewsArticlePages/ArticleDetailPage'

// import SearchResultsPage from './pages/Home/SearchPage/SearchResultsPage'
// import Home from "./pages/Home/Home";


import NewArticle from './pages/DashboardPage/Posts/News/AddNews'
import ArticleTemplates from './pages/DashboardPage/Posts/News/ArticleTemplates'

import SponsorsPageDash from './pages/DashboardPage/Posts/Sponsors/SponsorsPage'
import SocialsDashboard from './pages/DashboardPage/SocialsDashboard/SocialsDash'

import CategoryPage from './pages/Home/NewsComponents2/CategoryPages/CategoryPage'
import ArticleDetailPage from './pages/Home/NewsComponents2/NewsArticlePages/ArticleDetailPage'
import ContactUsPage from "./pages/Home/ContactUs/ContactUsPage2";

import SearchResultsPage from './pages/Home/SearchPage/SearchResultsPage2'
import HomePage2 from "./pages/Home/Home";
import MediaPage from "./pages/Home/MediaPage/MediaPage"
import EventsPageHome from "./pages/Home/EventsPage/EventsPage";
import Home from "./pages/Home/Home2";


const theme = createTheme({
  palette: {
    background: {
      default: "#EEEEEE",
    },
    primary: {
      main: "#006738",
    },
    secondary: {
      main: "#4BCDC4",
    },
    error: {
      main: "#FF6B6B",
    },
    customer: {
      main: "##ffd500",
    },
  },
  dropzone: {
    minHeight: "120px !important",
    border: "1px dashed #000",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    
  },
});

export const useStyles = makeStyles(() => ({
  // dropZone: {
  //   minHeight: "50px !important",
  // },
  dropZone: {
    "& p": { margin: "5px", fontSize: 18 },
    "& svg": { height: "30px", width: "30px" },
    minHeight: "50px !important",
  },
}));


// <!-- Matomo Tag Manager -->
// <script>
//   var _mtm = window._mtm = window._mtm || [];
//   _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
//   (function() {
//     var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
//     g.async=true; g.src='http://64.227.144.151:8081/js/container_JYolJ8yM.js'; s.parentNode.insertBefore(g,s);
//   })();
// </script>
// <!-- End Matomo Tag Manager -->
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  // padding: theme.spacing(0, 1),
  // ...theme.mixins.toolbar,
}));


// App.js


function App() {
  // const dispatch = useDispatch();
  // const { user, isTokenExpired, isCheckTokenLoading } = useSelector(
  //   (state) => state.auth
  // );
  // useEffect(() => {
  
  //   // Add the trending container script for tracking article views
  //   const script = document.createElement("script");
  //   script.async = true;
  //   script.src = "http://64.227.144.151:8081/js/container_sb3JlbeF.js";
  //   document.body.appendChild(script);
  //   var _mtm = window._mtm = window._mtm || [];
  //       _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
  //       (function() {
  //         var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
  //         g.async = true; 
  //         g.src = 'http://64.227.144.151:8081/js/container_sb3JlbeF.js'; 
  //         s.parentNode.insertBefore(g, s);
  //       })();
  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);
// useEffect(() => {
//     // var _mtm = window._mtm = window._mtm || [];
//     // _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
//     // (function() {
//     //   var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
//     //   g.async = true; 
//     //   g.src = 'http://64.227.144.151:8081/js/container_JYolJ8yM.js'; 
//     //   s.parentNode.insertBefore(g, s);
//     // })();


//     // var _mtm = window._mtm = window._mtm || [];
//     // _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
//     // (function() {
//     //   var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
//     //   g.async=true; g.src='http://64.227.144.151:8081/js/container_sb3JlbeF.js'; s.parentNode.insertBefore(g,s);
//     // })();

//     var _paq = window._paq = window._paq || [];
//     /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
//     _paq.push(['trackPageView']);
//     _paq.push(['enableLinkTracking']);
//     (function() {
//       var u="//64.227.144.151:8081/";
//       _paq.push(['setTrackerUrl', u+'matomo.php']);
//       _paq.push(['setSiteId', '1']);
//       var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
//       g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
//     })();


//        var _mtm = window._mtm = window._mtm || [];
//        _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
//        (function() {
//          var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
//          g.async=true; g.src='http://64.227.144.151:8081/js/container_JYolJ8yM.js'; s.parentNode.insertBefore(g,s);
//        })();


//   }, []);
  
  
  <script>
 
  </script>
 

  const [openDrawer, setOpenDrawer] = useState(true);

  const handleDrawerOpen = () => setOpenDrawer(true);
  const handleDrawerClose = () => setOpenDrawer(false);


  const [token, setToken] = useState(localStorage.getItem("authToken"));
  const storedUser = localStorage.getItem("user");
  // const user = storedUser ? JSON.parse(storedUser) : null;
  const user = storedUser ? storedUser : null;
  // const token = useSelector((state) => state.auth.accessToken);


  // const handleLogin = (token) => {
  //   // Store the token in local storage
  //   localStorage.setItem("token", token);
  //   setToken(token);
  // };

  // useEffect(() => {
  //   dispatch(checkToken());
  //   user && dispatch(getUserById(user?.userAccountId));
  // }, []);

  // if (isCheckTokenLoading) return <Loading />;

  // const [token, setToken] = useState(localStorage.getItem("token"));

  // const handleLogin = (token) => {
  //   localStorage.setItem("token", token);
  //   setToken(token);
  // };



  return (
    <BrowserRouter>
    
          <ToastContainer theme="colored" />
        
      <Routes>
       
      
        {/* <Route path="/signup" element={<SignupPage />} /> */}

   
         <Route path="/dashboard/*" element={user&& token? (
        <DashboardLayout />
      ) : (
        <Login/>
      )}>

{/* <Route path="/dashboard/*" element={
        <DashboardLayout />
     }> */}

          {/* <AuthProvider></AuthProvider> */}
          <Route path="" element={<DashboardPage />} />
          {/* <Route path="products" element={<ProductPage />} /> */}
          {/* <Route path="destinations" element={<DestinationsPage />} /> */}
          {/* <Route path="procedures" element={<ProceduresPage />} /> */}
          {/* <Route path="institutions" element={<InstitutionsPage />} />
          <Route path="documents" element={<DocumentsPage />} />  
        <Route path="promotion" element={<SponsorsPage />} />
        <Route path="add-news" element={<AddNews />} /> */}
        
{/* <Route path="custom-images" element={<CustomImages/>}/> */}
{/* <Route path="chats" element={<ChatPage/>}/> */}
{/* <Route path="product-detail/:id" element={<ProductDetail/>}/> */}
{/* <Route path="procedures/:id" element={<ProceduresPageDash/>}/> */}
{/* <Route path="procedures-list" element={<ProceduresList/>}/> */}
{/* <Route path="add-procedure/:id" element={<AddProcedure/>}/> */}
{/* <Route path="members" element={<MembersPage/>}/> */}
{/* <Route path="faq" element={<FaqDash/>}/> */}
<Route path="authors" element={<AuthorsPage/>}/>
<Route path="categories" element={<CategoriessPage/>}/>
<Route path="youTube-playlist" element={<YouTubePlaylistPage/>}/>


<Route path="users" element={<UsersPage/>}/>
<Route path="user-roles" element={<UserRolesPage/>}/>
<Route path="logs" element={<LogsPage/>}/>
{/* <Route path="flags" element={<FlagsPage/>}/> */}
<Route path="notifications" element={<NotificationsDash/>}/>
{/* <Route path="profile-detail/:id" element={<DashProfilePage/>}/> */}


{/* <Route path="edit-profile/:id" element={<UserEdit/>}/> */}

{/* <Route path="links" element={<LinksDash/>}/> */}

{/* <Outlet /> */}
{/* new links arthy */}
<Route path="new-article" element={<NewArticle/>}/>
<Route path="article-template/:id" element={<ArticleTemplates/>}/>
<Route path="sponsors" element={<SponsorsPageDash/>}/>
<Route path="socials" element={<SocialsDashboard/>}/>
<Route path="news" element={<NewsPage />} />
<Route path="user-detail/:userIdentifier" element={<UserDetail/>}/>

<Route path="profile-detail" element={<DashProfilePage/>}/>
<Route path="edit-profile" element={<UserEdit/>}/>
        <Route path="events" element={<EventsPage />} />
        <Route path="publications" element={<PublicationsPage />} />

        </Route>

{/* new links for arthy */}
        <Route path="news-category/:title" element={<CategoryPage/>} />
{/* <Route path="news-detail" element={<ArticleDetailPage/>}/> */}
<Route path="news-detail/:articleIdentifier" element={<ArticleDetailPage/>}/>


<Route path="/search" element={<SearchResultsPage/>} />

        {/* User-Side Pages */}
        <Route path="*" element={<Error404 />} />
        <Route path="/" element={<Home />} />
        {/* <Route path="/about-us" element={<AboutUs />} /> */}
        {/*
        <Route path="/export/procedures/:id" element={<Procedures />} />


        <Route path="/services" element={<Services />} />
        <Route path="/repository/:id" element={<Repository />} />
        <Route path="/repository/search/:searchBy/:query/:id" element={ <SearchResultsRepo
          />} />
           <Route path="/repository/search/:searchBy/:query" element={ <SearchResultsRepo
          />} />
  <Route path="/repository/:searchBy/:query" element={ <SearchResultsRepo
          />} />
        <Route path="/repository" element={<Repository />} />

        <Route path="/export/:id" element={<ExportPage />} />
        <Route path="/export" element={<ExportPage />} />

        <Route path="/faq" element={<FAQPage />} />
        <Route path="/logistics/:id" element={<LogisticsPage />} />
        <Route path="/logistics" element={<LogisticsPage />} /> */}

        {/* <Route path="/profile" element={<ProfilePage/>}/> */}
        {/* <Route path="/profile/:id" element={<ProfilePage/>}/>


        <Route path="/posts/news/:id" element={<PostsNews/>}/>
        <Route path="/posts" element={<PostsPage/>}/>




        <Route path="/posts/:section" element={<PostsPage2/>}/> */}


        {/* <Route path="/posts/publications/1" element={<PostsPublications/>}/> */}
        {/* <Route path="/posts/events/:id" element={<PostsEvents/>}/>
        <Route path="/chat" element={<Chat/>}/>
        <Route path="/business-matching" element={<MembersPageUser/>}/>
        <Route path="/resources" element={<ResourcesPage/>}/> */}
        {/* <Route path="/promotion" element={<Sponsorship/>}/> */}

        {/* <Route path="/promotion" element={user&& user?.role_name ==="Member" ? (
        <Sponsorship />
      ) : (
        <Login/>
      )}/> */}



        {/* <Route
        path="/search-results/:searchBy/:query"
        element={
          <SearchResultsPage
          />
        }
      /> */}
{/* <Route path="/edit-profile" element={<EditProfilePage/>}/> */}
{/* <Route path="/edit-profile/:id" element={<EditProfilePage/>}/> */}

{/* <Route path="/edit-profile/:id" element={<EditProfilePage/>}/> */}


      
        <Route path="/login" element={<Login />}
       />
               {/* <Route path="/signup" element={<SignUp />} /> */}

        <Route path="/forgot-password" element={<ForgotPassword />} />
        {/* <Route path="/password-change" element={<Login />} /> */}
        <Route path="/verify-code" element={<VerifyCode />} />

        <Route path="/password-change" element={<PasswordChange />} />
        <Route path="/hometwo" element={<HomePage2/>}/>
        <Route path="/contact-us" element={<ContactUsPage />} />
        <Route path="/addis-chamber-media" element={<MediaPage/>}/>
        <Route path="/events" element={<EventsPageHome/>}/>
        {/* <Route path="/launch" element={<CountDown />} /> */}

        

      {/* WOMEN IN EXPORT PAGE  --  FOR FUTURE UPDATE OF CONTENT */}
{/* <Route path="women-in-export" element={<WomenInExport/>}/> */}

{/* TERMS OF AGREEMENT PAGE -- For future content update */}
{/* <Route path="/terms-of-agreement" element={<TOA/>}/> */}
       

{/* DESTINATIONS PAGE  --  FOR FUTURE USE, UPDATE CONTENT AND DATA FETCHING FROM BACKEND (API/ GET DESTINATIONS) */}
{/* <Route path="/destinations" element={<DestinationPage/>}/> */}


{/* SIGN UP PAGE  --  FOR FUTURE USE, SIGN UP FUNCTIONALITY FOR FUTURE USE MAY APPLY, TO USE ALONG |CREATEPROFILE PAGE|*/}
{/* <Route path="/signup" element={<Login />} /> */}


{/* CREATE PROFILE PAGE  --  FOR FUTURE USE, ONCE MEMBERS CAN SIGN UP, SHOULD BE REDIRECTED TO AFTER SIGN UP */}
{/* <Route path="/create-profile" element={<CreateProfilePage/>}/> */}

{/* NOTIFICATIONS PAGE  --  FOR FUTRE USE, ONCE NOTIFICATION FUNCTIONALITY IS FULLY IMPLEMENTED WITH ADVANCED USE CASES */}
{/* <Route path="/notifications" element={<Notifications/>}/> */}



    
{/* s*/}
      </Routes>
      

  </BrowserRouter>
  );
}

export default App;
