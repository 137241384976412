import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";

import { Info } from "@mui/icons-material";
// import { useSelector } from "react-redux";
import { SimpleCard } from "../../../../components";
import { primaryColor, colorTags } from "../../../../constants/variants";
import GridData from "../../../../components/GridData";
import { useNavigate } from "react-router-dom";
import { images } from "../../../../assets/index";
import AddPlaylist from "./AddPlaylist";
import EditPlaylist from "./EditPlaylist";
import EditIcon from "@mui/icons-material/Edit";
import {
  useDeleteYoutubePlaylistRequestMutation,
  useGetYoutubePlaylistQuery,
} from "../../../../features/api/apiSlice";
import DeleteDialog from "../../../../components/DeleteDialog";
import AddIcon from "@mui/icons-material/Add";
import { BASE_FILE_URL } from "../../../../constants/apiTags";

const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
  },
}));
// const playlistsData = [
//   {
//     id: 1,
//     title: "Playlist 1",
//     link: "https://youtube.com/playlist1",
//     thumbnail: "/thumbnails/playlist1.jpg",
//     length: "10 videos",
//     createdAt: "2024-07-09T01:18:47.048Z",
//   },
//   {
//     id: 2,
//     title: "Playlist 2",
//     link: "https://youtube.com/playlist2",
//     thumbnail: "/thumbnails/playlist2.jpg",
//     length: "15 videos",
//     createdAt: "2024-07-09T01:18:47.048Z",
//   },
//   {
//     id: 3,
//     title: "Playlist 3",
//     link: "https://youtube.com/playlist3",
//     thumbnail: "/thumbnails/playlist3.jpg",
//     length: "8 videos",
//     createdAt: "2024-07-09T01:18:47.048Z",
//   },
// ];
export default function YouTubePlaylistPage() {
  const [token, setToken] = useState(localStorage.getItem("authToken"));

  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => setModalOpen(false);
  const handleModalOpen = () => setModalOpen(true);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const {
    data: playlistsData,
    isLoading: isLoadingPlaylistsData,
    isError: isErrorPlaylistsData,
    error: errorPlaylistsData,
  } = useGetYoutubePlaylistQuery();

  // const playlistsData = [
  //   {
  //     id: 1,
  //     title: "Playlist 1",
  //     link: "https://youtube.com/playlist1",
  //     thumbnail: "/thumbnails/playlist1.jpg",
  //     length: "10 videos",
  //     createdAt: "2024-07-09T01:18:47.048Z",
  //   },
  //   {
  //     id: 2,
  //     title: "Playlist 2",
  //     link: "https://youtube.com/playlist2",
  //     thumbnail: "/thumbnails/playlist2.jpg",
  //     length: "15 videos",
  //     createdAt: "2024-07-09T01:18:47.048Z",
  //   },
  //   {
  //     id: 3,
  //     title: "Playlist 3",
  //     link: "https://youtube.com/playlist3",
  //     thumbnail: "/thumbnails/playlist3.jpg",
  //     length: "8 videos",
  //     createdAt: "2024-07-09T01:18:47.048Z",
  //   },
  // ];

  const columns = [
    {
      field: "id",
      headerName: "No.",
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "thumbnail",
      headerName: "Thumbnail",
      flex: 1,
      renderCell: (params) => (
        <img
          src={BASE_FILE_URL + params.row.thumbnail}
          alt={params.row.title}
          style={{ width: "30px", height: "30px", borderRadius: "5%" }}
        />
      ),
    },
    {
      field: "title",
      headerName: "Title",
      flex: 2,
    },
    {
      field: "link",
      headerName: "Link",
      flex: 2,
    },
    {
      field: "length",
      headerName: "Length",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      type: "date",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: 1,
      align: "left",
      renderCell: (params) => {
        return (
          <div className="flex">
            <IconButton
              onClick={() => {
                setEditModalOpen(true);
                setSelectedRow(params.row);
              }}>
              <EditIcon color="primary" />
            </IconButton>
            <DeleteDialog
              api={useDeleteYoutubePlaylistRequestMutation}
              dialogLabel="Remove Playlist Item"
              id={params.row.id}
              name={`Playlist ${params.row.id}`}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div style={{ marginLeft: "5%", paddingTop: "5%", width: "90%" }}>
      {modalOpen ? (
        <Button
          variant="contained"
          style={{
            marginBottom: "1%",
            marginLeft: "88%",
            backgroundColor: colorTags.SECONDARY,
          }}
          onClick={handleModalClose}>
          Close
        </Button>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}>
          {" "}
          <Button
            variant="text"
            style={{
              marginBottom: "1%",
              // backgroundColor: colorTags.TERTIARY,
            }}
            onClick={() => navigate("/dashboard/socials")}>
            Back to Socials Dashboard
          </Button>
          <Button
            variant="contained"
            style={{
              marginBottom: "1%",
              // marginLeft: "88%",
              backgroundColor: colorTags.TERTIARY,
            }}
            onClick={handleModalOpen}>
            <AddIcon style={{ marginRight: "5px" }} />
            Playlist
          </Button>
        </div>
      )}

      <AddPlaylist
        open={modalOpen}
        onClose={handleModalClose}
        title="Add Playlist Item"
        content="This is the modal content."
        actionText="Close"
      />

      <EditPlaylist
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        title="Edit Playlist Item"
        content="This is the modal content."
        actionText="Close"
        playlistDetails={selectedRow}
      />

      <SimpleCard
        title="YouTube Playlists"
        style={{
          marginTop: "2%",
        }}>
        <Box width="100%" overflow="auto">
          <GridData
            rows={playlistsData ?? []}
            columns={columns}
            initialState={{
              pagination: {
                pageSize: 5,
              },
            }}
          />
        </Box>
      </SimpleCard>
    </div>
  );
}
