import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  Button,
  TextField,
  Typography,
  MenuItem,
  Autocomplete,
  Chip,
  Switch,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { FILE_LINK_URL } from "../../../../../constants/apiTags";
import {
  useCreateArticlesRequestMutation,
  useGetCategoriesQuery,
  useGetAuthorsQuery,
} from "../../../../../features/api/apiSlice";
import { toast } from "react-toastify";
import { colorTags } from "../../../../../constants/variants";
import { useNavigate } from "react-router-dom";
import { images } from "../../../../../assets";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

// Define styles
const useStyles = makeStyles((theme) => ({
  inputContainer: {
    marginBottom: theme.spacing(0),
  },
  imagePreview: {
    width: "100%",
    maxWidth: "270px",
    maxHeight: "150px",
    objectFit: "cover",
    border: "2px dashed #ccc",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  imagePreviewPlaceholder: {
    width: "100%",
    maxHeight: "150px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "2px dashed #ccc",
    cursor: "pointer",
    marginBottom: theme.spacing(2),
  },
  tagsContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  tagInputContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}));

const TextImageTemplate = () => {
  const [token, setToken] = useState(localStorage.getItem("authToken"));

  const classes = useStyles();
  const [image, setImage] = useState(null);
  const [imageToUpload, setImageToUpload] = useState([]);
  const [categories, setCategories] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    data: categoriesData,
    isLoading: isLoadingCategoriesData,
    isError: isErrorCategoriesData,
    error: errorCategoriesData,
  } = useGetCategoriesQuery();

  useEffect(() => {
    if (categoriesData) {
      const allData = [];

      categoriesData?.forEach((category) => {
        // Add the category itself
        allData.push({
          id: category.id,
          name: category.name,
          nameAm: category.nameAm,
        });

        // Add each subCategory within the category
        category?.subcategories?.forEach((subCategory) => {
          allData.push({
            id: subCategory.id,
            name: subCategory.name,
            nameAm: subCategory.nameAm,
          });
        });
      });

      setCategories(allData);
      console.log(categories);
    }
  }, [categoriesData]);

  const {
    data: authorsData,
    isLoading: isLoadingAuthorsData,
    isError: isErrorAuthorsData,
    error: errorAuthorsData,
  } = useGetAuthorsQuery();

  useEffect(() => {
    if (authorsData) setAuthors(authorsData);
    // if (categoriesData) setCategories();
  }, [authorsData]);

  // const {
  //   data: categoriesData,
  //   isLoading: isLoadingMenu,
  //   isError: isErrorMenu,
  //   error: errorMenu,
  // } = useGetCategoriesQuery();

  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState("");
  const [formData, setFormData] = useState({
    title: "",
    subtitle: "",
    paragraph1: "",
    paragraph2: "",
    paragraph3: "",
    language: "en",
    category: "",
    subcategory: "General",
  });
  const [isFeature, setIsFeature] = useState(false);
  const [createArticles, { isLoading: createArticlesRequestLoading }] =
    useCreateArticlesRequestMutation();

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImage(URL.createObjectURL(e.target.files[0]));
      setImageToUpload(e.target.files[0]);
    }
  };

  const handleTagAdd = () => {
    if (tagInput.trim() !== "") {
      setTags((prevTags) => [...prevTags, tagInput.trim()]);
      setTagInput("");
    }
  };

  const handleTagDelete = (tagToDelete) => {
    setTags((prevTags) => prevTags.filter((tag) => tag !== tagToDelete));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const [authorID, setAuthorID] = useState(0);

  const handleCategoryChange = (event, value) => {
    setFormData((prev) => ({ ...prev, category: value, subcategory: "" }));
  };

  // const handleSubcategoryChange = (event, value) => {
  //   setFormData((prev) => ({ ...prev, subcategory: value }));
  // };

  // const getSubcategories = () => {
  //   const selectedCategory = categoriesData?.find(
  //     (cat) => cat.name === formData.category
  //   );
  //   return selectedCategory ? selectedCategory?.subCategories : [];
  // };

  const uploadImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("files", file);

      const response = await fetch(FILE_LINK_URL, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        throw new Error("Image upload failed");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  };
  const navigate = useNavigate();
  const [textError, setTextError] = useState("");
  const [paragraphError, setParagraphError] = useState("");

  const handleSave = async (e) => {
    e.preventDefault();
    setTextError("");
    // Add validation if needed

    const data = {
      title: formData.title,
      subtitle: formData.subtitle,
      paragraph1: formData.paragraph1,
      paragraph2: formData.paragraph2,
      paragraph3: formData.paragraph3,
      language: formData.language,
      generalCategory: formData.category,
      specificCategory: formData.subcategory,
      templateId: 1,
      additionalImages: [],
      youtubeLink: "",
      // coverImage:[],
      authorID,
      isFeature,
      tags,
      // userId: 0,
    };

    if (formData.title.length < 10) {
      toast.error("Title can not be less than 10 characters.");
      return;
    }
    if (
      formData.paragraph1.length < 50 &&
      formData.paragraph2.length < 50 &&
      formData.paragraph2.length < 50
    ) {
      toast.error("Not Enough Content in the Paragraphs.");
      return;
    }

    // console.log(data);

    if (image) {
      try {
        const imageResponse = await uploadImage(imageToUpload);
        data.coverImage = imageResponse?.filenames[0] ?? "";
      } catch (error) {
        toast.error("Error uploading cover image");

        return;
      }
    }

    try {
      await createArticles(data).then((res) => {
        if (res.data && res.data.message != "Unauthorized") {
          toast.success("Article Added Successfully");
        } else if (
          res.data.message === "Unauthorized" ||
          res.data.message === "Forbidden"
        ) {
          toast.error("Error Publishing Article");
        }
      });
    } catch (err) {
      toast.error("Error Publishing Article");
    }

    navigate(-1);
  };

  return (
    <div style={{ width: "100%" }}>
      <form onSubmit={handleSave}>
        <Stack
          direction="row"
          spacing={3}
          mt={5}
          style={{
            width: "100%",
            justifyContent: "center",
          }}>
          <Box
            sx={{
              padding: 5,
              width: "50%",
              borderRadius: "0px",
              boxShadow:
                "rgba(6, 24, 44, 0.04) 0px 0px 0px 2px, rgba(6, 24, 44, 0.06) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
              margin: "20px",
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}>
            <TextField
              fullWidth
              label="Title"
              variant="outlined"
              className={classes.inputContainer}
              InputProps={{ style: { fontSize: "20px" } }}
              name="title"
              value={formData.title}
              required
              onChange={handleChange}
              error={!!textError}
              helperText={textError}
            />
            <TextField
              fullWidth
              label="Subtitle"
              variant="outlined"
              className={classes.inputContainer}
              InputProps={{ style: { fontSize: "14px" } }}
              name="subtitle"
              value={formData.subtitle}
              onChange={handleChange}
            />
            <Box
              className={
                image ? classes.imagePreview : classes.imagePreviewPlaceholder
              }
              onClick={() =>
                document.getElementById("upload-image-input").click()
              }>
              {image ? (
                <img
                  src={image}
                  alt="Preview"
                  className={classes.imagePreview}
                />
              ) : (
                <Typography variant="subtitle1" color="textSecondary">
                  Click to upload cover image/Thumbnail
                </Typography>
              )}
            </Box>
            <input
              type="file"
              hidden
              id="upload-image-input"
              onChange={handleImageChange}
            />
            <TextField
              fullWidth
              label="Paragraph 1"
              variant="outlined"
              required
              multiline
              rows={4}
              className={classes.inputContainer}
              InputProps={{ style: { fontSize: "13px" } }}
              name="paragraph1"
              value={formData.paragraph1}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              label="Paragraph 2"
              variant="outlined"
              multiline
              rows={4}
              className={classes.inputContainer}
              InputProps={{ style: { fontSize: "13px" } }}
              name="paragraph2"
              value={formData.paragraph2}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              label="Paragraph 3"
              variant="outlined"
              multiline
              rows={4}
              className={classes.inputContainer}
              InputProps={{ style: { fontSize: "13px" } }}
              name="paragraph3"
              value={formData.paragraph3}
              onChange={handleChange}
            />
          </Box>
          <Stack direction="column" spacing={2}>
            <Typography variant="subtitle1" color="textSecondary">
              Other Settings
            </Typography>

            <TextField
              fullWidth
              label="Language"
              variant="outlined"
              className={classes.inputContainer}
              select
              name="language"
              value={formData.language}
              onChange={handleChange}>
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="am">አማርኛ</MenuItem>
            </TextField>

            <FormControl variant="outlined" fullWidth>
              <InputLabel id="author-label">Author</InputLabel>
              <Select
                labelId="author-label"
                value={authorID}
                onChange={(e) => setAuthorID(e.target.value)}
                label="Author">
                {authorsData?.map((author) => (
                  <MenuItem key={author?.id} value={author?.id}>
                    {author?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* <Autocomplete
              options={categories}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Category"
                  variant="outlined"
                  className={classes.inputContainer}
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                />
              )}
              onChange={(event, newValue) => {
                setFormData((prev) => ({ ...prev, category: newValue }));
              }}
            /> */}
            {categoriesData && categories && (
              <Autocomplete
                options={categories?.map((category) => category?.name)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Category"
                    variant="outlined"
                    className={classes.inputContainer}
                  />
                )}
                value={formData.category}
                onChange={handleCategoryChange}
              />
            )}
            {/* <TextField
              fullWidth
              label="Subcategory"
              variant="outlined"
              className={classes.inputContainer}
              name="subcategory"
              value={formData.subcategory}
              onChange={handleChange}
            /> */}

            {/* <Autocomplete
              options={getSubcategories().map((subcat) => subcat.name)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Subcategory"
                  variant="outlined"
                  className={classes.inputContainer}
                />
              )}
              value={formData.subcategory}
              onChange={handleSubcategoryChange}
              disabled={!formData.category}
            /> */}

            <Box
              className={classes.item}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}>
              <Typography>is Feature?</Typography>
              <Switch
                checked={isFeature}
                onChange={() => setIsFeature(!isFeature)}
                color="success"
                style={{
                  color: colorTags.TERTIARY,
                }}
              />
            </Box>
            <Box className={classes.tagsContainer}>
              {tags.map((tag, index) => (
                <Chip
                  key={index}
                  label={tag}
                  onDelete={() => handleTagDelete(tag)}
                  color="primary"
                  style={{
                    color: "#fff",
                    backgroundColor: "#000",
                    border: `1px solid ${colorTags.TERTIARY}`,
                  }}
                />
              ))}
            </Box>

            <Box className={classes.tagInputContainer}>
              <TextField
                fullWidth
                label="Tags"
                variant="outlined"
                className={classes.inputContainer}
                value={tagInput}
                onChange={(e) => setTagInput(e.target.value)}
              />
              <Button
                variant="text"
                color="primary"
                onClick={handleTagAdd}
                style={{
                  height: "56px",
                  borderRadius: "5px",
                }}>
                Add
              </Button>
            </Box>

            <Stack
              direction="row"
              spacing={2}
              justifyContent="flex-end"
              sx={{ marginTop: 5 }}>
              <Button
                // type="submit"
                onClick={() => setIsModalOpen(true)}
                variant="contained"
                color="primary"
                style={{
                  borderRadius: "5px",
                  backgroundColor: colorTags.TERTIARY,
                }}>
                Publish Article
              </Button>
              <Button
                variant="outlined"
                color="error"
                style={{ borderRadius: "5px" }}
                onClick={() => navigate(-1)}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </form>
      <Dialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="confirmation-dialog-title"
        aria-describedby="confirmation-dialog-description"
        style={{
          padding: "20px",
        }}>
        <DialogTitle id="confirmation-dialog-title">Confirm Post</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmation-dialog-description">
            Are you sure you want to post this content?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            margin: "10px",
          }}>
          <Button
            onClick={() => setIsModalOpen(false)}
            color="error"
            variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            style={{ backgroundColor: colorTags.TERTIARY, color: "#fff" }}
            variant="contained"
            autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TextImageTemplate;
