import React, { useEffect, useState } from "react";
import {
  Paper,
  Typography,
  makeStyles,
  Divider,
  Card,
} from "@material-ui/core";
import { useMediaQuery, Stack } from "@mui/material";
import Footer from "../Footer/Footer2";
import Header from "../Header/Header2";
import LogoHead from "../Header/LogoHead2";
import Title from "../Header/Title2";
import { colorTags } from "../../../constants/variants";
import { useGetEventsQuery } from "../../../features/api/apiSlice";
import { format } from "date-fns";
import { BASE_FILE_URL } from "../../../constants/apiTags";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    boxShadow: "none",
    position: "relative",
    overflow: "hidden",
    width: "100%",
  },
  eventCardContainer: {
    width: "100%",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
  eventCard: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2),
    boxShadow:
      "rgb(204, 219, 232) 6px 6px 9px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset",
    borderRadius: "10px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  eventImage: {
    width: "30%",
    height: "auto",
    maxHeight: "450px",
    marginRight: theme.spacing(2),
    objectFit: "contain",
    // boxShadow:
    //   "rgb(204, 219, 232) 6px 6px 9px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset",
    borderRadius: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: 0,
      marginBottom: theme.spacing(2),
    },
  },
  eventContent: {
    width: "70%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: "5%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  eventTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
    fontSize: "2.5rem",
    color: colorTags.PRIMARY,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
  },
  eventDescription: {
    marginBottom: theme.spacing(1),
    fontSize: "1.7rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  eventLocation: {
    marginBottom: theme.spacing(1),
    fontSize: "1.4rem",
    color: colorTags.TERTIARY,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  eventDate: {
    color: theme.palette.text.secondary,
    fontSize: "1.3rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  topTitle: {
    height: "40%",
    position: "relative",
    minHeight: "300px",
    maxHeight: "300px",

    borderRadius: "35px",
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "1fr 1fr",
    gap: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      minHeight: "100px",
      borderRadius: "15px",
      gridTemplateColumns: "1fr",
      gridTemplateRows: "repeat(4, 1fr)",
    },
  },
  titleTextContainer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    padding: "10px",
    paddingRight: "13px",
    borderTopRightRadius: "15px",
    backgroundColor: "rgba(255,255,255,0.3)",
    [theme.breakpoints.down("sm")]: {
      padding: "5px",
    },
  },
  titleText: {
    fontSize: "1.5rem",
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  gridItem: {
    width: "100%",
    height: "100%",
    maxHeight: "300px",
    // borderRadius: "15px ",

    objectFit: "cover",
  },
}));

const EventsPage = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [events, setEvents] = useState([]);
  const {
    data: eventsData,
    isLoading: eventsLoading,
    isError: eventsError,
  } = useGetEventsQuery();

  const eventImages = eventsData
    ?.map((event) => `${BASE_FILE_URL}${event?.coverImage}`)
    .slice(0, 4);

  useEffect(() => {
    // Filter out events that have ended
    if (eventsData) {
      const filteredEvents = eventsData.filter(
        (event) => new Date(event.endDate) > new Date()
      );
      setEvents(filteredEvents);
    }
  }, [eventsData]);

  return (
    <div>
      <div>
        <Title />
        <LogoHead />
      </div>
      <Header />
      <Stack
        direction={isMobile ? "column" : "row"}
        spacing={3}
        style={{
          width: "100%",
          padding: isMobile ? "0 5%" : "0% 12%",
          marginTop: isMobile ? "0%" : "0%",
          justifyContent: isMobile ? "flex-start" : "center",
          alignItems: isMobile ? "center" : "flex-start",
        }}>
        <Card className={classes.topTitle}>
          {eventImages?.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Event ${index + 1}`}
              className={classes.gridItem}
            />
          ))}
          <div className={classes.titleTextContainer}>
            <Typography variant="h6" className={classes.titleText}>
              Addis Chamber Events
            </Typography>
          </div>
        </Card>
      </Stack>
      <Stack
        direction={isMobile ? "column" : "row"}
        spacing={3}
        style={{
          width: "100%",
          padding: isMobile ? "0 5%" : "0% 7%",
          marginTop: isMobile ? "2%" : "2%",
          justifyContent: isMobile ? "flex-start" : "center",
          alignItems: isMobile ? "center" : "flex-start",
        }}>
        <Paper className={classes.root}>
          <Divider
            style={{
              marginBottom: "10px",
              backgroundColor: colorTags.TERTIARY,
            }}
          />
          <Typography
            variant="h6"
            className={classes.mainVideoTitle}
            style={{
              color: colorTags.PRIMARY,
            }}>
            Upcoming Events
          </Typography>
          <div className={classes.eventCardContainer}>
            {eventsLoading && <Typography>Loading events...</Typography>}
            {eventsError && <Typography>Error loading events</Typography>}
            {eventsData &&
              events?.map((event) => (
                <div key={event.id} className={classes.eventCard}>
                  <img
                    src={`${BASE_FILE_URL}${event?.coverImage}`}
                    alt={event.title}
                    className={classes.eventImage}
                  />
                  <div className={classes.eventContent}>
                    <Typography className={classes.eventTitle}>
                      {event.title}
                    </Typography>
                    <Typography className={classes.eventDescription}>
                      {event.description}
                    </Typography>
                    <Typography className={classes.eventLocation}>
                      {event.location}
                    </Typography>
                    <Typography className={classes.eventDate}>
                      {format(new Date(event.startDate), "PPpp")} -{" "}
                      {format(new Date(event.endDate), "PPpp")}
                    </Typography>
                  </div>
                </div>
              ))}
          </div>
        </Paper>
      </Stack>
      <Footer />
    </div>
  );
};

export default EventsPage;
