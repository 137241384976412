import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  withStyles,
  Card,
  CardContent,
  Typography,
  IconButton,
  Fade,
} from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { Stack } from "@mui/material";
import { images } from "../../../assets/index";

const useStyles = makeStyles((theme) => ({
  carouselContainer: {
    position: "relative",
    width: "100%",
    // padding: theme.spacing(5),
    height: "auto",
    minHeight: "270px",
    maxHeight: "300px",
    margin: "0 auto",
    overflow: "hidden",

    alignItems: "center",
    justifyContent: "center",
  },
  carouselCard: {
    width: "100%",
    height: "90%",
    // maxHeight: "200px",
    display: "flex",
    // margin: "5%",
    backgroundColor: "rgba(255, 255, 0.3)",
    backgroundPosition: "center",
    flexDirection: "column",
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    position: "absolute",
    backgroundSize: "contain",
    top: 0,
    borderRadius: "10px",
    boxShadow: "none",
  },
  ad30: {
    width: "100%",
  },
  ad100: {
    width: "100%",
  },
  adCustom: {
    width: "50%",
  },
  arrowButton: {
    position: "absolute",
    top: 90,
    width: "60px",
    height: "60px",
    // transform: "translateY(-50%)",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    zIndex: 1,
  },
  leftArrowButton: {
    left: 50,
    [theme.breakpoints.down(600)]: {
      left: 5,
      width: "40px",
      height: "40px",
    },
    [theme.breakpoints.down(900)]: {
      left: 20,
      width: "40px",
      height: "40px",
    },
  },
  rightArrowButton: {
    right: 50,
    [theme.breakpoints.down(900)]: {
      right: 20,
      width: "40px",
      height: "40px",
    },
    [theme.breakpoints.down(600)]: {
      right: 5,
      width: "40px",
      height: "40px",
    },
  },
}));

const getArrowSize = (theme, screenWidth) => {
  if (screenWidth <= theme.breakpoints.values.sm) {
    return "32px";
  } else if (screenWidth <= theme.breakpoints.values.md) {
    return "48px";
  } else {
    return "60px";
  }
};

const ThinChevronLeft = withStyles((theme) => ({
  root: {
    fontSize: "20px",
    strokeWidth: "1.5",
  },
}))(ChevronLeft);

const ThinChevronRight = withStyles((theme) => ({
  root: {
    // fontSize: getArrowSize(theme, window.innerWidth),
    fontSize: "20px",

    strokeWidth: "1.5",
  },
}))(ChevronRight);

const Advertisements = ({ position, items }) => {
  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items?.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items?.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  let adClass;
  switch (position) {
    case "sidebar":
      adClass = classes.ad30;
      break;
    case "header":
      adClass = classes.ad100;
      break;
    case "footer":
      adClass = classes.adCustom;
      break;
    default:
      adClass = classes.ad30;
  }

  return (
    <div className={`${classes.carouselContainer} ${adClass}`}>
      {position === "header" && (
        <IconButton
          className={`${classes.arrowButton} ${classes.leftArrowButton}`}
          onClick={handlePrevious}>
          <ThinChevronLeft />
        </IconButton>
      )}
      <div>
        {items?.map((item, index) => (
          <Fade
            key={index}
            in={index === currentIndex}
            timeout={{ enter: 1000, exit: 1000 }}>
            <div
              className={classes.carouselCard}
              style={{
                backgroundImage: `url(${item.image})`,
                // height: position === "header" ? "90%" : "70%",
              }}>
              {/* <CardContent> */}
              {/* <Typography variant="h6">{item.title}</Typography> */}
              {/* <img
                  src={item.image}
                  alt={item.title}
                  style={{ maxWidth: "100%", maxHeight: "150px" }}
                /> */}
              {/* <Typography>{item.description}</Typography> */}
              {/* </CardContent> */}
            </div>
          </Fade>
        ))}
      </div>
      {position === "header" && (
        <IconButton
          className={`${classes.arrowButton} ${classes.rightArrowButton}`}
          onClick={handleNext}>
          <ThinChevronRight />
        </IconButton>
      )}
    </div>
  );
};

export default Advertisements;
