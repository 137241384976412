import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Info } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { SimpleCard } from "../../../../components";
import { primaryColor, colorTags } from "../../../../constants/variants";
import GridData from "../../../../components/GridData";
import AddSponsor from "./AddSponsor";
import DeleteDialog from "../../../../components/DeleteDialog";
import { useNavigate } from "react-router-dom";
import EditSponsor from "./EditSponsor";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";

import {
  useGetSponsorsQuery,
  useGetAdsQuery,
  useGetSponsorByIdQuery,
  useCreateSponsorRequestMutation,
  useUpdateSponsorRequestMutation,
  // useDeleteSponsorRequestMutation,
  useDeleteAdsRequestMutation,
  useDeleteEventRequestMutation,
} from "../../../../features/api/apiSlice";
import { BASE_FILE_URL } from "../../../../constants/apiTags";
import { OpenInNew } from "@mui/icons-material";
import { Bars } from "react-loader-spinner";

const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
  },
}));

const sponsorsDataq = [
  {
    id: 1,
    name: "TechCorp",
    description:
      "A leading technology company specializing in software and hardware.",
    logo: "techcorp_logo.jpg",
    startDate: "2024-01-01",
    endDate: "2024-12-31",
    page: 1,
    position: "Top",
  },
  {
    id: 2,
    name: "HealthPlus",
    description:
      "A health and wellness company providing top-notch healthcare services.",
    logo: "healthplus_logo.jpg",
    startDate: "2024-02-01",
    endDate: "2024-12-31",
    page: 1,
    position: "Middle",
  },
  {
    id: 3,
    name: "MusicMasters",
    description: "A company dedicated to producing and promoting music events.",
    logo: "musicmasters_logo.jpg",
    startDate: "2024-03-01",
    endDate: "2024-12-31",
    page: 1,
    position: "Bottom",
  },
  {
    id: 4,
    name: "ArtCulture",
    description:
      "An organization focused on promoting art and cultural events.",
    logo: "artculture_logo.jpg",
    startDate: "2024-04-01",
    endDate: "2024-12-31",
    page: 2,
    position: "Top",
  },
  {
    id: 5,
    name: "GourmetFest",
    description:
      "A company that organizes food and wine festivals around the world.",
    logo: "gourmetfest_logo.jpg",
    startDate: "2024-05-01",
    endDate: "2024-12-31",
    page: 2,
    position: "Middle",
  },
];

export default function SponsorsPage() {
  const [token, setToken] = useState(localStorage.getItem("authToken"));

  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const {
    data: sponsorsData,
    isLoading: isLoadingSponsorsData,
    isError: isErrorSponsorsData,
    error: errorSponsorsData,
  } = useGetAdsQuery();

  // useEffect(() => {
  //   if (sponsorsData) console.log(sponsorsData);
  // }, [sponsorsData]);

  const columns = [
    {
      field: "id",
      headerName: "No.",
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "logo",
      headerName: "Logo",
      flex: 1,
      renderCell: (params) => (
        <div>
          <img
            src={BASE_FILE_URL + params?.row?.logo}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      ),
    },
    {
      field: "startDate",
      headerName: "Start Date",
      type: "date",
      flex: 1,
    },
    {
      field: "endDate",
      headerName: "End Date",
      type: "date",
      flex: 1,
    },
    {
      field: "page",
      headerName: "Page",
      type: "number",
      flex: 1,
    },
    {
      field: "position",
      headerName: "Position",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: 1,
      align: "left",
      renderCell: (params) => {
        return (
          <div className="flex">
            {/* <IconButton
              onClick={() => navigate("/posts/sponsors/" + params?.row?.id)}>
              <Info color="primary" />
            </IconButton> */}
            <IconButton
              onClick={() => {
                setEditModalOpen(true);
                console.log(params?.row);
                setSelectedRow(params?.row);
              }}>
              <EditIcon color="primary" />
            </IconButton>
            <DeleteDialog
              api={useDeleteAdsRequestMutation}
              dialogLabel="Delete Advertiser"
              id={params?.row?.id}
              name={params?.row?.name}
            />
          </div>
        );
      },
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => setModalOpen(false);
  const handleModalOpen = () => setModalOpen(true);

  // if (isLoadingSponsorsData)
  //   return (
  //     <div
  //       style={{
  //         width: "100%",
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}>
  //       <Bars
  //         height="40"
  //         width="40"
  //         color="#4fa94d"
  //         ariaLabel="bars-loading"
  //         wrapperStyle={{}}
  //         wrapperClass=""
  //         visible={true}
  //       />
  //     </div>
  //   );

  return (
    <div style={{ marginLeft: "5%", marginTop: "5%", width: "90%" }}>
      <Button
        variant="contained"
        style={{
          marginBottom: "1%",
          marginLeft: "88%",
          // backgroundColor: primaryColor,
          backgroundColor: colorTags.TERTIARY,
        }}
        onClick={handleModalOpen}>
        <AddIcon style={{ marginRight: "5px" }} />
        Sponsor
      </Button>
      <AddSponsor
        open={modalOpen}
        onClose={handleModalClose}
        title="Add Sponsor"
        content="This is the modal content."
        actionText="Close"
      />
      <SimpleCard
        title="Sponsors"
        style={{
          marginTop: "2%",
        }}>
        <Box width="100%" overflow="auto">
          <GridData rows={sponsorsData ?? []} columns={columns} />
        </Box>
      </SimpleCard>

      <EditSponsor
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        title="Edit Sponsor"
        content="This is the modal content."
        actionText="Close"
        sponsorData={selectedRow}
      />
    </div>
  );
}
