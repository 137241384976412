import React, { useState, useEffect } from "react";
import {
  Stack,
  styled,
  Grid,
  Avatar,
  FormControl,
  InputLabel,
  Button,
  MenuItem,
  Select,
  Fade,
} from "@mui/material";
import {
  TextField,
  Typography,
  makeStyles,
  Container,
  Modal,
  Backdrop,
  Box,
} from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { FILE_LINK_URL } from "../../../../constants/apiTags";
import {
  useUploadFileMutation,
  useUpdateAuthorRequestMutation,
} from "../../../../features/api/apiSlice";
import { useStyles } from "../../../../App";
import { colorTags } from "../../../../constants/variants";
const useEditAuthorStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    backgroundColor: "#fff",
    borderRadius: "10px",
    padding: "2% 4%",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
    maxWidth: "40%",
    maxHeight: "90%",
    overflow: "auto",
    outline: "none",
    minWidth: "500px",
  },
  modalTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
    fontWeight: "medium",
    color: "#03AED2",
  },
  modalBody: {
    marginTop: theme.spacing(2),
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#03AED2",
      },
      "&:hover fieldset": {
        borderColor: "#03AED2",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#03AED2",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#03AED2",
    },
  },
  modalActions: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
  },
  imagePreview: {
    width: "100%",
    maxWidth: "270px",
    maxHeight: "150px",
    objectFit: "cover",
    border: "2px dashed #ccc",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  imagePreviewPlaceholder: {
    width: "100%",
    maxHeight: "150px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "2px dashed #ccc",
    cursor: "pointer",
    marginBottom: theme.spacing(2),
  },
}));

const EditAuthor = ({ open, onClose, title, data, onSave }) => {
  const classes = useEditAuthorStyles();
  const classez = useStyles();
  const [token, setToken] = useState(localStorage.getItem("authToken"));

  // State variables
  const [name, setName] = useState("");
  const [nameAm, setNameAm] = useState("");

  const [email, setEmail] = useState("");
  const [avatar, setAvatar] = useState([]);
  const [status, setStatus] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");

  // Error handling
  const [nameError, setNameError] = useState("");
  const [nameAmError, setNameAmError] = useState("");

  const [emailError, setEmailError] = useState("");
  const [avatarError, setAvatarError] = useState("");

  // Constants for dropdowns
  const statuses = ["Active", "Inactive"];

  const closeModal = () => {
    setName("");
    setNameAm("");
    setStatus("");
    setAvatar([]);
    setEmail("");
    onClose();
  };

  // Fetch user from local storage
  const storedUser = localStorage.getItem("user");
  // const user = storedUser ? JSON.parse(storedUser) : null;
  const user = storedUser ? storedUser : null;

  const [
    updateAuthorRequest,
    {
      isLoading: updateAuthorLoading,
      isError: isErrorupdateAuthor,
      error: updateAuthorError,
    },
  ] = useUpdateAuthorRequestMutation();

  // Set state based on incoming data
  useEffect(() => {
    if (data) {
      setName(data.name ?? "");
      setNameAm(data.nameAm ?? "");

      setEmail(data.email ?? "");
      setAvatar(data.avatar ? [data.avatar] : []);
      setStatus(data.status ?? "");
      setCreatedAt(data.createdAt ?? "");
      setUpdatedAt(data.updatedAt ?? "");
    }
  }, [data]);

  // Validate email function
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Function to upload image
  const uploadImage = async (file) => {
    try {
      const token = localStorage.getItem("authToken");
      const formData = new FormData();
      formData.append("files", file);

      const response = await fetch(FILE_LINK_URL, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        throw new Error("Image upload failed");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  };

  const handleAdditionalImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      // setAvatar(URL.createObjectURL(e.target.files[0]));
      setAvatar(e.target.files[0]);
    }
  };

  // Save function on button click
  const handleSave = async (e) => {
    e.preventDefault();

    // Clear previous errors
    setNameError("");
    setEmailError("");
    setAvatarError("");

    // Validation checks
    if (name.length === 0 || name.length > 100) {
      setNameError("Name must be between 1 and 100 characters");
      return;
    }
    if (nameAm.length === 0 || nameAm.length > 100) {
      setNameAmError("Name must be between 1 and 100 characters");
      return;
    }

    if (!validateEmail(email)) {
      setEmailError("Invalid email address");
      return;
    }

    // if (avatar.length === 0) {
    //   setAvatarError("Please upload an avatar");
    //   return;
    // }

    // Prepare updated data object
    const updatedData = {
      id: data.id, // Assuming data.id is provided
      name,
      nameAm,
      email,
      avatar: avatar.length > 0 ? avatar[0] : null,
      status,
      createdAt,
      updatedAt,
      userId: user?.user_id,
    };

    // Upload avatar if new avatar is selected
    if (avatar.length > 0 && avatar[0] !== data.avatar) {
      try {
        const imageResponse = await uploadImage(avatar[0]);
        updatedData.avatar =
          // imageResponse?.dbPaths[0]?.documentName ?? data.avatar;
          imageResponse?.filenames[0] ?? data.avatar;
      } catch (error) {
        toast.error("Error uploading avatar");
        return;
      }
    }

    await updateAuthorRequest(updatedData)
      .then((res) => {
        if (res) {
          toast.success("Author Updated Successfully");
        }
      })
      .catch((err) => {
        toast.error("Error Updating Author");
      });

    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}>
      <Fade in={open}>
        <div className={classes.modalContent}>
          <Typography variant="h6" className={classes.modalTitle}>
            {title}
          </Typography>
          <div className={classes.modalBody}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Name"
                  variant="outlined"
                  className={classes.textField}
                  fullWidth
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  error={!!nameError}
                  helperText={nameError}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="ስም"
                  variant="outlined"
                  className={classes.textField}
                  fullWidth
                  required
                  value={nameAm}
                  onChange={(e) => setNameAm(e.target.value)}
                  error={!!nameAmError}
                  helperText={nameAmError}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Email"
                  variant="outlined"
                  className={classes.textField}
                  fullWidth
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={!!emailError}
                  helperText={emailError}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  fullWidth>
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    label="Status">
                    {statuses.map((status) => (
                      <MenuItem key={status} value={status}>
                        {status}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={24} sm={12}>
                <Stack direction="row" justifyContent={"center"} spacing={2}>
                  {/* */}

                  {/* </Grid> */}
                  {/* <Grid item xs={12}> */}
                  {/* <Box
                    className={
                      avatar
                        ? classes.imagePreview
                        : classes.imagePreviewPlaceholder
                    }
                    onClick={() =>
                      document
                        .getElementById("upload-additional-image-input")
                        .click()
                    }>
                    {avatar ? (
                      <img
                        src={URL.createObjectURL(avatar[0])}
                        alt="Preview"
                        className={classes.imagePreview}
                      />
                    ) : (
                      <Typography variant="subtitle1" color="textSecondary">
                        Avatar
                      </Typography>
                    )}
                  </Box> */}
                  <DropzoneArea
                    dropzoneClass={classez?.dropZone}
                    style={{ flex: 1 }}
                    showAlerts={false}
                    useChipsForPreview={true}
                    filesLimit={1}
                    maxFiles={1}
                    maxFileSize={5000000}
                    dropzoneText={"Drop or Click to Upload Avatar"}
                    onChange={(files) => setAvatar(files)}
                    // acceptedFiles={[".pdf"]}
                    // error={!!documentError}
                    // helperText={documentError}
                  />
                </Stack>
              </Grid>
            </Grid>
          </div>
          <div className={classes.modalActions}>
            <Stack spacing={2} direction="row">
              <LoadingButton
                variant="contained"
                color="success"
                style={{
                  fontWeight: "bold",
                  color: "#fff",
                  fontWeight: "medium",
                  backgroundColor: colorTags.TERTIARY,
                }}
                onClick={handleSave}>
                Save
              </LoadingButton>
              <Button
                variant="outlined"
                color="error"
                style={{
                  color: "#FF0000",
                  marginLeft: "10px",
                  borderRadius: "15px",
                }}
                onClick={onClose}>
                Cancel
              </Button>
            </Stack>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default EditAuthor;
