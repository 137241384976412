import {
  BusinessCenter as BusinessCenterIcon,
  HowToReg as HowToRegIcon,
  History as HistoryIcon,
  WorkOutline as WorkOutlineIcon,
  Notifications as NotificationsIcon,
  SwapHoriz as SwapHorizIcon,
  ReceiptLongOutlined as ReceiptLongOutlinedIcon,
  Group as GroupIcon,
  HowToRegOutlined as HowToRegOutlinedIcon,
  PersonRemoveAlt1 as PersonRemoveAlt1Icon,
  Badge as BadgeIcon,
  PostAdd as PostAddIcon,
  Groups as GroupsIcon,
  Person as PersonIcon,
  ViewWeek as ViewWeekIcon,
  CleanHands as CleanHandsIcon,
  MonitorHeart as MonitorHeartIcon,
  AccountTree as AccountTreeIcon,
  GetAppOutlined,
  CategoryOutlined,
  LocationOnOutlined,
  AssignmentOutlined,
  BusinessOutlined,
  ArticleOutlined,
  AnnouncementOutlined,
  DescriptionOutlined,
  MonetizationOnOutlined,
  SupervisorAccountOutlined,
  NotificationsOutlined,
  SettingsOutlined,
  ChatOutlined,
  FlagOutlined,
  ContactSupportOutlined,
  LinkOutlined
} from "@mui/icons-material";
import OpinionIcon from '@mui/icons-material/Comment';
import FacebookIcon from '@mui/icons-material/Facebook';
import { Event } from "@mui/icons-material";
import { Description } from "@material-ui/icons";
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'; // Import the new icon
import CategoryIcon from '@mui/icons-material/Category';
import ArticleIcon from '@mui/icons-material/Article';
export default function MenuItems() {
  const menus = [
    {
      to: "/dashboard/news",
      label: "News Articles",
      permission: true,
      icon: <ArticleIcon />,
    },
    {
      to: "/dashboard/publications",
      label: "Publications",
      permission: true,
      icon: <DescriptionOutlined />,
    },
    {
      to: "/dashboard/events",
      label: "Events",
      permission: true,
      icon: <Event />,
    },
    {
      to: "/dashboard/sponsors",
      label: "Advertisements",
      permission: true,
      icon: <MonetizationOnOutlined />,
    },
    {
      to: "/dashboard/socials",
      label: "Socials",
      permission: true,
      icon: <FacebookIcon />,
    },
    {
      label: "Settings",
      icon: <SettingsOutlined />,
      children: [
     
       
     
        {
          to: "/dashboard/authors",
          label: "Authors",
          permission: true,
          icon: <OpinionIcon />, 
        },
        {
          to: "/dashboard/users",
          label: "Users",
          permission: true,
          icon: <GroupsIcon />,
        },
        {
          to: "/dashboard/user-roles",
          label: "User Roles",
          icon: <SupervisorAccountOutlined />,
          permission: true,
          selected: ["/add-user-role"],
        },
        {
          to: "/dashboard/categories",
          label: "Categories",
          permission: true,
          icon: <CategoryIcon />, 
        },
        {
          to: "/dashboard/logs",
          label: "Logs",
          icon: <AssignmentOutlined />,
          permission: true,
        },
      ],
    },
  ];

  return menus.map((obj) =>
    obj.children ? handleMultipleMenu(obj) : handleSingleMenu(obj)
  );
}

const handleSingleMenu = (obj) => {
  return obj.selected
    ? { ...obj, selected: [...obj.selected, obj.to] }
    : { ...obj, selected: [obj.to] };
};

const handleMultipleMenu = (obj) => {
  var menu = { ...obj };

  menu.permission = menu.children.reduce(
    (prev, current) => prev || current.permission,
    false
  );
  menu.hasChildren = true;
  menu.children = menu.children.map((child) => handleSingleMenu(child));

  return menu;
};
