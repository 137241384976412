import React, { useState } from "react";
import {
  Stack,
  styled,
  Link,
  Grid,
  Avatar,
  InputAdornment,
  FormControl,
  InputLabel,
  Button,
  MenuItem,
  Select,
  Fade,
  Divider,
  FormLabel,
  FormHelperText,
} from "@mui/material";
import {
  TextField,
  Typography,
  makeStyles,
  Container,
  Modal,
  Backdrop,
  TextareaAutosize,
} from "@material-ui/core";
import { colorTags } from "../../../../constants/variants";
import { useStyles } from "../../../../App";
import { DropzoneArea } from "material-ui-dropzone";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  useGetEventsQuery,
  useGetEventByIdQuery,
  useCreateEventRequestMutation,
  useUpdateEventRequestMutation,
  useDeleteEventRequestMutation,
  useUploadFileMutation,
} from "../../../../features/api/apiSlice";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import {
  FILE_DOWNLOAD_URL,
  FILE_LINK_URL,
} from "../../../../constants/apiTags";

const useStyless = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: theme.spacing(2),
    // boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
    boxShadow:
      "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(255,255,255, 0.25) 0px 50px 100px -20px, rgba(255,255,255, 0.3) 0px 30px 60px -30px",

    maxWidth: "90%",
    maxHeight: "90%",
    overflow: "auto",
    outline: "none",
  },
  modalTitle: {
    textAlign: "left",
    marginBottom: theme.spacing(2),
  },
  modalBody: {
    marginTop: theme.spacing(2),
  },
  inputField: {
    marginBottom: theme.spacing(2),
  },
  modalActions: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#03AED2",
      },
      "&:hover fieldset": {
        borderColor: "#03AED2",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#03AED2",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#03AED2",
    },
  },
  textArea: {
    width: "100%",
    minHeight: "100px",
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
    resize: "vertical",
  },
}));

const AddEvent = ({ open, onClose, title, onSave }) => {
  const classes = useStyless();
  const classez = useStyles();
  const [docTitle, setDocTitle] = useState("");
  const [docTitleAm, setDocTitleAm] = useState("");

  const [description, setDescription] = useState("");
  const [descriptionAm, setDescriptionAm] = useState("");

  const [cover, setCover] = useState([]);
  const [location, setLocation] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [endDateError, setEndDateError] = useState("");
  const [language, setLanguage] = useState("en");
  const [titleError, setTitleError] = useState("");
  const [coverError, setCoverError] = useState("");
  const storedUser = localStorage.getItem("user");
  // const user = storedUser ? JSON.parse(storedUser) : null;
  const user = storedUser ? storedUser : null;
  const [token, setToken] = useState(localStorage.getItem("authToken"));

  const [
    createEventRequest,
    {
      isLoading: createEventLoading,
      isError: isErrorCreateEvent,
      error: createEventError,
    },
  ] = useCreateEventRequestMutation();
  const [submitFileUpload] = useUploadFileMutation();

  const uploadImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("files", file);

      const response = await fetch(FILE_LINK_URL, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        throw new Error("Image upload failed");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  };

  const validateEndDate = (endDate, startDate) => {
    return endDate >= startDate;
  };

  const handleSave = async (e) => {
    e.preventDefault();

    setTitleError("");
    setEndDateError("");
    setCoverError("");

    const data = {
      title: docTitle,
      titleAm: docTitleAm,
      description: description,
      descriptionAm: descriptionAm,

      location: location,
      startDate: startDate,
      endDate: endDate,
      language,
    };

    if (docTitle.length === 0 || docTitle.length > 100) {
      setTitleError("Event name must be between 1 and 100 characters");
      return;
    }

    if (!validateEndDate(endDate, startDate)) {
      setEndDateError("End date must be equal or later than start date");
      return;
    }

    if (cover.length === 0) {
      setCoverError("Please upload a cover image");
      return;
    }

    try {
      const imageResponse = await uploadImage(cover[0]);
      data.coverImage = imageResponse?.filenames[0] ?? "";
    } catch (error) {
      toast.error("Error uploading image");
      data.coverImage = "";
      return;
    }

    await createEventRequest(data)
      .then((res) => {
        toast.success("Event Created Successfully");
      })
      .catch((err) => {
        toast.error("Error in creating event");
      });

    setDocTitle("");
    setDescription("");
    setDocTitleAm("");
    setDescriptionAm("");
    setCover([]);
    setLocation("");
    setStartDate(new Date());
    setEndDate(new Date());

    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}>
      <Fade in={open}>
        <div className={classes.modalContent}>
          <div className={classes.modalHead}>
            <Typography
              variant="h6"
              className={classes.modalTitle}
              style={{ fontWeight: "medium", color: "#03AED2" }}>
              {title}
            </Typography>
          </div>
          <div className={classes.modalBody}>
            <Stack
              spacing={3}
              direction="row"
              sx={{ width: "100%", margin: "15px 0px" }}>
              <TextField
                label="Event Name"
                variant="outlined"
                className={classes.textField}
                fullWidth
                required
                style={{ marginTop: "15px" }}
                value={docTitle}
                onChange={(e) => setDocTitle(e.target.value)}
                error={!!titleError}
                helperText={titleError}
              />
              <TextField
                label="የኩነት ስም"
                variant="outlined"
                className={classes.textField}
                fullWidth
                required
                style={{ marginTop: "15px" }}
                value={docTitleAm}
                onChange={(e) => setDocTitleAm(e.target.value)}
                error={!!titleError}
                helperText={titleError}
              />
            </Stack>
            <TextField
              // className={classes.textArea}
              style={{ marginTop: "10px" }}
              className={classes.textField}
              required
              fullWidth
              label="Event Description"
              multiline
              rows={3}
              // placeholder="Event Informaion"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <TextField
              // className={classes.textArea}
              style={{ marginTop: "10px" }}
              className={classes.textField}
              required
              fullWidth
              label="የኩነት መግለጫ"
              multiline
              rows={3}
              // placeholder="Event Informaion"
              value={descriptionAm}
              onChange={(e) => setDescriptionAm(e.target.value)}
            />

            <Stack
              spacing={3}
              direction="row"
              sx={{ width: "100%", margin: "15px 0px" }}>
              <TextField
                label="Location"
                variant="outlined"
                className={classes.textField}
                fullWidth
                required
                style={{ marginTop: "15px" }}
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
              {/* <Stack spacing={3} sx={{ width: "50%" }}>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}>
                  <FormLabel component="legend">Select Language</FormLabel>
                  <TextField
                    fullWidth
                    variant="outlined"
                    className={classes.inputContainer}
                    select
                    name="language"
                    value={language}
                    onChange={(e) => {
                      setLanguage(e.target.value);
                    }}>
                    <MenuItem value="EN">English</MenuItem>
                    <MenuItem value="AM">አማርኛ</MenuItem>
                  </TextField>
                </FormControl>
              </Stack> */}
            </Stack>
            <Stack
              direction="row"
              spacing={3}
              style={{ marginTop: 20, marginBottom: 20, width: "100%" }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={3} sx={{ width: "50%" }}>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}>
                    <FormLabel component="legend">Start Date</FormLabel>
                    <DesktopDatePicker
                      inputFormat="dd/MM/yyyy"
                      color="success"
                      value={startDate}
                      minDate={new Date()}
                      onChange={(date) => setStartDate(date)}
                      renderInput={(params) => (
                        <TextField required variant="outlined" {...params} />
                      )}
                    />
                  </FormControl>
                </Stack>
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={3} sx={{ width: "50%" }}>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}>
                    <FormLabel component="legend">End Date</FormLabel>
                    <DesktopDatePicker
                      inputFormat="dd/MM/yyyy"
                      color="success"
                      value={endDate}
                      minDate={startDate}
                      onChange={(date) => setEndDate(date)}
                      renderInput={(params) => (
                        <TextField required variant="outlined" {...params} />
                      )}
                    />
                    <FormHelperText error={!!endDateError}>
                      {endDateError}
                    </FormHelperText>
                  </FormControl>
                </Stack>
              </LocalizationProvider>
            </Stack>
            <div className={classes.modalBody}>
              <DropzoneArea
                dropzoneClass={classez?.dropZone}
                style={{ marginTop: "15px" }}
                showAlerts={false}
                useChipsForPreview={true}
                acceptedFiles={[
                  "image/jpeg",
                  "image/jpg",
                  "image/png",
                  "image/webp",
                ]}
                filesLimit={1}
                maxFiles={1}
                maxFileSize={5000000}
                dropzoneText={
                  "Drop or Click to Upload Cover Image (JPEG, JPG, PNG, WEBP)"
                }
                onChange={(files) => setCover(files)}
                error={!!coverError}
                helperText={coverError}
              />
            </div>
          </div>
          <div className={classes.modalActions}>
            <Stack spacing={2} direction="row">
              <div className={classes.modalActions}>
                <LoadingButton
                  loading={createEventLoading}
                  variant="contained"
                  color="success"
                  style={{
                    fontWeight: "bold",
                    color: "#fff",
                    borderRadius: "10px",
                    backgroundColor: colorTags.TERTIARY,
                  }}
                  onClick={handleSave}>
                  Save
                </LoadingButton>
                <Button
                  variant="outlined"
                  color="error"
                  style={{
                    color: "#FF0000",
                    marginLeft: "10px",
                    borderRadius: "15px",
                  }}
                  onClick={onClose}>
                  Cancel
                </Button>
              </div>
            </Stack>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default AddEvent;
