import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CardContent, Divider } from "@mui/material";
import { Card, Chip, Typography } from "@material-ui/core";

const categoryColors = {
  Editorial: { borderColor: "blue", backgroundColor: "rgba(0, 0, 255, 0.1)" },
  Economy: { borderColor: "green", backgroundColor: "rgba(0, 128, 0, 0.1)" },
  Agriculture: {
    borderColor: "brown",
    backgroundColor: "rgba(165, 42, 42, 0.1)",
  },
  Industry: {
    borderColor: "grey",
    backgroundColor: "rgba(128, 128, 128, 0.1)",
  },
  Service: { borderColor: "purple", backgroundColor: "rgba(128, 0, 128, 0.1)" },
  "Green Economy": {
    borderColor: "darkgreen",
    backgroundColor: "rgba(0, 100, 0, 0.1)",
  },
  "Climate Change": {
    borderColor: "lightgreen",
    backgroundColor: "rgba(195, 255, 147, 0.3)",
  },
  "Technology and Its Impact": {
    borderColor: "teal",
    backgroundColor: "rgba(0, 128, 128, 0.1)",
  },
  "Inclusive Economies": {
    borderColor: "orange",
    backgroundColor: "rgba(255, 165, 0, 0.1)",
  },
  "Business News": {
    borderColor: "navy",
    backgroundColor: "rgba(0, 0, 128, 0.1)",
  },
  Entrepreneurship: {
    borderColor: "darkorange",
    backgroundColor: "rgba(255, 140, 0, 0.1)",
  },
  "Wholesale and retail trade": {
    borderColor: "darkgoldenrod",
    backgroundColor: "rgba(184, 134, 11, 0.1)",
  },
  "Export/ Import": {
    borderColor: "darkcyan",
    backgroundColor: "rgba(0, 139, 139, 0.1)",
  },
  "Transport and Logistics": {
    borderColor: "gold",
    backgroundColor: "rgba(255, 215, 0, 0.1)",
  },
  Agribusiness: {
    borderColor: "darkolivegreen",
    backgroundColor: "rgba(85, 107, 47, 0.1)",
  },
  Manufacturing: {
    borderColor: "darkslategray",
    backgroundColor: "rgba(47, 79, 79, 0.1)",
  },
  Construction: {
    borderColor: "dimgray",
    backgroundColor: "rgba(105, 105, 105, 0.1)",
  },
  "Banking and Finance": {
    borderColor: "indigo",
    backgroundColor: "rgba(75, 0, 130, 0.1)",
  },
  "Tourism and Hospitality": {
    borderColor: "crimson",
    backgroundColor: "rgba(220, 20, 60, 0.1)",
  },
  Companies: {
    borderColor: "peru",
    backgroundColor: "rgba(205, 133, 63, 0.1)",
  },
  Education: {
    borderColor: "saddlebrown",
    backgroundColor: "rgba(139, 69, 19, 0.1)",
  },
  Energy: { borderColor: "olive", backgroundColor: "rgba(128, 128, 0, 0.1)" },
  "Fisheries and Aquaculture": {
    borderColor: "darkblue",
    backgroundColor: "rgba(0, 0, 139, 0.1)",
  },
  Health: {
    borderColor: "firebrick",
    backgroundColor: "rgba(178, 34, 34, 0.1)",
  },
  Insurance: {
    borderColor: "orchid",
    backgroundColor: "rgba(218, 112, 214, 0.1)",
  },
  "Features and Advocacy": {
    borderColor: "tomato",
    backgroundColor: "rgba(255, 99, 71, 0.1)",
  },
  Opinions: {
    borderColor: "slateblue",
    backgroundColor: "rgba(106, 90, 205, 0.1)",
  },
  Reports: {
    borderColor: "steelblue",
    backgroundColor: "rgba(70, 130, 180, 0.1)",
  },
  "Addis Chamber TV": {
    borderColor: "darkmagenta",
    backgroundColor: "rgba(139, 0, 139, 0.1)",
  },
  "Addis Chamber Podcast": {
    borderColor: "darkviolet",
    backgroundColor: "rgba(148, 0, 211, 0.1)",
  },
  Events: { borderColor: "coral", backgroundColor: "rgba(255, 127, 80, 0.1)" },
  Neutral: { borderColor: "black", backgroundColor: "rgba(0, 0, 0, 0.1)" },
};

const useStyles = makeStyles((theme) => ({
  mostRead: {
    // width: "80%",
    width: 350,

    border: "0.7px solid lightgrey",
    padding: theme.spacing(2),
  },
  header: {
    fontSize: 18,
    fontWeight: "bold",
  },
  card: {
    backgroundColor: "transparent",
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  content: {
    flex: 1,
  },
  title: {
    fontSize: 20,
    lineHeight: 1.2,
    fontFamily: "Times-new-roman",
    fontWeight: "bold",
  },
  chip: {
    position: "absolute",
    top: theme.spacing(1),
    left: theme.spacing(1),
    zIndex: 1,
  },
  description: {
    fontSize: 14,
    marginTop: theme.spacing(0.5),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

const MostReadThisMonth = () => {
  const classes = useStyles();
  const news = [
    {
      title: "Deep Dive into Quantum Computing",
      description: "Exploring the latest advancements in quantum computing.",
      category: "Technology and Its Impact",
    },
    {
      title: "The Impact of Climate Change on Global Agriculture",
      description: "How climate change is affecting agriculture worldwide.",
      category: "Climate Change",
    },
    {
      title: "The Future of Artificial Intelligence",
      description: "AI advancements and their potential impact on society.",
      category: "Technology and Its Impact",
    },
    {
      title: "New Developments in Renewable Energy",
      description: "Innovations in renewable energy sources.",
      category: "Energy",
    },
    {
      title: "Global Economic Trends in 2024",
      description: "An overview of the global economic landscape.",
      category: "Economy",
    },
  ];

  return (
    <div className={classes.mostRead}>
      <Typography className={classes.header}>Most Read This Month</Typography>
      <Divider className={classes.divider} />
      {news.map((item, index) => (
        <Card key={index} className={classes.card}>
          <CardContent className={classes.content}>
            <Typography className={classes.title}>{item.title}</Typography>
          </CardContent>
          <Chip
            label={item.category}
            className={classes.chip}
            size="small"
            style={{
              borderColor:
                categoryColors[item.category]?.borderColor ||
                categoryColors.Neutral.borderColor,
              backgroundColor:
                categoryColors[item.category]?.backgroundColor ||
                categoryColors.Neutral.backgroundColor,
            }}
          />
        </Card>
      ))}
    </div>
  );
};

export default MostReadThisMonth;
