import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  CardContent,
  Card,
  Divider,
  Grid,
  Chip,
} from "@material-ui/core";

// import { images } from "../../../assets";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "95%",
    margin: "0 auto",
  },
  divider: {
    marginTop: theme.spacing(3),
    backgroundColor: "#272829",
    width: "100%",
  },
  categoryTitle: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    margin: theme.spacing(2, 0),
    lineHeight: 1.2,
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  smallerStory: {
    position: "relative",
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: "none",
  },
  smallerMedia: {
    width: "100%",
    height: "120px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
  },
  smallerContent: {
    width: "100%",
    padding: theme.spacing(2),
    paddingTop: "0px",
    textAlign: "left",
  },
  smallerTitle: {
    fontSize: "1.2rem",
    fontFamily: "Times-new-roman",
    fontWeight: "bold",
    marginTop: "10px",
    color: "#222",
  },
  smallerIntro: {
    fontSize: "0.9rem",
    lineHeight: 1.2,
    color: "#555",
  },
  date: {
    position: "absolute",
    bottom: theme.spacing(1),
    left: theme.spacing(1),
    color: "#fff",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    padding: "2px 5px",
    borderRadius: "3px",
    fontSize: "0.8rem",
  },
}));

const CategoryNews = ({ category, articles, category2, articles2 }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Divider className={classes.divider} />
      <Typography className={classes.categoryTitle}>{category}</Typography>
      <Grid container spacing={4}>
        {articles.map((article, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card className={classes.smallerStory}>
              <div
                className={classes.smallerMedia}
                style={{ backgroundImage: `url(${article.image})` }}>
                <div className={classes.date}>{article.date}</div>
              </div>
              <CardContent
                className={classes.smallerContent}
                style={{
                  paddingLeft: "0px",
                }}>
                <Typography variant="h6" className={classes.smallerTitle}>
                  {article.title}
                </Typography>
                <Typography variant="body2" className={classes.smallerIntro}>
                  {article.intro}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Divider className={classes.divider} />
      <Typography className={classes.categoryTitle}>{category2}</Typography>
      <Grid container spacing={4}>
        {articles2.map((article, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card className={classes.smallerStory}>
              <div
                className={classes.smallerMedia}
                style={{ backgroundImage: `url(${article.image})` }}>
                <div className={classes.date}>{article.date}</div>
              </div>
              <CardContent
                className={classes.smallerContent}
                style={{
                  paddingLeft: "0px",
                }}>
                <Typography variant="h6" className={classes.smallerTitle}>
                  {article.title}
                </Typography>
                <Typography variant="body2" className={classes.smallerIntro}>
                  {article.intro}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default CategoryNews;
