import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import {
  Box,
  Typography,
  Card,
  CardContent,
  TextField,
  IconButton,
  Divider,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import CancelIconOutlined from "@material-ui/icons/Cancel";
// import CancelIconOutlined from
import Title from "../Header/Title2";
import LogoHead from "../Header/LogoHead2";
import Header from "../Header/Header2";
import Footer from "../Footer/Footer2";
import Advertisements from "../NewsComponents2/Advertisements";
import { images } from "../../../assets";
import { colorTags } from "../../../constants/variants";
import { useGetSearchResultsQuery } from "../../../features/api/apiSlice";
import { BASE_FILE_URL } from "../../../constants/apiTags";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  searchBox: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(2, 0),
    position: "relative",
  },
  searchInput: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#3333",
        borderBlockWidth: "0.5px",
      },
      "&:hover fieldset": {
        borderColor: "#333",
        borderBlockWidth: "0.5px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#333",
        borderBlockWidth: "0.5px",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#333",
      borderBlockWidth: "0.5px",
    },
  },
  searchIcons: {
    position: "absolute",
    right: 0,
    top: 0,
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
  section: {
    margin: theme.spacing(3, 0),
  },
  card: {
    display: "flex",
    padding: "20px 20px 20px 0px",
    margin: theme.spacing(1, 0),
    justifyContent: "space-between",
    boxShadow: "none",
    borderBottom: "1px solid #bbbb",
    borderRadius: "0px",
  },
  cardContent: {
    flex: 1,
    paddingLeft: "0px",
    fontFamily: "Times-new-roman",
  },
  image: {
    width: 100,
    height: 100,
    marginLeft: theme.spacing(2),
    borderRadius: "10px",
    objectFit: "contain",
    [theme.breakpoints.down("sm")]: {
      width: 100,
      height: 100,
    },
  },
  loadMoreButton: {
    display: "block",

    margin: "0 auto",
    marginTop: "15px",
    backgroundColor: "white",
    border: "1px solid black",
    color: "black",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  containerBox: {
    width: "100%",
    padding: "2% 25%",
    [theme.breakpoints.down("sm")]: {
      padding: "2% 10%",
    },
  },
  topText: {
    fontSize: "25px",
  },
  newsTitle: {
    color: "black",
    cursor: "pointer",
    "&:hover": {
      color: colorTags.TERTIARY,
    },
  },

  eventsTitle: {
    color: colorTags.PRIMARY,
    cursor: "pointer",
  },
}));

const SearchResults = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const { query } = queryString.parse(location.search);

  const [searchTerm, setSearchTerm] = useState(query || "");
  const [news, setNews] = useState([]);
  const [pdfDocuments, setPdfDocuments] = useState([]);
  const [events, setEvents] = useState([]);
  const [newsPage, setNewsPage] = useState(1);
  const newsPerPage = 2;
  const {
    data: searchResults,
    isLoading: isLoadingSearch,
    isError: isLoadingError,
    error: searchError,
  } = useGetSearchResultsQuery({ query: query && query });
  useEffect(() => {
    // Fetch data based on query (mock data for demonstration)
    const fetchData = () => {
      // Mock news data
      const newsData = Array.from({ length: 6 }, (_, i) => ({
        title: `News related to ${query} ${i + 1}`,
        description: "Description " + (i + 1),
        date: `2024-06-${10 + i}`,
        image: images.NewsPlaceholder3,
      }));

      setNews(searchResults?.articles?.slice(0, newsPage * newsPerPage));

      // Mock PDF documents data
      setPdfDocuments(
        //   [
        //   { title: `PDF document related to ${query} 1`, url: "/path/to/pdf1" },
        //   { title: `PDF document related to ${query} 2`, url: "/path/to/pdf2" },
        // ]
        searchResults?.publications ?? []
      );

      // Mock events data
      setEvents(
        //   [
        //   {
        //     title: `Event related to ${query} 1`,
        //     date: "2024-06-20",
        //     location: "Location 1",
        //   },
        //   {
        //     title: `Event related to ${query} 2`,
        //     date: "2024-07-15",
        //     location: "Location 2",
        //   },
        // ]
        searchResults?.events ?? []
      );
    };

    fetchData();
  }, [query, searchResults]);

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      navigate(`/search?query=${encodeURIComponent(searchTerm.trim())}`);
    }
  };
  const handleOpenInNew = (url) => {
    window.open(BASE_FILE_URL + url, "_blank");
  };
  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const loadMoreNews = () => {
    setNewsPage(newsPage + 1);
  };

  const sidebarAds = [
    {
      title: "Sidebar Ad 1",
      image: images.AdPlaceholderTop,
      description: "This is sidebar ad 1",
    },
    {
      title: "Sidebar Ad 2",
      image: images.AdPlaceholderTop2,
      description: "This is sidebar ad 2",
    },
    {
      title: "Sidebar Ad 3",
      image: images.AdPlaceholderTop3,
      description: "This is sidebar ad 3",
    },
  ];

  return (
    <>
      <Title />
      <LogoHead />
      <Header />

      <Box className={classes.containerBox}>
        <form className={classes.searchBox} onSubmit={handleSearch}>
          <TextField
            variant="outlined"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className={classes.searchInput}
          />
          <div className={classes.searchIcons}>
            {searchTerm && (
              <IconButton onClick={handleClearSearch}>
                <CancelIconOutlined />
              </IconButton>
            )}
            <IconButton type="submit">
              <SearchIcon />
            </IconButton>
          </div>
        </form>

        <Typography variant="h4" className={classes.topText}>
          {t("Search Results for")}: {query}
        </Typography>

        <Box className={classes.section}>
          <Divider
            style={{
              // backgroundColor: "#333",
              backgroundColor: colorTags.TERTIARY,
            }}
          />
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              color: "#222",
            }}>
            {t("News")}
          </Typography>
          {news?.length ? (
            news?.map((item, index) => (
              <Card key={index} className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <Link to={`/news-detail/${item && item?.id}`}>
                    <Typography
                      variant="h6"
                      style={{
                        fontFamily: "Times-new-roman",
                      }}
                      className={classes.newsTitle}>
                      {item.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{
                        fontFamily: "Times-new-roman",
                      }}>
                      {item.description}
                    </Typography>
                    <Typography variant="body2">{item.date}</Typography>
                  </Link>
                </CardContent>

                <img
                  // src={item.image}
                  src={
                    item.coverImage
                      ? BASE_FILE_URL + item.coverImage
                      : images?.NewsArticlePlaceholder
                  }
                  alt={item.title}
                  className={classes.image}
                />
              </Card>
            ))
          ) : (
            <Typography
              style={{
                fontFamily: "Times-new-roman",
              }}>
              {t("No news found")}
            </Typography>
          )}
          {news?.length < 6 && (
            <Button
              variant="outlined"
              //   color="primary"
              onClick={loadMoreNews}
              className={classes.loadMoreButton}>
              {t("Load More")}
            </Button>
          )}
        </Box>

        <Box className={classes.section}>
          <Divider
            style={{
              // backgroundColor: "#333",
              backgroundColor: colorTags.TERTIARY,
            }}
          />
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              color: "#222",
            }}>
            {t("Publications")}
          </Typography>
          {pdfDocuments?.length ? (
            pdfDocuments?.map((item, index) => (
              <Card key={index} className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <Typography
                    variant="h5"
                    style={{
                      // fontFamily: "Times-new-roman",
                      fontWeight: "bold",
                    }}>
                    {item.publicationTitle}
                  </Typography>
                  <a
                    className={classes.eventsTitle}
                    onClick={() => handleOpenInNew(item?.publicationFile)}
                    rel="noopener noreferrer">
                    {t("View Document")}
                  </a>
                </CardContent>
              </Card>
            ))
          ) : (
            <Typography
              style={{
                fontFamily: "Times-new-roman",
              }}>
              {t("No documents found")}
            </Typography>
          )}
        </Box>

        <Box className={classes.section}>
          <Divider
            style={{
              // backgroundColor: "#333",
              backgroundColor: colorTags.TERTIARY,
            }}
          />
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              color: "#222",
            }}>
            {t("Events")}
          </Typography>
          {events?.length ? (
            events?.map((item, index) => (
              <Card key={index} className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <Typography
                    variant="h5"
                    style={
                      {
                        // fontFamily: "Times-new-roman",
                      }
                    }
                    className={classes.eventsTitle}>
                    {item.title}
                  </Typography>
                  <Typography variant="body2">{item.date}</Typography>
                  <Typography variant="body2">{item.location}</Typography>
                </CardContent>
              </Card>
            ))
          ) : (
            <Typography
              style={{
                fontFamily: "Times-new-roman",
              }}>
              {t("No events found")}
            </Typography>
          )}
        </Box>
      </Box>
      <Advertisements position="header" items={sidebarAds} />

      <Footer />
    </>
  );
};

export default SearchResults;
