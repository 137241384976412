import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Stack } from "@mui/material";

import { images } from "../../../assets";
import { Link, useNavigate } from "react-router-dom";
import { colorTags } from "../../../constants/variants";

const useStyles = makeStyles((theme) => ({
  topBar: {
    width: "100%",
    height: 120,
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      height: 80,
    },
  },
  text: {
    fontFamily: "'Times New Roman', Times, serif",
    fontWeight: "bold",
    fontSize: "22px",
    margin: theme.spacing(0, 2),
    color: colorTags.PRIMARY,
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      margin: theme.spacing(0, 1),
    },
  },
  textBottom: {
    fontFamily: "'Times New Roman', Times, serif",
    fontWeight: "bold",
    fontSize: "15px",
    textAlign: "center",
    margin: theme.spacing(0, 2),
    color: colorTags.PRIMARY,
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      margin: theme.spacing(0, 1),
    },
  },
  image: {
    maxWidth: 60,
    height: "auto",
    objectFit: "contain",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      maxWidth: 50,
    },
  },
}));

const LogoHead = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <div className={classes.topBar}>
      <Stack direction="column">
        <Stack direction="row">
          <Typography className={classes.text}></Typography>
          <img
            src={images.AcLogo}
            alt="Top Bar"
            onClick={() => navigate("/")}
            className={classes.image}
          />
          <div
            //  onClick={() => navigate("/")}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              paddingLeft: "0px",
              alignItems: "flex-end",
            }}>
            <Link
              to={`/`}
              style={{
                marginLeft: "0px",
                display: "flex",

                justifyContent: "flex-start",
                paddingLeft: "0px",
                alignItems: "flex-end",
              }}>
              <Typography
                style={{ marginLeft: "5px" }}
                className={classes.text}>
                {" "}
                <span style={{ color: colorTags.TERTIARY }}>ዜና</span> አዲስ ቻምበር
              </Typography>
            </Link>
          </div>
        </Stack>
        <Typography className={classes.textBottom}>
          Addis Chamber Business News
        </Typography>
      </Stack>
    </div>
  );
};

export default LogoHead;

// import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import { BASE_FILE_URL } from "../../../constants/apiTags";
// import { useGetCustomImageByIdQuery } from "../../../features/api/apiSlice";
// import { images } from "../../../assets";
// import { useNavigate } from "react-router-dom";
// import { colorTags } from "../../../constants/variants";
// const useStyles = makeStyles((theme) => ({
//   topBar: {
//     width: "100%",
//     height: 120,
//     backgroundColor: "white",
//     // backgroundColor: `rgba(${colorTags.PRIMARY_RGBA},0.2)`,

//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     [theme.breakpoints.down("sm")]: {
//       height: 80,
//     },
//   },
//   image: {
//     maxWidth: 100,
//     // maxWidth: 300,
//     // width: 150,

//     // maxHeight: 100,
//     height: "auto",
//     objectFit: "contain",
//     cursor: "pointer",
//     [theme.breakpoints.down("sm")]: {
//       maxWidth: 80,
//     },
//   },
// }));

// const LogoHead = () => {
//   const classes = useStyles();
//   const navigate = useNavigate();
//   return (
//     <div className={classes.topBar}>
//       {
//         <img
//           src={images.AcLogo}
//           alt="Top Bar"
//           onClick={() => navigate("/")}
//           className={classes.image}
//         />
//       }
//     </div>
//   );
// };

// export default LogoHead;

// import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import { Typography } from "@material-ui/core";

// import { images } from "../../../assets";
// import { useNavigate } from "react-router-dom";
// import { colorTags } from "../../../constants/variants";

// const useStyles = makeStyles((theme) => ({
//   topBar: {
//     width: "100%",
//     height: 120,
//     backgroundColor: "white",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     [theme.breakpoints.down("sm")]: {
//       height: 80,
//     },
//   },
//   text: {
//     fontFamily: "'Times New Roman', Times, serif",
//     fontWeight: "bold",
//     fontSize: "24px",
//     margin: theme.spacing(0, 2),
//     color: colorTags.PRIMARY,
//     [theme.breakpoints.down("sm")]: {
//       fontSize: "18px",
//       margin: theme.spacing(0, 1),
//     },
//   },
//   image: {
//     maxWidth: 60,
//     height: "auto",
//     objectFit: "contain",
//     cursor: "pointer",
//     [theme.breakpoints.down("sm")]: {
//       maxWidth: 80,
//     },
//   },
// }));

// const LogoHead = () => {
//   const classes = useStyles();
//   const navigate = useNavigate();
//   return (
//     <div className={classes.topBar}>
//       <Typography className={classes.text}>ACBN</Typography>
//       <img
//         src={images.AcLogo}
//         alt="Top Bar"
//         onClick={() => navigate("/")}
//         className={classes.image}
//       />
//       <Typography className={classes.text}>Digital</Typography>
//     </div>
//   );
// };

// export default LogoHead;

// import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import { BASE_FILE_URL } from "../../../constants/apiTags";
// import { useGetCustomImageByIdQuery } from "../../../features/api/apiSlice";
// import { images } from "../../../assets";
// import { useNavigate } from "react-router-dom";
// const useStyles = makeStyles((theme) => ({
//   topBar: {
//     width: "100%",
//     height: 150,
//     backgroundColor: "#006837",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//   },
//   image: {
//     width: 400,
//     // maxWidth: 300,
//     maxHeight: 100,
//     height: "auto",
//     objectFit: "contain",
//     cursor: "pointer",
//   },
// }));

// const LogoHead = () => {
//   const classes = useStyles();
//   const navigate = useNavigate();
//   return (
//     <div className={classes.topBar}>
//       {
//         <img
//           src={images.AACCSALogoWhite}
//           alt="Top Bar"
//           onClick={() => navigate("/")}
//           className={classes.image}
//         />
//       }
//     </div>
//   );
// };

// export default LogoHead;
