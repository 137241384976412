import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Card } from "@material-ui/core";
import { useGetPublicationsQuery } from "../../../features/api/apiSlice";
import { BASE_FILE_URL, BASE_FILE_URL_HTTP } from "../../../constants/apiTags";

const useStyles = makeStyles((theme) => ({
  monthlyEdition: {
    position: "relative",
    width: "100%",
    maxWidth: "300px",
    height: "400px",
    marginBottom: theme.spacing(3),
    borderRadius: "8px",
    overflow: "hidden",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  overlay: {
    position: "absolute",
    bottom: "0",
    left: "0",
    right: "0",

    height: "50%",
    clipPath: "polygon(0 0%, 100% 70%, 100% 100%, 0 100%)",

    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    backgroundColor: "rgba(2, 131, 145, 0.7)",
    // backdropFilter: "blur(1px)",
    zIndex: 2, // Ensure overlay is on top of the image
    boxShadow:
      "rgba(136, 165, 191, 0.6) 6px 6px 16px 0px, rgba(0, 0, 0, 0.2) -6px -6px 16px 0px", // Adjusted shadow strength
  },
  overlayText: {
    position: "relative",
    // color: "#000",
    color: "#fff",

    fontWeight: "bold",
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-start",
    zIndex: 3, // Ensure text is above everything else
  },
}));

// Helper function to extract and transform the month
const getMonthName = (dateString) => {
  const date = new Date(dateString);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return monthNames[date.getMonth()];
};

const MonthlyEdition = () => {
  const classes = useStyles();
  const {
    data: publicationData,
    isLoading: publicationDataLoading,
    isError: publicationDataError,
  } = useGetPublicationsQuery();

  if (publicationDataLoading) {
    return <div>Loading...</div>;
  }

  if (publicationDataError) {
    return <div>Error loading data.</div>;
  }

  // Assuming publicationData is an array and using the first item
  const publication = publicationData?.[0];

  // Extract month name from the publication date
  const monthName = publication?.month
    ? getMonthName(publication.month)
    : "Current";

  // Placeholder image if the coverImage isn't present
  const imageUrl = publication?.coverImage
    ? `${BASE_FILE_URL}${publication?.coverImage}`
    : "https://via.placeholder.com/400x400?text=No+Image+Available";

  return (
    <Card
      className={classes.monthlyEdition}
      onClick={() =>
        window.open(
          publication
            ? `${BASE_FILE_URL_HTTP}${publication?.publicationFile}`
            : "http://64.227.144.151:3000/uploads/08c95d064680cea416c85601d96c28d2.pdf"
        )
      }>
      <img src={imageUrl} alt="Monthly Edition" className={classes.image} />
      <div className={classes.overlay}>
        <div className={classes.overlayText}>
          <Typography
            variant="h3"
            style={{
              // fontFamily: "Times-new-roman",
              fontWeight: "bold",
              fontSize: "2rem",
            }}>
            {monthName}
            <br />
            Edition
          </Typography>
        </div>
      </div>
    </Card>
  );
};

export default MonthlyEdition;
