import { forwardRef, useMemo } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink, useLocation } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

export default function ListItemLink(props) {
  const { icon, primary, to, isPaddingLeft } = props;
  const { pathname } = useLocation();

  const renderLink = useMemo(
    () =>
      forwardRef(function Link(itemProps, ref) {
        return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />;
      }),
    [to]
  );

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        backdropFilter: "blur(7px)",
        // backgroundColor: "rgba(0,0,0, 0.5)",
        backgroundColor: props.bgColor,
        borderRadius: "10px",
        margin: "5px",
      }}>
      <ListItem button component={renderLink} sx={{ pl: isPaddingLeft && 4 }}>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText primary={primary} sx={{ color: props.textColor }} />
      </ListItem>
    </div>
  );
}

ListItemLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  isPaddingLeft: PropTypes.bool,
  textColor: PropTypes.string,
};
