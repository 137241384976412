import React, { useState, useEffect } from "react";
import Header from "./Header/Header2";
import LogoHead2 from "./Header/LogoHead2";
import Footer from "./Footer/Footer2";
import { images } from "../../assets/index";
import LoadingScreen from "../../components/LoadingMain";
import BackToTop from "../../components/BackToTop";
import TitleTop from "./Header/Title2";
import { Stack, useMediaQuery } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import {
  useGetCustomImagesQuery,
  usePostAnalyticsVisitsMutation,
} from "../../features/api/apiSlice";
import { Helmet } from "react-helmet";
import axios from "axios";
import { BASE_URL } from "../../constants/apiTags";
import { useTranslation } from "react-i18next";

import TrendingNews from "./NewsComponents2/TrendingNews";
import TopNewsToday from "./NewsComponents2/TopNewsToday";
import Advertisements from "./NewsComponents2/Advertisements";
import TopStory from "./NewsComponents2/TopStory";
import CategoryNewsLine from "./NewsComponents2/CategoryNewsLines";
import CategoryNewsFull from "./NewsComponents2/CategoryNewsFull";
import MonthlyEdition from "./NewsComponents2/MonthlyEdition";
import MostReadThisMonth from "./NewsComponents2/MostReadThisMonth";
import YoutubeChannelFrame from "./NewsComponents2/YoutubeFrame";
import MonthlyPublications from "./NewsComponents2/MonthlyPublications";
import EventsSection from "./NewsComponents2/EventsSection";

import {
  useGetArticlesQuery,
  useGetArticlesTopTodayQuery,
  useGetArticlesCategoryLatestQuery,
  // useGetArticlesCategoryQuery,
  useGetArticlesMostReadQuery,
  useGetArticlesLatestFiveQuery,
  useGetTrendingArticlesQuery,
  useGetAdsQuery,
  useGetEventsQuery,
  useGetYoutubePlaylistQuery,
  useGetPublicationsQuery,
  useGet,
  useGetLayoutByIdQuery,
} from "../../features/api/apiSlice";
import { getMonthFromDate } from "../../components/DateConverter";
const useStyles = makeStyles((theme) => ({
  root: {},
  mobileStack: {
    flexDirection: "column",
    alignItems: "center",
  },
  desktopStack: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
}));

const HomePage = () => {
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const isTab = useMediaQuery("(max-width: 1300px)");

  const { data: customData, error, isLoading } = useGetCustomImagesQuery();
  const [analyticsApi] = usePostAnalyticsVisitsMutation();
  const [token, setToken] = useState(localStorage.getItem("authToken"));
  // const storedUser = localStorage.getItem("user");
  // const user = storedUser ? JSON.parse(storedUser) : null;
  const storedUser = localStorage.getItem("user");

  const user = storedUser ? storedUser : null;
  const [visit, setVisit] = useState(false);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  // Layout Data
  const {
    data: layoutData,
    isLoading: isLoadingLayoutData,
    isError: isErrorLayoutData,
    error: errorLayoutData,
  } = useGetLayoutByIdQuery({ id: 1 });
  useEffect(() => {
    if (layoutData)
      setTimeout(() => {
        setLoading(false);
      }, 100);
  }, [layoutData]);
  useEffect(() => {
    if (layoutData) console.log(layoutData);
  }, [layoutData]);
  // useEffect(() => {
  //   // var _mtm = window._mtm = window._mtm || [];
  //   // _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
  //   // (function() {
  //   //   var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
  //   //   g.async=true; g.src='http://64.227.144.151:8081/js/container_sb3JlbeF.js'; s.parentNode.insertBefore(g,s);
  //   // })();
  //   var _mtm = (window._mtm = window._mtm || []);
  //   _mtm.push({ "mtm.startTime": new Date().getTime(), event: "mtm.Start" });
  //   (function () {
  //     var d = document,
  //       g = d.createElement("script"),
  //       s = d.getElementsByTagName("script")[0];
  //     g.async = true;
  //     g.src = "http://64.227.144.151:8081/js/container_JYolJ8yM.js";
  //     s.parentNode.insertBefore(g, s);
  //   })();
  // }, []);
  // Advertisement data
  const {
    data: adsData,
    isLoading: isLoadingAds,
    isError: isErrorAds,
    error: adsError,
  } = useGetAdsQuery();

  const topAds = adsData?.filter((item) => {
    const matchesPage =
      item?.page?.includes("Home") || item?.page?.includes("All");
    const matchesPosition =
      item?.position?.includes("Top") || item?.position?.includes("All");
    return matchesPage && matchesPosition;
  });
  const middleAds = adsData?.filter((item) => {
    const matchesPage =
      item?.page?.includes("Home") || item?.page?.includes("All");
    const matchesPosition =
      item?.position?.includes("Middle") || item?.position?.includes("All");
    return matchesPage && matchesPosition;
  });
  const bottomAds = adsData?.filter((item) => {
    const matchesPage =
      item?.page?.includes("Home") || item?.page?.includes("All");
    const matchesPosition =
      item?.position?.includes("Bottom") || item?.position?.includes("All");

    return matchesPage && matchesPosition;
  });

  // Top Five Story Data
  const {
    data: topFiveStoryData,
    isLoading: topFiveStoryLoading,
    isError: topFiveStoryError,
    error: errorTopFiveStory,
  } = useGetArticlesLatestFiveQuery({ lang: "en" });

  const {
    data: topFiveStoryAmData,
    isLoading: topFiveStoryAmLoading,
    isError: topFiveStoryAmError,
    error: errorTopFiveStoryAm,
  } = useGetArticlesLatestFiveQuery({ lang: "am" });
  // Category lines data EN
  const {
    data: categoryLatestArticlesDataEn,
    isLoading: categoryLatestArticlesLoading,
    isError: categoryLatestArticlesError,
    error: errorCategoryLatestArticles,
  } = useGetArticlesCategoryLatestQuery({
    lang: "en",
    title: "Business",
    amount: 4,
  });
  // Category lines data EN
  const {
    data: categoryLatestArticlesDataAm,
    isLoading: categoryLatestArticlesAmLoading,
    isError: categoryLatestArticlesAmError,
    error: errorCategoryLatestArticlesAm,
  } = useGetArticlesCategoryLatestQuery({
    lang: "am",
    title: layoutData && layoutData?.categoryLatest2,
    amount: 4,
  });

  // Category full data
  const {
    data: categoryFullArticlesData,
    isLoading: categoryFullArticlesLoading,
    isError: categoryFullArticlesError,
    error: errorCategoryFullArticles,
  } = useGetArticlesCategoryLatestQuery({
    title: layoutData && layoutData?.categoryNews1,
    lang: "en",
    // "Editorial"
    amount: 7,
  });
  const {
    data: categoryFullArticlesAmData,
    isLoading: categoryFullArticlesAmLoading,
    isError: categoryFullArticlesAmError,
    error: errorCategoryFullArticlesAm,
  } = useGetArticlesCategoryLatestQuery({
    title: layoutData && layoutData?.categoryNews1,
    lang: "am",

    // "Editorial"
    amount: 7,
  });

  // Category full data2
  const {
    data: categoryFullArticlesData2,
    isLoading: categoryFullArticles2Loading,
    isError: categoryFullArticles2Error,
    error: errorCategoryFullArticles2,
  } = useGetArticlesCategoryLatestQuery({
    //  title: "Economy",
    title: layoutData && layoutData?.categoryNews2,
    lang: "en",

    amount: 7,
  });

  const {
    data: categoryFullArticlesAmData2,
    isLoading: categoryFullArticlesAm2Loading,
    isError: categoryFullArticlesAm2Error,
    error: errorCategoryFullArticlesAm2,
  } = useGetArticlesCategoryLatestQuery({
    //  title: "Economy",
    title: layoutData && layoutData?.categoryNews2,
    lang: "am",

    amount: 7,
  });
  // all articles
  const {
    data: allArticlesData,
    isLoading: allArticlesLoading,
    isError: allArticlesError,
    error: errorAllArticles,
  } = useGetArticlesQuery({ lang: "en" });
  const {
    data: allArticlesDataAm,
    isLoading: allArticlesAmLoading,
    isError: allArticlesAmError,
    error: errorAllArticlesAm,
  } = useGetArticlesQuery({ lang: "am" });

  //Trending News
  const {
    data: trendingArticlesData,
    isLoading: trendingArticlesLoading,
    isError: trendingArticlesError,
    error: errortrendingArticles,
  } = useGetTrendingArticlesQuery();

  // Top News Today
  const {
    data: topNewsTodayData,
    isLoading: topNewsTodayLoading,
    isError: topNewTodayError,
  } = useGetArticlesTopTodayQuery();

  // // category full data
  // const {
  //   data: categoryFullData,
  //   isLoading: categoryFullLoading,
  //   isError: categoryFullError,
  // } = useGetArticlesCategoryQuery();

  // most read data
  const {
    data: mostReadData,
    isLoading: mostReadLoading,
    isError: mostReadError,
  } = useGetArticlesMostReadQuery();

  // events data
  const {
    data: eventsData,
    isLoading: eventsDataLoading,
    isError: eventsDataError,
  } = useGetEventsQuery();

  // youtube playlist data
  const {
    data: playlistData,
    isLoading: playlistDataLoading,
    isError: playlistDataError,
  } = useGetYoutubePlaylistQuery();

  // monthly publications data
  const {
    data: publicationData,
    isLoading: publicationDataLoading,
    isError: publicationDataError,
  } = useGetPublicationsQuery();

  // monthly edition data
  const {
    data: editionData,
    isLoading: editionDataLoading,
    isError: editionDataError,
  } = useGetPublicationsQuery();

  if (loading) {
    return <LoadingScreen />;
  }

  // Ads data
  const sidebarAds = [
    {
      title: "Sidebar Ad 1",
      image: images.AdPlaceholderTop,
      description: "This is sidebar ad 1",
      link: "https://example.com",
    },
    // {
    //   title: "Sidebar Ad 1",
    //   image: images.AdPlaceholderTop3,
    //   description: "This is sidebar ad 1",
    // },
    {
      title: "Sidebar Ad 2",
      image: images.AdPlaceholderTop4,
      description: "This is sidebar ad 2",
      link: "https://example.com",
    },
    {
      title: "Sidebar Ad 3",
      image: images.AdPlaceholderTop5,
      description: "This is sidebar ad 3",
      link: "https://example.com",
    },
    {
      title: "Sidebar Ad 3",
      image: images.AdPlaceholderTop6,
      description: "This is sidebar ad 3",
      link: "https://example.com",
    },
  ];

  // top Story[0]
  const sampleArticle = {
    title:
      "Public Procurement and Property Authority Calls for Private sector to use the newly launched procurement Portal for Effective and Efficient Business ",
    image: images.TopStoryPlaceholderNative,
    imageCaption:
      "Haji Ibsa, Director General of Public Procurement and Property Authority",
    intro:
      "In order to raise the awareness of stakeholders towards electronic procurement, the authority has made successive high profile panel discussion with concerned stakeholders including business communities. On June 6 2023 the authority made a consultation forum in Addis Ababa with diverse stakeholders that comprise business communities, representatives of CSOs, professional associations among others.",
    category: "Editorial",
  };
  // top storyAm[0]
  const sampleArticleAm = {
    title: "ውጤታማና ቀልጣፋ የሰው ሀይል ላይ ኢንቨስት በማድረግ የኢንዱስትሪ እድገትን የማፋጠን አስፈላጊነት",
    image: images.TopStoryPlaceholderNativeAm,
    imageCaption: "በ78ኛው የተባበሩት መንግስታት ጉባኤ ላይ ከአንድ ወር በፊት ንግግር አድርገው ነበር፡፡ ",
    intro:
      "በቅርቡ የተደረገው የተባበሩት መንግስታት ጉባኤ የተባበሩት መንግስታት የአፈሪቃ ኢኮኖሚክ ኮሚሽን ተጠባባቂ ዋና ስራ አስፈጻሚ አንቶንዮ ፔድሮ የአፈሪቃ ሀገራት መሪዎችን በኢንዱስትሪ ክህሎት ላይ መዋለ ንዋያቸውን በማፍሰስ በአአፈሪቃ የኢንዱስትሪ ሽግግር እውን እንዲሆን ጥሪ አቅርበዋል፡፡ ",
    category: "ርዕሰ አንቀጽ",
  };

  // top story
  const smallerArticles = [
    {
      title: "Chamber  conducts training on RBC ",
      image: images.SmallNewsStoryNative,
      imageCaption: "By Yidnekachew Alemayehu",
      intro:
        "The training was help companies engaged in  papaya value chains install the principles of  Responsible Business Conduct, RBC in their production system.",
      category: "Economy",
    },
    {
      title:
        "“Promoting Responsible Business Conduct (RBC) is key to attract and retain quality investment” Mr. Shibeshi Bettemariam , Secretary General of Addis Chamber underscores ",
      image: images.SmallNewsStoryNative2,
      imageCaption: "Ashenafi Mitiku",
      intro:
        "Responsible Business conduct (RBC) is a concept based on the expectation that all companies can do business with positive contribution to economic, environment, and social progress with a view to achieving sustainable development and avoiding, remediating and addressing adverse impacts related to an enterprises direct and indirect operation, products or services. ",
      category: "Technology",
    },
    {
      title:
        "Public Procurement and Property Authority Calls for Private sector to use the newly launched procurement Portal for Effective and Efficient Business ",
      image: images.SmallNewsStoryNative3,
      imageCaption: "Ashenafi Mitiku",
      intro:
        "Back in 2018 the Federal Public Procurement and property Administration launched the Electronic Government Procurement System (e-GP) Implementation Strategy in Ethiopia to undertake the procurement process to be electronically and in an easy way. However introducing and implementing the new procurement system will not be an easy task since the idea of electronic procurement system in Ethiopia is uncommon. ",
      category: "Health",
    },
    {
      title:
        "“Improving Doing Business Environment is Key for Ethiopia to Attract More Israeli Investors” Alelegn Admassu , Israeli Ambassador to Ethiopia  ",
      image: images.SmallNewsStoryNative4,
      imageCaption: "Ashenafi Mitiku",
      intro:
        "According to the report on the Israeli Economy today, Israel is one of the most resilient and technologically-advanced market economies in the world. Its skilled workforce and concentration of venture capital allow the country to lead in innovative industries such as high-tech, cleantech, and the life sciences.",
      category: "Business",
    },
  ];
  // top storyAm
  const smallerArticlesAm = [
    {
      title: "ብሪክስ እና  የኢትዮጵያ  የግሉ የምጣኔ ሀብት ዘርፍ",
      image: images.SmallNewsStoryPlaceholderNative1,
      imageCaption: "በእጹብድንቅ ስለሺ(ዶ/ር)",
      intro:
        "የየአባል አገሮችን  የመጀመሪያ ፊደላት  በመደርደር BRICS  (ብራዚል፣ ሩሲያ፣ ህንድ፣ ቻይና እና ደቡብ አፍሪካ) ተብሎ የሚታወቀዉ ጥምረት ምንድን ነው? አሁን  ኢትዮጵያን ጨምሮ ስድስት አገራትን በማስገባት አድማሱን  እያሰፋ ነው። ",
      category: "ኢኮኖሚ",
    },
    {
      title: "ኩባንያዎች ለነጻ ገበያው ውድድር ራሳቸውን ያዘጋጁ!",
      image: images.NewsArticlePlaceholder,
      imageCaption: "በየአለም አቀፉ አቅማና አነስተኞች የሚዘጋጁ እውነተኛ አካባቢያት.",
      intro: "በአለም ተለዋጭ አቅማ እና ተሳካ እንደአነስተኞች በአለም ላይ ሊሄድ ይችላል የሚሆን.",
      category: "ኢኮኖሚ",
    },
    {
      title: "ሚስተር ክላቨር ጋቴቴ የተባበሩት መንግስታት ኢኮኖሚክ ኮሚሽን ዋና ስራ አስፈጻሚ ሆነው ተሾሙ",
      image: images.SmallNewsStoryPlaceholderNative2,
      imageCaption: "የሩዋንዳው ሚስተር ክላቨር ጋቴቴ",
      intro:
        "የተባበሩት መንግስታት ድርጅት ዋና ጸሀፊ አንቶኒዮ ጉቴሬዝ የሩዋንዳውን ሚስተር ክላቨር ጋቴቴ የአፈሪቃ ኢኮኖሚክ ኮሚሽን ዋና ስራ አስፈጻሚ አድርገው መሾማቸውን አስታውቀዋል፡፡ አንቶኒዮ ጉቴሬዝ ለቀድሞው የድርጅቱ ዋና ጸሀፊ ቬራ ሶንግዊ እንዲሁም ለምክትላቸው አንቶንዮ ፔድሮ አድናቆታቸውን ቸረዋል፡፡ ",
      category: "ርዕሰ አንቀጽ",
    },
    {
      title:
        "ምክር ቤቱ በውል ግብርና (Contract Farming) ምንነት እና ጠቀሜታ ዙርያ የግንዛቤ ማስጨበጫ   መድረክ አካሄደ",
      image: images.SmallNewsStoryPlaceholderNative3,
      imageCaption: "አቅማ እና ልምምዶችን አቅማ በማስታወቅ.",
      intro: "በማህበረሰቡ ውስጥ የሚታይ ተቋቋሚ እድገትና እንደማህበረሰብ እውነተኛ ማህበራዊ ተግባር.",
      category: "ግብርና",
    },
  ];

  // categoryLatest
  const categoryArticlesLine = [
    {
      title: "Responsible Business Conduct for Sustainability",
      intro:
        "Responsible Business conduct (RBC) is a concept based on the expectation that all companies can do business with positive contribution to economic, environment, and social progress with a view to achieving sustainable development and avoiding, remediating and addressing adverse impacts related to an enterprises direct and indirect operation, products or services. ",
      image: images.NewsArticlePlaceholder,
      category: "Editorial",

      date: "June 4, 2024",
    },
    {
      title: "Opening Skies for Africa",
      intro:
        "Despite having about 18% of the world's population, Africa contributes about 2% of global air travel. This is because many states restrict their air service markets to protect their national carriers, and for that reason, many countries have not signed on to the open skies initiative. ",
      image: images.CategoryNewsLinePlaceholder,
      category: "Editorial",

      date: "June 3, 2024",
    },
    {
      title:
        "IoD to inculcate highest standards of Ethics and Corporate Governance",
      intro:
        "Directorship is one of the most important leadership roles in society. Organizations in the private, public and non-for-profit sectors rely on directors and their equivalents to make crucial decisions in respect of their welfare and prosperity. Directors have ultimate legal responsibility for corporate entities, and play a crucial role in shaping organizational behavior and culture. Board members serve as role models in their respective.",
      image: images.NewsArticlePlaceholder,
      category: "Editorial",

      date: "June 2, 2024",
    },
    {
      title: "Chamber Extends Training for Corporate Leaders",
      intro: "Chamber Extends Training for Corporate Leaders",
      image: images.NewsArticlePlaceholder,
      category: "Editorial",

      date: "June 1, 2024",
    },
  ];
  // categoryLatestAm
  const categoryArticlesLineAm = [
    {
      title:
        "የአዲስ አበባ ንግድና የዘርፍ ማህበራት ም/ቤት ሠራተኞች ምርታማነትን ለማሳደግ ተግተው እንዲሰሩ የምክር ቤቱ ከፍተኛ ሀላፊዎች ጥሪ አቀረቡ፡፡",
      intro:
        "የአዲስ አበባ ንግድና ዘርፍ ማህበራት ምክር ቤት በኢትዮጵያ ንግድና ኢንቨስትመንት እንዲፋፋ ከሚሰሩ መንግስታዊ ያልሆኑ ድርጅቶች መካከል አንዱና ግንባር ቀደም ነው፡፡",
      image: images.CategoryNewsLineNative,
      category: "አርታዒ",

      date: "ሰኔ 4, 2024",
    },
    {
      title: "‘አስታዋሽ  ያጣው’ የጣፋጭ ምግቦች ዘርፍ  ",
      intro:
        "የጣፋጭ ምግቦች ማለት በአብዛኛው ለምርታቸው ግብዓት ስኳርን የሚጠቀሙ እና በአብዛኛው ለቀን ውሎ እና  ለበዓላት ፍጆታ የሚውሉ ናቸው፡፡ ",
      image: images.CategoryNewsLineNative2,
      category: "አርታዒ",

      date: "ሰኔ 3, 2024",
    },
    {
      title: "የውል ግብርና እድሎችና ተግዳሮቶች በኢትዮጵያ",
      intro:
        "እንደ አለምአቀፉ የምግብና እርሻ ድርጅት ሪፖርት የአለም የምግብ ስርአት እንዲዘምንና የምግብ ተደራሽነት እንዲጨምር አነስተኛ ገበሬዎችን በተለያዩ ሁኔታዎች መደገፍና አቅማቸውን ማሳደግ እንደሚገባ ጠቁማል፡፡ ",
      image: images.CategoryNewsLineNative3,
      category: "አርታዒ",

      date: "ሰኔ 2, 2024",
    },
    {
      title:
        "አዲስ ቻምበር አለምአቀፉን የግብርና እና የምግብ አውደርእይ በአዲስ አበባ ሚሊኒየም ለማድረግ ዝግጅቱን እያጠናቀቀ እንደሆነ ተነገረ፡፡",
      intro:
        "በመጪው ህዳር ወር በአዲስ አበባ ሚሌንየም አዳራሽ የሚደረገው የእርሻና የምግብ አውደርእይ በግብርና ኢንዱስትር እሴትን መጨመርን እናሳድግ የሚል መሪ ቃልን ያነገበ ሲሆን የንግዱ ማህበረሰብ በጉጉት ከሚጠብቃቸው ሁነቶች መካከል ዋናው ነው፡፡ ",
      image: images.AcLogo,
      category: "አርታዒ",

      date: "ሰኔ 1, 2024",
    },
  ];

  // categoryLatest2
  const categoryArticlesLine2 = [
    {
      title:
        "Addis Chamber (AACCSA) Gets Recognition for Championing Quality Management",
      intro:
        "As part of its reform measures, Addis Chamber is revamping quality management to ensure active service delivery to business communities. This outstanding achievement has enabled the organization to receive an IQNET certificate from DQS Holding for the provision of services and support on policy analysis and advocacy, membership, business development, communication and media, trade and investment promotion, arbitration, training, Corporate Governance, support services and management systems. ",
      image: images.CategoryNewsLineNativeEng2,
      category: "Business",
      date: "May 31, 2024",
    },
    {
      title:
        "A high Level Chamber Delegation paid a working visit to Istanbul, Turkiye ",
      intro:
        "A high-level delegation led by the President of Addis Chamber, Mrs. Mesenbet Shenkute, paid a working visit to Istanbul, Turkiye , the city that accounts for more than 60% of total export 21% of GDP, 20% of total employment, 40% of startups, and 36% of tourists visiting the country in a year.",
      image: images.CategoryNewsLineNativeEng3,
      category: "Business",

      date: "May 30, 2024",
    },
    {
      title:
        "Mayor Office of Addis Ababa City Administration and Addis Chamber Come to an Agreement to Promote trade and Investment",
      intro:
        "The two parties have signed Memorandum of Understanding that considers cooperation as major instrument to promote trade and investment of the city of Addis Ababa. ",
      image: images.CategoryNewsLineNativeEng4,
      category: "Business",

      date: "May 29, 2024",
    },
    {
      title:
        " Addis Chamber Calls on Media Houses to promote Business and Investment in Ethiopia  ",
      intro:
        "This has been disclosed during the annual media day marked by Addis Chamber on Tuesday July 04 2023 at Inter luxury Hotel. The annual media day, that is marked by Addis Chamber have also brought together key players of the media industry in Ethiopia.",
      image: images.CategoryNewsLineNativeEng5,
      category: "Business",

      date: "May 28, 2024",
    },
  ];
  // categoryLatest2Am
  const categoryArticlesLine2Am = [
    {
      title: "ሥጋት እና ተስፋ በባንኮች መንደር…",
      intro: "…አገር በቀል ባንኮች ቁጭ ብለን በጋራ ማሰብ ፤መወያየት፤ነገሮችን በጥንቃቄ ማየት አለብን",
      image: images.CategoryNewsLineNative4,
      category: "ቢዝነስ",

      date: "መጋቢት 31, 2024",
    },
    {
      title: "የአዲስ ቻምበር የቢዝነስ ማበልጸጊያ/ ኢንኩቤሽን/ ማዕከል ለስራ ፈጣሪዎች የሚሰጠውን ሰልጠና  ጀመረ",
      intro:
        "በስራ አጥነት ምክንያት ሊመጣ የሚችልን ማህበራዊ እና ኢኮኖሚያዊ ተግዳሮት ለመታደግ የበኩሉን እየሰራ መሆኑን የአዲስ አበባ ንግድና ዘርፍ ማህበራት ምክር ቤት/ አዲስ ቻምበር/ አስታወቀ፡፡ ",
      image: images.CategoryNewsLineNative5,
      category: "ቢዝነስ",

      date: "መጋቢት 30, 2024",
    },
    {
      title: "በግብርና  ለተሰማሩ አነስተኛ እና መካከለኛ   ኤንተርፕይዞች  መንግሥት ትኩረት እንዲሰጥ ተጠየቀ",
      intro:
        "አነስተኛ እና መካከከለኛ ኢንተርፕራይዞች  በኢንግሊዚኛው   Small and Medium –Sized  Enterprises (SMEs)  በመባል የሚታወቁት ድርጅቶች በሌላው ዓለም ከፍተኛው የሥራ ዕድል የሚፈጥሩ እና አንደ ዋና  የኢኮኖሚ ሞተሮች የሚታዩ ናቸው፡፡ ",
      image: images.CategoryNewsLineNative6,
      category: "ቢዝነስ",

      date: "መጋቢት 30, 2024",
    },
    {
      title: "'ከአዲስ አበባ-ደሴ በ77 ብር የግሉን ዘርፍ' በታዳሽ ኃይል ልማት በስፋት አንዲሳተፍ",
      intro:
        "ዛሬዛሬ የዓለማችን አሳሳቢ እና አንኳር የመነጋገሪያ ጉዳዮች ከሆኑት መካከል አንዱ የከባቢ አየር ብክለት  ጉዳይ ነው፡፡ ",
      image: images.CategoryNewsLineNative7,
      category: "ቢዝነስ",

      date: "ጀምት 3, 2024",
    },
  ];

  // categoryArticles
  const categoryArticlesFull = [
    {
      title: "AI Revolution in 2024",
      intro:
        "Artificial intelligence is transforming industries at an unprecedented pace.",
      image: images.CategoryNewsFullPlaceholder,

      imageCaption: "AI and the future",
      date: "June 5, 2024",
    },
    {
      title: "5G Networks Expanding Worldwide",
      intro:
        "5G networks are rolling out globally, offering faster speeds and more reliable connections.",
      image: images.CategoryNewsFullPlaceholder2,

      imageCaption: "5G networks",
      date: "June 4, 2024",
    },
    {
      title: "Quantum Computing Breakthrough",
      intro:
        "Scientists achieve a major milestone in the field of quantum computing.",
      image: images.CategoryNewsFullPlaceholder2,

      imageCaption: "Quantum computing",
      date: "June 3, 2024",
    },
    {
      title: "New Advances in Robotics",
      intro:
        "Robotics technology is advancing rapidly, with new applications emerging daily.",
      image: images.CategoryNewsFullPlaceholder2,

      imageCaption: "Robotics advances",
      date: "June 2, 2024",
    },
    {
      title: "The Future of Electric Vehicles",
      intro: "Electric vehicles are becoming more affordable and mainstream.",
      image: images.CategoryNewsFullPlaceholder2,

      imageCaption: "Electric vehicles",
      date: "June 1, 2024",
    },
    {
      title: "Cybersecurity Trends to Watch",
      intro: "Top cybersecurity trends and challenges for businesses in 2024.",
      image: images.CategoryNewsFullPlaceholder2,

      imageCaption: "Cybersecurity",
      date: "May 31, 2024",
    },
    {
      title: "The Future of Electric Vehicles",
      intro: "Electric vehicles are becoming more affordable and mainstream.",
      image: images.CategoryNewsFullPlaceholder2,
      imageCaption: "Electric vehicles",
      date: "June 1, 2024",
    },
  ];
  // categoryArticlesAm
  const categoryArticlesFullAm = [
    {
      title:
        "የአዲስ አበባ ከተማ አስተዳደር ከንቲባ ጽ/ቤት እና አዲስ ቻምበር አብሮ መስራት የሚያስችላቸውን የመግባቢያ ሰነድ  ተፈራረሙ፡",
      intro:
        "የአዲስ አበባ ንግድና የዘርፍ ማህበራት ምክር ቤትና የአዲስ አበባ ከተማ አስተዳደር ከንቲባ ጽ/ቤት አብሮ መስራት የሚያስችላቸውን የመግባቢያ ሰነድ  ተፈራረሙ፡፡የመግባቢያ ሰነዱ በግሉ ዘርፍ እና በመንግስት ትብብር ንግድና ኢንቨስትመንትን ይበልጥ ለማስፋፋት የሚያስችል ምቹ ሁኔታን የሚፈጥር ሲሆን ሁለቱ ተቋማት ያላቸውን  አቅም በማስተባበር የከተማዋን የንግድና ኢንቨስትመንት እድሎች በሀገር ውስጥና በውጪ ለማስተዋወቅ ምቹ ሁኔታን እንደሚፈጥር ተገልጿል፡፡",
      image: images.CategoryNewsFullNative,
      imageCaption: "የአዲስ አበባ ከተማ አስተዳደር",
      date: "ሰኔ 5, 2024",
    },
    {
      title: "ምክር ቤቱ በውል ግብርና ምንነት እና ጠቀሜታ ዙርያ የግንዛቤ ማስጨበጫ መድረክ አካሄደ",
      intro:
        "የአዲስ አበባ ንግድ እና የዘርፍ ማህበራት ምክር ቤት የግብርና ምርትን በተለይ ፍራፍሬ እና አትክልት አምራቾች እና አቀነባባሪዎች ላይ ትኩረት በማድረግ በውል ግብርና  አሰራር እና ጠቀሜታ ላይ ትኩረት ያደረገ የውይይት መድረክ አካሂዷል፡፡",
      image: images.CategoryNewsFullNative1,
      imageCaption: "በስታፍ ሪፖርተር ",
      date: "ሰኔ 4, 2024",
    },
    {
      title:
        "አዲስ ቻምበር 24 ሰዓት የሚተላለፍ የማህበረሰብ ሬዴዮ ፕሮግራም ለመጀመር በዝግጅት ላይ መሆኑን የምክር ቤቱ ዋና ፀሀፊ አስታወቁ",
      intro:
        "የምክር ቤቱ ስራዎችን  ለማስተዋወቅ  ከመንግስትና የግል ሚዲያዎች ጋር  አብረን መስራታችንን እንቀጥላለን  ያሉት አቶ ሺበሺ ለዚህም ማሳያ አዲስ ቻምበር  ከፋና ብሮድ ካስቲንግ ኮርፖሬትና  ከኢትዮጵያ ዜና አገልግሎት ጋር  በጋራ ለመስራት የሚያስችል  የመግባቢያ ሰነድ መፈራረሙን ጠቅሰዋል ፡፡",
      image: images.CategoryNewsFullNative2,
      imageCaption: "የምክር ቤቱ ዋና ፀሀፊ አቶ ሺበሺ ቤተ ማርያም ",
      date: "ሰኔ 3, 2024",
    },
    {
      title: "አዲስ ቻምበር የአረንጓዴ ሽግግርን ፍኖተ ካርታ አስተዋወቀ",
      intro:
        "ያለግሉ ዘርፍ ጠንካራ ተሳትፎ አረንጓዴ ሽግግርን ማሳካት እንደማይቻል ተጠቁሟል:: ምክር ቤቱ ከዳኒሽ ኮንፌዴሬሽን ኢንደስትሪ በተገኘ ድጋፍ አረንጓዴ ሽግግር ላይ ትኩረት በማድረግ ያስጠናውን ጥናት በባለሞያዎች አስተችቷል፡፡",
      image: images.CategoryNewsFullNative3,
      imageCaption: "በሰናይት እሸቱ ",
      date: "ሰኔ 2, 2024",
    },
    {
      title: "የአዲስ ቻምበር የዳይሬክተሮች ተቃም (Institute of Directors’) ይፋ ተደረገ",
      intro:
        "የዳይሬክተሮች ተቋም አዲስ ቻምበር በቅርቡ መስጠት ከጀመረው አገልግሎቶች ትልቁ እንደሆነ ይነገራል፡፡ ይህ አገልግሎት በኢትዮጵያ በስፋት የሚስተዋለውን የኩባንያ አመራር ክፍተትን በመሙላት ተወዳዳሪ ኩባንያዎች እውን እንዲሆኑ በር ከፋች እንደሆነ የበርካቶች እምነት ነው፡፡ አዲስ ቻምበር የዳይሬክተሮች ተቋምን በቅርቡ ይፋ ያደረገ ሲሆን በዚህ የማብሰሪያ ፕሮግራም ወቅት ከፍተኛ የመንግስት የስራ ሀላፊዎች፤ መንግስታዊ ያልሆኑ ተቋማት ተወካዮች፤ የግሉ ዘርፍ ተወካዮች እና ጥሪ የተደረገላቸው እንግዶች ታድመዋል፡፡",
      image: images.CategoryNewsFullNative4,
      imageCaption: "የአዲስ ቻምበር የዳይሬክተሮች ተቃም ",
      date: "ሰኔ 1, 2024",
    },
    {
      title: "የቦርድ አመራርን   የሚያሰለጥን ፣ የሚያስተምር ፣ ዓለም አቀፍ ደረጃ ያለው ተቋም በኢትዮጵያ",
      intro:
        "በተለይ በካፒታል ገበያ ላይ ኢንቨስት የሚያደርጉ ባለሃብቶች ፤ ገንዘባቸውን  ከማፍሰሳቸው በፊት  በቅድሚያ  ኩባንያው   ምን አይነት ቦርድ አባላት አሉት  ፣ ቦርዱስ ምን ያህል  ጠንካራ ነው  የሚለውን  ይጠይቃሉ ያሉት ብሩክ ( ዶ/ር)  ኢንስቲትዩቱ እነዚህን ጥያቄዎች በመመለስ ለካፒታል ገበያ  የጎላ  ጠቀሜታ  ይኖረዋል ብለዋል፡፡",
      image: images.CategoryNewsFullNative5,
      imageCaption: "ብሩክ ( ዶ/ር )",
      date: "መጋቢት 31, 2024",
    },
    {
      title: "የቤት ግብር ተመን ማሻሻያው በአተገባበሩ ላይ ክፍተቶች እንዳሉበት የንግዱ ማህበረሰብ አባላት ገለጹ::",
      intro:
        "ከማሻሻያው በፊት የነበረው የክፍያ መጠን እዚህ ግባ የማይባል እና የከተማዋን ነባራዊ ሁኔታ የማያንጸባርቅ እንደነበር የአዲስ አበባ ገቢዎች ቢሮ በበኩሉ አስታውቋል፡፡ ይህ የተባለው አዲስ ቻምበር  የቤት ግብር ማሻሻያ ላይ የግንዛቤ ማስጨበጫ መድረክ በሚል በካሄደው የውይይት መድረክ ላይ ነው ፡፡",
      image: images.CategoryNewsFullNative6,
      imageCaption: "በከፍያለው ዋሲሁን",
      date: "ሰኔ 1, 2024",
    },
  ];
  // categoryArticles2
  const categoryArticlesFullEconomy = [
    {
      title: "Economic Growth in Ethiopia",
      intro:
        "Ethiopia's economy is experiencing significant growth, driven by various sectors.",
      image: images.CategoryNewsFullEconomyPlaceholder2,
      imageCaption: "Economic growth",
      date: "June 5, 2024",
      category: "Economy",
    },
    {
      title: "Advancements in Ethiopian Agriculture",
      intro:
        "New agricultural techniques and technologies are boosting productivity in Ethiopia.",
      image: images.CategoryNewsFullEconomyPlaceholder,
      imageCaption: "Agriculture advancements",
      date: "June 4, 2024",
      category: "Agriculture",
    },
    {
      title: "Industry Growth in Ethiopia",
      intro:
        "Ethiopia's industrial sector is expanding with new investments and developments.",
      image: images.CategoryNewsFullEconomyPlaceholder,
      imageCaption: "Industry growth",
      date: "June 3, 2024",
      category: "Industry",
    },
    {
      title: "Service Sector Boom in Ethiopia",
      intro:
        "The service sector in Ethiopia is seeing rapid growth and increased contributions to the GDP.",
      image: images.CategoryNewsFullEconomyPlaceholder,
      imageCaption: "Service sector boom",
      date: "June 2, 2024",
      category: "Service",
    },
    {
      title: "Green Economy Initiatives in Ethiopia",
      intro:
        "Ethiopia is implementing green economy strategies to promote sustainable development.",
      image: images.CategoryNewsFullEconomyPlaceholder,
      imageCaption: "Green economy initiatives",
      date: "June 1, 2024",
      category: "Green Economy",
    },
    {
      title: "Climate Change Impact on Ethiopia",
      intro:
        "Exploring how climate change is affecting various sectors in Ethiopia.",
      image: images.CategoryNewsFullEconomyPlaceholder,
      imageCaption: "Climate change impact",
      date: "May 31, 2024",
      category: "Climate Change",
    },
    {
      title: "Inclusive Economic Growth in Ethiopia",
      intro:
        "Efforts to ensure inclusive economic growth in Ethiopia are gaining momentum.",
      image: images.CategoryNewsFullEconomyPlaceholder,
      imageCaption: "Inclusive economic growth",
      date: "June 1, 2024",
      category: "Inclusive Economies",
    },
  ];
  // categoryArticles2Am
  const categoryArticlesFullEconomyAm = [
    {
      title: "በግብርና  ለተሰማሩ አነስተኛ እና መካከለኛ   ኤንተርፕይዞች  መንግሥት ትኩረት እንዲሰጥ ተጠየቀ",
      intro:
        "በተለይ በግብርና የዕሴት ሰንሰለት ላይ ለተሰማሩት ኤንተርፕራይዞች በፋይናንስ አቅርቦት ላይ ያለባቸውን ችግር አስመልክቶ  የአዲስ አበባ ንግድ ም/ቤት ሰሞኑን  ያስጠናው የፖሊሲ ጥናት ለባለድርሻዎች ውይይት ቀርቦ ተተችቷል፡፡ ",
      image: images.CategoryNewsFullNative7,
      imageCaption: "በይድነቃቸው ዓለማየሁ",
      date: "መጋቢት 31, 2024",
      category: "ባለሀብት",
    },
    {
      title: "25ኛው የዓለማቀፍ  ትርዒት ትሩፋት",
      intro: "አዲስ ተክን እና ቴክኖሎጂዎች በኢትዮጵያ ህክምና እየታደሰ እና እየተሻሻለ ነው።",
      image: images.CategoryNewsFullNative8,
      imageCaption: "ህክምና አዳዲስ አሻራ",
      date: "ሰኔ 4, 2024",
      category: "ህክምና",
    },
    {
      title: "በኢትዮጵያ ኢንዱስትሪያል እድገት",
      intro: "ኢንዱስትሪያል ተንቀሳቃሽ ማህበረሰቦች እየታደሰ እና እየተሻሻለ ነው።",
      image: images.CategoryNewsFullNative9,
      imageCaption: "ኢንዱስትሪያል እድገት",
      date: "ሰኔ 3, 2024",
      category: "ኢንዱስትሪ",
    },
    {
      title:
        "ከአገራችን ሳንወጣ  እና ተጨማሪ ገንዘብ ሳናወጣ ከዓለም ዙሪያ ከሚመጡ ደንበኞች  ጋር መገናኘት ችለናል፡፡",
      intro:
        "ኩባንያችን በቡና፤በቅባት እና በጥራጥሬ ዕህሎት ለውጪ ገበያ ያቀርባል፡፡ የግብርና ግብዓቶችንም ለአገርውስጥ ገበያ አንቀርባለን፡፡ በዚህ አግዚቢሽን ላይ በመሳተፋችን ደስተኞች ነን፡፡ እዚሁ ከአገራችን ሳንወጣ ፤ሳንጓዝ እና ተጨማሪ ገንዘብ ሳናወጣ ከዓለም ዙሪያ ከሚመጡ ደንበኞች ጋር መገናኘት ችለናል፡፡ ",
      image: images.NewsArticlePlaceholder,
      imageCaption: "ምክሩ ደንቢ (ከስዌታ ኃ.የተ.የግ,ማሕ)",
      date: "ሰኔ 2, 2024",
      category: "አገልግሎት",
    },
    {
      title: "የአዲስ ቻምበር የቢዝነስ ማበልጸጊያ/ ኢንኩቤሽን/ ማዕከል ለስራ ፈጣሪዎች የሚሰጠውን ሰልጠና  ጀመረ",
      intro:
        "በየአመቱ ከመንግስትና ከግል ከፍተኛ ትምህርት ተቋማት በመቶሺዎች የሚቆጠሩ ወጣቶች ተመርቀው እንደሚወጡ ጠቁመው  ወጣቱ ስራ እንዲፈጠር እና ወደ ቢዝነስ እንዲገባ  ልንደግፈው ይገባል ብለዋል ፡፡ ይህንን በማድረግ በስራ አጥነት ምክንያት ሊመጣ የሚችልን ማህበራዊና ኢኮኖሚያዊ ፈተና እንደንግድ ምክር ቤት ልንታደገው እንችላለለን ያሉት አቶ ሺበሺ ፤ አዲስቻምበር ከአውሮፓ  ህብረት ባገኝው የገንዘብ ድጋፍ አማካኝነት የቢዝነስ ኢንኩቤሽን  ማዕከል አቋቁሞ ስራ ፈጣሪዎችን ለመደገፍ እየሰራ ይገኛል ብለዋል ፡፡",
      image: images.NewsArticlePlaceholder,
      imageCaption: "በከፍያለው ዋሲሁን",
      date: "ሰኔ 1, 2024",
      category: "ኢኮኖሚ",
    },

    {
      title: "ንግድ ም/ቤቱ ለከፍተኛ የኩባንያ መሪዎች ስልጠና መስጠት ጀመረ",
      intro:
        "በአዲስ ቻምበር እና በኬንያው የዳይሬክተሮች ተቋም በጋራ የተዘጋጀው ስልጠና የኩባንያ የቦርድ አባላት እና የማኔጅመንት መሪዎች ሚናቸውን እና ኃላፊነታቸውን በብቃት አንዲወጡ ያግዛል ተብሏል፡፡ ሥልጠናውን በይፋ ያስጀመሩት የንግድ ም/ቤቱ ዋና ጸሐፊ አቶ ሺበሺ ቤተማያም ለረዥም አመታት የአገራቸን ኩባንያዎች  በልምድ የሚተዳደሩ እና በቤተሰብ አባላት የሚመሩ ናቸው፡፡ ነገር ግን ውድድር የበዛበት የዓለም ንግድ ሥርዓት ጋር  አብሮ ለመሄድ እና ብቁ ተወዳዳሪ ሆኖ ለመገኘት የቢዝነስ ድርጅቶች አመራር ሥልጠና በጣም አስፈላጊ እየሆነ መጥቷል ብለዋል፡፡ ",
      image: images.NewsArticlePlaceholder,
      imageCaption: "ኢኮኖሚያዊ እድገት",
      date: "ሰኔ 5, 2024",
      category: "ኢኮኖሚ",
    },
  ];

  return (
    <div className={classes.root}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Arthy</title>
        <meta
          name="description"
          content="Stay updated on Ethiopian export news, services, and resources. Explore the 'Addis Chamber export portal for trade facilitation' for business and trade opportunities."
        />
        <meta
          name="keywords"
          content="Ethiopian export, Addis Chamber, trade opportunities, export news, business services, Ethiopia"
        />
        <meta
          name="author"
          content="Addis Chamber export portal for trade facilitation"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="index, follow" />
        <meta name="theme-color" content="#0077B5" />
        <meta
          property="og:title"
          content="Ethiopian Export and Addis Chamber - Your App Title"
        />
        <meta
          property="og:description"
          content="Stay updated on Ethiopian export news, services, and resources. Explore the Addis Chamber for business and trade opportunities."
        />
        <meta
          property="og:image"
          content="https://example.com/your-image.jpg"
        />{" "}
        {/* Add a relevant image URL */}
        <meta property="og:url" content="https://your-website-url.com" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />{" "}
        {/* Add your Twitter handle */}
        <meta
          name="twitter:title"
          content="Ethiopian Export and Addis Chamber - Your App Title"
        />
        <meta
          name="twitter:description"
          content="Stay updated on Ethiopian export news, services, and resources. Explore the Addis Chamber for business and trade opportunities."
        />
        <link rel="canonical" href="https://your-website-url.com" />
      </Helmet>

      <div>
        <TitleTop />
        <LogoHead2 />
      </div>
      <Header />
      <Stack
        direction={isMobile ? "column" : "row"}
        spacing={3}
        style={{
          width: "100%",
          padding: isMobile ? "0 5%" : "0% 7%",
          marginTop: isMobile ? "0%" : "0%",
          justifyContent: isMobile ? "flex-start" : "center",
          alignItems: isMobile ? "center" : "flex-start",
        }}>
        {/* <Advertisements position="header" items={sidebarAds} /> */}
        <Advertisements position="header" items={topAds && topAds} />
      </Stack>
      {isMobile ? (
        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={3}
          style={{
            width: "100%",
            padding: isMobile ? "0% 0%" : isTab ? "0% 5%" : "0% 7%",

            marginTop: isMobile ? "0%" : "2%",
            justifyContent: isMobile ? "flex-start" : "center",
            alignItems: isMobile ? "center" : "flex-start",
          }}>
          <TopStory
            mainArticle={
              currentLanguage === "am"
                ? topFiveStoryAmData[0]
                : topFiveStoryData[0]
            }
            smallerArticles={
              currentLanguage === "am" ? topFiveStoryAmData : topFiveStoryData
            }
          />
          <Stack
            direction="column"
            spacing={2}
            style={{ width: "100%", padding: "5%" }}>
            <TrendingNews />
            {/* <Advertisements position="sidebar" items={sidebarAds} /> */}
            <Advertisements position="header" items={topAds && topAds} />
          </Stack>
        </Stack>
      ) : (
        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={3}
          style={{
            width: "100%",
            padding: isMobile ? "0 5%" : "0% 7%",
            marginTop: isMobile ? "5%" : "2%",
            justifyContent: isMobile ? "flex-start" : "center",
            alignItems: isMobile ? "center" : "flex-start",
          }}>
          <Stack direction="column" spacing={2}>
            <TrendingNews />
            {/* <Advertisements position="sidebar" items={sidebarAds} /> */}
            {/* <Advertisements position="header" items={topAds && topAds} /> */}
            <Advertisements position="header" items={topAds && topAds} />
          </Stack>
          <TopStory
            mainArticle={
              currentLanguage === "am"
                ? topFiveStoryAmData && topFiveStoryAmData[0]
                  ? topFiveStoryAmData[0]
                  : {}
                : topFiveStoryData && topFiveStoryData[0]
                ? topFiveStoryData[0]
                : {}
            }
            smallerArticles={
              currentLanguage === "am"
                ? topFiveStoryAmData ?? []
                : topFiveStoryData ?? []
            }
          />
        </Stack>
      )}
      <Stack
        spacing={2}
        direction="column"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          // padding: "0% 10%",
          padding: isMobile ? "0% 5%" : "0% 10%",
        }}>
        <CategoryNewsLine
          category={
            // layoutData
            //   ? layoutData.categoryLatest1
            //     ? layoutData.categoryLatest1
            //     : "Latest"
            //   : "Latest"
            "Latest"
          }
          articles={
            currentLanguage === "am"
              ? allArticlesDataAm && allArticlesDataAm
              : // ? allArticlesData
                // : categoryArticlesLine2Am
                allArticlesData && allArticlesData
          }
          // category2="Editorial"
          // category2="Business"
          category2={
            layoutData
              ? layoutData.categoryLatest2
                ? layoutData.categoryLatest2
                : "Business"
              : "Business"
          }
          articles2={
            currentLanguage === "am"
              ? categoryLatestArticlesDataAm && categoryLatestArticlesDataAm
              : categoryLatestArticlesDataEn && categoryLatestArticlesDataEn
          }
        />
        {isMobile ? (
          <Stack
            direction={isMobile ? "column" : "row"}
            spacing={3}
            style={{
              width: "100%",
              //  padding: "0% 10%",
              // padding: isMobile ? "0% 0%" : "0% 10%",

              margin: "5% 0%",

              justifyContent: isMobile ? "flex-start" : "center",
              alignItems: isMobile ? "center" : "flex-start",
            }}>
            <Stack
              direction="column"
              spacing={3}
              style={{
                width: "100%",
                padding: "2%",
                justifyContent: isMobile ? "flex-start" : "center",
                alignItems: isMobile ? "center" : "flex-start",
              }}>
              <TopNewsToday />
              <MonthlyEdition
                // imageUrl={editionData && editionData[0]?.coverImage}
                // // month={t("JUNE")}
                // month={t(
                //   `${getMonthFromDate(editionData && editionData[0]?.month)}`
                // )}
                imageUrl={images.MonthlyEditionPlaceholder}
                month="JUNE"
              />
            </Stack>

            {/* <CategoryNewsFull
              categoryTitle="Editorial"
              // articles={
              //   currentLanguage === "am"
              //     ? // ? categoryArticlesFullAm
              //       // : categoryArticlesFull
              //       categoryFullArticlesData && categoryFullArticlesData
              //     : categoryFullArticlesData
              // }
              articles={
                currentLanguage === "am"
                  ? categoryArticlesFullAm
                  : categoryArticlesFull
                //   ?categoryFullArticlesData && categoryFullArticlesData
                // : categoryFullArticlesData
              }
            /> */}
            {layoutData && layoutData?.categoryNews1 === "None" ? null : (
              <CategoryNewsFull
                // categoryTitle={t(layoutData?.categoryNews1)}
                categoryTitle={layoutData?.categoryNews1}
                // categoryTitle={t("Editorial")}
                articles={
                  currentLanguage === "am"
                    ? // ? categoryArticlesFullAm
                      // : categoryArticlesFull
                      categoryFullArticlesAmData && categoryFullArticlesAmData
                    : categoryFullArticlesData && categoryFullArticlesData
                  //   ?categoryFullArticlesData && categoryFullArticlesData
                  // : categoryFullArticlesData
                }
              />
            )}
          </Stack>
        ) : (
          <Stack
            direction={isMobile ? "column" : "row"}
            spacing={3}
            style={{
              width: "100%",
              //  padding: "0% 10%",
              // padding: isMobile ? "0% 0%" : "0% 10%",

              margin: "5% 0%",

              justifyContent: isMobile ? "flex-start" : "center",
              alignItems: isMobile ? "center" : "flex-start",
            }}>
            <Stack
              direction="column"
              spacing={3}
              style={{
                justifyContent: isMobile ? "flex-start" : "center",
                alignItems: isMobile ? "center" : "flex-start",
              }}>
              <TopNewsToday />
              <MonthlyEdition
                // imageUrl={editionData && editionData[0]?.coverImage}
                // // month={t("JUNE")}
                // month={t(
                //   `${getMonthFromDate(editionData && editionData[0]?.month)}`
                // )}
                imageUrl={images.MonthlyEditionPlaceholder}
                month="JUNE"
              />
            </Stack>

            {layoutData && layoutData?.categoryNews1 === "None" ? null : (
              <CategoryNewsFull
                // categoryTitle={t(layoutData?.categoryNews1)}
                categoryTitle={layoutData?.categoryNews1}
                // categoryTitle={t("Editorial")}
                articles={
                  currentLanguage === "am"
                    ? // ? categoryArticlesFullAm
                      // : categoryArticlesFull
                      categoryFullArticlesAmData && categoryFullArticlesAmData
                    : categoryFullArticlesData && categoryFullArticlesData
                  //   ?categoryFullArticlesData && categoryFullArticlesData
                  // : categoryFullArticlesData
                }
              />
            )}
          </Stack>
        )}
        {/* <Advertisements position="header" items={sidebarAds} /> */}
        <Advertisements position="header" items={middleAds && middleAds} />

        {isMobile ? (
          <Stack
            direction={isMobile ? "column" : "row"}
            spacing={3}
            style={{
              width: "100%",
              // padding: "0% 10%",
              // marginTop: "5%",
              // justifyContent: "center",
              // alignItems: "flex-start",
              justifyContent: isMobile ? "flex-start" : "center",
              alignItems: isMobile ? "center" : "flex-start",
            }}>
            {layoutData?.categoryNews2 === "None" ? null : (
              <CategoryNewsFull
                // categoryTitle={t("Economy")}
                // categoryTitle={t(layoutData?.categoryNews2)}
                categoryTitle={layoutData?.categoryNews2}
                // articles={
                //   currentLanguage === "am"
                //     ? categoryFullArticlesData2 && categoryFullArticlesData2
                //     : categoryFullArticlesData2
                // }
                articles={
                  currentLanguage === "am"
                    ? // ? categoryArticlesFullEconomyAm
                      // : categoryArticlesFullEconomy
                      categoryFullArticlesAmData2 && categoryFullArticlesAmData2
                    : categoryFullArticlesData2 && categoryFullArticlesData2
                }
              />
            )}
            <Stack
              direction="column"
              spacing={2}
              style={{
                justifyContent: isMobile ? "flex-start" : "center",
                alignItems: isMobile ? "center" : "flex-start",
              }}>
              <MostReadThisMonth />
              {/* <Advertisements position="sidebar" items={sidebarAds} /> */}
              <Advertisements position="header" items={adsData && adsData} />
            </Stack>
          </Stack>
        ) : (
          <Stack
            direction={isMobile ? "column" : "row"}
            spacing={3}
            style={{
              width: "100%",
              // padding: "0% 10%",
              // marginTop: "5%",
              // justifyContent: "center",
              // alignItems: "flex-start",
              justifyContent: isMobile ? "flex-start" : "center",
              alignItems: isMobile ? "center" : "flex-start",
            }}>
            <Stack
              direction="column"
              spacing={2}
              style={{
                justifyContent: isMobile ? "flex-start" : "center",
                alignItems: isMobile ? "center" : "flex-start",
              }}>
              <MostReadThisMonth />
              {/* <Advertisements position="sidebar" items={sidebarAds} /> */}
              <Advertisements
                position="header"
                items={middleAds && middleAds}
              />
            </Stack>
            <CategoryNewsFull
              // categoryTitle={t("Economy")}
              categoryTitle={layoutData?.categoryNews2}
              articles={
                currentLanguage === "am"
                  ? // ? categoryArticlesFullEconomyAm
                    // : categoryArticlesFullEconomy
                    categoryFullArticlesAmData2 && categoryFullArticlesAmData2
                  : categoryFullArticlesData2 && categoryFullArticlesData2
              }
            />
          </Stack>
        )}
      </Stack>

      <Stack
        direction={isMobile ? "column" : "row"}
        spacing={3}
        style={{
          width: "100%",
          padding: isMobile ? "0% 0%" : "0% 10%",
          margin: "5% 0%",

          justifyContent: "center",
          alignItems: "flex-start",
        }}>
        <EventsSection />
      </Stack>
      <Stack
        direction={isMobile ? "column" : "row"}
        spacing={3}
        style={{
          width: "100%",
          padding: isMobile ? "0% 5%" : "0% 10%",

          margin: "5% 0%",

          justifyContent: "center",
          alignItems: "flex-start",
        }}>
        <YoutubeChannelFrame />
      </Stack>
      <Stack
        direction={isMobile ? "column" : "row"}
        spacing={3}
        style={{
          width: "100%",
          // padding: "0% 10%",
          padding: isMobile ? "0% 0%" : "0% 10%",

          margin: "5% 0%",

          // justifyContent: "center",
          // alignItems: "flex-start",
          justifyContent: isMobile ? "flex-start" : "center",
          alignItems: isMobile ? "center" : "flex-start",
        }}>
        <MonthlyPublications />
      </Stack>
      <Advertisements position="header" items={bottomAds && bottomAds} />

      <Footer />

      <BackToTop />
    </div>
  );
};

export default HomePage;
