import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Link,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { colorTags } from "../../constants/variants";
import Header from "../Home/Header/Header2";
import LogoHead from "../Home/Header/LogoHead2";
import Footer from "../Home/Footer/Footer2";
import { images } from "../../assets";
import { useVerifyCodeMutation } from "../../features/api/apiSlice";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import Title from "../Home/Header/Title2";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "90vh",
    backgroundImage: `linear-gradient(to bottom, #ffffff 50%, transparent 80%), url(${images.BlurBg1})`,

    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  card: {
    maxWidth: 300,
    padding: theme.spacing(0),
    marginTop: theme.spacing(7),
    textAlign: "center",
    borderRadius: theme.spacing(2),
    boxShadow: "rgba(0, 0, 0, 0.45) 0px 35px 20px -20px",

    [theme.breakpoints.up("sm")]: {
      maxWidth: 400,
      padding: theme.spacing(4),
      paddingTop: theme.spacing(0),
      textAlign: "center",
      borderRadius: theme.spacing(2),
      boxShadow: "rgba(0, 0, 0, 0.45) 0px 35px 20px -20px",
    },
  },
  inputField: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: colorTags.SECONDARY,
    "&:hover": {
      backgroundColor: colorTags.PRIMARY,
    },
  },
  title: {
    marginBottom: theme.spacing(4),
    color: colorTags.PRIMARY,
  },
  otherText: {
    color: colorTags.PRIMARY,
  },
  passwordPolicy: {
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4),
      marginLeft: theme.spacing(7),
      textAlign: "left",
    },
  },
}));

const PasswordChange = () => {
  const classes = useStyles();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  const [emailSubmitted, setEmailSubmitted] = useState(false);

  const [verificationCode, setVerificationCode] = useState("");
  const [email, setEmail] = useState("");
  const [verifyCode, { isError: isVerifyError, isLoading: isVerifyLoading }] =
    useVerifyCodeMutation();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handleVerificationCodeChange = (event) => {
    setVerificationCode(event.target.value);
  };
  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };
  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Password Policy Specifications
    const passwordRegex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{6,})/;

    if (!newPassword || newPassword.trim().length < 6) {
      toast.error("Password must be at least 6 characters long.");
      return;
    }

    if (!/\d/.test(newPassword)) {
      toast.error("Password must include at least one digit.");
      return;
    }

    if (!/[a-z]/.test(newPassword)) {
      toast.error("Password must include at least one lowercase letter.");
      return;
    }

    if (!/[A-Z]/.test(newPassword)) {
      toast.error("Password must include at least one uppercase letter.");
      return;
    }

    if (!/[!@#$%^&*]/.test(newPassword)) {
      toast.error("Password must include at least one special character.");
      return;
    }

    const data = {
      email: email,
      resetTokon: verificationCode,
      newPassword,
      // userType: toggle ? "user" : "member",
    };
    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match. Please try again.");
      return;
    }

    if (newPassword === confirmPassword) {
      verifyCode(data)
        .then((res) => {
          if (res) {
            toast.success("Password Changed Successfully!");
            setEmailSubmitted(false);
            setEmail("");
            setVerificationCode("");
            navigate("/login");
          } else {
            toast.error("Something went wrong. Please try again later.");
          }
        })
        .catch(() => {
          toast.error("Something went wrong. Please try again later.");
        });
    } else {
      toast.error("Passwords do not match. Please try again.");
    }
  };
  return (
    <>
      <Title />
      <Header />
      <LogoHead />
      <div className={classes.root}>
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h5" className={classes.title}>
              Change Password
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                type="email"
                label="Email"
                value={email}
                variant="outlined"
                color="success"
                onChange={handleEmailChange}
                fullWidth
                className={classes.inputField}
                sx={{
                  marginBottom: "20px",
                  marginTop: "10px",
                  width: "100%",
                }}
              />
              <TextField
                type="text"
                label="Verification Code"
                value={verificationCode}
                variant="outlined"
                color="success"
                onChange={handleVerificationCodeChange}
                fullWidth
                className={classes.inputField}
                sx={{
                  marginBottom: "10px",
                  marginTop: "10px",
                  width: "100%",
                }}
              />
              <TextField
                type="password"
                label="New Password"
                value={newPassword}
                variant="outlined"
                color="success"
                onChange={handleNewPasswordChange}
                fullWidth
                className={classes.inputField}
                sx={{
                  marginBottom: "10px",
                  marginTop: "10px",
                  width: "100%",
                }}
              />
              <TextField
                type="password"
                label="Confirm Password"
                value={confirmPassword}
                variant="outlined"
                color="success"
                onChange={handleConfirmPasswordChange}
                fullWidth
                className={classes.inputField}
                sx={{
                  marginBottom: "10px",
                  marginTop: "10px",
                  width: "100%",
                }}
              />
              <LoadingButton
                loading={isVerifyLoading}
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                className={classes.button}>
                Change Password
              </LoadingButton>
            </form>
            <Typography variant="body2" color="textSecondary">
              Remember your password?{" "}
              <Link href="/login" className={classes.otherText}>
                Sign In
              </Link>
            </Typography>
          </CardContent>
        </Card>
        <div className={classes.passwordPolicy}>
          <List style={{ fontSize: "13px", color: "#006738" }}>
            <ListItem>
              <ListItemText>Minimum 8 characters</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>At least one uppercase letter</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>At least one lowercase letter</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>At least one number</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>Special characters allowed</ListItemText>
            </ListItem>
          </List>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default PasswordChange;
