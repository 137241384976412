import React, { useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardMedia } from "@material-ui/core";
import { images } from "../../../assets/index";
const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    height: "100%",
    marginTop: "2%",
    overflowY: "auto",
    scrollbarWidth: "thin",
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#555",
    },
  },
  carousel: {
    display: "flex",
    transition: "transform 0.5s ease",
  },
  card: {
    minWidth: 300,
    margin: theme.spacing(2),
    flex: "0 0 auto",
  },
  media: {
    height: 400,
  },
}));

const MonthlyPublications = () => {
  const classes = useStyles();
  const containerRef = useRef(null);
  const carouselRef = useRef(null);

  const publications = Array.from({ length: 10 }).map((_, i) => ({
    title: `Publication ${i + 1}`,
    image: images.MonthlyPublicationPlaceholder,
    description: `Description for publication ${i + 1}`,
  }));

  useEffect(() => {
    const container = containerRef.current;
    const handleScroll = (e) => {
      if (
        container.scrollTop >
        container.scrollHeight - container.clientHeight - 50
      ) {
        container.scrollTop = 0;
      }
      carouselRef.current.style.transform = `translateX(-${container.scrollTop}px)`;
    };
    container.addEventListener("scroll", handleScroll);
    return () => container.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className={classes.root} ref={containerRef}>
      <div className={classes.carousel} ref={carouselRef}>
        {publications.map((pub, index) => (
          <Card key={index} className={classes.card}>
            <CardMedia
              className={classes.media}
              image={pub.image}
              title={pub.title}
            />
          </Card>
        ))}
      </div>
    </div>
  );
};

export default MonthlyPublications;
