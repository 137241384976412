import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Typography, Divider } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  trendingNews: {
    width: 300,
    border: "1px solid lightgrey",
    padding: theme.spacing(2),
  },
  header: {
    fontSize: 18,
    fontWeight: "bold",
  },
  card: {
    backgroundColor: "transparent",
    boxShadow: "none",
    marginTop: theme.spacing(1),
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
  },
  description: {
    fontSize: 14,
    marginTop: theme.spacing(0.5),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

const TrendingNews = () => {
  const classes = useStyles();
  const news = [
    { title: "News 1", description: "Brief description of news 1." },
    { title: "News 2", description: "Brief description of news 2." },
    { title: "News 3", description: "Brief description of news 3." },
    { title: "News 4", description: "Brief description of news 4." },
    { title: "News 5", description: "Brief description of news 5." },
  ];
  return (
    <div className={classes.trendingNews}>
      <Typography className={classes.header}>
        Trending
        {/* News */}
      </Typography>
      <Divider className={classes.divider} />
      {news.map((item, index) => (
        <Card
          key={index}
          className={classes.card}
          sx={{
            // boxShadow: "none"

            boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 2px 0px",
          }}>
          <CardContent
            style={{
              padding: "20px",
            }}>
            {/* <Typography
              className={classes.title}
              style={{ fontWeight: "bold" }}>
            </Typography> */}
            <Typography
              className={classes.description}
              style={{ fontWeight: "bold" }}>
              {item.description}
              {/* {item.title} */}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default TrendingNews;
