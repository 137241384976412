import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Card } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  monthlyEdition: {
    position: "relative",
    width: "100%",
    height: "400px",
    marginBottom: theme.spacing(3),
    borderRadius: "8px",
    overflow: "hidden",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  overlay: {
    position: "absolute",
    bottom: "0",
    left: "0",
    right: "0",
    height: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    backgroundColor: "rgb(2, 131, 145, 0.5)",
    clipPath: "polygon(0 50%, 100% 100%, 100% 100%, 0 100%)",
  },
  overlayText: {
    position: "relative",
    color: "#000",
    fontWeight: "bold",
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    // textAlign: "jusify",
    display: "flex",
    justifyContent: "flex-start",
    zIndex: 1,
  },
  overlayBefore: {
    content: '""',
    position: "absolute",
    bottom: "0",
    left: "0",
    right: "0",
    height: "100%",
    backgroundColor: "rgba(255, 219, 0, 0.5)",
    transform: "skewY(-10deg)",
    transformOrigin: "bottom left",
    zIndex: 0,
  },
}));

const MonthlyEdition = ({ imageUrl, month }) => {
  const classes = useStyles();

  return (
    <Card className={classes.monthlyEdition}>
      <img src={imageUrl} alt="Monthly Edition" className={classes.image} />
      <div className={classes.overlay}>
        <div className={classes.overlayText}>
          <Typography
            variant="h4"
            style={{
              fontFamily: "Times-new-roman",
              fontWeight: "700",
              fontSize: "2rem",
            }}>
            {month}
            <br></br>EDITION
          </Typography>
        </div>
      </div>
    </Card>
  );
};

export default MonthlyEdition;
