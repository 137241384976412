import React, { useState, useEffect } from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Box,
  Card,
  CardContent,
  CardActions,
  CircularProgress,
} from "@material-ui/core";
import { Switch, Button } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import {
  useGetLayoutQuery,
  useGetCategoriesQuery,
  useCreateLayoutRequestMutation,
  useUpdateLayoutRequestMutation,
} from "../../../features/api/apiSlice";
import {toast} from 'react-toastify'



const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
  },
  item: {
    display: "flex",
    width: "45%",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
  select: {
    width: "100%",
  },
  card: {
    width: '90%',
    margin: '20px auto',
    paddingBottom: "10px",
    padding: '20px',
    backgroundColor: 'white',
    borderRadius: '15px',
    boxShadow: "rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px"
  },
  cardActions: {
    justifyContent: "flex-end",
    padding: theme.spacing(0),
  },
  actionButton: {
    marginLeft: theme.spacing(1),
  },
}));

const dummyData = {
  categoryLatest1: "Finance",
  categoryLatest2: "Marketing",
  categoryNews1: "Technology",
  categoryNews2: "Health",
  categoryNews3: "Education",
  categoryNews4: "Finance",
  youtubePlaylist: true,
  publications: false,
};

const getCategoriesWithNone = (categories) => ["None", ...categories.map(category => category.name)];

const HomepageSettings = () => {
  const classes = useStyles();
  const [settings, setSettings] = useState(dummyData);
  const [token, setToken] = useState(localStorage.getItem("authToken"));

  // Fetch categories and layout data
  const {
    data: categoriesData,
    isLoading: isLoadingCategoriesData,
    isError: isErrorCategoriesData,
    error: errorCategoriesData,
  } = useGetCategoriesQuery();

  const {
    data: layoutData,
    isLoading: isLoadingLayoutData,
    isError: isErrorLayoutData,
    error: errorLayoutData,
  } = useGetLayoutQuery();

  const [
    createLayout,
    {
      isLoading: isLoadingCreateLayout,
      isError: isErrorCreateLayout,
      error: errorCreateLayout,
    },
  ] = useCreateLayoutRequestMutation();

  const [
    updateLayout,
    {
      isLoading: isLoadingUpdateLayout,
      isError: isErrorUpdateLayout,
      error: errorUpdateLayout,
    },
  ] = useUpdateLayoutRequestMutation();

  useEffect(() => {
    if (layoutData && layoutData.length > 0) {
      setSettings(layoutData[0]);
    }
  }, [layoutData]);

  const handleCategoryChange = (e) => {
    const { name, value } = e.target;
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
  };

  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: checked,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    console.log(settings);
    if (layoutData && layoutData.length > 0) {
      // Update existing layout
      await updateLayout({ id: 1, ...settings })
        .then((res) => {
          if (res.data) {
            // console.log("Layout updated successfully");
            toast.success("Layout updated successfully")
          } else {
            // console.error("Error updating layout");
            toast.error("Error updating layout");

          }
        })
        .catch((err) => {
          // console.error("Error updating layout:", err);
          toast.error("Error updating layout");

        });
    } else {
      // Create new layout
      await createLayout(settings)
        .then((res) => {
          if (res.data) {
            console.log("Layout created successfully");
          } else {
            console.error("Error creating layout");
          }
        })
        .catch((err) => {
          console.error("Error creating layout:", err);
        });
    }
  };

  const handleCancel = () => {
    if (layoutData && layoutData.length > 0) {
      setSettings(layoutData[0]);
    } else {
      setSettings(dummyData);
    }
    console.log("Changes cancelled");
  };

  const renderCategorySelect = (label, name) => (
    <Box className={classes.item}>
      <Typography>{label}</Typography>
      <FormControl variant="outlined" className={classes.select}>
        <InputLabel>{name}</InputLabel>
        <Select
          name={name}
          value={settings[name]}
          onChange={handleCategoryChange}
          label={name}
        >
          {categoriesData && getCategoriesWithNone(categoriesData).map((category) => (
            <MenuItem key={category} value={category}>
              {category}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );

  const renderSwitch = (label, name) => (
    <Box className={classes.item} style={{ display: "flex", justifyContent: "flex-start" }}>
      <Typography>{label}</Typography>
      <Switch
        checked={settings[name]}
        onChange={handleSwitchChange}
        name={name}
        color="success"
      />
    </Box>
  );

  if (isLoadingCategoriesData || isLoadingLayoutData) {
    return <CircularProgress />;
  }

  if (isErrorCategoriesData) {
    return <Typography color="error">Error loading categories: {errorCategoriesData.message}</Typography>;
  }

  if (isErrorLayoutData) {
    return <Typography color="error">Error loading layout: {errorLayoutData.message}</Typography>;
  }

  return (
    <Card className={classes.card}>
      <CardContent style={{ width: "100%", paddingBottom: "0px", paddingTop: "0px", marginBottom: "0px" }}>
        <Typography variant="h6">HomePage Sections</Typography>
        <Grid container spacing={2} className={classes.container}>
          <Grid container spacing={2} style={{ marginBottom: "15px", display: "flex", justifyContent: "space-around" }}>
            {renderCategorySelect("", "categoryLatest1")}
            {renderCategorySelect("", "categoryLatest2")}
          </Grid>
          <Grid container spacing={2} style={{ display: "flex", marginBottom: "15px", justifyContent: "space-around" }}>
            {renderCategorySelect("", "categoryNews1")}
            {renderCategorySelect("", "categoryNews2")}
          </Grid>
          <Grid container spacing={2} style={{ display: "flex", marginBottom: "15px", justifyContent: "space-around" }}>
            {renderCategorySelect("", "categoryNews3")}
            {renderCategorySelect("", "categoryNews4")}
          </Grid>
          <Grid container spacing={2} style={{ display: "flex", marginBottom: "0px", justifyContent: "space-around" }}>
            {renderSwitch("YouTube Playlist", "youtubePlaylist")}
            {renderSwitch("Publications", "publications")}
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Button
          color="primary"
          onClick={handleSave}
          className={classes.actionButton}
        >
          <Typography variant="button">Save</Typography>
        </Button>
        <Button
          color="error"
          onClick={handleCancel}
          className={classes.actionButton}
        >
          <Typography variant="button">Cancel</Typography>
        </Button>
      </CardActions>
    </Card>
  );
};

export default HomepageSettings;
