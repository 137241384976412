import React,{useState} from "react";
import { Grid, Card, Typography, Box } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ShareIcon from '@mui/icons-material/Share';
import { images } from "../../../assets/index"; // Adjust the import based on your project structure

const trendingNews = [
  { title: "Breaking News: Market Hits Record High", image: images?.TrendsHolderBg, views: 300, shares: 50 },
  { title: "Sports Update: Local Team Wins Championship", image: images?.TrendsHolderBg, views: 450, shares: 80 },
  { title: "Tech News: New Smartphone Released", image: images?.TrendsHolderBg, views: 500, shares: 100 },
  { title: "Entertainment: New Movie Breaks Box Office Records", image: images?.TrendsHolderBg, views: 200, shares: 40 },
  { title: "Health: New Study Reveals Benefits of Walking", image: images?.TrendsHolderBg, views: 150, shares: 30 },
  { title: "Breaking News: Market Hits Record High", image: images?.TrendsHolderBg, views: 300, shares: 50 },
  { title: "Sports Update: Local Team Wins Championship", image: images?.TrendsHolderBg, views: 450, shares: 80 },
  { title: "Tech News: New Smartphone Released", image: images?.TrendsHolderBg, views: 500, shares: 100 },
  { title: "Entertainment: New Movie Breaks Box Office Records", image: images?.TrendsHolderBg, views: 200, shares: 40 },
  { title: "Health: New Study Reveals Benefits of Walking", image: images?.TrendsHolderBg, views: 150, shares: 30 },
];

const TrendingNewsComponent = () => {
  const [token, setToken] = useState(localStorage.getItem("authToken"));

  return (
    <Box style={styles.cardHolder}>
      <Typography variant="h6" style={styles.pageTitle}>Trending Content</Typography>
      <Box style={styles.scrollContainer}>
        <Grid container spacing={3}>
          {trendingNews.map((news, index) => (
            <Grid item xs={12} key={index}>
              <Card style={{ ...styles.smallCard, backgroundImage: `url(${news?.image})` }}>
                <Box style={styles.overlay}>
                  <Typography variant="h6" style={styles.cardRank}>
                    #{index + 1}
                  </Typography>
                  <Typography variant="h6" style={styles.cardTitle}>
                    {news.title}
                  </Typography>
                  <Box style={styles.statsContainer}>
                    <Box style={styles.stat}>
                      <VisibilityIcon style={styles.statIcon} />
                      <Typography variant="body2" style={styles.statText}>{news.views}</Typography>
                    </Box>
                    <Box style={styles.stat}>
                      <ShareIcon style={styles.statIcon} />
                      <Typography variant="body2" style={styles.statText}>{news.shares}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

const styles = {
  cardHolder: {
    width: '90%',
    margin: '20px auto',
    padding: '20px',
    backgroundColor: 'white',
    borderRadius: '15px',
    boxShadow: "rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px",
    overflowY: 'auto',
    maxHeight: '500px', // Adjust based on your preference
  },
  pageTitle: {
    marginBottom: '20px',
    color: '#333',
  },
  scrollContainer: {
    maxHeight: '400px', // Adjust based on your preference
    overflowY: 'scroll',
  },
  smallCard: {
    width: "90%",
    height: '150px',
    borderRadius: '10px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px',
  },
  cardRank: {
    color: 'gold',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    position: 'absolute',
    top: '10px',
    left: '10px',
  },
  cardTitle: {
    color: 'white',
    fontSize: '1rem',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 'auto',
    marginBottom: '10px',
    padding: '0 10px', // Padding to avoid text overflow
  },
  statsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '50%',
    position: 'absolute',
    top: '5px',
  },
  stat: {
    display: 'flex',
    alignItems: 'center',
  },
  statIcon: {
    color: 'lightgrey',
    marginRight: '4px',
    fontSize: '0.8rem',
  },
  statText: {
    color: 'lightgrey',
    fontSize: '0.9rem',
  },
};

export default TrendingNewsComponent;
