import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Info, Edit as EditIcon, OpenInNew } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { SimpleCard } from "../../../../components";
import { primaryColor, colorTags } from "../../../../constants/variants";
import GridData from "../../../../components/GridData";
import AddNews from "./AddNews";
import DeleteDialog from "../../../../components/DeleteDialog";
import { useNavigate } from "react-router-dom";
import EditNews from "./EditNews";
import {
  useGetArticlesQuery,
  useDeleteArticlesRequestMutation,
} from "../../../../features/api/apiSlice";
import { BASE_FILE_URL } from "../../../../constants/apiTags";
import { Bars } from "react-loader-spinner";
import AddIcon from "@mui/icons-material/Add";

const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
  },
}));

const newsDataq = [
  {
    id: 1,
    title: "Ethiopian Exports Soar to New Heights",
    subtitle: "Record-breaking achievements in Ethiopia's export sector.",
    paragraph1:
      "A celebration of Ethiopia's record-breaking export achievements.",
    paragraph2:
      "A celebration of Ethiopia's record-breaking export achievements.",

    paragraph3:
      "A celebration of Ethiopia's record-breaking export achievements.",

    coverImage: "ethiopian-exports.jpg",
    additionalImages: ["trade-agreements.jpg", "trade-agreements.jpg"],
    tags: [],
    category: "",
    subcategory: "",
    language: "en",
    templateId: 1,
    views: 1500,
    shares: 300,
    createdAt: "2023-06-01T10:00:00Z",
    updatedAt: "2023-06-10T12:00:00Z",
  },
  {
    id: 2,
    title: "New Trade Agreements Boost Ethiopia's Export Sector",
    subtitle: "Impact of recent trade agreements on Ethiopia's exports.",
    paragraph1:
      "A celebration of Ethiopia's record-breaking export achievements.",
    paragraph2:
      "A celebration of Ethiopia's record-breaking export achievements.",

    paragraph3:
      "A celebration of Ethiopia's record-breaking export achievements.",

    coverImage: "ethiopian-exports.jpg",

    additionalImages: ["trade-agreements.jpg", "trade-agreements.jpg"],

    tags: [],
    category: "",
    subcategory: "",
    language: "en",
    templateId: 2,
    views: 1800,
    shares: 450,
    createdAt: "2023-06-02T11:00:00Z",
    updatedAt: "2023-06-12T14:00:00Z",
  },
];

export default function NewsArticlesPage() {
  // const { user } = useSelector((state) => state.auth);
  const storedUser = localStorage.getItem("user");

  const user = storedUser ? storedUser : null;
  const navigate = useNavigate();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const handleEditModalClose = () => setEditModalOpen(false);
  const handleEditModalOpen = () => setEditModalOpen(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("authToken"));

  const {
    data: newsData,
    isLoading: isLoadingNewsData,
    isError: isErrorNewsData,
    error: errorNewsData,
  } = useGetArticlesQuery({ lang: "am" });
  const {
    data: newsDataEn,
    isLoading: isLoadingNewsDataEn,
    isError: isErrorNewsDataEn,
    error: errorNewsDataEn,
  } = useGetArticlesQuery({ lang: "en" });
  useEffect(() => {
    if (newsData) {
      console.log(newsData);
    }
  }, [newsData]);

  const columns = [
    {
      field: "id",
      headerName: "No.",
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
    },
    {
      field: "subtitle",
      headerName: "Subtitle",
      flex: 1,
    },
    // {
    //   field: "intro",
    //   headerName: "Introduction",
    //   flex: 2,
    // },
    // {
    //   field: "contentType",
    //   headerName: "Content Type",
    //   flex: 1,
    // },
    // {
    //   field: "coverImage",
    //   headerName: "Cover Image",
    //   flex: 1,
    //   renderCell: (params) => (
    //     <div>
    //       <img
    //         src={BASE_FILE_URL + params?.row?.coverImage}
    //         style={{ width: "100%", height: "100%" }}
    //         alt="cover"
    //       />
    //     </div>
    //   ),
    // },
    {
      field: "templateId",
      headerName: "Template ID",
      flex: 1,
    },
    {
      field: "views",
      headerName: "Views",
      flex: 0.5,
    },
    {
      field: "shares",
      headerName: "Shares",
      flex: 0.5,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      type: "date",
      flex: 1,
    },
    // {
    //   field: "updatedAt",
    //   headerName: "Updated At",
    //   type: "date",
    //   flex: 1,
    // },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: 1,
      align: "left",
      renderCell: (params) => {
        return (
          <div className="flex">
            <IconButton
              onClick={() =>
                navigate(`/news-detail/${params?.row?.id}`, "_blank")
              }>
              <Info color="primary" />
            </IconButton>
            <IconButton
              onClick={() => {
                setEditModalOpen(true);
                setSelectedRow(params?.row);
              }}>
              <EditIcon color="primary" />
            </IconButton>
            <DeleteDialog
              api={useDeleteArticlesRequestMutation}
              dialogLabel="Delete News Article"
              id={params?.row?.id}
              name={`Article ${params?.row?.title}`}
            />
          </div>
        );
      },
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => setModalOpen(false);
  const handleModalOpen = () => setModalOpen(true);

  if (isLoadingNewsData)
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Bars
          height="40"
          width="40"
          // color="#4fa94d"
          color={colorTags.TERTIARY}
          ariaLabel="bars-loading"
          visible={true}
        />
      </div>
    );

  return (
    <div style={{ marginLeft: "5%", marginTop: "5%", width: "90%" }}>
      <div>
        {modalOpen ? (
          <Button
            variant="contained"
            style={{
              marginBottom: "1%",
              marginLeft: "88%",
              backgroundColor: colorTags.SECONDARY,
            }}
            onClick={handleModalClose}>
            Close
          </Button>
        ) : (
          // <Button
          //   variant="contained"
          //   style={{
          //     marginBottom: "1%",
          //     marginLeft: "88%",
          //     backgroundColor: primaryColor,
          //   }}
          //   onClick={handleModalOpen}>
          // </Button>
          <Button
            variant="contained"
            style={{
              marginBottom: "1%",
              marginLeft: "88%",
              backgroundColor: colorTags.TERTIARY,
            }}
            onClick={handleModalOpen}>
            <AddIcon style={{ marginRight: "5px" }} />
            News
          </Button>
        )}
      </div>
      {modalOpen && <AddNews onClose={handleModalClose} actionText="Close" />}
      <SimpleCard
        title="News Articles List"
        style={{
          marginTop: "2%",
        }}>
        <Box width="100%" overflow="auto">
          <GridData rows={newsData ?? []} columns={columns} />
        </Box>
      </SimpleCard>
      {editModalOpen && (
        <EditNews
          open={editModalOpen}
          onClose={handleEditModalClose}
          data={selectedRow}
        />
      )}
    </div>
  );
}
