import React, { useState, useEffect } from 'react';
import { Grid, Paper, Stack } from '@mui/material';
import HomepageSettings from "./SectionSelect";
import { images } from '../../../assets';
import { makeStyles } from "@material-ui/core/styles";
import { useGetAnalyticsSocialsQuery } from '../../../features/api/apiSlice';


const useStyles = makeStyles((theme) => ({
  cardsHolder: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "5% 0px 20px 0px",
    padding: "0px 10px 0px 10px",
    backgroundSize: "cover",
  },
  smallCard: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    minHeight: "250px", // Adjusted height for more space
    borderRadius: "15px",
    position: "relative",
    overflow: "hidden",
  },
  smallCardTitles: {
    color: "#FFF", 
    fontSize: "2rem",
    fontWeight: "bold",
    textAlign: "center",
  },
  icon: {
    width: "90px",
    height: "90px",
    position:'absolute',
    top:'70%',
    left:'0%',
    marginRight: "10px",
    filter: "drop-shadow(0 0 10px rgba(0, 0, 0, 0.6))",
  },
  darkOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.4)", // Dark overlay
    backdropFilter:"blur(4px)",
    zIndex: 1,
  },
  contentContainer: {
    position: "relative",
    zIndex: 2, // Ensures content is above the overlay
    textAlign: "center",
    color: "#FFF",
  },
}));

const AnalyticsDashboard = () => {
  const classes = useStyles();

  const {
    data: socialsData,
    isLoading: isLoadingSocialsData,
    isError: isErrorSocialsData,
    error: errorSocialsData,
  } = useGetAnalyticsSocialsQuery(); 

  

  const [analyticsData, setAnalyticsData] = useState({
    facebook: {
      views: 1234,
      members: { count: 200, change: 10 }, // +10 from last month
      postsMade: 20,
    },
    telegram: {
      views: 5678,
      members: { count: 450, change: -15 }, // -15 from last month
      postsMade: 35,
    },
  });

  const AnimatedCounter = ({ value }) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
      let start = 0;
      const interval = setInterval(() => {
        if (start >= value) {
          clearInterval(interval);
          setCount(value); // Set the final value when the animation is done
        } else {
          setCount(start);
          start += 10;
        }
      }, 100);

      return () => {
        clearInterval(interval);
      };
    }, [value]);

    return (
      <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
        {count}
      </span>
    );
  };

  const renderAnalyticsCard = (platform, data, backgroundImage) => {
    return (
      <Paper
        className={classes.smallCard}
        style={{
          backgroundImage:platform === 'facebook' ? `url(${images.FacebookStock})`:`url(${images.TelegramStock})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
    borderRadius: "15px",

        }}
      >
        <div className={classes.darkOverlay}></div>
        <Stack
          p={5}
          direction="row"
        //   alignItems="center"
        //   justifyContent="space-between"
          zIndex={2}
          spacing={4}
          className={classes.contentContainer}
          style={{
            width:'100%',
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          <img
            src={platform === 'facebook' ? images.FacebookIcon : images.TelegramIcon}
            alt={`${platform} Icon`}
            className={classes.icon}
          />
          <div>
            <h3 className={classes.smallCardTitles}>Views</h3>
            <AnimatedCounter value={data.views} />
          </div>
          <div>
            <h3 className={classes.smallCardTitles}>Members</h3>
            <AnimatedCounter value={data.members.count} />
            <p
              style={{
                color: data.members.change >= 0 ? 'lightgreen' : 'tomato',
                fontSize:'1.3rem',
                fontWeight: 'bold',
              }}
            >
              {data.members.change >= 0 ? `+${data.members.change}` : data.members.change}
            </p>
          </div>
          <div>
            <h3 className={classes.smallCardTitles}>Posts Made</h3>
            <AnimatedCounter value={data.postsMade} />
          </div>
        </Stack>
      </Paper>
    );
  };

  return (
    <Grid container spacing={3} pl={2} mt={2}>
      <Grid
        container
        mb={8}
        // mt={2}
        spacing={3}
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
        item
        xs={12}
      >
        {/* Facebook Analytics Card */}
        <Grid item xs={6}>
          {renderAnalyticsCard('facebook', analyticsData.facebook, 'path/to/facebook-background.jpg')}
        </Grid>

        {/* Telegram Analytics Card */}
        <Grid item xs={6}
        // className={classes.cardsHolder}
       
        >
          {renderAnalyticsCard('telegram', analyticsData.telegram, 'path/to/telegram-background.jpg')}
        </Grid>
      </Grid>

      {/* Right-hand Side - Homepage Settings */}
   
    </Grid>
  );
};

export default AnalyticsDashboard;
