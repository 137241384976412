import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Avatar,
  styled,
  Button,
  TextField,
  IconButton,
} from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";
import InstagramIcon from "@mui/icons-material/Instagram";
import YoutubeIcon from "@mui/icons-material/YouTube";
import { useStyles } from "../../../App";
import { DropzoneArea } from "material-ui-dropzone";
import { useNavigate } from "react-router-dom";
import { useCreateSocialPostRequestMutation } from "../../../features/api/apiSlice";
import { FILE_LINK_URL } from "../../../constants/apiTags";
import { toast } from "react-toastify";
import { useGetAnalyticsSocialsQuery } from "../../../features/api/apiSlice";
import { colorTags } from "../../../constants/variants";
import NewPostForm from "./SocialsPost";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const GlowingButton = styled(Button)(({ theme }) => ({
  padding: "0.6em 2em",
  border: "none",
  outline: "none",
  color: "rgb(255, 255, 255)",
  background: "#111",
  cursor: "pointer",
  position: "relative",
  zIndex: 0,
  borderRadius: "10px",
  userSelect: "none",
  WebkitUserSelect: "none",
  touchAction: "manipulation",
  "&:before": {
    content: '""',
    background: `linear-gradient(
      45deg,
      #0088cc,
      #3b5998,
      #000,
      #fffb00,
      #ff00c8,
      #ff0000
    )`,
    position: "absolute",
    top: "-2px",
    left: "-2px",
    backgroundSize: "400%",
    zIndex: -1,
    filter: "blur(5px)",
    WebkitFilter: "blur(5px)",
    width: "calc(100% + 4px)",
    height: "calc(100% + 4px)",
    animation: "glowing-button-85 20s linear infinite",
    transition: "opacity 0.3s ease-in-out",
    borderRadius: "10px",
  },
  "&:after": {
    zIndex: -1,
    content: '""',
    position: "absolute",
    width: "100%",
    height: "100%",
    background: "#222",
    left: 0,
    top: 0,
    borderRadius: "10px",
  },
  "@keyframes glowing-button-85": {
    "0%": {
      backgroundPosition: "0 0",
    },
    "50%": {
      backgroundPosition: "400% 0",
    },
    "100%": {
      backgroundPosition: "0 0",
    },
  },
}));

const YouTubeButton = styled(Button)(({ theme }) => ({
  "--glow-color": "rgba(255, 24, 0, 0.8)",
  "--glow-spread-color": "rgba(255, 24, 0, 0.2)",
  "--enhanced-glow-color": "rgb(231, 206, 255)",
  // "--btn-color": "rgb(100, 61, 136)",
  "--btn-color": "rgb(255,255,255)",

  border: "0.1px solid var(--glow-color)",
  padding: "1em 3em",
  color: "var(--glow-color)",
  fontSize: "15px",
  fontWeight: "bold",
  backgroundColor: "var(--btn-color)",
  borderRadius: "1em",
  outline: "none",
  boxShadow: `0 0 1em .25em var(--glow-color),
              `,
  textShadow: `0 0 .5em var(--glow-color)`,
  position: "relative",
  transition: "all 0.3s",
  "&::after": {
    pointerEvents: "none",
    content: '""',
    position: "absolute",
    top: "120%",
    left: 0,
    height: "100%",
    width: "100%",
    filter: "blur(2em)",
    opacity: ".7",
    transform: "perspective(1.5em) rotateX(35deg) scale(1, .6)",
  },
  "&:hover": {
    color: "var(--btn-color)",
    backgroundColor: "var(--glow-color)",
    boxShadow: `0 0 1em .25em var(--glow-color),
                `,
  },
  "&:active": {
    boxShadow: `0 0 0.6em .25em var(--glow-color),
                0 0 2.5em 2em var(--glow-spread-color),
                inset 0 0 .5em .25em var(--glow-color)`,
  },
}));

const socialMediaData = [
  {
    name: "Facebook",
    views: 1200,
    icon: <FacebookIcon sx={{ color: "#3b5998" }} />,
  },
  // { name: "Twitter", views: 900, icon: <TwitterIcon sx={{ color: "#000" }} /> },
  {
    name: "Telegram",
    views: 1100,
    // icon: <TelegramIcon sx={{ color: "#0088cc" }} />,
    icon: <TelegramIcon sx={{ color: "#000" }} />,
  },
  // {
  //   name: "YouTube",
  //   views: 500,
  //   icon: <YoutubeIcon sx={{ color: "#FF0000" }} />,
  // },
];

const generateDynamicLabels = () => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const currentMonth = new Date().getMonth();
  const labels = monthNames
    .slice(currentMonth + 1)
    .concat(monthNames.slice(0, currentMonth + 1));
  return labels;
};

const reachData = {
  labels: generateDynamicLabels(),
  datasets: [
    {
      label: "Facebook",
      data: [65, 59, 70, 81, 76, 55, 60, 71, 66, 65, 65, 50],
      borderColor: "rgba(24, 119, 242, 1)",
      backgroundColor: "rgba(24, 119, 242, 0.2)",
      fill: false,
    },
    // {
    //   label: "YouTube",
    //   data: [75, 69, 90, 71, 66, 65, 50, 59, 70, 81, 60, 71],
    //   borderColor: "rgba(255, 24, 0, 1)",
    //   backgroundColor: "rgba(255, 24, 0, 0.2)",
    //   fill: false,
    // },
    {
      label: "Telegram",
      data: [45, 49, 60, 61, 80, 91, 66, 56, 45, 40, 69, 90],
      // borderColor: "rgba(0, 136, 204, 1)",
      // backgroundColor: "rgba(0, 136, 204, 0.8)",
      borderColor: "rgba(0,0,0, 1)",
      backgroundColor: "rgba(0,0,0, 0.8)",
      fill: false,
    },
    // {
    //   label: "Twitter(X)",
    //   data: [55, 59, 76, 55, 60, 80, 91, 66, 55, 50, 70, 81],
    //   borderColor: "rgba(0, 0, 0, 1)",
    //   backgroundColor: "rgba(0, 0, 0, 0.2)",
    //   fill: false,
    // },
  ],
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Reach Over Last 12 Months",
    },
  },
};
const uploadImage = async (file) => {
  try {
    const formData = new FormData();
    formData.append("files", file);

    const response = await fetch(FILE_LINK_URL, {
      method: "POST",
      body: formData,
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error("Image upload failed");
    }
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error;
  }
};
// const NewPostForm = ({ onCancel }) => {
//   const [selectedIcons, setSelectedIcons] = useState({
//     facebook: false,
//     twitter: false,
//     telegram: false,
//   });
//   const classez = useStyles();
//   const [text, setText] = useState("");
//   const [link, setLink] = useState("");

//   // const [
//   //   createSocialPost,
//   //   { isLoading: isCreateSocialPostLoading, isError: isCreateSocialPostError },
//   // ] = useCreateSocialPostRequestMutation();
//   const [
//     createSocialPost,
//     {
//       isLoading: isCreateUserLoading,
//       isError: isCreateUserError,
//       error: createUserError,
//     },
//   ] = useCreateSocialPostRequestMutation();
//   const handleIconClick = (platform) => {
//     setSelectedIcons((prevState) => ({
//       ...prevState,
//       [platform]: !prevState[platform],
//     }));
//   };
//   const [cover, setCover] = useState([]);

//   const handleSave = async (e) => {
//     e.preventDefault();

//     // Collect form data
//     // const text = e.target.text.value;
//     // const link = e.target.link.value;

//     // Check if text and link are provided
//     // if (!text || !link) {
//     //   alert("Text and Link are required!");
//     //   return;
//     // }

//     let coverImageUrl = "";

//     // Upload cover image if there is one
//     if (cover.length > 0) {
//       try {
//         const uploadedImage = await uploadImage(cover[0]);
//         coverImageUrl = uploadedImage?.filenames[0] ?? "";
//       } catch (error) {
//         console.error("Error uploading image:", error);
//         alert("Error uploading image. Please try again.");
//         return;
//       }
//     }

//     // Prepare the post data
//     const postData = {
//       text,
//       link,
//       cover: coverImageUrl,
//       platforms: [
//         selectedIcons.facebook ? "Facebook" : "",
//         selectedIcons.twitter ? "Twitter" : "",
//         selectedIcons.telegram ? "Telegram" : "",
//       ],
//     };

//     // Create the social post
//     // try {
//     console.log(postData);
//     await createSocialPost(postData)
//       .then((res) => {
//         if (res) toast.success("Post created successfully!");
//         setCover([]);
//         // e.target.reset();
//         setSelectedIcons({
//           facebook: false,
//           twitter: false,
//           telegram: false,
//         });
//         setText("");
//         setLink("");
//         onCancel();
//       })
//       .catch((error) => {
//         console.error("Error creating post:", error);
//         alert("Error creating post. Please try again.");
//       });
//     setCover([]);
//     // e.target.reset();
//     setSelectedIcons({
//       facebook: false,
//       twitter: false,
//       telegram: false,
//     });
//     setText("");
//     setLink("");
//     onCancel();
//   };

//   return (
//     <Box
//       component="form"
//       sx={{
//         mt: 1,
//       }}>
//       <DropzoneArea
//         dropzoneClass={classez?.dropZone}
//         showAlerts={false}
//         useChipsForPreview={true}
//         acceptedFiles={["image/jpeg", "image/jpg", "image/png", "image/webp"]}
//         filesLimit={1}
//         maxFiles={1}
//         maxFileSize={5000000}
//         dropzoneText={"Drop or Click to Upload Avatar (JPEG, JPG, PNG, WEBP)"}
//         onChange={(files) => setCover(files)}
//       />
//       <TextField
//         margin="normal"
//         required
//         fullWidth
//         id="text"
//         label="Text"
//         value={text}
//         onChange={(e) => setText(e.target.value)}
//         name="text"
//         autoComplete="text"
//       />
//       <TextField
//         margin="normal"
//         required
//         fullWidth
//         id="link"
//         label="Link"
//         name="link"
//         value={link}
//         onChange={(e) => setLink(e.target.value)}
//         autoComplete="link"
//       />
//       {/* <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
//         <IconButton
//           onClick={() => handleIconClick("facebook")}
//           sx={{
//             color: selectedIcons.facebook ? "#3b5998" : "gray",
//           }}>
//           <FacebookIcon />
//         </IconButton>
//         <IconButton
//           onClick={() => handleIconClick("twitter")}
//           sx={{
//             color: selectedIcons.twitter ? "#1DA1F2" : "gray",
//           }}>
//           <TwitterIcon />
//         </IconButton>
//         <IconButton
//           onClick={() => handleIconClick("telegram")}
//           sx={{
//             color: selectedIcons.telegram ? "#0088cc" : "gray",
//           }}>
//           <TelegramIcon />
//         </IconButton>
//       </Box> */}
//       <Box
//         sx={{
//           display: "flex",
//           justifyContent: "flex-end",
//           mt: 2,
//         }}>
//         <Button
//           // type="submit"
//           style={{
//             borderRadius: "10px",
//             marginRight: "18px",
//             backgroundColor: colorTags.TERTIARY,
//           }}
//           variant="contained"
//           onClick={handleSave}>
//           Post
//         </Button>
//         <Button
//           onClick={onCancel}
//           style={{ borderRadius: "10px" }}
//           variant="outlined"
//           color="error">
//           Cancel
//         </Button>
//       </Box>
//     </Box>
//   );
// };

export default function SocialsDashboard() {
  const { user } = useSelector((state) => state.auth);
  const [showForm, setShowForm] = useState(false);
  const classez = useStyles();
  const [token, setToken] = useState(localStorage.getItem("authToken"));
  const navigate = useNavigate();

  const {
    data: socialsData,
    isLoading: isLoadingSocialsData,
    isError: isErrorSocialsData,
    error: errorSocialsData,
  } = useGetAnalyticsSocialsQuery();

  return (
    <Box sx={{ marginLeft: "5%", marginTop: "5%", width: "90%" }}>
      <Grid container spacing={3}>
        <Grid item xs={10} md={5}>
          <Card
            style={{
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
            }}>
            <CardContent style={{ minHeight: "200px" }}>
              <Typography
                variant="h6"
                gutterBottom
                style={{ color: "#03AED2" }}>
                Social Media Views
              </Typography>
              <Grid container spacing={3} sx={{ marginTop: "10px" }}>
                {socialMediaData?.map((social, index) => (
                  <Grid item xs={6} key={index}>
                    <Box display="flex" alignItems="center">
                      <Avatar sx={{ bgcolor: "transparent", marginRight: 2 }}>
                        {social.icon}
                      </Avatar>
                      <Box>
                        <Typography variant="body1">{social.name}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {social.views} views
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
          <GlowingButton
            role="button"
            style={{ width: "100%", marginTop: "28px" }}
            onClick={() => setShowForm(true)}>
            New Post
          </GlowingButton>
          <YouTubeButton
            role="button"
            style={{ width: "100%", marginTop: "28px" }}
            onClick={() => navigate("/dashboard/youTube-playlist")}>
            <YoutubeIcon sx={{ marginRight: 1 }} /> Playlist
          </YouTubeButton>
        </Grid>
        <Grid item xs={13} md={7}>
          <Card
            style={{
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
            }}>
            <CardContent>
              <Box
                sx={{
                  marginTop: "10px",
                }}>
                {showForm ? (
                  <NewPostForm onCancel={() => setShowForm(false)} />
                ) : (
                  <Line data={reachData} options={options} />
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
