import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Collapse,
  useMediaQuery,
  useTheme,
  makeStyles,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import HomeIcon from "@material-ui/icons/Home";
import EditIcon from "@material-ui/icons/Edit";
import BusinessIcon from "@material-ui/icons/Business";
import CommentIcon from "@material-ui/icons/Comment";
import ReportIcon from "@material-ui/icons/Report";
import TvIcon from "@material-ui/icons/Tv";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import EventIcon from "@material-ui/icons/Event";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import NatureIcon from "@material-ui/icons/Nature";
import AgricultureIcon from "@material-ui/icons/LocalFlorist";
import IndustryIcon from "@material-ui/icons/Apartment";
import ServiceIcon from "@material-ui/icons/RoomService";
import { images } from "../../../assets";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
    [theme.breakpoints.down(650)]: {
      width: "100%",
    },
  },
  menuButton: {
    marginLeft: theme.spacing(1),
    color: "black",
    borderBottom: "1px solid grey",
  },
  closeIcon: {
    position: "absolute",
    top: theme.spacing(0.2),
    right: theme.spacing(1),
  },
  menuTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(0.5),
  },
  menuItem: {
    paddingLeft: theme.spacing(3),
    borderBottom: "1px solid grey",
  },
  bigItemIcon: {
    marginRight: theme.spacing(1),
  },
  topBar: {
    width: "100%",
    height: 50,
    backgroundColor: "white",
    padding: "0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width: "50%",
    maxWidth: 300,
    maxHeight: 100,
    height: "auto",
    objectFit: "cover",
    cursor: "pointer",
  },
}));

const Sidebar = ({ menuData }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState({});
  const navigate = useNavigate();
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmenuClick = (menu, event) => {
    event.stopPropagation(); // Prevents sidebar from closing
    setOpenSubmenu({
      ...openSubmenu,
      [menu]: !openSubmenu[menu],
    });
  };

  const renderIcon = (text) => {
    switch (text) {
      case "Home":
        return <HomeIcon className={classes.bigItemIcon} />;
      case "Editorial":
        return <EditIcon className={classes.bigItemIcon} />;
      case "Green Economy":
        return <NatureIcon className={classes.bigItemIcon} />;
      case "Economy":
        return <BusinessIcon className={classes.bigItemIcon} />;
      case "Agriculture":
        return <AgricultureIcon className={classes.bigItemIcon} />;
      case "Industry":
        return <IndustryIcon className={classes.bigItemIcon} />;
      case "Service":
        return <ServiceIcon className={classes.bigItemIcon} />;
      case "Opinions":
        return <CommentIcon className={classes.bigItemIcon} />;
      case "Reports":
        return <ReportIcon className={classes.bigItemIcon} />;
      case "Addis Chamber TV":
        return <TvIcon className={classes.bigItemIcon} />;
      case "Addis Chamber Podcast":
        return <AnnouncementIcon className={classes.bigItemIcon} />;
      case "Events":
        return <EventIcon className={classes.bigItemIcon} />;
      default:
        return null;
    }
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
      }}>
      <IconButton
        edge="start"
        className={classes.menuButton}
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(true)}>
        <MenuIcon />
      </IconButton>

      <Drawer open={open} onClose={toggleDrawer(false)}>
        <div
          className={classes.list}
          role="presentation"
          onKeyDown={toggleDrawer(false)}>
          <IconButton
            className={classes.closeIcon}
            onClick={handleClose}
            color="inherit">
            <CloseIcon />
          </IconButton>
          <div className={classes.topBar}>
            {
              <img
                src={images.NewsLogoPlaceholder}
                alt="Top Bar"
                onClick={() => navigate("/")}
                className={classes.image}
              />
            }
          </div>
          <List>
            {Object.entries(menuData).map(([menuItem, subMenu]) => (
              <div key={menuItem}>
                <ListItem
                  button
                  onClick={toggleDrawer(false)}
                  style={{
                    borderBottom: "1px solid #cccc",
                  }}>
                  <ListItemIcon>{renderIcon(menuItem)}</ListItemIcon>
                  <ListItemText primary={menuItem} />
                  {subMenu && subMenu.length > 0 && (
                    <IconButton
                      edge="end"
                      onClick={(event) => handleSubmenuClick(menuItem, event)}>
                      {openSubmenu[menuItem] ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  )}
                </ListItem>
                {subMenu && subMenu.length > 0 && (
                  <Collapse
                    in={openSubmenu[menuItem]}
                    timeout="auto"
                    unmountOnExit>
                    {subMenu.map((subMenuItem) => (
                      <ListItem
                        button
                        key={subMenuItem}
                        className={classes.menuItem}
                        onClick={toggleDrawer(false)}
                        style={{
                          borderBottom: "0.3px solid #eeee",
                        }}>
                        <ListItemText primary={subMenuItem} />
                      </ListItem>
                    ))}
                  </Collapse>
                )}
              </div>
            ))}
          </List>
        </div>
      </Drawer>
    </div>
  );
};

const menuData = {
  Home: [],
  Editorial: [],
  "Green Economy": ["Climate Change", "Technology", "Inclusive Economies"],
  Economy: [
    "Business News",
    "Entrepreneurship",
    "Wholesale and Retail Trade",
    "Export/Import",
    "Transport and Logistics",
    "Agribusiness",
    "Manufacturing",
    "Construction",
    "Banking and Finance",
    "Tourism and Hospitality",
    "Companies",
    "Education",
    "Energy",
    "Fisheries and Aquaculture",
    "Health",
    "Insurance",
    "Features and Advocacy",
  ],
  Agriculture: [],
  Industry: [],
  Service: [],
  Opinions: [],
  Reports: [],
  "Addis Chamber TV": [],
  "Addis Chamber Podcast": [],
  Events: [],
};

const App = () => {
  return <Sidebar menuData={menuData} />;
};

export default App;
