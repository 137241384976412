import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Stack, Box, useMediaQuery } from "@mui/material";
import Header from "../../Header/Header2";
import LogoHead from "../../Header/LogoHead2";
import Footer from "../../Footer/Footer2";
import LoadingScreen from "../../../../components/LoadingMain";
import ChipComponent from "../../../../components/ChipGenerator";
import { images } from "../../../../assets";
import Advertisements from "../Advertisements";
import Title from "../../Header/Title2";
import RelatedArticles from "../RelatedArticles";
import { useTranslation } from "react-i18next";
import TrendingNews from "../TrendingNews";
import TrendingNewsInCategory from "../CategoryPages/TrendingNewsInCategory";
import MostReadThisMonth from "../CategoryPages/MostReadThisMonthInCategory";
import ShareDialog from "./Share";
import {
  useGetArticlesByIdQuery,
  useGetAdsQuery,
  useGetAuthorByIdQuery,
} from "../../../../features/api/apiSlice";
import { useParams } from "react-router-dom";
import { BASE_FILE_URL } from "../../../../constants/apiTags";
import { Helmet } from "react-helmet";
import { Avatar } from "@material-ui/core";
import { colorTags } from "../../../../constants/variants";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    // alignItems: "flex-start",
    // backgroundColor: "red",

    alignItems: "flex-end",
    padding: theme.spacing(4),
    paddingTop: theme.spacing(0),
    paddingLeft: "5%",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      paddingLeft: "2%",
      width: "95%",
    },
  },
  coverImage: {
    width: "50%",
    height: "auto",
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(2),
    maxHeight: "400px",
    objectFit: "contain",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  contentContainer: {
    width: "95%",
    // backgroundColor: "blue",

    // padding: theme.spacing(4),
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: theme.spacing(2),
    },
  },
  newsTitle: {
    fontSize: "52px",
    fontFamily: "Times-new-roman",
    marginBottom: theme.spacing(1),
    textAlign: "left",
    lineHeight: "3.4rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "32px",
    },
  },
  newsSubtitle: {
    fontSize: "20px",
    fontWeight: "300",
    marginBottom: theme.spacing(0),
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  newsParagraph: {
    fontSize: "20px",
    fontFamily: "Times-new-roman",
    marginBottom: theme.spacing(3),
    textAlign: "justify",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  additionalImage: {
    width: "49%",
    height: "auto",
    marginLeft: "10px",
    maxHeight: "200px",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    objectFit: "contain",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  metadata: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
    fontSize: "14px",

    color: "#777",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      fontSize: "12px",
    },
  },
  chipContainer: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      marginBottom: theme.spacing(2),
    },
  },
  youtubeFrameContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  youtubeFrame: {
    width: "60%",
    height: "auto",
    minHeight: "300px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minHeight: "200px",
    },
  },
  AdFrame: {
    width: "60%",
    height: "auto",
    // minHeight: "300px",
    marginTop: "10",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      // minHeight: "200px",
      display: "flex",
      flexDirection: "center",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  RelatedFrame: {
    width: "60%",
    height: "fit-content",
    // minHeight: "300px",
    // marginTop: "30px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      // minHeight: "200px",
      display: "flex",
      flexDirection: "center",
      justifyContent: "center",
      alignItems: "center",
    },
  },

  addtionalImagesContainer: {
    display: "flex",
    justifyContent: "flex-start",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
}));

const convertToDateWithMonthName = (dateString) => {
  const date = new Date(dateString);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString("en-US", options);
};

const NewsPage = () => {
  const classes = useStyles();
  const [isLoadingNews, setIsLoadingNews] = useState(false);
  const { t, i18n } = useTranslation();
  const params = useParams();
  // Advertisement data
  const {
    data: adsData,
    isLoading: isLoadingAds,
    isError: isErrorAds,
    error: adsError,
  } = useGetAdsQuery();

  const topAds = adsData?.filter((item) => {
    const matchesPage = item?.page?.includes("") || item?.page?.includes("All");
    const matchesPosition =
      item?.position?.includes("NewsDetail") || item?.position?.includes("All");
    return matchesPage && matchesPosition;
  });
  const middleAds = adsData?.filter((item) => {
    const matchesPage =
      item?.page?.includes("NewsDetail") || item?.page?.includes("All");
    const matchesPosition =
      item?.position?.includes("Middle") || item?.position?.includes("All");
    return matchesPage && matchesPosition;
  });
  const bottomAds = adsData?.filter((item) => {
    const matchesPage =
      item?.page?.includes("NewsDetail") || item?.page?.includes("All");
    const matchesPosition =
      item?.position?.includes("Bottom") || item?.position?.includes("All");

    return matchesPage && matchesPosition;
  });

  const {
    data: DetailnewsData,
    isLoading: isLoadingNewsData,
    isError: isErrorNewsData,
    error: errorNewsData,
  } = useGetArticlesByIdQuery({ id: params.articleIdentifier * 1 });

  const {
    data: authorData,
    isLoading: isAuthorLoading,
    isError: isAuthorError,
  } = useGetAuthorByIdQuery({ id: DetailnewsData && DetailnewsData?.authorID });

  // useEffect(() => {
  //   // Track page view for the news detail page
  //   if (window._mtm) {
  //     window._mtm.push({
  //       event: "pageView",
  //       pageUrl: `${window.location.origin}/news-detail/${
  //         params.articleIdentifier * 1
  //       }`,
  //       pageTitle: `News Detail - ${params.articleIdentifier * 1}`,
  //     });
  //   }
  // }, [params]);

  // useEffect(() => {
  //   if (DetailnewsData && window._mtm) {
  //     console.log(
  //       `${window.location.origin}/news-detail/${params.articleIdentifier * 1}`
  //     );
  //     window._mtm.push({
  //       event: "pageView",
  //       pageUrl: `${window.location.origin}/news-detail/${
  //         params.articleIdentifier * 1
  //       }`,
  //       pageTitle: `${DetailnewsData?.title}`,
  //     });
  //   }
  // }, [DetailnewsData]);

  // useEffect(() => {
  //   if (DetailnewsData) console.log(DetailnewsData);
  // }, DetailnewsData);
  // useEffect(() => {
  //   // Matomo tracking function
  //   const _paq = window._paq || [];

  //   // Track a custom event with the article identifier
  //   _paq.push([
  //     "trackEvent",
  //     "Trending Article",
  //     "view",
  //     params?.articleIdentifier,
  //   ]);

  //   // Trigger a page view for the trending article
  //   _paq.push(["trackPageView"]);
  // }, [params?.articleIdentifier]);

  // useEffect(() => {
  //   if (DetailnewsData) {
  //     window._mtm.push({
  //       event: "pageView",
  //       pageUrl: `${window.location.origin}/news-detail/${
  //         params.articleIdentifier * 1
  //       }`,
  //       pageTitle: `${DetailnewsData?.title}`,
  //     });
  //   }
  // }, [DetailnewsData]);

  const currentLanguage = i18n.language;
  // const newsData =
  //   currentLanguage === "am" ? dummyNewsDataAmharic : dummyNewsData;
  const newsData = currentLanguage === "am" ? DetailnewsData : DetailnewsData;
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [isShareDialogOpen, setShareDialogOpen] = useState(false);
  const [selectedNews, setSelectedNews] = useState(null);
  useEffect(() => {
    // Simulate a loading state
    setIsLoadingNews(true);
    setTimeout(() => setIsLoadingNews(false), 1000);
  }, []);

  if (isLoadingNews) {
    return <LoadingScreen />;
  }
  const sidebarAds = [
    {
      title: "Sidebar Ad 1",
      image: images.AdPlaceholderTop,
      description: "This is sidebar ad 1",
    },
    {
      title: "Sidebar Ad 1",
      image: images.AdPlaceholderTop3,
      description: "This is sidebar ad 1",
    },
    {
      title: "Sidebar Ad 2",
      image: images.AdPlaceholderTop4,
      description: "This is sidebar ad 2",
    },
    {
      title: "Sidebar Ad 3",
      image: images.AdPlaceholderTop5,
      description: "This is sidebar ad 3",
    },
    {
      title: "Sidebar Ad 3",
      image: images.AdPlaceholderTop6,
      description: "This is sidebar ad 3",
    },
  ];
  const openShareDialog = (news) => {
    setSelectedNews(news);
    setShareDialogOpen(true);
  };
  const closeShareDialog = () => {
    setSelectedNews(null);
    setShareDialogOpen(false);
  };
  // Render news content based on the template ID
  const renderNewsContent = () => {
    switch (newsData?.templateId) {
      case 1:
        return (
          <div className={classes.root}>
            <div className={classes.contentContainer}>
              <Typography className={classes.newsTitle}>
                {newsData?.title}
              </Typography>
              <Typography className={classes.newsSubtitle}>
                {newsData?.subtitle}
              </Typography>
              <div className={classes.chipContainer}>
                <span
                  style={{
                    marginBottom: "20px",
                    fontSize: "15px",
                    color: "grey",
                  }}>
                  {convertToDateWithMonthName(newsData?.createdAt)}
                </span>
                {newsData?.generalCategory && (
                  <div style={{ marginLeft: "10px" }}>
                    <ChipComponent category={newsData?.generalCategory} />
                  </div>
                )}
                <div
                  style={{ marginLeft: "20px", cursor: "pointer" }}
                  onClick={() => openShareDialog(newsData)}>
                  <img src={images.ShareIconDetail} height={25} width={25} />
                </div>
                {/* {newsData?.specificCategory && (
                  <div style={{ marginLeft: "10px" }}>
                    <ChipComponent category={newsData?.specificCategory} />
                  </div>
                )} */}

                {/* <img src={images.ShareIconDetail} height={50} width={50} /> */}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}>
                by{" "}
                <Avatar
                  style={{ width: "25px", height: "25px", marginLeft: "5px" }}
                  alt={authorData?.fullName}
                  src={
                    authorData?.avatar
                      ? BASE_FILE_URL + authorData?.avatar
                      : images.AvatarIcon
                  }
                  className={classes.avatar}
                />
                <h3 style={{ color: colorTags.TERTIARY, marginLeft: "5px" }}>
                  {" "}
                  {authorData?.name}
                </h3>
              </div>
              <img
                src={BASE_FILE_URL + newsData?.coverImage}
                alt="Cover"
                className={classes.coverImage}
              />
              <Typography className={classes.newsParagraph}>
                {newsData?.paragraph1}
              </Typography>
              <Typography className={classes.newsParagraph}>
                {newsData?.paragraph2}
              </Typography>
              <Typography className={classes.newsParagraph}>
                {newsData?.paragraph3}
              </Typography>
            </div>
          </div>
        );
      case 2:
        return (
          <div className={classes.root}>
            <div className={classes.contentContainer}>
              <Typography className={classes.newsTitle}>
                {newsData?.title}
              </Typography>
              <Typography className={classes.newsSubtitle}>
                {newsData?.subtitle}
              </Typography>
              <div className={classes.chipContainer}>
                <span
                  style={{
                    marginBottom: "20px",
                    fontSize: "15px",
                    color: "grey",
                  }}>
                  {convertToDateWithMonthName(newsData?.createdAt)}
                </span>
                <div style={{ marginLeft: "10px" }}>
                  <ChipComponent category={newsData?.generalCategory} />
                </div>
                {/* {newsData?.specificCategory && (
                  <div style={{ marginLeft: "10px" }}>
                    <ChipComponent category={newsData?.specificCategory} />
                  </div>
                )} */}
                <div
                  style={{ marginLeft: "20px", cursor: "pointer" }}
                  onClick={() => openShareDialog(newsData)}>
                  <img src={images.ShareIconDetail} height={25} width={25} />
                </div>
              </div>
              <img
                src={BASE_FILE_URL + newsData?.coverImage}
                alt="Cover"
                className={classes.coverImage}
              />
              <Typography className={classes.newsParagraph}>
                {newsData?.paragraph1}
              </Typography>
              {/* <Box
                className={classes.youtubeFrameContainer}
                sx={{ marginBottom: 2 }}>
                <iframe
                  // width="100%"
                  // height="200px"
                  className={classes.youtubeFrame}
                  src={newsData?.youtubeLink}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="YouTube Video"
                />
              </Box> */}
              <div className={classes.addtionalImagesContainer}>
                {" "}
                {newsData?.additionalImages &&
                  newsData?.additionalImages.map((image, index) => (
                    <img
                      key={index}
                      src={BASE_FILE_URL + image}
                      alt={`Additional ${index + 1}`}
                      className={classes.additionalImage}
                    />
                  ))}
              </div>
              <Typography className={classes.newsParagraph}>
                {newsData?.paragraph2}
              </Typography>
              <Typography className={classes.newsParagraph}>
                {newsData?.paragraph3}
              </Typography>
            </div>
          </div>
        );
      case 3:
        return (
          <div className={classes.root}>
            <div className={classes.contentContainer}>
              <Typography className={classes.newsTitle}>
                {newsData?.title}
              </Typography>
              <Typography className={classes.newsSubtitle}>
                {newsData?.subtitle}
              </Typography>
              <div className={classes.chipContainer}>
                <span
                  style={{
                    marginBottom: "20px",
                    fontSize: "15px",
                    color: "grey",
                  }}>
                  {convertToDateWithMonthName(newsData?.createdAt)}
                </span>
                <div style={{ marginLeft: "10px" }}>
                  <ChipComponent category={newsData?.generalCategory} />
                </div>
                {/* {newsData?.specificCategory && (
                  <div style={{ marginLeft: "10px" }}>
                    <ChipComponent category={newsData?.specificCategory} />
                  </div>
                )} */}
                <div
                  style={{ marginLeft: "20px", cursor: "pointer" }}
                  onClick={() => openShareDialog(newsData)}>
                  <img src={images.ShareIconDetail} height={25} width={25} />
                </div>
              </div>
              <img
                src={BASE_FILE_URL + newsData?.coverImage}
                alt="Cover"
                className={classes.coverImage}
              />
              <Typography className={classes.newsParagraph}>
                {newsData?.paragraph1}
              </Typography>
              <Typography className={classes.newsParagraph}>
                {newsData?.paragraph2}
              </Typography>
              <Box
                className={classes.youtubeFrameContainer}
                sx={{ marginBottom: 2 }}>
                <iframe
                  // width="100%"
                  // height="200px"
                  className={classes.youtubeFrame}
                  src={newsData?.youtubeLink}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="YouTube Video"
                />
              </Box>
              <Typography className={classes.newsParagraph}>
                {newsData?.paragraph3}
              </Typography>
              <div className={classes.addtionalImagesContainer}>
                {" "}
                {newsData?.additionalImages &&
                  newsData?.additionalImages.map((image, index) => (
                    <img
                      key={index}
                      src={BASE_FILE_URL + image}
                      alt={`Additional ${index + 1}`}
                      className={classes.additionalImage}
                    />
                  ))}
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <div className={classes.root}>
            <div className={classes.contentContainer}>
              <Typography className={classes.newsTitle}>
                {newsData?.title}
              </Typography>
              <Typography className={classes.newsSubtitle}>
                {newsData?.subtitle}
              </Typography>
              <div className={classes.chipContainer}>
                <span
                  style={{
                    marginBottom: "20px",
                    fontSize: "15px",
                    color: "grey",
                  }}>
                  {convertToDateWithMonthName(newsData?.createdAt)}
                </span>
                <div style={{ marginLeft: "10px" }}>
                  <ChipComponent category={newsData?.generalCategory} />
                </div>
                {/* {newsData?.specificCategory && (
                  <div style={{ marginLeft: "10px" }}>
                    <ChipComponent category={newsData?.specificCategory} />
                  </div>
                )} */}
                <div
                  style={{ marginLeft: "20px", cursor: "pointer" }}
                  onClick={() => openShareDialog(newsData)}>
                  <img src={images.ShareIconDetail} height={25} width={25} />
                </div>
              </div>
              <img
                src={BASE_FILE_URL + newsData?.coverImage}
                alt="Cover"
                className={classes.coverImage}
              />
              <Typography className={classes.newsParagraph}>
                {newsData?.paragraph1}
              </Typography>
              <Typography className={classes.newsParagraph}>
                {newsData?.paragraph2}
              </Typography>
              <Typography className={classes.newsParagraph}>
                {newsData?.paragraph3}
              </Typography>
              {/* <Box
                className={classes.youtubeFrameContainer}
                sx={{ marginBottom: 2 }}>
                <iframe
                  // width="100%"
                  // height="200px"
                  className={classes.youtubeFrame}
                  src={newsData?.youtubeLink}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="YouTube Video"
                />
              </Box> */}
              <div className={classes.addtionalImagesContainer}>
                {" "}
                {newsData?.additionalImages &&
                  newsData?.additionalImages.map((image, index) => (
                    <img
                      key={index}
                      src={BASE_FILE_URL + image}
                      alt={`Additional ${index + 1}`}
                      className={classes.additionalImage}
                    />
                  ))}
              </div>
            </div>
          </div>
        );
      case 5:
        return (
          <div className={classes.root}>
            <div className={classes.contentContainer}>
              <Typography className={classes.newsTitle}>
                {newsData?.title}
              </Typography>
              <Typography className={classes.newsSubtitle}>
                {newsData?.subtitle}
              </Typography>
              <div className={classes.chipContainer}>
                <span
                  style={{
                    marginBottom: "20px",
                    fontSize: "15px",
                    color: "grey",
                  }}>
                  {convertToDateWithMonthName(newsData?.createdAt)}
                </span>
                <div style={{ marginLeft: "10px" }}>
                  <ChipComponent category={newsData?.generalCategory} />
                </div>
                {/* {newsData?.specificCategory && (
                  <div style={{ marginLeft: "10px" }}>
                    <ChipComponent category={newsData?.specificCategory} />
                  </div>
                )} */}
                <div
                  style={{ marginLeft: "20px", cursor: "pointer" }}
                  onClick={() => openShareDialog(newsData)}>
                  <img src={images.ShareIconDetail} height={25} width={25} />
                </div>
              </div>
              <img
                src={BASE_FILE_URL + newsData?.coverImage}
                alt="Cover"
                className={classes.coverImage}
              />
              <Typography className={classes.newsParagraph}>
                {newsData?.paragraph1}
              </Typography>

              <Typography className={classes.newsParagraph}>
                {newsData?.paragraph2}
              </Typography>
              <Typography className={classes.newsParagraph}>
                {newsData?.paragraph3}
              </Typography>
              <div className={classes.addtionalImagesContainer}>
                {" "}
                {newsData?.additionalImages &&
                  newsData?.additionalImages.map((image, index) => (
                    <img
                      key={index}
                      src={BASE_FILE_URL + image}
                      alt={`Additional ${index + 1}`}
                      className={classes.additionalImage}
                    />
                  ))}
              </div>
              <Box
                className={classes.youtubeFrameContainer}
                sx={{ marginBottom: 2 }}>
                <iframe
                  // width="100%"
                  // height="200px"
                  className={classes.youtubeFrame}
                  src={newsData?.youtubeLink}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="YouTube Video"
                />
              </Box>
            </div>
          </div>
        );
      case 6:
        return (
          <div className={classes.root}>
            <div className={classes.contentContainer}>
              <Typography className={classes.newsTitle}>
                {newsData?.title}
              </Typography>
              <Typography className={classes.newsSubtitle}>
                {newsData?.subtitle}
              </Typography>
              <div className={classes.chipContainer}>
                <span
                  style={{
                    marginBottom: "20px",
                    fontSize: "15px",
                    color: "grey",
                  }}>
                  {convertToDateWithMonthName(newsData?.createdAt)}
                </span>
                <div style={{ marginLeft: "10px" }}>
                  <ChipComponent category={newsData?.generalCategory} />
                </div>
                {/* {newsData?.specificCategory && (
                  <div style={{ marginLeft: "10px" }}>
                    <ChipComponent category={newsData?.specificCategory} />
                  </div>
                )} */}
                <div
                  style={{ marginLeft: "20px", cursor: "pointer" }}
                  onClick={() => openShareDialog(newsData)}>
                  <img src={images.ShareIconDetail} height={25} width={25} />
                </div>
              </div>
              <img
                src={BASE_FILE_URL + newsData?.coverImage}
                alt="Cover"
                className={classes.coverImage}
              />
              <Typography className={classes.newsParagraph}>
                {newsData?.paragraph1}
              </Typography>
              <div className={classes.addtionalImagesContainer}>
                {" "}
                {newsData?.additionalImages &&
                  newsData?.additionalImages.map((image, index) => (
                    <img
                      key={index}
                      src={BASE_FILE_URL + image}
                      alt={`Additional ${index + 1}`}
                      className={classes.additionalImage}
                    />
                  ))}
              </div>
              <Typography className={classes.newsParagraph}>
                {newsData?.paragraph2}
              </Typography>

              <Typography className={classes.newsParagraph}>
                {newsData?.paragraph3}
              </Typography>

              <Box
                className={classes.youtubeFrameContainer}
                sx={{ marginBottom: 2 }}>
                <iframe
                  width="100%"
                  height="200px"
                  className={classes.youtubeFrame}
                  src={newsData?.youtubeLink}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="YouTube Video"
                />
              </Box>
            </div>
          </div>
        );
      default:
        return (
          <div>
            {isLoadingNewsData ? (
              <div>Article Loading</div>
            ) : (
              <div>Something Went Wrong</div>
            )}
            ;
          </div>
        );
    }
  };
  const truncateText = (text, limit) => {
    return text.length > limit ? text.substring(0, limit) + "..." : text;
  };
  return (
    <div>
      {/* <Helmet>
        <head>
          <title>{`${newsData && newsData?.title}`}</title>

          <meta
            property="og:title"
            content={`${newsData && newsData?.title}`}
          />
          <meta
            property="og:description"
            content={`${newsData && truncateText(newsData?.paragraph1, 100)}`}
          />
          <meta
            property="og:image"
            content={`${
              newsData?.coverImage
                ? `${BASE_FILE_URL}${newsData?.coverImage}`
                : images.NEWS_PLACEHOLDER
            }`}
          />
          <meta
            property="og:url"
            content={`https://addischambernews.com/news-detail/${newsData?.id}`}
          />
          <meta property="og:type" content="article" />
        </head>
        <title>{`${newsData && newsData?.title}`}</title>

        <meta property="og:title" content={`${newsData && newsData?.title}`} />
        <meta
          property="og:description"
          content={`${newsData && truncateText(newsData?.paragraph1, 100)}`}
        />
        <meta
          property="og:image"
          content={`${
            newsData?.coverImage
              ? `${BASE_FILE_URL}${newsData?.coverImage}`
              : images.NEWS_PLACEHOLDER
          }`}
        />
        <meta
          property="og:url"
          content={`https://exportportal.addischamber.com/newsapi/news-detail/${newsData?.id}`}
        />
        <meta property="og:type" content="article" />
      </Helmet> */}
      <Helmet>
        <title>{newsData?.title || "News Detail"}</title>

        <meta property="og:title" content={newsData?.title || "News Detail"} />
        <meta
          property="og:description"
          content={
            newsData
              ? truncateText(newsData.paragraph1, 100)
              : "Default description"
          }
        />
        <meta
          property="og:image"
          content={
            newsData?.coverImage
              ? `${BASE_FILE_URL}${newsData.coverImage}`
              : "default-image-url.jpg"
          }
        />
        <meta
          property="og:url"
          content={`https://addischambernews.com/news-detail/${newsData?.id}`}
        />
        <meta property="og:type" content="article" />
      </Helmet>

      <Title />
      <LogoHead />
      <Header />
      <Stack
        direction={isMobile ? "column" : "row"}
        spacing={2}
        style={{
          width: "100%",
          padding: isMobile ? "0 3%" : "0% 7%",
          marginTop: isMobile ? "0%" : "0%",
          justifyContent: isMobile ? "flex-start" : "center",
          alignItems: isMobile ? "center" : "flex-start",
        }}>
        <Box sx={{ marginTop: "30px", width: "100%" }}>
          {renderNewsContent()}
        </Box>
        <Stack
          direction="column"
          spacing={2}
          style={{ width: isMobile ? "100%" : "50%" }}>
          <TrendingNews />
          <div style={{ width: isMobile ? "100%" : "300px" }}>
            <Advertisements position="sidebar" items={topAds && topAds} />
          </div>
          {/* <TrendingNewsInCategory category={newsData?.generalCategory} /> */}
          <MostReadThisMonth
            category={`Most Read In ${newsData?.generalCategory}`}
          />
        </Stack>
      </Stack>
      <Box
        sx={{ display: "flex", justifyContent: "center", marginTop: "30px" }}>
        <div className={classes.AdFrame}>
          <Advertisements position="header" items={bottomAds && bottomAds} />
        </div>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          padding: "0% 5%",
          justifyContent: "center",
          marginTop: "0px",
        }}>
        <div className={classes.RelatedFrame}>
          <RelatedArticles
            relatedArticles={sidebarAds}
            category={newsData?.generalCategory ?? null}
          />
        </div>
      </Box>
      {isShareDialogOpen && (
        <ShareDialog
          open={isShareDialogOpen}
          onClose={closeShareDialog}
          news={selectedNews}
        />
      )}
      <Footer />
    </div>
  );
};

export default NewsPage;
