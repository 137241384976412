import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { DropzoneArea } from "material-ui-dropzone";
import { useCreateSocialPostRequestMutation } from "../../../features/api/apiSlice";
import { FILE_LINK_URL } from "../../../constants/apiTags";
import { toast } from "react-toastify";
import { colorTags } from "../../../constants/variants";

const NewPostForm = ({ onCancel }) => {
  const [selectedIcons, setSelectedIcons] = useState({
    facebook: false,
    twitter: false,
    telegram: false,
  });
  const [text, setText] = useState("");
  const [link, setLink] = useState("");
  const [cover, setCover] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState("");

  const [createSocialPost, { isLoading: isCreateUserLoading }] =
    useCreateSocialPostRequestMutation();

  const handleIconClick = (platform) => {
    setSelectedIcons((prevState) => ({
      ...prevState,
      [platform]: !prevState[platform],
    }));
  };

  const handlePostClick = () => {
    if (!text.trim()) {
      setError("Text field cannot be empty");
      return;
    }
    setError(""); // Clear any previous error
    setIsModalOpen(true); // Open the confirmation modal
  };

  const uploadImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("files", file);

      const response = await fetch(FILE_LINK_URL, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        throw new Error("Image upload failed");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  };

  const handleSave = async () => {
    setIsModalOpen(false); // Close the modal

    let coverImageUrl = "";
    if (cover.length > 0) {
      try {
        const uploadedImage = await uploadImage(cover[0]);
        coverImageUrl = uploadedImage?.filenames[0] ?? "";
      } catch (error) {
        console.error("Error uploading image:", error);
        alert("Error uploading image. Please try again.");
        return;
      }
    }

    const postData = {
      text,
      link,
      cover: coverImageUrl,
      platforms: [
        selectedIcons.facebook ? "Facebook" : "",
        selectedIcons.twitter ? "Twitter" : "",
        selectedIcons.telegram ? "Telegram" : "",
      ],
    };

    try {
      await createSocialPost(postData);
      toast.success("Post created successfully!");
      setCover([]);
      setSelectedIcons({ facebook: false, twitter: false, telegram: false });
      setText("");
      setLink("");
      onCancel();
    } catch (error) {
      console.error("Error creating post:", error);
      alert("Error creating post. Please try again.");
    }
  };

  return (
    <Box component="form" sx={{ mt: 1 }}>
      <DropzoneArea
        dropzoneClass="dropZone"
        showAlerts={false}
        useChipsForPreview={true}
        acceptedFiles={["image/jpeg", "image/jpg", "image/png", "image/webp"]}
        filesLimit={1}
        maxFiles={1}
        maxFileSize={5000000}
        dropzoneText={"Drop or Click to Upload Avatar (JPEG, JPG, PNG, WEBP)"}
        onChange={(files) => setCover(files)}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="text"
        label="Text"
        value={text}
        onChange={(e) => setText(e.target.value)}
        name="text"
        autoComplete="text"
        error={!!error} // Add error prop
        helperText={error} // Show error message
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="link"
        label="Link"
        name="link"
        value={link}
        onChange={(e) => setLink(e.target.value)}
        autoComplete="link"
      />

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
        <Button
          style={{
            borderRadius: "10px",
            marginRight: "18px",
            backgroundColor: "#00BFA5",
          }}
          variant="contained"
          onClick={handlePostClick} // Trigger modal open on click
        >
          Post
        </Button>
        <Button
          onClick={onCancel}
          style={{ borderRadius: "10px" }}
          variant="outlined"
          color="error">
          Cancel
        </Button>
      </Box>

      {/* Confirmation Modal */}
      <Dialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="confirmation-dialog-title"
        aria-describedby="confirmation-dialog-description"
        style={{
          padding: "20px",
        }}>
        <DialogTitle id="confirmation-dialog-title">Confirm Post</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmation-dialog-description">
            Are you sure you want to post this content?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            margin: "10px",
          }}>
          <Button
            onClick={() => setIsModalOpen(false)}
            color="error"
            variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            style={{ backgroundColor: colorTags.TERTIARY, color: "#fff" }}
            variant="contained"
            autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default NewPostForm;
