import React,{useState} from "react";
import { Grid, Card, Typography, Box } from "@mui/material";
import {images} from "../../../assets/index"
import { useGetAnalyticsByCategoryQuery } from "../../../features/api/apiSlice";

const categories = [
  { title: "Politics", count: 120, image: images.CardHolderBg },
  { title: "Sports", count: 200, image: images?.CardHolderBg2 },
  { title: "Technology", count: 150, image: images?.CardHolderBg3 },
  { title: "Entertainment", count: 80, image: images?.CardHolderBg4 },
];

const CardGridComponent = () => {
  const [token, setToken] = useState(localStorage.getItem("authToken"));


  
  return (
    <Box style={styles.cardHolder}>
      <Typography variant="h6" style={styles.pageTitle}>New Content This Week</Typography>
      <Grid container spacing={3}>
        {categories.map((category, index) => (
          <Grid item xs={6} key={index}>
            <Card style={{ ...styles.smallCard, backgroundImage: `url(${category?.image})` }}>
              <Box style={styles.overlay}>
                <Typography variant="h6" style={styles.cardTitle}>
                  {category.title}
                </Typography>
                <Typography variant="body2" style={styles.cardCount}>
                  {category.count}
                </Typography>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const styles = {
  cardHolder: {
    width: '100%',
    margin: '20px auto',
    marginRight:"0px",
    padding: '20px',
    backgroundColor: 'white',
    borderRadius: '15px',
    // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    boxShadow: "rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px"
  },
  pageTitle: {
    marginBottom: '20px',
    color: '#333',
  },
  smallCard: {
    height: '150px',
    borderRadius: '10px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px',
  },
  cardTitle: {
    color: 'white',
    fontSize: '1.2rem',
    fontWeight: 'bold',
  },
  cardCount: {
    color: '#eeee',
    fontSize: '0.9rem',
    marginTop: '5px',
  },
};

export default CardGridComponent;
