import ac from "./ac2.jpg";
import ac7 from "./ac.jpg";

import cf from "./cf.jpg";
import ch from "./ch.jpg"
import documentsIcon from "./documentsIcon.png"
import eu from "./eu.jpg"
import fl from "./fl.jpg"
import h1 from "./h1.jpg"
import h2 from "./h2.png"
import institutionsIcon from "./institutionsIcon.jpg"
import lt from "./lt.jpg"
import mn from "./mn.jpg"
import proceduresIcon from "./proceduresIcon2.webp"
import productsIcon from "./productsIcon.webp"
import sa from "./sa.jpg"
import tradeIcon from "./tradeIcon.png"
import transportationIcon from "./transportationIcon.jpg"
import tx from "./tx.jpg"
import uae from "./uae.jpg"
import us from "./us.jpg"

import Africameet from "./Africameet.png"
import PanAfrican from "./PanAfrican.jpg"
import Coffeeview from "./coffeeview.jpg"


import LinkIcon from "./icons8-links-64.png"
import TruckIcon from "./icons8-loading-truck-50.png"
import DocumentIcon from "./icons8-documents-50.png"
import InstitutionIcon from "./icons8-institution-80 (1).png"
import ProductIcon from "./icons8-container-91.png"
import ProcedureIcon from "./icons8-workflow-64.png"

import MainImage from "./aaccsa.png"
import MainImage2 from "./aaccsa2.jpg"
import MainImage3 from "./aaccsa3.jpg"

import MainImage4 from "./aaccsa4.jpg"
import MainBG from "./bglight.jpg"
import MainBG2 from "./bggreen2=3.jpg"


import IcontSet from "./agriculture.gif"
import AcMeet from "./acmeet.jpg"
import MainBG4 from "./bglight2.jpg"
import MainBG3 from "./bglight3.jpg"


import ChatA from "./chat.jpg"
import Logistics from "./logistics.jpg";
import Logistics2 from "./logistics2.jpg";
import Logistics3 from "./logistics3.avif";
import Logistics4 from "./logistics4.webp";
import Logistics5 from "./logistics5.webp";
import Logistics6 from "./logistics6.jpg";
import Logistics7 from "./logistics7.jpg";





import Plane from "./plane.jpg";
import Ship from "./ship.jpg";
import Truck from "./truck.jpg";
// import Anim from "./anim.mp4";
import MainBG7 from "./mainBG7.jpg";
import PlaneTrs from "./planetrs.png";
import GlobeTrs from "./globetrs.png";
import DocCover from "./doc.svg"
import Law from "./Law.jpeg"
import Corp from "./corp.jpg"
import CorpLogo from "./corpLogo.png"
import Radio2 from "./radio2.png"
import Blog2 from "./blog7.png"
import Amplifier from "./amp3.png"
import BlurBg1 from "./blurbg1.avif"
import BlurBg2 from "./blurbg2.jpeg"
import BlurBg3 from "./blurbg3.jpeg"
import BlurBg4 from "./blurbg4.jpg"
import BlurBg5 from "./blurbg5.jpg"
import BlurBg6 from "./blurbg6.jpg"
import BlurBg7 from "./blurbg7.avif"
import BlurBg8 from "./blurbg8.avif"
import BlurBg9 from "./blurbg9.avif"
import BlurBg10 from "./blurbg10.jpg"
import BlurBg11 from "./blurbg11.jpeg"




import WaterBg1 from "./waterbg1.webp"


import Mission from "./mission.png"
import Vision from "./vision2.png"
import Values from "./values2.png"
import Truckts from "./truckts3.png"
import Rocket from "./rocket1.png"
import Services from "./Services.webp"

import Asia from "./asia.webp"
import Asia2 from "./asia2.jpg"


import Eu2 from "./eu2.png"
import Content from "./content.png"
import Publication from "./publication.jpeg"
import NodePattern from "./nodepattern.avif"

import ChatIcon from "./chatIcon.png"
import Avatar from "./avatar.jpeg"

import WIE1 from "./wie1.jpg"
import FemaleAvatar2 from "./FemaleAvatar2.png"
import FemaleAvatar from "./FemaleAvatar.png"
import WIE2 from "./wie2.jpg"

// partners
import GIZ from "./giz.bmp"
import GIZ2 from "./giz2.png"
import AcLogo from "./acLogo.png"
import Sequa from "./sequa.png"


// news
import NewsImage from "./newsImage.jpg"


// about-us
import Chamber from "./chamber.jpg"
import Chamber1 from "./chamber1.png"


// promotion cover
import Promote from './promotion.jpeg'


// Company Avatar
import CorpAvatar from './corp-avatar.png'

// Ministry of Industry logo
import MOI_LOGO from './Minstry-Of-Industry-LOGO.png'



// pdf place holder
import PDF_PLACEHOLDER from './pdf-placeholder.webp'

// news place holder
import NEWS_PLACEHOLDER from './news-placeholder.jpg'
// Add more image imports as needed

import Dashbackg from './dashbackg.avif'

// social icons
import YouTubeIcon from './icons8-youtube.png'
import FacebookIcon from './icons8-facebook.png'
import TelegramIcon from './icons8-telegram.png'
import TwitterXicon from './icons8-twitterx.png'

import CardHolderBg from './cover_4.jpg'
import CardHolderBg2 from './cover_7.jpg'
import CardHolderBg3 from './cover_22.jpg'
import CardHolderBg4 from './cover_1.jpg'
import TrendsHolderBg from './cover_19.jpg'

import TemplateCover from './templateCover.jpg'
import TemplateCoverImage from './templateCoverImage.jpg'

import TemplateCoverText from './templateCoverText.png'
import TemplateCoverVideo from './templateCoverVideo.png'

import AvatarIcon from './icons8-avatar-94.png'
import NotificationsIcon from './icons8-topic-push-notification-100.png'

import NewsLogoPlaceholder from "./news-logo-placeholder.png"
// import NewsLogoPlaceholderAm from "./news-logo-placeholderAm.png"

import NewsArticlePlaceholder from "./news-story-placeholder.png"

import TopStoryPlaceholder from "./top-story-placeholder.png"
import TopStoryPlaceholderNative from "./top-story-placeholder-native.png"
import TopStoryPlaceholderNativeAm from "./top-story-placeholder-nativeAm.jpg"

import AdPlaceholderTop from "./ad-placeholder-top.png"
import AdPlaceholderTop2 from "./ad-placeholder-top2.jpeg"
import AdPlaceholderTop3 from "./ad-placeholder-top3.jpg"
import AdPlaceholderTop4 from "./ad-placeholder-top4.jpg"
import AdPlaceholderTop5 from "./ad-placeholder-top5.jpg"
import AdPlaceholderTop6 from "./ad-placeholder-top6.jpg"

import SmallNewsStoryPlaceholder from "./small-news-story-placeholder.webp"
import SmallNewsStoryPlaceholderNative1 from "./small-news-story-placeholder-native1.jpg"
import SmallNewsStoryPlaceholderNative2 from "./small-news-story-placeholder-native2.png"
import SmallNewsStoryPlaceholderNative3 from "./small-news-story-placeholder-native3.jpg"

import SmallNewsStoryNative from "./small-news-story-native.jpg"
import SmallNewsStoryNative2 from "./small-news-story-native2.jpg"
import SmallNewsStoryNative3 from "./small-news-story-native3.jpg"
import SmallNewsStoryNative4 from "./small-news-story-native4.png"






import TopNewsStoryPlaceholder2 from "./news-placeholder2.avif"
import CategoryNewsLinePlaceholder from "./category-news-line-placeholder.webp"
import CategoryNewsLinePlaceholder2 from "./category-news-line-placeholder2.webp"
import CategoryNewsFullPlaceholder from "./category-news-full-placeholder.jpeg"
import CategoryNewsFullPlaceholder2 from "./category-news-full-placeholder2.webp"


import CategoryNewsLineNativeEng from "./category-news-line-nativeEng.jpg"
import CategoryNewsLineNativeEng2 from "./category-news-line-nativeEng2.jpg"
import CategoryNewsLineNativeEng3 from "./category-news-line-nativeEng3.png"
import CategoryNewsLineNativeEng4 from "./category-news-line-nativeEng4.jpg"
import CategoryNewsLineNativeEng5 from "./category-news-line-nativeEng5.jpg"
// import CategoryNewsLineNativeEng from "./category-news-line-nativeEng.jpg"






import CategoryNewsFullNative from "./category-news-full-native.jpg"
import CategoryNewsFullNative1 from "./category-news-full-native1.jpg"
import CategoryNewsFullNative2 from "./category-news-full-native2.jpg"
import CategoryNewsFullNative3 from "./category-news-full-native3.jpg"
import CategoryNewsFullNative4 from "./category-news-full-native4.jpg"
import CategoryNewsFullNative5 from "./category-news-full-native5.jpg"
import CategoryNewsFullNative6 from "./category-news-full-native6.jpg"

import CategoryNewsFullNative7 from "./category-news-full-native7.jpg"
import CategoryNewsFullNative8 from "./category-news-full-native8.png"
import CategoryNewsFullNative9 from "./category-news-full-native9.jpg"
// import CategoryNewsFullNative6 from "./category-news-full-native6.jpg"
// import CategoryNewsFullNative6 from "./category-news-full-native6.jpg"








import MonthlyEditionPlaceholder from "./monthly-edition-placeholder.png"
import MonthlyPublicationPlaceholder from "./monthly-publication-placeholder.jpg"
import MonthlyPublicationPlaceholder2 from "./monthly-publication-placeholder2.jpg"


import CategoryNewsFullEconomyPlaceholder from "./category-news-full-economy-placeholder.jpg"
import CategoryNewsFullEconomyPlaceholder2 from "./category-news-full-economy-placeholder.jpeg"

import NewsPlaceholder3 from "./news-placeholder3.jpg"
import CoverImagePlaceholder from "./cover-image-placeholder.jpg"
import CoverImagePlaceholder2 from "./cover-image-placeholder2.jpg"

import ContactUsPlaceholder from "./contact-us-placeholder.jpg"
import ProfilePagePlaceholder from "./profile-page-placeholder.avif"
// import LoginPagePlaceholder from "./login-page-placeholder.avif"
import LoginPagePlaceholder from "./login-page-placeholder.webp"

import EventPagePlaceholder from "./events-page-placeholder.jpeg"
import EventPagePlaceholder2 from "./events-page-placeholder2.jpg"
import EventPagePlaceholder3 from "./events-page-placeholder3.jpg"
import EventPagePlaceholder4 from "./events-page-placeholder4.jpg"
import EventPagePlaceholder5 from "./events-page-placeholder5.jpg"
// import EventPagePlaceholder3 from "./events-page-placeholder3.jpg"


import AACCSALogoWhite from "./addis-chamber-logo-white.png"
import ShareIconDetail from "./share-icon-detail-page.png"

import ArtcileTemplatePlaceholder1 from "./article-image-placeholder-1.png"
import ArtcileTemplatePlaceholder2 from "./article-image-placeholder-2.png"

import ArtcileTemplatePlaceholder3 from "./article-image-placeholder-3.png"

import ArtcileTemplatePlaceholder4 from "./article-image-placeholder-4.png"

import ArtcileTemplatePlaceholder5 from "./article-image-placeholder-5.png"

import ArtcileTemplatePlaceholder6 from "./article-image-placeholder-6.png"

import Placeholder404 from "./404-placeholder.png"


import FacebookIconPlaceholder from "./facebook-icon-share-placeholder.png"
import CopyIconPlaceholder from "./copy-icon-share-placeholder.png"
import WhatsAppIconPlaceholder from "./whatsapp-icon-share-placeholder.png"
import TelegramIconPlaceholder from "./telegram-icon-share-placeholder.png"
import TwitterXIconPlaceholder from "./twitterx-icon-share-placeholder.png"

import MediaPageCoverPlaceholder from "./mediaCoverPlaceholder.png"
import MediaPageCoverPlaceholder2 from "./mediaCoverPlaceholder2.png"
import MediaPageCoverPlaceholder3 from "./mediaCoverPlaceholder3.png"
import MediaPageCoverPlaceholder4 from "./mediaCoverPlaceholder4.png"
import MediaPageCoverPlaceholder6 from "./mediaCoverPlaceholder6.png"

// category images
import PoliticsCoverPlaceholder from "./politics-image-placeholder.png"
import TechnologyCoverPlaceholder from "./technology-image-placeholder.png"
import SportsCoverPlaceholder from "./sports-image-placeholder.png"
import BusinessCoverPlaceholder from "./business-image-placeholder.png"
import EntertainmentCoverPlaceholder from "./entertainment-image-placeholder.png"



import CategoryNewsLineNative from "./category-news-line-native.jpg"
import CategoryNewsLineNative2 from "./category-news-line-native2.jpg"
import CategoryNewsLineNative3 from "./category-news-line-native3.png"
import CategoryNewsLineNative4 from "./category-news-line-native4.jpg"
import CategoryNewsLineNative5 from "./category-news-line-native5.png"
import CategoryNewsLineNative6 from "./category-news-line-native6.jpg"
import CategoryNewsLineNative7 from "./category-news-line-native7.jpg"



import LoginBackground from './login-background.jpg'
import FacebookStock from './facebook-stock.avif'
import TelegramStock from "./telegram-stock.jpg"


// Export the imported images as an object
export const images = {
  NEWS_PLACEHOLDER,
  PDF_PLACEHOLDER,
  Africameet,
  Coffeeview,
  PanAfrican,
  ac ,
  ac7 ,

   cf ,
  ch ,
   documentsIcon,
     eu,
     fl ,
     h1 ,
     h2 ,
     institutionsIcon ,
     lt ,
     mn ,
     proceduresIcon ,
     productsIcon ,
     sa ,
     tradeIcon ,
     transportationIcon ,
     tx ,
     uae ,
     us ,
    
      LinkIcon ,
      TruckIcon ,
      DocumentIcon,
      InstitutionIcon, 
      ProductIcon,
      ProcedureIcon,
      MainImage ,
     MainImage3 ,
     MainImage2,
     MainImage4 ,
     MainBG,
      MainBG2,
      IcontSet,
      AcMeet,
      MainBG3,
      MainBG4,
ChatA,
Logistics,
Plane,
Ship,
Truck,
Logistics2,
Logistics3,
// Anim,
MainBG7,
PlaneTrs,
GlobeTrs,
DocCover,
Law,
Corp,
CorpLogo,
Radio2,
Blog2,
Amplifier,
BlurBg1,
BlurBg2,
BlurBg3,
BlurBg4,
Mission,
Vision,
Values,
Truckts,
BlurBg5,
BlurBg6,
BlurBg7,
BlurBg8,
BlurBg9,
WaterBg1,
BlurBg10,
BlurBg11,
Rocket,
Services,
Content,
NodePattern,
Eu2,
Asia,
Asia2,
Publication,
ChatIcon,
Avatar,
WIE1,
FemaleAvatar,
FemaleAvatar2,
WIE2,

// partners
GIZ,
GIZ2,
AcLogo,
Sequa,
NewsImage,
Logistics4,
Logistics5,
Logistics6,
Logistics7
,
Chamber,
Chamber1,
Promote,
CorpAvatar,
MOI_LOGO,
Dashbackg,


TwitterXicon,
TelegramIcon,
FacebookIcon,
YouTubeIcon,
CardHolderBg,
CardHolderBg2,
CardHolderBg3,
CardHolderBg4,
TrendsHolderBg,
TemplateCover,
TemplateCoverImage,
TemplateCoverText,
TemplateCoverVideo,
AvatarIcon,
NotificationsIcon,
NewsLogoPlaceholder,
TopStoryPlaceholder,
AdPlaceholderTop,
AdPlaceholderTop2,
AdPlaceholderTop3,
AdPlaceholderTop4,
AdPlaceholderTop5,
AdPlaceholderTop6,
SmallNewsStoryPlaceholder,
SmallNewsStoryPlaceholderNative1,
SmallNewsStoryPlaceholderNative2,
SmallNewsStoryPlaceholderNative3,

SmallNewsStoryNative,
SmallNewsStoryNative2,
SmallNewsStoryNative3,
SmallNewsStoryNative4,




TopNewsStoryPlaceholder2,
CategoryNewsLinePlaceholder,
CategoryNewsLinePlaceholder2,
CategoryNewsFullPlaceholder,
CategoryNewsFullPlaceholder2,

CategoryNewsLineNativeEng,
CategoryNewsLineNativeEng2,
CategoryNewsLineNativeEng3,
CategoryNewsLineNativeEng4,
CategoryNewsLineNativeEng5,


CategoryNewsFullNative,
CategoryNewsFullNative1,
CategoryNewsFullNative2,
CategoryNewsFullNative3,
CategoryNewsFullNative4,
CategoryNewsFullNative5,
CategoryNewsFullNative6,
CategoryNewsFullNative7,
CategoryNewsFullNative8,
CategoryNewsFullNative9,






MonthlyEditionPlaceholder,
CategoryNewsFullEconomyPlaceholder,
CategoryNewsFullEconomyPlaceholder2,
MonthlyPublicationPlaceholder,
MonthlyPublicationPlaceholder2,
NewsPlaceholder3,
CoverImagePlaceholder,
CoverImagePlaceholder2,
ContactUsPlaceholder,
ProfilePagePlaceholder,
LoginPagePlaceholder,
EventPagePlaceholder,
EventPagePlaceholder2,
EventPagePlaceholder3,
EventPagePlaceholder4,
EventPagePlaceholder5,

AACCSALogoWhite,
ShareIconDetail,

ArtcileTemplatePlaceholder1,
ArtcileTemplatePlaceholder2,
ArtcileTemplatePlaceholder3,
ArtcileTemplatePlaceholder4,
ArtcileTemplatePlaceholder5,
ArtcileTemplatePlaceholder6,
Placeholder404,
TwitterXIconPlaceholder,
TelegramIconPlaceholder,
WhatsAppIconPlaceholder,
CopyIconPlaceholder,
FacebookIconPlaceholder,
MediaPageCoverPlaceholder,
MediaPageCoverPlaceholder2,
MediaPageCoverPlaceholder3,
MediaPageCoverPlaceholder4,
MediaPageCoverPlaceholder6,
PoliticsCoverPlaceholder,
TechnologyCoverPlaceholder,

SportsCoverPlaceholder,
BusinessCoverPlaceholder,
EntertainmentCoverPlaceholder,
TopStoryPlaceholderNative,
NewsLogoPlaceholder,
TopStoryPlaceholderNativeAm,
NewsArticlePlaceholder,
CategoryNewsLineNative,
CategoryNewsLineNative2,
CategoryNewsLineNative3,
CategoryNewsLineNative4,
CategoryNewsLineNative5,
CategoryNewsLineNative6,
CategoryNewsLineNative7,

LoginBackground,
FacebookStock,
TelegramStock






};