import React, { useState, useEffect } from "react";
import {
  Stack,
  styled,
  Link,
  Grid,
  Avatar,
  InputAdornment,
  FormControl,
  InputLabel,
  Button,
  MenuItem,
  Select,
  Fade,
  Divider,
  Box,
  FormHelperText,
  FormLabel,
} from "@mui/material";
import {
  TextField,
  Typography,
  makeStyles,
  Container,
  Modal,
  Backdrop,
} from "@material-ui/core";
import { colorTags } from "../../../../constants/variants";
import { useStyles } from "../../../../App";
import { DropzoneArea } from "material-ui-dropzone";
import {
  useGetPublicationsQuery,
  useGetPublicationByIdQuery,
  useCreatePublicationRequestMutation,
  useUpdatePublicationRequestMutation,
  useDeletePublicationRequestMutation,
  useUploadFileMutation,
} from "../../../../features/api/apiSlice";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { FILE_LINK_URL } from "../../../../constants/apiTags";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
const useStyless = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    backgroundColor: "#fff",
    borderRadius: "5px",
    padding: theme.spacing(2),
    // boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
    boxShadow:
      "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(255,255,255, 0.25) 0px 50px 100px -20px, rgba(255,255,255, 0.3) 0px 30px 60px -30px",
    maxWidth: "60%",
    maxHeight: "90%",
    overflow: "auto",
    outline: "none",
  },
  modalTitle: {
    textAlign: "left",
    marginBottom: theme.spacing(2),
  },
  modalBody: {
    marginTop: theme.spacing(2),
  },
  inputField: {
    marginBottom: theme.spacing(2),
  },
  modalActions: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#03AED2",
      },
      "&:hover fieldset": {
        borderColor: "#03AED2",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#03AED2",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#03AED2",
    },
  },
}));

const EditPublication = ({ open, onClose, title, onSave, data }) => {
  const classes = useStyless();
  const classez = useStyles();
  const [docTitle, setDocTitle] = useState("");
  const [cover, setCover] = useState([]);
  const [document, setDocument] = useState([]);
  const [titleError, setTitleError] = useState("");
  const [documentError, setDocumentError] = useState("");
  const [coverError, setCoverError] = useState("");
  const storedUser = localStorage.getItem("user");
  // const user = storedUser ? JSON.parse(storedUser) : null;
  const [token, setToken] = useState(localStorage.getItem("authToken"));
  const [publicationDate, setPublicationDate] = useState(new Date());
  const [month, setMonth] = useState(new Date());

  const [language, setLanguage] = useState("en");
  const user = storedUser ? storedUser : null;
  const [intro, setIntro] = useState("");
  const closeModal = () => {
    setDocTitle("");
    setLanguage("en");
    setPublicationDate(null);
    setCover([]);
    setDocument([]);
    onClose();
  };
  useEffect(() => {
    if (data) {
      setDocTitle(data?.publicationTitle);
      setMonth(data?.month);
      setLanguage(data?.language);
    }
  }, [data]);

  const [
    updatePublicationRequest,
    {
      isError: isErrorUpdateRequest,
      isLoading: isLoadingUpdateRequest,
      error: errorUpdateRequest,
    },
  ] = useUpdatePublicationRequestMutation();

  const [submitFileUpload] = useUploadFileMutation();

  const uploadImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("files", file);

      const response = await fetch(FILE_LINK_URL, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        // Access the uploaded image data, e.g., data.documentName
        return data;
      } else {
        throw new Error("File upload failed");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const updateData = {
      id: data?.id,
      publicationTitle: docTitle,
      coverImage: data?.coverImage ?? "",
      publicationFile: data?.publicationFile ?? "",
      language,
      month: publicationDate,
    };

    // Validate document file
    if (document.length > 0) {
      try {
        const imageResponse = await uploadImage(document[0]);

        updateData.publicationFile =
          imageResponse?.filenames[0] ?? data?.publicationFile;
      } catch (error) {
        toast.error("Error uploading file");
        updateData.publicationFile = data?.publicationFile;
        return;
      }
    }

    // Validate cover image
    if (cover.length > 0) {
      try {
        const imageResponse = await uploadImage(cover[0]);

        updateData.coverImage = imageResponse?.filenames[0] ?? data?.coverImage;
      } catch (error) {
        toast.error("Error uploading image");
        updateData.coverImage = data?.coverImage;
        return;
      }
    }

    await updatePublicationRequest(updateData)
      .then((res) => {
        toast.success("Publication updated successfully");
        onClose();
      })
      .catch((err) => {
        toast.error("Error updating publication");
        onClose();
      });

    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}>
      <Fade in={open}>
        <div className={classes.modalContent}>
          <div className={classes.modalHead}>
            <Typography
              variant="h6"
              className={classes.modalTitle}
              style={{ fontWeight: "medium", color: "#03AED2" }}>
              {title}
            </Typography>
          </div>
          {/* <Divider style={{ backgroundColor: "#00FF00" }} /> */}
          <div className={classes.modalBody}>
            <TextField
              label="Publication Title"
              variant="outlined"
              className={classes.textField}
              fullWidth
              required
              style={{ marginTop: "15px" }}
              value={docTitle}
              onChange={(e) => setDocTitle(e.target.value)}
              error={!!titleError}
              helperText={titleError}
            />
            <Stack
              spacing={3}
              direction="row"
              sx={{ width: "100%", margin: "15px 0px" }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={3} sx={{ width: "50%" }}>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}>
                    <FormLabel component="legend">Publication Date</FormLabel>
                    <DesktopDatePicker
                      inputFormat="dd/MM/yyyy"
                      color="success"
                      value={publicationDate}
                      onChange={(date) => setPublicationDate(date)}
                      renderInput={(params) => (
                        <TextField required variant="outlined" {...params} />
                      )}
                    />
                  </FormControl>
                </Stack>
              </LocalizationProvider>
              <Stack spacing={3} sx={{ width: "50%" }}>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}>
                  <FormLabel component="legend">Select Language</FormLabel>

                  <TextField
                    fullWidth
                    // label="Language"
                    variant="outlined"
                    className={classes.inputContainer}
                    select
                    name="language"
                    value={language}
                    // sx={{ width: "50%", margin: "15px 0px" }}
                    onChange={(e) => setLanguage(e)}>
                    <MenuItem value="en">English</MenuItem>
                    <MenuItem value="am">አማርኛ</MenuItem>
                    <MenuItem value="AM&EN">Both</MenuItem>
                  </TextField>
                </FormControl>
              </Stack>
            </Stack>

            <Box className={classes.modalBody} mt={2}>
              <Stack direction="column" spacing={2}>
                <DropzoneArea
                  dropzoneClass={classez?.dropZone}
                  style={{ flex: 1 }}
                  showAlerts={false}
                  useChipsForPreview={true}
                  filesLimit={1}
                  maxFiles={1}
                  maxFileSize={5000000}
                  acceptedFiles={[
                    "image/jpeg",
                    "image/jpg",
                    "image/png",
                    "image/webp",
                  ]}
                  dropzoneText={
                    "Drop or Click to Upload Cover Image (Optional)"
                  }
                  onChange={(files) => setCover(files)}
                  error={!!coverError}
                  helperText={coverError}
                />
                <DropzoneArea
                  dropzoneClass={classez?.dropZone}
                  style={{ flex: 1 }}
                  showAlerts={false}
                  useChipsForPreview={true}
                  filesLimit={1}
                  maxFiles={1}
                  maxFileSize={5000000}
                  dropzoneText={"Drop or Click to Upload Publication File"}
                  onChange={(files) => setDocument(files)}
                  acceptedFiles={[".pdf"]}
                  error={!!documentError}
                  helperText={documentError}
                />
              </Stack>
            </Box>
          </div>
          <div className={classes.modalActions}>
            <Stack spacing={2} direction="row">
              <div className={classes.modalActions} style={{}}>
                <LoadingButton
                  // loading={isLoadingPublicationRequest}
                  variant="contained"
                  color="success"
                  style={{
                    fontWeight: "bold",
                    color: "#fff",
                    borderRadius: "15px",
                  }}
                  onClick={handleSave}>
                  Save
                </LoadingButton>

                <Button
                  variant="outlined"
                  color="error"
                  style={{
                    color: "#FF0000",
                    marginLeft: "10px",
                    borderRadius: "15px",
                  }}
                  onClick={onClose}>
                  Cancel
                </Button>
              </div>
            </Stack>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default EditPublication;
