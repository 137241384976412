import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Link,
} from "@mui/material";

import { Info } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { SimpleCard } from "../../../../components";
import { primaryColor, colorTags } from "../../../../constants/variants";
import GridData from "../../../../components/GridData";
import { useNavigate } from "react-router-dom";
import { images } from "../../../../assets/index";
import AddUser from "./AddUser";
import EditIcon from "@mui/icons-material/Edit";
import DeleteDialog from "../../../../components/DeleteDialog";

import {
  useGetUsersQuery,
  useGetUserRolesQuery,
  useGetUserByIdQuery,
  useCreateUserRequestMutation,
  useUpdateUserRequestMutation,
  useDeleteUserRequestMutation,
} from "../../../../features/api/apiSlice";
import { FILE_LINK_URL, BASE_FILE_URL } from "../../../../constants/apiTags";

import EditUser from "./EditUser";
import AddIcon from "@mui/icons-material/Add";

const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
  },
}));

const initialUsersData = [
  {
    user_id: 1,
    user_name: "aachambermedia@gmail.com",
    role_id: 1,
    avatar: "AC",
    fullName: "Addis Chamber",
    email: "aachambermedia@gmail.com",
    phone: "0911111111",
    status: "active",
  },
  {
    user_id: 2,
    user_name: null,
    role_id: null,
    avatar: "5b34751425c0cbe0aaa5ce2900469587.png",
    fullName: null,
    email: "qw@qw.com",
    phone: "9123402",
    status: "Active",
  },
];

const UsersPage = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => setModalOpen(false);
  const handleModalOpen = () => setModalOpen(true);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("authToken"));

  const {
    data: userRolesData,
    isLoading: isLoadingUserRolesData,
    isError: isErrorUserRolesData,
    error: errorUserRolesData,
  } = useGetUserRolesQuery();

  const {
    data: usersDataFromApi,
    isLoading: isLoadingUsersData,
    isError: isErrorUsersData,
    error: errorUsersData,
  } = useGetUsersQuery();

  useEffect(() => {
    if (usersDataFromApi) console.log(usersDataFromApi);
  }, [usersDataFromApi]);

  // Create a mapping of role_id to roleName
  const roleMap = userRolesData?.reduce((acc, role) => {
    acc[role.id] = role.roleName;
    return acc;
  }, {});

  const columns = [
    {
      field: "id",
      headerName: "No.",
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "avatar",
      headerName: "Avatar",
      flex: 1,
      renderCell: (params) => (
        <img
          src={BASE_FILE_URL + params.row.avatar}
          alt={params.row.fullName || "Avatar"}
          style={{ width: "30px", height: "30px", borderRadius: "50%" }}
        />
      ),
    },
    {
      field: "fullName",
      headerName: "Full Name",
      flex: 1,
    },
    // {
    //   field: "user_id",
    //   headerName: "User ID",
    //   filterable: false,
    // },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      renderCell: (params) => {
        const roleName = roleMap?.[params.row.role_id];
        return roleName || "Not assigned"; // Show "Not assigned" if role_id is null or doesn't match
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 2,
    },
    {
      field: "phone",
      headerName: "Phone",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: 2,
      align: "left",
      renderCell: (params) => {
        return (
          <div className="flex">
            <IconButton
              onClick={() =>
                navigate(`/dashboard/user-detail/${params.row.user_id}`)
              }>
              <Info color="primary" />
            </IconButton>
            <IconButton
              onClick={() => {
                setEditModalOpen(true);
                setSelectedRow(params.row);
              }}>
              <EditIcon color="primary" />
            </IconButton>
            <DeleteDialog
              api={useDeleteUserRequestMutation}
              dialogLabel="Remove User"
              id={params.row.id}
              name={`User ${params.row.id}`}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div style={{ marginLeft: "5%", paddingTop: "5%", width: "90%" }}>
      {modalOpen ? (
        <Button
          variant="contained"
          style={{
            marginBottom: "1%",
            marginLeft: "88%",
            backgroundColor: colorTags.SECONDARY,
          }}
          onClick={handleModalClose}>
          Close
        </Button>
      ) : (
        <Button
          variant="contained"
          style={{
            marginBottom: "1%",
            marginLeft: "88%",
            backgroundColor: colorTags.TERTIARY,
          }}
          onClick={handleModalOpen}>
          <AddIcon style={{ marginRight: "5px" }} />
          User
        </Button>
      )}

      <AddUser
        open={modalOpen}
        onClose={handleModalClose}
        title="Add User"
        content="This is the modal content."
        actionText="Close"
      />

      <EditUser
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        title="Edit User"
        content="This is the modal content."
        actionText="Close"
        data={selectedRow}
      />

      <SimpleCard
        title="Users"
        style={{
          marginTop: "2%",
        }}>
        <Box width="100%" overflow="auto">
          <GridData
            rows={(usersDataFromApi || initialUsersData).map((user) => ({
              ...user,
              id: user.user_id,
            }))}
            columns={columns}
            initialState={{
              pagination: {
                pageSize: 5,
              },
            }}
          />
        </Box>
      </SimpleCard>
    </div>
  );
};

export default UsersPage;
