import React, { useState } from "react";
import {
  Button,
  Grid,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Typography,
  TablePagination,
} from "@mui/material";
import {
  GetApp as GetAppIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CSVLink } from "react-csv";
import { useGetLogsQuery } from "../../../features/api/apiSlice";
import { Bars } from "react-loader-spinner";
import { colorTags } from "../../../constants/variants";

const dummyLogData = [
  {
    id: 1,
    username: "User1",
    date: "2023-06-01",
    action: "Login",
  },
  {
    id: 2,
    username: "User2",
    date: "2023-06-02",
    action: "Logout",
  },
  {
    id: 3,
    username: "User3",
    date: "2023-06-03",
    action: "View Dashboard",
  },
  {
    id: 4,
    username: "User3",
    date: "2023-06-04",
    action: "Update Profile",
  },
  {
    id: 5,
    username: "User1",
    date: "2023-06-01",
    action: "Login",
  },
  {
    id: 6,
    username: "User2",
    date: "2023-06-02",
    action: "Logout",
  },
  {
    id: 7,
    username: "User1",
    date: "2023-06-03",
    action: "View Dashboard",
  },
  {
    id: 8,
    username: "User894",
    date: "2023-06-04",
    action: "Update Profile",
  },
  {
    id: 9,
    username: "User102",
    date: "2023-06-01",
    action: "Login",
  },
  {
    id: 10,
    username: "User2",
    date: "2023-06-02",
    action: "Logout",
  },
  {
    id: 11,
    username: "User99",
    date: "2023-06-03",
    action: "View Dashboard",
  },
  {
    id: 12,
    username: "User12",
    date: "2023-06-04",
    action: "Update Profile",
  },
];

const LogsPage = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [username, setUsername] = useState("");
  const [action, setAction] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("authToken"));

  const {
    data: logsData,
    isLoading: isLoadingLogsData,
    isError: isErrorLogsData,
    error: errorLogsData,
  } = useGetLogsQuery();

  const formData = {
    username: username,
    action: action,
    startDate: startDate,
    endDate: endDate,
  };
  const date = new Date().toISOString().slice(0, 10);
  const [filteredLogs, setFilteredLogs] = useState([]);

  const exportFileName = "logs";

  const handleInputChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchQuery(value);

    const filtered = logsData?.filter((log) => {
      const username = log.username.toLowerCase();
      const action = log.action.toLowerCase();
      const date = log.date;

      return (
        username.includes(value) ||
        action.includes(value) ||
        date.includes(value)
      );
    });

    setFilteredLogs(filtered);
  };

  // const handleSearch = () => {
  //   let filtered = dummyLogData;

  //   if (username) {
  //     const lowercaseUsername = username.toLowerCase();
  //     filtered = filtered.filter((log) =>
  //       log.username.toLowerCase().includes(lowercaseUsername)
  //     );
  //   }

  //   if (action) {
  //     const lowercaseAction = action.toLowerCase();
  //     filtered = filtered.filter((log) =>
  //       log.action.toLowerCase().includes(lowercaseAction)
  //     );
  //   }

  //   if (selectedDate) {
  //     const formattedDate = selectedDate.toISOString().slice(0, 10);
  //     filtered = filtered.filter((log) => log.date.includes(formattedDate));
  //   }

  //   setFilteredLogs(filtered);
  // };

  const handleSearch = () => {
    let filtered = logsData ?? [];

    if (username) {
      const lowercaseUsername = username.toLowerCase();
      filtered = filtered.filter((log) =>
        log.username.toLowerCase().includes(lowercaseUsername)
      );
    }

    if (action) {
      const lowercaseAction = action.toLowerCase();
      filtered = filtered.filter((log) =>
        log.action.toLowerCase().includes(lowercaseAction)
      );
    }

    if (startDate && endDate) {
      const formattedStartDate = startDate.toISOString().slice(0, 10);
      const formattedEndDate = endDate.toISOString().slice(0, 10);
      filtered = filtered.filter(
        (log) => log.date >= formattedStartDate && log.date <= formattedEndDate
      );
    }

    setFilteredLogs(filtered);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (isLoadingLogsData)
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Bars
          height="40"
          width="40"
          // color="#4fa94d"
          color={colorTags.TERTIARY}
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  return (
    <div style={{ margin: "5%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          backgroundColor: "white",
        }}>
        <Stack
          direction="column"
          spacing={3}
          justifyContent="center"
          style={{
            width: "50%",
            padding: "2% 10%",

            boxShadow:
              "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",
          }}>
          <TextField
            fullWidth
            name="username"
            label="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            fullWidth
            name="action"
            label="Action"
            value={action}
            onChange={(e) => setAction(e.target.value)}
          />
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            style={{ width: "50%" }}>
            <Stack
              spacing={3}
              style={{ width: "100%" }}
              justifyContent={"flex-end"}
              direction="row">
              <DesktopDatePicker
                inputFormat="dd/MM/yyyy"
                color="success"
                value={startDate}
                onChange={(date) => setStartDate(date)}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    {...params}
                    label="Start Date"
                  />
                )}
              />
              <DesktopDatePicker
                inputFormat="dd/MM/yyyy"
                color="success"
                value={endDate}
                onChange={(date) => setEndDate(date)}
                renderInput={(params) => (
                  <TextField variant="outlined" {...params} label="End Date" />
                )}
              />
            </Stack>
          </LocalizationProvider>
        </Stack>
      </div>
      <Grid container alignItems="center" justifyContent="space-between">
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "20px",
          }}></div>
        <Grid item>
          <Stack direction="row" mt={3} alignItems="center" spacing={3}>
            <Button
              variant="contained"
              color="success"
              style={{
                backgroundColor: colorTags.TERTIARY,
              }}
              onClick={handleSearch}>
              Search
            </Button>
            <Button
              variant="outlined"
              // color="success"
              startIcon={<GetAppIcon />}
              style={{
                marginLeft: "10px",
                color: colorTags.TERTIARY,
              }}>
              <CSVLink
                data={filteredLogs ?? []}
                filename={exportFileName + " " + date + ".csv"}
                style={{ textDecoration: "none", color: "inherit" }}>
                Export CSV
              </CSVLink>
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "4%",
        }}>
        <TextField
          name="search"
          label="Search"
          variant="outlined"
          color="success"
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
          value={searchQuery}
          onChange={handleInputChange}
        />
      </div>
      <TableContainer style={{}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredLogs
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((log) => (
                <TableRow key={log.id}>
                  <TableCell>{log.username}</TableCell>
                  <TableCell>{log.date}</TableCell>
                  <TableCell>{log.action}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredLogs?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default LogsPage;
