import React, { useEffect, useState } from "react";
import { Button, Grid, Paper, Stack, Pagination } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import { images } from "../../assets/index";

import DoughnutChart from "./Charts/Doughnut";
import CategoryHolder from "./Charts/CategoryHolder";
import TrendsHolder from "./Charts/TrendsHolder";
import HomepageSettings from "./Charts/SectionSelect";
import AnalyticsDashboard from "./Charts/AnalyticsDashboard";
// import { useGetAnalyticsSocialsQuery } from "../../features/api/apiSlice";

import {
  // getAnalyticsVisits,
  useGetAnalyticsVisitsQuery,
  useGetArticlesQuery,
  useGetPublicationsQuery,
  useGetEventsQuery,
  useGetPromotionsQuery,
} from "../../features/api/apiSlice";
import { Line } from "react-chartjs-2";

const useStyles = makeStyles((theme) => ({
  cardsHolder: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "5% 0px 20px 0px",
    padding: "0px 10px 0px 10px",
    backgroundSize: "cover",
  },
  smallCard: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    minHeight: "150px",
    borderRadius: "15px",
    boxShadow: "rgba(255,255,255, 0.4) 0px 48px 100px 0px",
    position: "relative",
  },
  smallCardTitles: {
    color: "#000",
    fontSize: "1.5rem",
    fontWeight: "bold",
    textAlign: "center",
  },
  icon: {
    width: "70px",
    height: "70px",
    marginRight: "10px",
    boxShadow:
      "rgba(255,255,255, 0.25) 0px 30px 60px -12px inset, rgba(255,255,255, 0.3) 0px 18px 36px -18px inset",
  },
}));

// const data = {
//   labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
//   datasets: [
//     {
//       label: "Monthly Activities",
//       backgroundColor: "rgba(75, 192, 192, 0.2)",
//       borderColor: "rgba(75, 192, 192, 1)",
//       borderWidth: 1,
//       data: [4000, 3000, 2000, 2780, 1890, 2390, 3490],
//     },
//   ],
// };

const options = {
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};
const pieData = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 },
];

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const AnimatedCounter = ({ value }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let start = 0;
    const interval = setInterval(() => {
      if (start >= value) {
        clearInterval(interval);
        setCount(value); // Set the final value when the animation is done
      } else {
        setCount(start);
        start += 10;
      }
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [value]);

  return (
    <span style={{ color: "#686D76", fontSize: "20px", textAlign: "center" }}>
      {count}
    </span>
  );
};

const generateDynamicLabels = () => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const currentMonth = new Date().getMonth();
  const labels = monthNames
    .slice(currentMonth + 1)
    .concat(monthNames.slice(0, currentMonth + 1));
  return labels;
};
export default function DashboardPage() {
  // DASHBOARD STATISTICS DATA
  const [token, setToken] = useState(localStorage.getItem("authToken"));

  const {
    data: analyticsData,
    isLoading: isLoadingAnalyticsData,
    isError: isErrorAnalyticsData,
  } = useGetAnalyticsVisitsQuery();

  const {
    data: newsData,
    isLoading: newsLoading,
    isError: newsError,
  } = useGetArticlesQuery();
  const {
    data: publicationsData,
    isLoading: publicationsLoading,
    isError: publicationsError,
  } = useGetPublicationsQuery();
  const {
    data: eventsData,
    isLoading: eventsLoading,
    isError: eventsError,
  } = useGetEventsQuery();

  const [contentValue, setContentValue] = useState(0);
  const [viewsValue, setViewsValue] = useState(0);
  const [flagsValue, setFlagsValue] = useState(0);
  const [membersValue, setMembersValue] = useState(0);

  useEffect(() => {
    if (analyticsData) {
      const filteredData = analyticsData.filter(
        (item) => item.action === "visit"
      );
      const count = filteredData.length;
      console.log(count);
      setViewsValue(count);
    }
  }, [analyticsData]);

  const navigate = useNavigate();

  const classes = useStyles();

  const [data, setData] = useState({
    labels: generateDynamicLabels(),
    datasets: [
      {
        label: "Monthly Traffic",
        data: [30, 50, 70, 60, 30, 20, 50, 55, 70, 80, 65, 60],
        fill: false,
        borderColor: "#03AED2",
        tension: 0.1,
      },
      {
        label: "Shares and Downloads",
        data: [40, 50, 55, 30, 20, 50, 20, 10, 40, 60, 80],
        fill: false,
        borderColor: "#6D8",
        tension: 0.1,
      },
    ],
  });

  const [ad, setAd] = useState(true);
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   if (analyticsData) {
  //     analyticsData.forEach((item) => {
  //       const monthIndex = new Date(item.createdAt).getMonth();
  //       data.datasets[0].data[monthIndex]++;
  //     });
  //     setAd(true);
  //     setLoading(false);
  //   }
  // }, [analyticsData]);

  const config = {
    type: "line",
    data: data,
    options: {
      scales: {
        x: {
          type: "category",
          labels: data.labels,
        },
        y: {
          beginAtZero: true,
        },
      },
      elements: {
        point: {
          radius: 5,
        },
      },
    },
  };

  // if (isLoadingAnalyticsData) return <h1>Loading...</h1>;
  return (
    <Stack
      style={{
        marginLeft: "-15px",
        paddingTop: "15px",
        backgroundColor: "#ebebeb",
      }}>
      {/* <PageTitle title="Welcome" /> */}
      {/* <div className={classes.cardsHolder}> */}

      {/* </div> */}

      <Grid style={{ width: "100%" }} container mt={5} spacing={3} pl={2}>
        <AnalyticsDashboard />
      </Grid>

      <Grid container spacing={2} mt={2} pl={2}>
        <Grid item xs={6}>
          {/* Line Chart */}
          {/* <Paper> */}
          {/* <Bar data={data} options={options} /> */}
          {/* {loading ? (
            <h1>Loading...</h1>
          ) : ( */}
          <div
            style={{
              width: "100%",
              height: "400px",
              backgroundColor: "white",
              borderRadius: "15px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}>
            {" "}
            <Line data={data} options={config} />
          </div>
          {/* )} */}

          {/* </Paper> */}
        </Grid>
        <Grid item xs={6}>
          {/* Bar Chart */}
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}>
            {" "}
            <DoughnutChart />
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={3} pl={2}>
        <Grid item xs={6}>
          <div style={{ width: "100%", height: "70%" }}>
            <CategoryHolder />
          </div>
        </Grid>
        <Grid item xs={6}>
          <HomepageSettings />
        </Grid>
      </Grid>
    </Stack>
  );
}
