import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";

import { Info } from "@mui/icons-material";
// import { useSelector } from "react-redux";
import { SimpleCard } from "../../../../components";
import { primaryColor, colorTags } from "../../../../constants/variants";
import GridData from "../../../../components/GridData";
import { useNavigate } from "react-router-dom";
import { images } from "../../../../assets/index";
import AddAuthor from "./AddAuthor";
import EditAuthor from "./EditAuthor";
import EditIcon from "@mui/icons-material/Edit";
import {
  useDeleteAuthorRequestMutation,
  useGetAuthorsQuery,
} from "../../../../features/api/apiSlice";
import DeleteDialog from "../../../../components/DeleteDialog";
import AddIcon from "@mui/icons-material/Add";
import { BASE_FILE_URL } from "../../../../constants/apiTags";
// Dummy data for authors

const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
  },
}));

export default function AuthorsPage() {
  const [token, setToken] = useState(localStorage.getItem("authToken"));

  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => setModalOpen(false);
  const handleModalOpen = () => setModalOpen(true);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const authorsDataq = [
    {
      id: 1,
      name: "John Doe",
      email: "john.doe@example.com",
      avatar: images.Avatar,
      status: "active",
      createdAt: "2023-06-01",
      updatedAt: "2023-06-01",
    },
    {
      id: 2,
      name: "Jane Smith",
      email: "jane.smith@example.com",
      avatar: images.Avatar,
      status: "inactive",
      createdAt: "2023-06-02",
      updatedAt: "2023-06-02",
    },
    {
      id: 3,
      name: "Alice Johnson",
      email: "alice.johnson@example.com",
      avatar: images.Avatar,
      status: "active",
      createdAt: "2023-06-03",
      updatedAt: "2023-06-03",
    },
    // More authors...
  ];

  const {
    data: authorsData,
    isLoading: isLoadingAuthorsData,
    isError: isErrorAuthorsData,
    error: errorAuthorsData,
  } = useGetAuthorsQuery();

  // Placeholder function for API call (useGetAuthorsQuery)
  // const {
  //   data: authorsData,
  //   isLoading: isLoadingAuthorsData,
  //   isError: isErrorAuthorsData,
  //   error: errorAuthorsData,
  // } = useGetAuthorsQuery();

  // useEffect(() => {
  //   if (authorsData) console.log(authorsData);
  // }, [authorsData]);

  const columns = [
    {
      field: "id",
      headerName: "No.",
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "avatar",
      headerName: "Avatar",
      flex: 1,
      renderCell: (params) => (
        <img
          src={BASE_FILE_URL + params.row.avatar}
          alt={params.row.name}
          style={{ width: "30px", height: "30px", borderRadius: "50%" }}
        />
      ),
    },
    {
      field: "name",
      headerName: "Name",
      flex: 2,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 2,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      type: "date",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: 1,
      align: "left",
      renderCell: (params) => {
        return (
          <div className="flex">
            {/* <IconButton
              onClick={() => {
                navigate(`/dashboard/author-detail/${params.row.id}`);
              }}>
              <Info color="primary" />
            </IconButton> */}
            <IconButton
              onClick={() => {
                setEditModalOpen(true);
                setSelectedRow(params.row);
              }}>
              <EditIcon color="primary" />
            </IconButton>
            <DeleteDialog
              api={useDeleteAuthorRequestMutation}
              dialogLabel="Remove Author"
              id={params.row.id}
              name={`Author ${params.row.id}`}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div style={{ marginLeft: "5%", paddingTop: "5%", width: "90%" }}>
      {modalOpen ? (
        <Button
          variant="contained"
          style={{
            marginBottom: "1%",
            marginLeft: "88%",
            backgroundColor: colorTags.SECONDARY,
          }}
          onClick={handleModalClose}>
          Close
        </Button>
      ) : (
        <Button
          variant="contained"
          style={{
            marginBottom: "1%",
            marginLeft: "88%",
            // backgroundColor: primaryColor,
            backgroundColor: colorTags.TERTIARY,
          }}
          onClick={handleModalOpen}>
          <AddIcon style={{ marginRight: "5px" }} />
          Author
        </Button>
      )}

      <AddAuthor
        open={modalOpen}
        onClose={handleModalClose}
        title="Add Author"
        content="This is the modal content."
        actionText="Close"
      />

      <EditAuthor
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        title="Edit Author"
        content="This is the modal content."
        actionText="Close"
        data={selectedRow}
      />

      <SimpleCard
        title="Authors"
        style={{
          marginTop: "2%",
        }}>
        <Box width="100%" overflow="auto">
          <GridData
            rows={authorsData ?? []}
            columns={columns}
            initialState={{
              pagination: {
                pageSize: 5,
              },
            }}
          />
        </Box>
      </SimpleCard>
    </div>
  );
}
