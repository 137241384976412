import React, { useState } from "react";
import {
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  IconButton,
  Box,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import { TextFields, Image, YouTube, ArrowForward } from "@mui/icons-material";
import { makeStyles } from "@material-ui/core/styles";
import { images } from "../../../../assets/index";
import { useNavigate, useParams } from "react-router-dom";

// Import the template components
import TextTemplate from "./ArticleTemplates/TextTemplate1";
import TextImageTemplate from "./ArticleTemplates/TextImageTemplate2";
import TextImageYoutubeTemplate from "./ArticleTemplates/TextImageYoutubeTemplate3";
import TextTwoImagesTemplate from "./ArticleTemplates/TextTwoImagesTemplate4";
import TextYoutubeTemplate from "./ArticleTemplates/TextYoutubeTemplate5";
import TextTwoImagesYoutubeTemplate from "./ArticleTemplates/TextTwoImagesYoutubeTemplate6";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  card: {
    position: "relative",
    borderRadius: "15px",
    boxShadow: "rgba(0, 0, 0, 0.25) 0px 25px 50px -12px",
  },
  media: {
    height: 140,
  },
  iconContainer: {
    top: 0,
    width: "100%",
    left: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
  },
  activeIcon: {
    color: "cyan",
    width: "20px",
  },
  inactiveIcon: {
    color: theme.palette.grey[500],
    width: "20px",
  },
  activeIconImg: {
    color: "#F4CE14",
    width: "20px",
  },
  activeIconYtb: {
    color: "#FF0000",
    width: "20px",
  },
}));

const templates = [
  { id: 1, image: "template1.jpg" },
  { id: 2, image: "template2.jpg" },
  { id: 3, image: "template3.jpg" },
];

const ArticleTemplate = ({ onClose }) => {
  const classes = useStyles();
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();

  const handleTemplateSelect = (id) => {
    setSelectedTemplate(id);
  };

  const renderTemplate = (id) => {
    switch (id) {
      case "1":
        return <TextTemplate />;
      case "2":
        return <TextImageTemplate />;
      case "3":
        return <TextImageYoutubeTemplate />;
      case "4":
        return <TextTwoImagesTemplate />;
      case "5":
        return <TextYoutubeTemplate />;
      case "6":
        return <TextTwoImagesYoutubeTemplate />;
      default:
        return null;
    }
  };

  return (
    <Box className={classes.container}>
      <Stack
        style={{ width: "100%" }}
        direction="row"
        spacing={2}
        justifyContent={"space-between"}>
        <Typography variant="h6"> New Article</Typography>
        {/* <Button
          variant="outlined"
          style={{
            marginBottom: "1%",
            marginRight: "5%",
            borderRadius: "18px",
            color: "#03AED2",
          }}
          disabled={!selectedTemplate}
          onClick={() =>
            navigate(`/dashboard/article-template/${selectedTemplate}`)
          }>
          next
          <ArrowForward />
        </Button> */}
      </Stack>
      <Grid container spacing={8} justifyContent={"center"} pl={10} pr={10}>
        {renderTemplate(id)}
      </Grid>
    </Box>
  );
};

export default ArticleTemplate;
