import React from "react";
import { Chip } from "@material-ui/core";

const categoryColors = {
  Editorial: { borderColor: "blue", backgroundColor: "rgba(0, 0, 255, 0.7)" },
  Economy: { borderColor: "green", backgroundColor: "rgba(0, 128, 0, 0.7)" },
  Agriculture: {
    borderColor: "brown",
    backgroundColor: "rgba(165, 42, 42, 0.7)",
  },
  Industry: {
    borderColor: "grey",
    backgroundColor: "rgba(128, 128, 128, 0.7)",
  },
  Service: { borderColor: "purple", backgroundColor: "rgba(128, 0, 128, 0.7)" },
  "Green Economy": {
    borderColor: "darkgreen",
    backgroundColor: "rgba(0, 100, 0, 0.7)",
  },
  "Climate Change": {
    borderColor: "lightgreen",
    backgroundColor: "rgba(195, 255, 147, 0.7)",
  },
  Technology: {
    borderColor: "teal",
    backgroundColor: "rgba(0, 128, 128, 0.7)",
  },
  "Inclusive Economies": {
    borderColor: "orange",
    backgroundColor: "rgba(255, 165, 0, 0.7)",
  },
  "Business News": {
    borderColor: "navy",
    backgroundColor: "rgba(0, 0, 128, 0.7)",
  },
  Business: {
    borderColor: "navy",
    backgroundColor: "rgba(0, 128, 128, 0.7)",
  },
  Entrepreneurship: {
    borderColor: "darkorange",
    backgroundColor: "rgba(255, 140, 0, 0.7)",
  },
  "Wholesale and retail trade": {
    borderColor: "darkgoldenrod",
    backgroundColor: "rgba(184, 134, 11, 0.7)",
  },
  "Export-Import": {
    borderColor: "darkcyan",
    backgroundColor: "rgba(0, 139, 139, 0.7)",
  },
  "Transport and Logistics": {
    borderColor: "gold",
    backgroundColor: "rgba(255, 215, 0, 0.7)",
  },
  Agribusiness: {
    borderColor: "darkolivegreen",
    backgroundColor: "rgba(85, 107, 47, 0.7)",
  },
  Manufacturing: {
    borderColor: "darkslategray",
    backgroundColor: "rgba(47, 79, 79, 0.7)",
  },
  Construction: {
    borderColor: "dimgray",
    backgroundColor: "rgba(105, 105, 105, 0.7)",
  },
  "Banking and Finance": {
    borderColor: "indigo",
    backgroundColor: "rgba(75, 0, 130, 0.7)",
  },
  "Tourism and Hospitality": {
    borderColor: "crimson",
    backgroundColor: "rgba(220, 20, 60, 0.7)",
  },
  Companies: {
    borderColor: "peru",
    backgroundColor: "rgba(205, 133, 63, 0.7)",
  },
  Education: {
    borderColor: "saddlebrown",
    backgroundColor: "rgba(139, 69, 19, 0.7)",
  },
  Energy: { borderColor: "olive", backgroundColor: "rgba(128, 128, 0, 0.7)" },
  "Fisheries and Aquaculture": {
    borderColor: "darkblue",
    backgroundColor: "rgba(0, 0, 139, 0.7)",
  },
  Health: {
    borderColor: "firebrick",
    backgroundColor: "rgba(178, 34, 34, 0.7)",
  },
  Insurance: {
    borderColor: "orchid",
    backgroundColor: "rgba(218, 112, 214, 0.7)",
  },
  "Features and Advocacy": {
    borderColor: "tomato",
    backgroundColor: "rgba(255, 99, 71, 0.7)",
  },
  Opinions: {
    borderColor: "slateblue",
    backgroundColor: "rgba(106, 90, 205, 0.7)",
  },
  Reports: {
    borderColor: "steelblue",
    backgroundColor: "rgba(70, 130, 180, 0.7)",
  },
  "Addis Chamber TV": {
    borderColor: "darkmagenta",
    backgroundColor: "rgba(139, 0, 139, 0.7)",
  },
  "Addis Chamber Podcast": {
    borderColor: "darkviolet",
    backgroundColor: "rgba(148, 0, 211, 0.7)",
  },
  Events: { borderColor: "coral", backgroundColor: "rgba(255, 127, 80, 0.7)" },
  Neutral: { borderColor: "black", backgroundColor: "rgba(0, 0, 0, 0.7)" },
};

const CategoryChip = ({ category }) => {
  const { borderColor, backgroundColor } =
    categoryColors[category] || categoryColors.Neutral;

  // Function to determine text color based on background luminance
  const getContrastText = (backgroundColor) => {
    // Convert hex color to RGB
    const rgb = parseInt(backgroundColor.substring(1), 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = (rgb >> 0) & 0xff;

    // Calculate luminance
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

    // Return black or white depending on luminance
    return luminance > 128
      ? "rgba(0, 0, 0, 0.87)"
      : "rgba(255, 255, 255, 0.87)";
  };

  const textColor = getContrastText(backgroundColor);

  return (
    <Chip
      label={category}
      size="small"
      style={{
        borderColor: borderColor,
        backgroundColor: backgroundColor,
        color: textColor,
        borderRadius: "5px",
      }}
    />
  );
};

export default CategoryChip;
