import React, { useState } from "react";
import { Paper, Typography, makeStyles, Divider } from "@material-ui/core";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useGetYoutubePlaylistQuery } from "../../../features/api/apiSlice";
import { BASE_FILE_URL } from "../../../constants/apiTags";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    boxShadow: "none",
    position: "relative",
    overflow: "hidden",
    width: "100%",
  },
  playlistCardContainer: {
    position: "relative",
    width: "100%",
    padding: theme.spacing(0),
    marginTop: theme.spacing(5),
    paddingRight: "0px",
    height: "520px",
    overflow: "hidden",
    display: "flex",
    borderRadius: "5px",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      height: "auto",
      flexDirection: "column",
    },
  },
  mainVideo: {
    width: "75%",
    marginRight: theme.spacing(2),
    height: "500px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: 0,
      height: "auto",
      marginBottom: theme.spacing(2),
    },
  },
  videoList: {
    width: "25%",
    overflowY: "scroll",
    height: "500px",
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "300px",
    },
  },
  videoItem: {
    display: "flex",
    marginBottom: theme.spacing(2),
    borderRadius: "10px",
    padding: "10px",
    cursor: "pointer",
    boxShadow: "rgba(255, 25, 0, 0.4) 0px 2px 4px 0px inset",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
    },
  },
  currentVideoItem: {
    backgroundColor: "rgba(255, 25, 0, 0.8)",
    boxShadow: "rgba(255, 255,255, 0.9) 0px 2px 4px 0px inset",
    color: "#fff",
  },
  thumbnail: {
    width: "90px",
    height: "60px",
    marginRight: theme.spacing(2),
    borderRadius: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "100px",
      height: "70px",
      borderRadius: "5px",
      marginRight: 0,
      marginBottom: theme.spacing(1),
    },
  },
  videoTitle: {
    fontWeight: "bold",
    textAlign: "left",
    fontSize: "0.9rem",
    color: theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
      marginLeft: "10px",
    },
  },
  videoLength: {
    textAlign: "left",
    fontSize: "0.8rem",
    color: theme.palette.text.secondary,
    position: "absolute",
    bottom: 0,
    right: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.7rem",
      marginLeft: "10px",
    },
  },
  mainVideoTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
    textAlign: "left",
    fontSize: "1.5rem",
    color: "#f00000",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
      marginBottom: theme.spacing(0),
    },
  },
}));

const YouTubePlaylist = () => {
  const classes = useStyles();
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const {
    data: playlistData,
    isLoading: playlistLoading,
    isError: playlistError,
  } = useGetYoutubePlaylistQuery();

  return (
    <Paper className={classes.root}>
      <Divider
        style={{
          marginBottom: "10px",
          backgroundColor: "#FFA27F",
        }}
      />
      <Typography variant="h6" className={classes.mainVideoTitle}>
        {t("From Our YouTube Channel")}
      </Typography>
      <div className={classes.playlistCardContainer}>
        <div className={classes.mainVideo}>
          {playlistData && playlistData ? (
            <iframe
              width="100%"
              height={isMobile ? "300" : "500"}
              style={{
                borderRadius: "5px",
              }}
              src={playlistData && playlistData[currentVideoIndex]?.link}
              title={playlistData && playlistData[currentVideoIndex]?.title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen></iframe>
          ) : (
            <iframe
              width="100%"
              height={isMobile ? "300" : "500"}
              style={{
                borderRadius: "5px",
              }}
              src={playlistData && playlistData[currentVideoIndex]?.link}
              title={playlistData && playlistData[currentVideoIndex]?.title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen></iframe>
          )}
        </div>
        <div className={classes.videoList}>
          {playlistData &&
            playlistData?.map((video, index) => (
              <div
                key={video.id}
                className={`${classes.videoItem} ${
                  index === currentVideoIndex ? classes.currentVideoItem : ""
                }`}
                onClick={() => setCurrentVideoIndex(index)}>
                <img
                  src={BASE_FILE_URL + video.thumbnail}
                  alt={video.title}
                  className={classes.thumbnail}
                />
                <div style={{ position: "relative", width: "100%" }}>
                  <Typography
                    className={classes.videoTitle}
                    style={{
                      color: index === currentVideoIndex ? "#fff" : "",
                    }}>
                    {video.title}
                  </Typography>
                  <Typography
                    className={classes.videoLength}
                    style={{
                      color: index === currentVideoIndex ? "#ccc" : "",
                    }}>
                    {video.length}
                  </Typography>
                </div>
              </div>
            ))}
        </div>
      </div>
    </Paper>
  );
};

export default YouTubePlaylist;
