import React from "react";
import {
  makeStyles,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Chip,
  Divider,
  Grid,
} from "@material-ui/core";
import { useMediaQuery } from "@mui/material";
import ChipComponent from "../../../components/ChipGenerator";
import { images } from "../../../assets";
import { colorTags } from "../../../constants/variants";
import { faCalendarXmark } from "@fortawesome/free-solid-svg-icons";
import { useGetArticlesCategoryLatestQuery } from "../../../features/api/apiSlice";
import { BASE_FILE_URL } from "../../../constants/apiTags";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(4),
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(4),
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
  },
  cardsHolder: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start",
      alignItems: "flex-start",

      paddingLeft: "0px",
    },
  },
  card: {
    position: "relative",
    background: "transparent",
    height: "280px",
    paddingLeft: "0px",
    boxShadow: "none",
    width: "90%",
    [theme.breakpoints.down("sm")]: {
      height: "200px",
      width: "80%",
    },
  },
  media: {
    height: 115,
    marginBottom: "0px",
    [theme.breakpoints.down("xs")]: {
      height: 100,
    },
  },
  chip: {
    position: "absolute",
    top: theme.spacing(0.2),
    left: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  date: {
    position: "absolute",
    top: theme.spacing(0),
    right: theme.spacing(0),
    fontSize: "0.75rem",
    color: "#222",
    backgroundColor: "rgba(255,255,255,0.9)",
    padding: "2px 4px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.65rem",
    },
  },
  title: {
    fontFamily: "Times New Roman, serif",
    fontWeight: "bold",
    paddingTop: "0px",
    color: colorTags.PRIMARY,
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.9rem",
    },
    "&:hover": {
      // color: "#007bff",
      color: colorTags.TERTIARY,
    },
  },
  divider: {
    width: "100%",
    marginBottom: theme.spacing(2),
    backgroundColor: colorTags.TERTIARY,
  },
  dividerText: {
    marginBottom: theme.spacing(2),
    color: colorTags.PRIMARY,

    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(2),
      fontSize: "1rem",
    },
  },
}));

const articles = [
  {
    title: "Economic Growth in 2024: Predictions and Trends",
    imageUrl: images.CoverImagePlaceholder,
    date: "June 12, 2024",
    category: "Economy",
  },
  {
    title: "Top 10 Technologies to Watch in 2024",
    imageUrl: images.NEWS_PLACEHOLDER,
    date: "June 13, 2024",
    category: "Technology",
  },
  {
    title: "The Future of Small Business in a Post-Pandemic World",
    imageUrl: images.NewsPlaceholder3,
    date: "June 14, 2024",
    category: "Business",
  },
  {
    title: "Sustainable Energy Solutions for a Greener Planet",
    imageUrl: images.CategoryNewsLinePlaceholder2,
    date: "June 15, 2024",
    category: "Energy",
  },
];

const RelatedArticles = ({ category }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 600px)");

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const {
    data: categoryLatestArticlesData,
    isLoading: categoryLatestArticlesLoading,
    isError: categoryLatestArticlesError,
    error: errorCategoryLatestArticles,
  } = useGetArticlesCategoryLatestQuery({
    title: category ?? "Business",
    amount: 4,
  });

  return (
    <div className={classes.root}>
      <Divider className={classes.divider} />
      <Typography variant="h6" className={classes.dividerText}>
        {t("Related News")}
      </Typography>
      <Grid
        container
        spacing={4}
        style={{
          padding: isMobile ? "0 5%" : "0",
        }}
        justifyContent={isMobile ? "flex-start" : "flex-start"}>
        {/* {articles.map((article, index) => ( */}
        {categoryLatestArticlesData?.map((article, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            key={index}
            className={classes.cardsHolder}
            style={{
              paddingLeft: "0px",
              marginLeft: "0px",
            }}>
            <Card className={classes.card}>
              <CardMedia
                className={classes.media}
                // image={article.imageUrl}
                image={BASE_FILE_URL + article?.coverImage}
                title={article?.title}
              />
              {/* <ChipComponent category={article?.generalCategory} /> */}
              <CardContent
                style={{
                  paddingLeft: "0px",
                  paddingBottom: "20px",
                }}>
                <Link
                  to={`/news-detail/${article && article?.id}`}
                  target="_blank">
                  <Typography
                    variant="body2"
                    className={classes.title}
                    gutterBottom>
                    {article?.title}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    className={classes.date}>
                    {/* {article?.date} */}
                    {new Date(article?.createdAt).toLocaleDateString("en-US")}
                  </Typography>
                </Link>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default RelatedArticles;
