import React, { useState, useEffect } from "react";
import { PieChart } from '@mui/x-charts';
import { useGetAnalyticsByCategoryQuery } from "../../../features/api/apiSlice";
import { CircularProgress } from "@mui/material";

const PieChartComponent = () => {
  const {
    data: analyticsData,
    isLoading: isLoadingAnalyticsData,
    isError: isErrorAnalyticsData,
    error: errorAnalyticsData,
  } = useGetAnalyticsByCategoryQuery();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  useEffect(() => {
    if (analyticsData && !isLoadingAnalyticsData && !isErrorAnalyticsData) {
      // Map the analytics data to the format required for the pie chart
      const updatedData = analyticsData.map(item => ({
        name: item.category,
        value: item.views,
        color: getRandomColor(),
      }));
      setData(updatedData);
      setLoading(false);
    }
  }, [analyticsData, isLoadingAnalyticsData, isErrorAnalyticsData]);

  return (
    <div style={styles.card}>
      <h3 style={styles.title}>Category Views</h3>
      {loading ? (
        // <h1>Loading...</h1>

        <CircularProgress/>

      )  :
      //  isErrorAnalyticsData?(
      //   <>
      //   <h1 style={{color:'red'}}>Error Fetching</h1>
      //   </>
      // ):
           (
        <>
          <PieChart
            series={[
              {
                data: data.map(item => ({
                  ...item,
                  id: item.name, // Adding id for each item
                })),
                innerRadius: 30,
                outerRadius: 150,
                paddingAngle: 5,
                cornerRadius: 5,
                startAngle: 90,
                endAngle: 360,
                cx: 200,
                cy: 170,
              }
            ]}
          />
          <div style={styles.legend}>
            {data.map(item => (
              <div key={item.name} style={styles.legendItem}>
                <span style={{ ...styles.legendColor, backgroundColor: item.color }}></span>
                <span style={styles.legendText}>{item.name}</span>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

const styles = {
  card: {
    width: '89%',
    height: '400px',
    backgroundColor: 'white',
    borderRadius: '15px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  title: {
    position: 'absolute',
    top: '10px',
    left: '20px',
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#333',
  },
  legend: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '15px',
    marginBottom: '10px',
  },
  legendColor: {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    marginRight: '5px',
  },
  legendText: {
    fontSize: '12px',
  },
};

export default PieChartComponent;
