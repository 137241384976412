import React, { useRef, useEffect, useState } from "react";
import {
  makeStyles,
  Typography,
  Divider,
  Card,
  CardMedia,
  IconButton,
  useMediaQuery,
} from "@material-ui/core";
import { Share, GetApp, OpenInNew } from "@material-ui/icons";
import { images } from "../../../assets/index";
import { colorTags } from "../../../constants/variants";
import { useTranslation } from "react-i18next";
import {
  BASE_FILE_URL,
  FILE_DOWNLOAD_URL,
  INTERNAL_LINK_URL,
  BASE_FILE_URL_HTTP,
} from "../../../constants/apiTags";
import ShareDialog from "./Share";

import { useGetPublicationsQuery } from "../../../features/api/apiSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    height: "100%",
    marginTop: "2%",
    overflowY: "auto",
    scrollbarWidth: "thin",
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#555",
    },
  },
  carousel: {
    display: "flex",
    transition: "transform 0.5s ease",
    width: "100%",
  },
  card: {
    minWidth: 300,
    margin: theme.spacing(2),
    flex: "0 0 auto",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      minWidth: 200,
    },
    cursor: "pointer",
  },
  media: {
    height: 400,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      height: 300,
    },
  },
  mobileRoot: {
    display: "flex",
    overflowX: "auto",
    scrollbarWidth: "thin",
    "&::-webkit-scrollbar": {
      height: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#555",
    },
    width: "100%",
  },
  mobileCard: {
    minWidth: "60%",
    margin: theme.spacing(2),
    flex: "0 0 auto",
    position: "relative",
  },
  divider: {
    marginTop: theme.spacing(3),
    backgroundColor: colorTags.TERTIARY,
    width: "100%",
  },
  categoryTitle: {
    width: "100%",
    display: "flex",
    color: colorTags.PRIMARY,
    justifyContent: "flex-start",
    alignItems: "flex-start",
    margin: theme.spacing(2, 0),
    lineHeight: 1.2,
    fontSize: "1.5rem",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
  },
  buttonContainer: {
    position: "absolute",
    bottom: 10,
    right: 10,
    display: "flex",
    gap: theme.spacing(1),
    backgroundColor: "rgba(255,255,255,0.5)",
    border: "1px solid black",
    borderRadius: "20px",
  },
  iconButton: {
    color: "#fff",
    width: "35px",
    height: "35px",
    backgroundColor: `rgba(${colorTags.PRIMARY_RGBA}, 0.6)`,
    "&:hover": {
      backgroundColor: `rgba(${colorTags.TERTIARY_RGBA}, 0.8)`,
    },
  },
}));

const MonthlyPublications = () => {
  const classes = useStyles();
  const containerRef = useRef(null);
  const carouselRef = useRef(null);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const {
    data: publicationData,
    isLoading: publicationDataLoading,
    isError: publicationDataError,
  } = useGetPublicationsQuery();

  const [isShareDialogOpen, setShareDialogOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const openShareDialog = (file) => {
    setSelectedFile(file);
    setShareDialogOpen(true);
  };

  const closeShareDialog = () => {
    setSelectedFile(null);
    setShareDialogOpen(false);
  };

  const handleDownloadPublication = (url) => {
    window.open(BASE_FILE_URL + url);
  };

  const handleOpenInNew = (url) => {
    window.open(BASE_FILE_URL_HTTP + url, "_blank");
  };

  useEffect(() => {
    if (!isMobile) {
      const container = containerRef.current;
      const handleScroll = (e) => {
        if (
          container.scrollTop >
          container.scrollHeight - container.clientHeight - 50
        ) {
          container.scrollTop = 0;
        }
        carouselRef.current.style.transform = `translateX(-${container.scrollTop}px)`;
      };
      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, [isMobile]);

  return (
    <div style={{ width: "100%", padding: isMobile ? "0% 5%" : "0%" }}>
      <Divider className={classes.divider} />
      <Typography className={classes.categoryTitle}>
        {t("Monthly Publications")}
      </Typography>
      <div
        className={isMobile ? classes.mobileRoot : classes.root}
        ref={containerRef}>
        <div className={classes.carousel} ref={carouselRef}>
          {publicationData?.map((pub, index) => (
            <Card
              key={index}
              className={isMobile ? classes.mobileCard : classes.card}>
              <CardMedia
                className={classes.media}
                image={BASE_FILE_URL + pub?.coverImage}
                title={pub?.publicationTitle}
              />
              <div className={classes.buttonContainer}>
                <IconButton
                  className={classes.iconButton}
                  onClick={() => openShareDialog(pub?.publicationFile)}>
                  <Share />
                </IconButton>
                <IconButton
                  className={classes.iconButton}
                  onClick={() =>
                    handleDownloadPublication(pub?.publicationFile)
                  }>
                  <GetApp />
                </IconButton>
                <IconButton
                  className={classes.iconButton}
                  onClick={() => handleOpenInNew(pub?.publicationFile)}>
                  <OpenInNew />
                </IconButton>
              </div>
            </Card>
          ))}
        </div>
      </div>
      {isShareDialogOpen && (
        <ShareDialog
          open={isShareDialogOpen}
          onClose={closeShareDialog}
          publication={selectedFile}
        />
      )}
    </div>
  );
};

export default MonthlyPublications;
