import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Divider, Chip } from "@mui/material";
import { Typography } from "@material-ui/core";
import { images } from "../../../assets";
import { colorTags } from "../../../constants/variants";
import { useTranslation } from "react-i18next";
import { useGetArticlesTopTodayQuery } from "../../../features/api/apiSlice";
import { BASE_FILE_URL } from "../../../constants/apiTags";
const useStyles = makeStyles((theme) => ({
  trendingNews: {
    width: 300,
    border: "1px solid lightgrey",
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      width: "100%",

      // padding: 15,
    },
  },
  header: {
    fontSize: 18,
    fontWeight: "bold",
    color: colorTags.PRIMARY,
  },
  card: {
    backgroundColor: "transparent",
    boxShadow: "none",
    marginTop: theme.spacing(0),
    display: "flex",
    alignItems: "center",
  },
  content: {
    flex: 1,
  },
  title: {
    fontSize: 18,
    lineHeight: 1.2,
    fontFamily: "Times-new-roman",

    fontWeight: "bold",
  },
  description: {
    fontSize: 14,
    marginTop: theme.spacing(0.5),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
    backgroundColor: colorTags.TERTIARY,
  },
  chip: {
    position: "absolute",
    top: theme.spacing(1),
    left: theme.spacing(1),
    zIndex: 1,
  },
  image: {
    width: 40,
    height: 40,
    marginLeft: theme.spacing(2),
    borderRadius: "5px",
    objectFit: "cover",
  },
}));

const TopNewsToday = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text?.substring(0, maxLength) + "...";
    }
    return text;
  };
  const news = [
    {
      title: "Ethiopia Announces Plan to Increase Wheat Production",
      description:
        "Ethiopia's government has unveiled a plan to significantly boost domestic wheat production in an effort to reduce reliance on imports.",
      category: "Business",
      image: images.TopNewsStoryPlaceholder2,
    },
    {
      title: "Global Chip Shortage Continues to Impact Tech Industry",
      description:
        "The global chip shortage that began during the pandemic continues to disrupt the tech industry, causing delays in production and higher prices for consumers.",
      category: "Technology",
      image: images.TopNewsStoryPlaceholder2,
    },
    {
      title: "Scientists Discover New Exoplanet with Potential for Life",
      description:
        "Scientists have discovered a new exoplanet located in the habitable zone of its star system, raising hopes of finding extraterrestrial life.",
      category: "Science",
      image: images.TopNewsStoryPlaceholder2,
    },
    {
      title: "Heavy Rains Cause Flooding in Several Ethiopian Regions",
      description:
        "Heavy rains in recent days have caused flooding in several regions of Ethiopia, displacing thousands of people and damaging infrastructure.",
      category: "Local",
      image: images.TopNewsStoryPlaceholder2,
    },
    {
      title: "Ethiopia and Eritrea Agree to Normalize Relations",
      description:
        "After years of tension, Ethiopia and Eritrea have agreed to normalize relations, a move that could bring peace and stability to the region.",
      category: "World",
      image: images.TopNewsStoryPlaceholder2,
    },
  ];

  const newsAm = [
    {
      title:
        "ኢትዮጵያ ምርት እንዲጨምር ያዘጋጀ እቅድ (Ethiopia Announces Plan to Increase Wheat Production)",
      description:
        "የኢትዮጵያ መንግስት በአገር ውስጥ  கೋማ ምርት በከፍተኛ ሁኔ ለማሳደግ ያዘጋጀ እቅድ አውጥቶ ለውጭ አገር መግ afhankelijkhet ለመ குறைት ጥረት አድርጓል። (Ethiopia's government has unveiled a plan to significantly boost domestic wheat production in an effort to reduce reliance on imports.)",
      category: "ንግድ (Business)",
      image: images.TopNewsStoryPlaceholder2,
    },
    {
      title:
        "በዓለም አቀፍ ደረጃ ያለው የቺፕ እጥቅት በቴክኖሎጂ ኢንዱስትሪ ላይ ተጽዕኖ ማድረ continu አለ (Global Chip Shortage Continues to Impact Tech Industry)",
      description:
        "በወረርሽን ወቅት የጀመረው በዓለም አቀፍ የቺፕ እጥቅት በቴክኖሎጂ ኢንዱስትሪ ላይ መልቀም አለች ሲሆን፣ በምርት ሂደት እዘአት እንዲሁም ለደንበኛ ደንበኞች ከፍተኛ ዋጋ እንዲያስከትል አድርጓል። (The global chip shortage that began during the pandemic continues to disrupt the tech industry, causing delays in production and higher prices for consumers.)",
      category: "ቴክኖሎጂ (Technology)",
      image: images.TopNewsStoryPlaceholder2,
    },
    {
      title:
        "ሳይንቲስቶች የህይወት አቅም ያለው አዲስ ኤክስፕላኔት አገኝተዋል (Scientists Discover New Exoplanet with Potential for Life)",
      description:
        "ሳይንቲስቶች በከዋክብት ስርዓት ውስጥ የሚገኝ የህይወት አቅም ያለው አዲስ ኤክስፕላኔት አገኝተዋል። ይህም  مصرى ህይወት ለመፈለግ የተጨናነቀ ተስፋ አስነስቶ ሰጥቷል። (Scientists have discovered a new exoplanet located in the habitable zone of its star system, raising hopes of finding extraterrestrial life.)",
      category: "ሳይንስ (Science)",
      image: images.TopNewsStoryPlaceholder2,
    },
    {
      title:
        "በብዙ የኢትዮጵያ ክልል ውስጥ ከፍተኛ ዝናብ  powodowała powodzie (Heavy Rains Cause Flooding in Several Ethiopian Regions)",
      description:
        "በቅርብ ጊዜ የዘለቀ ከፍተኛ ዝናብ በብዙ የኢትዮጵያ ክልል ውስጥ  powodziała (flooding) አስከትሎ በሺዎች የሚቆጠሩ ሰዎችን ከመኖሪያ ቦታቸው አዘው although (displacing) እንዲሁም መሰናክል አስከትሏል (damaging infrastructure). (Heavy rains in recent days have caused flooding in several regions of Ethiopia, displacing thousands of people and damaging infrastructure.)",
      category: "አ המקומי (Local)",
      image: images.TopNewsStoryPlaceholder2,
    },
    {
      title:
        "ኢትዮጵያ እና ኤርትራ ግንኙነት እንደማደርג ተስማሙ (Ethiopia and Eritrea Agree to Normalize Relations)",
      description:
        "ከረጅም ጊዜ ውጥ ውዝገት በኋላ ኢትዮጵያ እና ኤርትራ ግንኙነት እንደማደርግ ተስማሙ ሲሆን ይህ እንቅ",
      category: "ሳይንስ (Science)",
      image: images.TopNewsStoryPlaceholder2,
    },
  ];

  // Top News Today
  const {
    data: topNewsTodayData,
    isLoading: topNewsTodayLoading,
    isError: topNewTodayError,
  } = useGetArticlesTopTodayQuery({ lang: currentLanguage });
  return (
    <div className={classes.trendingNews}>
      <Typography className={classes.header}>{t("Top News Today")}</Typography>
      <Divider className={classes.divider} style={{ marginBottom: "15px" }} />
      {currentLanguage === "en"
        ? topNewsTodayData?.map((item, index) => (
            // news?.map((item, index) => (
            <Card
              key={index}
              className={classes.card}
              style={{ boxShadow: "none" }}>
              <CardContent className={classes.content}>
                {/* <Chip
              label={item.category}
              color="primary"
              className={classes.chip}
              size="small"
            /> */}
                <Typography
                  className={classes.title}
                  style={{
                    fontWeight: "bold",
                  }}>
                  {truncateText(item?.title, 50)}
                </Typography>
                {/* <Typography className={classes.description}>
              {item.description}
            </Typography> */}
              </CardContent>
              <img
                src={item.image}
                // src={`${BASE_FILE_URL}${item.coverImage}`}
                alt={item.title}
                className={classes.image}
              />
            </Card>
          ))
        : topNewsTodayData?.map((item, index) => (
            // newsAm?.map((item, index) => (
            <Card
              key={index}
              className={classes.card}
              style={{ boxShadow: "none" }}>
              <CardContent className={classes.content}>
                {/* <Chip
              label={item.category}
              color="primary"
              className={classes.chip}
              size="small"
            /> */}
                <Typography
                  className={classes.title}
                  style={{
                    fontWeight: "bold",
                  }}>
                  {/* {item.title} */}
                  {truncateText(item?.title, 50)}
                </Typography>
                {/* <Typography className={classes.description}>
              {item.description}
            </Typography> */}
              </CardContent>
              <img
                src={item.image}
                // src={`${BASE_FILE_URL}${item.coverImage}`}
                alt={item?.title}
                className={classes.image}
              />
            </Card>
          ))}
    </div>
  );
};

export default TopNewsToday;
