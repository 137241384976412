import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Typography, Link } from "@material-ui/core";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { images } from "../../assets/index";
import { colorTags } from "../../constants/variants";
import Header from "../Home/Header/Header2";
import Footer from "../Home/Footer/Footer2";
import {
  useForgotPasswordMutation,
  useVerifyCodeMutation,
} from "../../features/api/apiSlice";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import Title from "../Home/Header/Title2";
import LogoHead from "../Home/Header/LogoHead2";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "60vh",
    backgroundColor: "#fff",
  },
  card: {
    maxWidth: 400,
    padding: theme.spacing(4),
    textAlign: "flex-start",
    borderRadius: theme.spacing(1),
    // boxShadow: "rgba(0, 0, 0, 0.45) 0px 35px 20px -20px",
    boxShadow:
      " rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px",
    [theme.breakpoints.up("sm")]: {
      position: "relative",
      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "10%",
        height: "100%",
        backgroundImage: `url(${images.BlurBg1})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        filter: "blur(10px)",
        zIndex: -1,
      },
    },
  },
  inputField: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: colorTags.SECONDARY,
    "&:hover": {
      backgroundColor: colorTags.PRIMARY,
    },
  },
  title: {
    marginBottom: theme.spacing(3),
    // color: colorTags.PRIMARY,
    color: "#000",
  },
  otherText: {
    color: colorTags.PRIMARY,
  },
}));

const ForgotPassword = () => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();

  const [forgotPassword, { isLoading: isForgotLoading }] =
    useForgotPasswordMutation();
  const [verifyCode, { isLoading: isVerifyLoading }] = useVerifyCodeMutation();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleVerificationCodeChange = (event) => {
    setVerificationCode(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = { email };
    forgotPassword(data)
      .then((res) => {
        if (res) {
          toast.success("A reset email has been sent to your email address.");
          setEmailSubmitted(true);
        } else {
          toast.error("Something went wrong. Please try again later.");
        }
      })
      .catch(() => {
        toast.error("Something went wrong. Please try again later.");
      });
  };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleCodeSubmit = (event) => {
    event.preventDefault();
    // Your code for verifying the code and setting a new password
  };

  const handleSendAgain = () => {
    setEmailSubmitted(false);
  };

  return (
    <>
      <Title />
      <LogoHead />
      <Header />
      <div className={classes.root}>
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h5" className={classes.title}>
              {emailSubmitted ? "Reset Password" : "Forgot Password"}
            </Typography>
            <form onSubmit={!emailSubmitted ? handleSubmit : handleCodeSubmit}>
              {!emailSubmitted && (
                <TextField
                  type="email"
                  label="Email"
                  value={email}
                  variant="outlined"
                  color="success"
                  onChange={handleEmailChange}
                  fullWidth
                  className={classes.inputField}
                />
              )}
              {emailSubmitted && (
                <>
                  <TextField
                    type="text"
                    label="Verification Code"
                    value={verificationCode}
                    variant="outlined"
                    color="success"
                    onChange={handleVerificationCodeChange}
                    fullWidth
                    className={classes.inputField}
                  />
                  <TextField
                    type="password"
                    label="New Password"
                    value={newPassword}
                    variant="outlined"
                    color="success"
                    onChange={handleNewPasswordChange}
                    fullWidth
                    className={classes.inputField}
                  />
                  <TextField
                    type="password"
                    label="Confirm Password"
                    value={confirmPassword}
                    variant="outlined"
                    color="success"
                    onChange={handleConfirmPasswordChange}
                    fullWidth
                    className={classes.inputField}
                  />
                </>
              )}
              <LoadingButton
                loading={emailSubmitted ? isVerifyLoading : isForgotLoading}
                type="submit"
                variant="outlined"
                color="primary"
                fullWidth
                style={{
                  marginTop: "15px",
                  marginBottom: "15px",
                }}
                className={classes.button}>
                {emailSubmitted ? "Change Password" : "Send Reset Code"}
              </LoadingButton>
            </form>
            <Typography variant="body2" color="textSecondary">
              {!emailSubmitted
                ? "Remember your password? "
                : "Didn't receive a code? "}
              {!emailSubmitted ? (
                <Link
                  onClick={() => navigate("/login")}
                  className={classes.otherText}>
                  Sign In
                </Link>
              ) : (
                <Link onClick={handleSendAgain} className={classes.otherText}>
                  Send again
                </Link>
              )}
            </Typography>
          </CardContent>
        </Card>
      </div>
      <Footer />
    </>
  );
};

export default ForgotPassword;
