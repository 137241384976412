import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { BASE_FILE_URL } from "../../../constants/apiTags";
import { useGetCustomImageByIdQuery } from "../../../features/api/apiSlice";
import { images } from "../../../assets";
import { useNavigate } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  topBar: {
    width: "100%",
    height: 150,
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width: 400,
    // maxWidth: 300,
    maxHeight: 100,
    height: "auto",
    objectFit: "contain",
    cursor: "pointer",
  },
}));

const LogoHead = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <div className={classes.topBar}>
      {
        <img
          src={images.AcLogo}
          alt="Top Bar"
          onClick={() => navigate("/")}
          className={classes.image}
        />
      }
    </div>
  );
};

export default LogoHead;
