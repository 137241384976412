import React, { useState, useEffect } from "react";
import Header from "./Header/Header";
import LogoHead from "./Header/LogoHead";

import Footer from "./Footer/Footer";

import { images } from "../../assets/index";
import LoadingScreen from "../../components/LoadingMain";
import BackToTop from "../../components/BackToTop";
import TitleTop from "./Header/Title";
import { Stack } from "@mui/material";

import {
  useGetCustomImagesQuery,
  usePostAnalyticsVisitsMutation,
} from "../../features/api/apiSlice";
import { Helmet } from "react-helmet";
import axios from "axios";
import { BASE_URL } from "../../constants/apiTags";

import TrendingNews from "./NewsComponents/TrendingNews";
import TopNewsToday from "./NewsComponents/TopNewsToday";

import Advertisements from "./NewsComponents/Advertisements";
import TopStory from "./NewsComponents/TopStory";
import CategoryNewsLine from "./NewsComponents/CategoryNewsLines";
import CategoryNewsFull from "./NewsComponents/CategoryNewsFull";
import MonthlyEdition from "./NewsComponents/MonthlyEdition";
import MostReadThisMonth from "./NewsComponents/MostReadThisMonth";
import YoutubeChannelFrame from "./NewsComponents/YoutubeFrame";
import MonthlyPublications from "./NewsComponents/MonthlyPublications";
import EventsSection from "./NewsComponents/EventsSection";
const HomePage = () => {
  const [loading, setLoading] = useState(true);

  const { data: customData, error, isLoading } = useGetCustomImagesQuery();
  const [analyticsApi] = usePostAnalyticsVisitsMutation();
  const [token, setToken] = useState(localStorage.getItem("authToken"));
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;

  const [visit, setVisit] = useState(false);

  // const [postAnalyticsData, { isLoading: isAnalyticsLoading }] =
  //   usePostAnalyticsMutation();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  // useEffect(() => {
  //   if (!visit) {
  //     const data = {
  //       action: "visit",
  //       value: "1",
  //       content: "HomePage",
  //     };
  //     postAnalyticsData(data).then((res) => setVisit(true));
  //   }
  // }, [visit]);

  if (loading) {
    return <LoadingScreen />;
  }
  const sidebarAds = [
    {
      title: "Sidebar Ad 1",
      image: images.AdPlaceholderTop,
      description: "This is sidebar ad 1",
    },
    {
      title: "Sidebar Ad 2",
      image: images.AdPlaceholderTop2,
      description: "This is sidebar ad 2",
    },
    {
      title: "Sidebar Ad 3",
      image: images.AdPlaceholderTop3,
      description: "This is sidebar ad 3",
    },
  ];
  const sampleArticle = {
    title: "Ethiopian Exports Soar to New Heights",
    image: images.TopStoryPlaceholder,
    imageCaption: "An image caption describing the event.",
    intro:
      "This is a detailed introduction to the top story of the day, providing an overview of the key points and setting the stage for the article. It goes into more detail than a typical intro, engaging the reader with a compelling narrative.",
  };
  const smallerArticles = [
    {
      title: "Second Story Breaking News",
      image: images.SmallNewsStoryPlaceholder,
      imageCaption: "Second story image caption.",
      intro:
        "This is a brief intro to the second story, summarizing the key points in a few sentences.",
    },
    {
      title: "Third Story Latest Update",
      image: images.SmallNewsStoryPlaceholder,
      imageCaption: "Third story image caption.",
      intro:
        "This is a brief intro to the third story, providing a quick update on recent developments.",
    },
    {
      title: "Second Story Breaking News",
      image: images.SmallNewsStoryPlaceholder,
      imageCaption: "Second story image caption.",
      intro:
        "This is a brief intro to the second story, summarizing the key points in a few sentences.",
    },
    {
      title: "Third Story Latest Update",
      image: images.SmallNewsStoryPlaceholder,
      imageCaption: "Third story image caption.",
      intro:
        "This is a brief intro to the third story, providing a quick update on recent developments.",
    },
  ];
  const categoryArticlesLine = [
    {
      title: "AI Revolution in 2024",
      intro:
        "Artificial intelligence is transforming industries at an unprecedented pace.",
      image: images.CategoryNewsLinePlaceholder,
      date: "June 4, 2024",
    },
    {
      title: "5G Networks Expanding Worldwide",
      intro:
        "5G networks are rolling out globally, offering faster speeds and more reliable connections.",
      image: images.CategoryNewsLinePlaceholder,

      date: "June 3, 2024",
    },
    {
      title: "Quantum Computing Breakthrough",
      intro:
        "Scientists achieve a major milestone in the field of quantum computing.",
      image: images.CategoryNewsLinePlaceholder,

      date: "June 2, 2024",
    },
    {
      title: "New Advances in Robotics",
      intro:
        "Robotics technology is advancing rapidly, with new applications emerging daily.",
      image: images.CategoryNewsLinePlaceholder,

      date: "June 1, 2024",
    },
  ];
  const categoryArticlesLine2 = [
    {
      title: "The Future of Electric Vehicles",
      intro: "Electric vehicles are becoming more affordable and mainstream.",
      image: images.CategoryNewsLinePlaceholder2,

      date: "May 31, 2024",
    },
    {
      title: "Cybersecurity Trends to Watch",
      intro: "Top cybersecurity trends and challenges for businesses in 2024.",
      image: images.CategoryNewsLinePlaceholder2,

      date: "May 30, 2024",
    },
    {
      title: "Innovations in Smart Home Technology",
      intro: "Smart home devices are getting smarter and more integrated.",
      image: images.CategoryNewsLinePlaceholder2,

      date: "May 29, 2024",
    },
    {
      title: "Virtual Reality: The Next Big Thing",
      intro:
        "Virtual reality is poised to revolutionize entertainment and education.",
      image: images.CategoryNewsLinePlaceholder2,

      date: "May 28, 2024",
    },
  ];

  const categoryArticlesFull = [
    {
      title: "AI Revolution in 2024",
      intro:
        "Artificial intelligence is transforming industries at an unprecedented pace.",
      image: images.CategoryNewsFullPlaceholder,

      imageCaption: "AI and the future",
      date: "June 5, 2024",
    },
    {
      title: "5G Networks Expanding Worldwide",
      intro:
        "5G networks are rolling out globally, offering faster speeds and more reliable connections.",
      image: images.CategoryNewsFullPlaceholder2,

      imageCaption: "5G networks",
      date: "June 4, 2024",
    },
    {
      title: "Quantum Computing Breakthrough",
      intro:
        "Scientists achieve a major milestone in the field of quantum computing.",
      image: images.CategoryNewsFullPlaceholder2,

      imageCaption: "Quantum computing",
      date: "June 3, 2024",
    },
    {
      title: "New Advances in Robotics",
      intro:
        "Robotics technology is advancing rapidly, with new applications emerging daily.",
      image: images.CategoryNewsFullPlaceholder2,

      imageCaption: "Robotics advances",
      date: "June 2, 2024",
    },
    {
      title: "The Future of Electric Vehicles",
      intro: "Electric vehicles are becoming more affordable and mainstream.",
      image: images.CategoryNewsFullPlaceholder2,

      imageCaption: "Electric vehicles",
      date: "June 1, 2024",
    },
    {
      title: "Cybersecurity Trends to Watch",
      intro: "Top cybersecurity trends and challenges for businesses in 2024.",
      image: images.CategoryNewsFullPlaceholder2,

      imageCaption: "Cybersecurity",
      date: "May 31, 2024",
    },
    {
      title: "The Future of Electric Vehicles",
      intro: "Electric vehicles are becoming more affordable and mainstream.",
      image: images.CategoryNewsFullPlaceholder2,
      imageCaption: "Electric vehicles",
      date: "June 1, 2024",
    },
  ];
  const categoryArticlesFullEconomy = [
    {
      title: "Economic Growth in Ethiopia",
      intro:
        "Ethiopia's economy is experiencing significant growth, driven by various sectors.",
      image: images.CategoryNewsFullEconomyPlaceholder2,
      imageCaption: "Economic growth",
      date: "June 5, 2024",
      category: "Economy",
    },
    {
      title: "Advancements in Ethiopian Agriculture",
      intro:
        "New agricultural techniques and technologies are boosting productivity in Ethiopia.",
      image: images.CategoryNewsFullEconomyPlaceholder,
      imageCaption: "Agriculture advancements",
      date: "June 4, 2024",
      category: "Agriculture",
    },
    {
      title: "Industry Growth in Ethiopia",
      intro:
        "Ethiopia's industrial sector is expanding with new investments and developments.",
      image: images.CategoryNewsFullEconomyPlaceholder,
      imageCaption: "Industry growth",
      date: "June 3, 2024",
      category: "Industry",
    },
    {
      title: "Service Sector Boom in Ethiopia",
      intro:
        "The service sector in Ethiopia is seeing rapid growth and increased contributions to the GDP.",
      image: images.CategoryNewsFullEconomyPlaceholder,
      imageCaption: "Service sector boom",
      date: "June 2, 2024",
      category: "Service",
    },
    {
      title: "Green Economy Initiatives in Ethiopia",
      intro:
        "Ethiopia is implementing green economy strategies to promote sustainable development.",
      image: images.CategoryNewsFullEconomyPlaceholder,
      imageCaption: "Green economy initiatives",
      date: "June 1, 2024",
      category: "Green Economy",
    },
    {
      title: "Climate Change Impact on Ethiopia",
      intro:
        "Exploring how climate change is affecting various sectors in Ethiopia.",
      image: images.CategoryNewsFullEconomyPlaceholder,
      imageCaption: "Climate change impact",
      date: "May 31, 2024",
      category: "Climate Change",
    },
    {
      title: "Inclusive Economic Growth in Ethiopia",
      intro:
        "Efforts to ensure inclusive economic growth in Ethiopia are gaining momentum.",
      image: images.CategoryNewsFullEconomyPlaceholder,
      imageCaption: "Inclusive economic growth",
      date: "June 1, 2024",
      category: "Inclusive Economies",
    },
  ];

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Arthy</title>
        <meta
          name="description"
          content="Stay updated on Ethiopian export news, services, and resources. Explore the 'Addis Chamber export portal for trade facilitation' for business and trade opportunities."
        />
        <meta
          name="keywords"
          content="Ethiopian export, Addis Chamber, trade opportunities, export news, business services, Ethiopia"
        />
        <meta
          name="author"
          content="Addis Chamber export portal for trade facilitation"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="index, follow" />
        <meta name="theme-color" content="#0077B5" />
        <meta
          property="og:title"
          content="Ethiopian Export and Addis Chamber - Your App Title"
        />
        <meta
          property="og:description"
          content="Stay updated on Ethiopian export news, services, and resources. Explore the Addis Chamber for business and trade opportunities."
        />
        <meta
          property="og:image"
          content="https://example.com/your-image.jpg"
        />{" "}
        {/* Add a relevant image URL */}
        <meta property="og:url" content="https://your-website-url.com" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />{" "}
        {/* Add your Twitter handle */}
        <meta
          name="twitter:title"
          content="Ethiopian Export and Addis Chamber - Your App Title"
        />
        <meta
          name="twitter:description"
          content="Stay updated on Ethiopian export news, services, and resources. Explore the Addis Chamber for business and trade opportunities."
        />
        <link rel="canonical" href="https://your-website-url.com" />
      </Helmet>

      <div>
        <TitleTop />
        <LogoHead />
      </div>
      <Header />
      <Advertisements position="header" items={sidebarAds} />
      <Stack
        direction="row"
        spacing={3}
        style={{
          width: "100%",
          padding: "0% 10%",
          marginTop: "5%",
          justifyContent: "center",
          alignItems: "flex-start",
        }}>
        {/* <Advertisements position="sidebar" items={sidebarAds} /> */}
        <Stack direction="column" spacing={2}>
          {" "}
          {/* <TopNewsToday /> */}
          <TrendingNews />
          <Advertisements position="sidebar" items={sidebarAds} />
        </Stack>
        <TopStory
          mainArticle={sampleArticle}
          smallerArticles={smallerArticles}
        />

        {/* <TrendingNews /> */}
      </Stack>
      <Stack
        direction="row"
        spacing={3}
        style={{
          width: "100%",
          padding: "0% 10%",
          // marginTop: "5%",
          justifyContent: "center",
          alignItems: "flex-start",
        }}>
        <CategoryNewsLine
          category="Business"
          articles={categoryArticlesLine2}
          category2="Editorial"
          articles2={categoryArticlesLine}
        />
      </Stack>
      <Stack
        direction="row"
        spacing={3}
        style={{
          width: "100%",
          padding: "0% 10%",
          // marginTop: "5%",
          justifyContent: "center",
          alignItems: "flex-start",
        }}>
        <Stack direction="column" spacing={2}>
          <TopNewsToday />
          <MonthlyEdition
            imageUrl={images.MonthlyEditionPlaceholder}
            month="JUNE"
          />
        </Stack>
        <CategoryNewsFull
          categoryTitle="Technology"
          articles={categoryArticlesFull}
        />
      </Stack>
      <Stack direction="column" mt={5}>
        <Advertisements position="header" items={sidebarAds} />
      </Stack>
      <Stack
        direction="row"
        spacing={3}
        style={{
          width: "100%",
          padding: "0% 10%",
          // marginTop: "5%",
          justifyContent: "center",
          alignItems: "flex-start",
        }}>
        <Stack direction="column" spacing={2}>
          <MostReadThisMonth />
          <Advertisements position="sidebar" items={sidebarAds} />
        </Stack>
        <CategoryNewsFull
          categoryTitle="Economy"
          articles={categoryArticlesFullEconomy}
        />
      </Stack>
      <Stack
        direction="row"
        spacing={3}
        style={{
          width: "100%",
          padding: "0% 10%",
          marginTop: "2%",
          justifyContent: "center",
          alignItems: "flex-start",
        }}>
        <EventsSection />
      </Stack>
      <Stack
        direction="row"
        spacing={3}
        style={{
          width: "100%",
          padding: "0% 10%",
          margin: "5% 0%",

          justifyContent: "center",
          alignItems: "flex-start",
        }}>
        <YoutubeChannelFrame />
      </Stack>
      <Stack
        direction="row"
        spacing={3}
        style={{
          width: "100%",
          padding: "0% 10%",
          marginTop: "2%",
          justifyContent: "center",
          alignItems: "flex-start",
        }}>
        <MonthlyPublications />
      </Stack>
      <BackToTop />

      <Footer />
    </div>
  );
};

export default HomePage;
