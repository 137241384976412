import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent } from "@mui/material";
import { Typography, Divider } from "@material-ui/core";
import { Skeleton } from "@mui/lab";
import { colorTags } from "../../../constants/variants";
import { useTranslation } from "react-i18next";
import {
  useGetTrendingArticlesQuery,
  useGetArticlesQuery,
} from "../../../features/api/apiSlice";
const useStyles = makeStyles((theme) => ({
  trendingNews: {
    width: 300,
    border: "1px solid lightgrey",
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      // border: "1px solid blue",
      padding: theme.spacing(1),
    },
  },
  header: {
    fontSize: 18,
    fontWeight: "bold",
    color: colorTags.PRIMARY,
  },
  card: {
    backgroundColor: "transparent",
    boxShadow: "none",
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(0.5),
      padding: 0,
    },
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
  },
  description: {
    fontSize: 18,
    marginTop: theme.spacing(0.5),
    fontFamily: "Times-new-roman",
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    backgroundColor: colorTags.TERTIARY,
  },
}));

const TrendingNews = () => {
  const classes = useStyles();
  const news = [
    {
      title: "News 1",
      description: "Ethiopian Airlines Expands Fleet with New Airbus A350",
    },
    {
      title: "News 2",
      description: "Ethiopia Launches Massive Reforestation Campaign",
    },
    {
      title: "News 3",
      description: "Grand Ethiopian Renaissance Dam Reaches New Milestone",
    },
    {
      title: "News 4",
      description: "Ethiopia's Coffee Exports Hit Record High",
    },
    {
      title: "News 5",
      description: "Ethiopia Hosts African Union Summit on Peace and Security",
    },
  ];
  const newsAm = [
    {
      title: "News 1",
      description: "ኢትዮጵያ አየር መንገድ አዳዲስ ኤርባስ A350 እንዲሰካ ጨምሯል",
    },
    {
      title: "News 2",
      description: "ኢትዮጵያ የልክ አስተዳደር የተወሰነ እንቅስቃሴ መጀመሪያ እንደመስራች",
    },
    { title: "News 3", description: "ታላቁ የኢትዮጵያ እንግዳ ተልዕኮ አዲስ አቅጣጫ ደርሷል" },
    { title: "News 4", description: "የኢትዮጵያ ቡና ላክ እንደ አሁን የተባረከ ነው" },
    {
      title: "News 5",
      description: "ኢትዮጵያ የአፍሪካ ህብረት የሰላም እና ደህንነት ጉባኤን አስተናግዳለች",
    },
  ];
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  // Trending Today
  const {
    data: trendingData,
    isLoading: trendingLoading,
    isError: trendingError,
  } = useGetTrendingArticlesQuery({ lang: "en" });
  const {
    data: trendingDataAm,
    isLoading: trendingAmLoading,
    isError: trendingAmError,
  } = useGetTrendingArticlesQuery({ lang: "am" });

  const {
    data: allArticlesDataAm,
    isLoading: allArticlesAmLoading,
    isError: allArticlesAmError,
    error: errorAllArticlesAm,
  } = useGetArticlesQuery({ lang: "am" });

  const {
    data: allArticlesData,
    isLoading: allArticlesLoading,
    isError: allArticlesError,
    error: errorAllArticles,
  } = useGetArticlesQuery({ lang: "en" });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={classes.trendingNews}>
      <Typography className={classes.header}>{t("Trending")}</Typography>
      <Divider className={classes.divider} />
      {loading
        ? Array?.from(new Array(5))?.map((_, index) => (
            <Card
              key={index}
              className={classes.card}
              sx={{
                boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 2px 0px",
              }}>
              <CardContent
                style={{
                  padding: "10px",
                }}>
                <Skeleton variant="text" height={40} />
                <Skeleton variant="text" height={20} />
              </CardContent>
            </Card>
          ))
        : (currentLanguage === "am" ? allArticlesDataAm : allArticlesData)
            ?.slice(0, 4)
            // trendingData && []
            // : trendingData && []
            ?.map((item, index) => (
              <Card
                key={index}
                className={classes.card}
                sx={{
                  boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 2px 0px",
                }}>
                <CardContent
                  style={{
                    padding: "10px",
                  }}>
                  <Typography
                    className={classes.description}
                    style={{ fontWeight: "bold" }}>
                    {/* {item?.description} */}
                    {item?.title}
                  </Typography>
                </CardContent>
              </Card>
            ))}
    </div>
  );
};

export default TrendingNews;
