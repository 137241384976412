import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import TwitterIcon from "@material-ui/icons/Twitter";
import TelegramIcon from "@material-ui/icons/Telegram";
import FacebookIcon from "@material-ui/icons/Facebook";
import YouTubeIcon from "@material-ui/icons/YouTube";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import { format } from "date-fns";
import {
  WiDaySunny,
  WiCloudy,
  WiRain,
  WiSnow,
  WiThunderstorm,
} from "react-icons/wi";
import { Switch } from "../../../language-switch/Switch";
import { colorTags } from "../../../constants/variants";
import { useNavigate, Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    height: "35px",
    backgroundColor: "#000",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),

    [theme.breakpoints.down(600)]: {
      fontSize: "0.8rem",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      height: "45px",
      // flexDirection: "column",
      // alignItems: "flex-start",
    },
    [theme.breakpoints.down(370)]: {
      fontSize: "0.7rem",
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
  },
  contactInfo: {
    display: "flex",
    alignItems: "center",
    color: "#efefef",
  },
  mainImage: {
    width: "25px",
    height: "25px",
    color: "#fff",
  },
  icon: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    color: "#fff",
    fontSize: "1rem",
    transition: "color 0.3s",
    "&:hover": {
      color: colorTags.SECONDARY,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: "0.8rem",
      marginRight: theme.spacing(0.5),
      marginLeft: theme.spacing(0.5),
    },
  },
  iconYoutube: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    color: "#fff",
    fontSize: "1rem",
    transition: "color 0.3s",
    "&:hover": {
      color: "#FF0000",
    },
    display: "none",
    [theme.breakpoints.down(600)]: {
      fontSize: "0.8rem",
      marginRight: theme.spacing(0.5),
      marginLeft: theme.spacing(0.5),
    },
  },
  mainTitle: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    color: "#efefef",
    marginLeft: "5px",
    [theme.breakpoints.down(600)]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down(370)]: {
      fontSize: "0.9rem",
    },
  },
  mail: {
    display: "flex",
    color: "#efefef",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  socialIcons: {
    display: "flex",
    alignItems: "center",
    color: "#efefef",
    [theme.breakpoints.down(600)]: {
      marginTop: theme.spacing(1),
      display: "none",
    },
  },
  langNcontact: {
    display: "flex",
    alignItems: "center",
    color: "#efefef",
    [theme.breakpoints.down(600)]: {
      marginTop: theme.spacing(1),
      // display: "none",
    },
  },
  dateWeather: {
    display: "flex",
    alignItems: "center",
    color: "#efefef",
    [theme.breakpoints.down(600)]: {
      // flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  weatherIcon: {
    marginRight: theme.spacing(1),
    fontSize: "1rem",
    [theme.breakpoints.down(600)]: {
      fontSize: "1rem",
      marginRight: theme.spacing(0.5),
    },
  },

  link: {
    color: "white",
    display: "flex",
    alignItems: "center",
  },
}));

const weatherIcons = {
  Clear: <WiDaySunny />,
  Sunny: <WiDaySunny />,
  Cloudy: <WiCloudy />,
  Overcast: <WiCloudy />,
  Rain: <WiRain />,
  Drizzle: <WiRain />,
  Showers: <WiRain />,
  Snow: <WiSnow />,
  Thunderstorm: <WiThunderstorm />,
};

const Title = ({ text }) => {
  const classes = useStyles();
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [date, setDate] = useState(format(new Date(), "EEEE, MMMM d, yyyy"));
  const [weather, setWeather] = useState("");
  const isMobile = useMediaQuery("(max-width: 600px)");
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const checkScreenSize = () => {
    setIsSmallScreen(window.innerWidth <= 500);
  };

  const fetchWeather = async () => {
    const city = "Addis Ababa";
    const url = `https://wttr.in/${city}?format=%C+%t`;

    try {
      const response = await fetch(url);
      const data = await response.text();
      setWeather(data);
    } catch (error) {
      console.error("Error fetching weather data", error);
      setWeather("Unable to fetch weather data");
    }
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    // Fetch weather data on component mount
    fetchWeather();

    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  const getWeatherIcon = (weatherDescription) => {
    for (const [key, icon] of Object.entries(weatherIcons)) {
      if (weatherDescription.includes(key)) {
        return icon;
      }
    }
    return <WiDaySunny />;
  };

  return (
    <div className={classes.titleContainer}>
      <div className={classes.dateWeather}>
        <Typography
          variant="body1"
          style={{
            marginRight: "20px",
            fontSize: isSmallScreen ? "0.6rem" : "0.7rem",
          }}>
          {date}
        </Typography>
        <Typography
          variant="body1"
          style={{
            marginRight: "5px",
            fontSize: isSmallScreen ? "0.6rem" : "0.7rem",
            color: "#fff",
          }}>
          {getWeatherIcon(weather)}
        </Typography>
        <Typography
          variant="body1"
          style={{
            marginRight: "20px",
            fontSize: isSmallScreen ? "0.6rem" : "0.7rem",
          }}>
          {weather}
        </Typography>
        <Link
          // onClick={() => navigate("/login")}
          to="/login"
          // color="inherit"
          className={classes.link}>
          {isMobile ? null : `${t("Log in")}`}
        </Link>
      </div>
      <div style={{ display: "flex" }}>
        <div className={classes.langNcontact}>
          <Switch />

          <a
            href="tel:+251115518055"
            aria-label="Call"
            style={{ marginRight: "10px" }}>
            <PhoneIcon className={classes.icon} />
          </a>
          <a
            href="info@addischamber.com"
            aria-label="Email"
            style={{ marginRight: "10px" }}>
            <EmailIcon className={classes.icon} />
          </a>
        </div>

        <div className={classes.socialIcons}>
          <a
            href="https://twitter.com/aaccsa?lang=en"
            target="_blank"
            rel="noopener noreferrer">
            <TwitterIcon className={classes.icon} />
          </a>
          <a
            href="https://t.me/addis_chamber"
            target="_blank"
            rel="noopener noreferrer">
            <TelegramIcon className={classes.icon} />
          </a>
          <a
            href="https://www.facebook.com/addischamber/"
            target="_blank"
            rel="noopener noreferrer">
            <FacebookIcon className={classes.icon} />
          </a>
          <a
            href="https://www.youtube.com/addischamber"
            target="_blank"
            rel="noopener noreferrer">
            <YouTubeIcon className={classes.iconYoutube} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Title;
