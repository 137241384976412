import React, { useState, useEffect } from "react";
import { makeStyles, TextField, Typography, Avatar } from "@material-ui/core";
import { Grid, Button } from "@mui/material";
import { images } from "../../../assets";
import { BorderColor } from "@mui/icons-material";
import {
  useGetUserByIdQuery,
  useGetUserRoleByIdQuery,
  useUpdateUserRequestMutation,

  // useGetUserRolesQuery,
} from "../../../features/api/apiSlice";
import { useNavigate } from "react-router-dom";
import { FILE_LINK_URL, BASE_FILE_URL } from "../../../constants/apiTags";
import { set } from "lodash";
import { colorTags } from "../../../constants/variants";
import { toast } from "react-toastify";
import ChangePasswordModal from "../../../components/changePasswordModal";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(9),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(0),
    background: "#fff",
    marginBottom: theme.spacing(8),
  },
  formContainer: {
    width: "100%",
    padding: theme.spacing(0),
    boxShadow:
      "rgb(204, 219, 232) 6px 6px 9px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset",
    borderRadius: theme.spacing(1),
    background: "#fff",
    display: "flex",
  },
  avatarContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing(5),
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    cursor: "pointer",
  },
  imageInput: {
    display: "none",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  imageSection: {
    flex: "0 0 20%", // Fix width to 20%
    backgroundImage: `url(${images.ProfilePagePlaceholder})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  gridSection: {
    flex: "0 0 80%", // Fix width to 80%
    padding: "5%",
  },
  TextField: {
    margin: theme.spacing(2),
    color: "#03a9f4",
  },
}));

const EditProfile = () => {
  const classes = useStyles();
  const [token, setToken] = useState(localStorage.getItem("authToken"));
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [userName, setUserName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [avatar, setAvatar] = useState(null);
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const uploadImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("files", file);

      const response = await fetch(FILE_LINK_URL, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        throw new Error("Image upload failed");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  };

  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phone: "",
    bio: "",
    facebook: "",
    twitter: "",
    linkedin: "",
    industry: "",
    exports: "",
    destinations: "",
    activeSince: new Date(),
    profilePicture: null,
    status: "",
    role: "",
  });

  const user = JSON.parse(localStorage.getItem("user"));

  const {
    data: profileData,
    isLoading: isLoadingProfileData,
    isError: isErrorProfileData,
    error: errorProfileData,
  } = useGetUserByIdQuery({ id: user && user?.user_id });

  const {
    data: roleData,
    isLoading: isLoadingRoleData,
    isError: isErrorRoleData,
    error: errorRoleData,
  } = useGetUserRoleByIdQuery({ id: user && user?.role_id });

  const [profilePicturePreview, setProfilePicturePreview] = useState(null);
  const [
    updateUserRequest,
    {
      isLoading: updateUserLoading,
      isError: isErrorUpdateUser,
      error: updateUserError,
    },
  ] = useUpdateUserRequestMutation();
  useEffect(() => {
    if (profileData) {
      setName(profileData?.fullName ?? "");
      setUserName(profileData?.user_name ?? "");
      setEmail(profileData?.email);
      setPhone(profileData?.phone);
    }
  }, [profileData]);

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setUserData((prevUserData) => ({
  //     ...prevUserData,
  //     [name]: value,
  //   }));
  // };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setAvatar(event.target.files);

    setUserData((prevUserData) => ({
      ...prevUserData,
      profilePicture: file,
    }));

    const reader = new FileReader();
    reader.onloadend = () => {
      setProfilePicturePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleEditProfile = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const data = {
      id: user?.user_id,
      user_name: userName,
      role_id: user?.role_id,

      fullName: name,
      email,
      phone,
      status: profileData?.status,
    };

    if (avatar) {
      try {
        const imageResponse = await uploadImage(avatar[0]);
        data.avatar = imageResponse?.filenames[0] ?? profileData?.Avatar;
      } catch (error) {
        toast.error("Error uploading avatar");
        setIsLoading(false);

        return;
      }
    }

    await updateUserRequest(data)
      .then((res) => {
        if (res.data) {
          toast.success("User Updated Successfully");
        }
      })
      .catch((err) => {
        toast.error("Error Updating User");
      });
    setIsLoading(false);
  };

  const handleCancelEditProfile = () => {
    // console.log("Updated User Data:", userData);
    navigate("/dashboard/profile-detail");
  };

  return (
    <div style={{ margin: "5%" }}>
      <div className={classes.root}>
        <div className={classes.formContainer}>
          <div className={classes.gridSection}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <div className={classes.avatarContainer}>
                  <label htmlFor="image-upload">
                    {profilePicturePreview ? (
                      <Avatar
                        alt={profileData?.fullName ?? ""}
                        src={profilePicturePreview}
                        className={classes.avatar}
                      />
                    ) : (
                      <Avatar
                        alt={profileData?.fullName ?? ""}
                        src={
                          profileData?.avatar
                            ? BASE_FILE_URL + profileData?.avatar
                            : userData.profilePicture
                        }
                        className={classes.avatar}
                      />
                    )}
                    <input
                      type="file"
                      id="image-upload"
                      accept="image/*"
                      className={classes.imageInput}
                      onChange={handleImageChange}
                    />
                  </label>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={8}>
                <Typography variant="h5">Edit Profile</Typography>
                <TextField
                  label="Name"
                  name="name"
                  style={{ color: "#03a9f4" }}
                  className={classes.TextField}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  fullWidth
                />
                <TextField
                  label="Email"
                  name="email"
                  className={classes.TextField}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                />
                {/* <TextField
                  label="Username"
                  name="userName"
                  style={{ color: "#03a9f4" }}
                  className={classes.TextField}
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  fullWidth
                /> */}
                <TextField
                  label="Phone"
                  name="phone"
                  value={phone}
                  className={classes.TextField}
                  onChange={(e) => setPhone(e.target.value)}
                  fullWidth
                />

                {/* <TextField
                  label="Status"
                  name="status"
                  value={userData.status}
                  className={classes.TextField}

                  onChange={handleInputChange}
                  fullWidth
                />
                <TextField
                  label="Role"
                  name="role"
                  value={userData.role}
                  className={classes.TextField}

                  onChange={handleInputChange}
                  fullWidth
                /> */}
              </Grid>
              <div
                style={{
                  // backgroundColor: "red",
                  width: "100%",
                  // height: "50px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}>
                <Button
                  variant="contained"
                  disabled={isLoading}
                  style={{
                    marginTop: "15px",
                    marginLeft: "15px",
                    backgroundColor: colorTags.TERTIARY,
                  }}
                  onClick={() => setPasswordModalOpen(true)}>
                  Change Password
                </Button>
              </div>
              <div
                style={{
                  // backgroundColor: "red",
                  width: "100%",
                  // height: "50px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}>
                <Button
                  variant="contained"
                  disabled={isLoading}
                  style={{
                    marginTop: "15px",
                    marginLeft: "15px",
                    backgroundColor: colorTags.TERTIARY,
                  }}
                  onClick={handleEditProfile}>
                  Save
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  style={{ marginTop: "15px", marginLeft: "15px" }}
                  onClick={handleCancelEditProfile}>
                  Cancel
                </Button>
              </div>
            </Grid>
          </div>
          <div className={classes.imageSection}></div>
        </div>
      </div>
      <ChangePasswordModal open={passwordModalOpen} data={profileData} />
    </div>
  );
};

export default EditProfile;
