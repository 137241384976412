import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Divider, Chip } from "@mui/material";
import { Typography } from "@material-ui/core";
import { images } from "../../../assets";

const useStyles = makeStyles((theme) => ({
  trendingNews: {
    width: 300,
    border: "1px solid lightgrey",
    padding: theme.spacing(2),
  },
  header: {
    fontSize: 18,
    fontWeight: "bold",
  },
  card: {
    backgroundColor: "transparent",
    boxShadow: "none",
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  content: {
    flex: 1,
  },
  title: {
    fontSize: 18,
    lineHeight: 1.2,
    fontFamily: "Times-new-roman",

    fontWeight: "bold",
  },
  description: {
    fontSize: 14,
    marginTop: theme.spacing(0.5),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  chip: {
    position: "absolute",
    top: theme.spacing(1),
    left: theme.spacing(1),
    zIndex: 1,
  },
  image: {
    width: 40,
    height: 40,
    marginLeft: theme.spacing(2),
    borderRadius: "5px",
    objectFit: "cover",
  },
}));

const TopNewsToday = () => {
  const classes = useStyles();
  const news = [
    {
      title: "Brief description of news 1.",
      description: "Brief description of news 1.",
      category: "Category 1",
      image: images.TopNewsStoryPlaceholder2,
    },
    {
      title: "Brief description of news 2",
      description: "Brief description of news 2.",
      category: "Category 2",
      image: images.TopNewsStoryPlaceholder2,
    },
    {
      title: "Brief description of news 3",
      description: "Brief description of news 3.",
      category: "Category 3",
      image: images.TopNewsStoryPlaceholder2,
    },
    {
      title: "Brief description of news 4",
      description: "Brief description of news 4.",
      category: "Category 4",
      image: images.TopNewsStoryPlaceholder2,
    },
    {
      title: "Brief description of news 5",
      description: "Brief description of news 5.",
      category: "Category 5",
      image: images.TopNewsStoryPlaceholder2,
    },
  ];

  return (
    <div className={classes.trendingNews}>
      <Typography className={classes.header}>Top News Today</Typography>
      <Divider className={classes.divider} />
      {news.map((item, index) => (
        <Card
          key={index}
          className={classes.card}
          style={{ boxShadow: "none" }}>
          <CardContent className={classes.content}>
            {/* <Chip
              label={item.category}
              color="primary"
              className={classes.chip}
              size="small"
            /> */}
            <Typography
              className={classes.title}
              style={{
                fontWeight: "bold",
              }}>
              {item.title}
            </Typography>
            {/* <Typography className={classes.description}>
              {item.description}
            </Typography> */}
          </CardContent>
          <img src={item.image} alt={item.title} className={classes.image} />
        </Card>
      ))}
    </div>
  );
};

export default TopNewsToday;
