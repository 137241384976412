import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Paper,
  MenuItem,
  IconButton,
  makeStyles,
  useMediaQuery,
  useTheme,
  InputBase,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import Sidebar from "./Sidebar2"; // Importing Sidebar component
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { colorTags } from "../../../constants/variants";
import { useGetCategoriesQuery } from "../../../features/api/apiSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
  },
  title: {
    flexGrow: 1,
  },
  menuCard: {
    position: "absolute",
    top: theme.spacing(5.9),
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(2),
    backgroundColor: "#f5f5f5",
    boxShadow: theme.shadows[0.3],
    zIndex: 10,
    borderTopColor: "#BBBBBB",
  },
  menuSection: {
    marginLeft: theme.spacing(35),
  },
  menuTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(0.1),
    fontSize: "14px",
    cursor: "pointer",
  },
  menuItem: {
    fontSize: "14px",
    lineHeight: "0.8rem",
    display: "flex",
    justifyContent: "flex-start",
    cursor: "pointer",
  },
  button: {
    height: "100%",
    backgroundColor: "transparent",
    margin: "0px",
    fontSize: "13px",
    color: "#373A40",
    "&:hover": {
      backgroundColor: "#f5f5f5",
      color: colorTags.PRIMARY,
    },
  },
  highlightedButton: {
    backgroundColor: "#fff",
    borderTop: "1px solid #444",
    color: colorTags.PRIMARY,
    borderRightColor: "1px solid #333",
    borderLeftColor: "1px solid #333",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  searchButton: {},
  searchInput: {
    marginLeft: theme.spacing(0),
    transition: theme.transitions.create("width"),
    borderBottom: "1px solid grey",
    width: "200px",
    "&:focus": {
      width: "200px",
    },
  },
}));

const Header = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [hoveredMenu, setHoveredMenu] = useState(null);
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const currentLanguage = i18n.language;

  const handleMouseEnter = (menu) => {
    setHoveredMenu(menu);
  };

  const handleMouseLeave = () => {
    setHoveredMenu(null);
  };

  const toggleSearch = () => {
    setSearchOpen(!isSearchOpen);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchKeyPress = (event) => {
    if (event.key === "Enter") {
      navigate(`/search?query=${searchQuery}`);
    }
  };

  const convertToSlug = (text) => {
    return text.toLowerCase().replace(/\s+/g, "-");
  };

  const {
    data: menuData,
    isLoading: isLoadingMenu,
    isError: isErrorMenu,
    error: errorMenu,
  } = useGetCategoriesQuery();

  const constantMenuItems = [
    { id: "home", name: "Home", nameAm: "መነሻ ገጽ" },
    { id: "events", name: "Events", nameAm: "ኩነቶች" },
    {
      id: "addis-chamber-media",
      name: "Addis Chamber Media",
      nameAm: "አዲስ ቻምበር ሚዲያ",
    },
    // { id: "publications", name: "Publications" },
    { id: "contact", name: "Contact", nameAm: "አግኙን" },
  ];

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        style={{
          backgroundColor: "#ffff",
          boxShadow: "none",
          borderTop: "1px solid lightgrey",
        }}>
        <Toolbar
          style={{
            backgroundColor: "#ffff",
            boxShadow: "none",
            borderTop: "1px solid lightgrey",
            padding: "0px",
            height: "30",
            display: "flex",
            justifyContent: "center",
          }}>
          {isMobile ? (
            <Sidebar menuData={menuData && menuData} />
          ) : (
            <>
              {[...constantMenuItems, ...(menuData || [])].map((menu) => (
                <Button
                  key={menu.id}
                  className={`${classes.button} ${
                    location.pathname.includes(convertToSlug(menu.name))
                      ? classes.highlightedButton
                      : ""
                  }`}
                  onClick={() =>
                    menu.name === "Home"
                      ? navigate(`/`)
                      : menu.name === "Addis Chamber Media"
                      ? navigate(`/addis-chamber-media`)
                      : menu.name === "Contact"
                      ? navigate(`/contact-us`)
                      : menu.name === "Events"
                      ? navigate(`/events`)
                      : navigate(`/news-category/${convertToSlug(menu.name)}`)
                  }
                  onMouseEnter={() => handleMouseEnter(menu)}
                  onMouseLeave={handleMouseLeave}
                  style={{
                    fontSize: currentLanguage === "am" && "18px",
                  }}>
                  {currentLanguage === "am" ? menu.nameAm : menu.name}
                </Button>
              ))}
              <div className={classes.searchButton}>
                <IconButton onClick={toggleSearch}>
                  <SearchIcon />
                </IconButton>
                {isSearchOpen && (
                  <InputBase
                    placeholder="Search..."
                    className={classes.searchInput}
                    value={searchQuery}
                    onChange={handleSearchChange}
                    onKeyPress={handleSearchKeyPress}
                  />
                )}
              </div>
            </>
          )}
        </Toolbar>
      </AppBar>
      {hoveredMenu && !isMobile && hoveredMenu.subcategories && (
        <Paper
          className={classes.menuCard}
          onMouseEnter={() => setHoveredMenu(hoveredMenu)}
          onMouseLeave={handleMouseLeave}>
          {hoveredMenu.subcategories.map((sub) => (
            <div key={sub.id} className={classes.menuSection}>
              <Typography
                className={classes.menuTitle}
                onClick={() =>
                  navigate(`/news-category/${convertToSlug(sub.name)}`)
                }
                style={{
                  fontSize: currentLanguage === "am" && "16px",
                }}>
                {t(sub.name)}
              </Typography>
              <MenuItem
                className={classes.menuItem}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  paddingLeft: "0px",
                  fontSize: currentLanguage === "am" && "15px",
                }}
                onClick={() =>
                  navigate(`/news-category/${convertToSlug(sub.name)}`)
                }>
                {t(sub.name)}
              </MenuItem>
            </div>
          ))}
        </Paper>
      )}
    </div>
  );
};

export default Header;
