import React, { useState } from "react";
import {
  makeStyles,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import { TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { colorTags } from "../../constants/variants";
import { useNavigate } from "react-router-dom";
import { authService } from "../../features/auth2/authService";
import { useDispatch } from "react-redux";
import { login } from "../../features/auth2/authSlice";
import { toast } from "react-toastify";
import { images } from "../../assets/index";
import Header from "../Home/Header/Header2";
import Footer from "../Home/Footer/Footer2";
import LogoHead from "../Home/Header/LogoHead2";
import Title from "../Home/Header/Title2";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    minHeight: "90vh",
    paddingTop: "13%",
    // backgroundColor: "#fff",
    // backgroundColor: "#fff",

    maxWidth: "100%",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "23%",
    },
  },
  card: {
    display: "flex",

    width: "30%",
    maxHeight: "350px",
    borderRadius: "0px",
    backgroundColor: "rgba(255,255,255,0.6)",
    backdropFilter: "blur(10px)",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "90%",
    },
    // boxShadow:
    //   "rgb(204, 219, 232) 6px 6px 9px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset",
  },
  cardMedia: {
    width: "5%", // Reducing the width of the image
    // height: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: 200,
    },
  },
  cardContent: {
    width: "90%", // Adjusting the content to take up more space as the image width is reduced
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  inputField: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: colorTags.PRIMARY,
    "&:hover": {
      backgroundColor: colorTags.SECONDARY,
    },
  },
  title: {
    marginBottom: theme.spacing(4),
  },
}));

const Login = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [load, setLoad] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoad(true);
    const data = {
      email: email,
      password: password,
    };
    try {
      const response = await authService.login(data);
      dispatch(login({ user: response?.user, accessToken: response?.token }));
      localStorage.setItem("authToken", response?.token);
      // localStorage.setItem("user", JSON.stringify(response.user[0]));
      // localStorage.setItem("user", JSON.stringify(response.user));
      // localStorage.setItem("user", response?.user);
      localStorage.setItem("user", JSON.stringify(response.user));

      toast.success("Welcome");
      // if (response.user && response.user.isAdmin) {
      if (response.user) {
        // navigate(`/dashboard`);
        window.open(`/dashboard`);
      } else {
        // navigate(`/profile/${response.user[0].id}`);
        // navigate(`/profile/${response.user.user_id}`);
        navigate(`/`);
      }
    } catch (error) {
      console.error(error);
      toast.error("Login Failed");
    }
    setLoad(false);
  };

  return (
    <div
      style={{
        backgroundImage: `url(${images.LoginBackground})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}>
      <Title />
      <LogoHead />
      <Header />
      <Container className={classes.root}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Typography
              variant="h5"
              className={classes.title}
              style={{ color: colorTags.TERTIARY }}>
              Login
            </Typography>
            <form onSubmit={handleLogin}>
              <TextField
                type="email"
                label="Email"
                value={email}
                onChange={handleEmailChange}
                fullWidth
                className={classes.inputField}
                variant="outlined"
                color="success"
              />
              <TextField
                type="password"
                label="Password"
                value={password}
                color="success"
                onChange={handlePasswordChange}
                fullWidth
                style={{ margin: "15px 0px" }}
                className={classes.inputField}
                variant="outlined"
              />
              <LoadingButton
                loading={load}
                type="submit"
                variant="contained"
                // variant="outlined"
                color="success"
                fullWidth
                className={classes.button}>
                Sign In
              </LoadingButton>
            </form>
            <Typography variant="body2">
              <Button
                onClick={() => navigate("/forgot-password")}
                className={classes.otherText}>
                Forgot password?
              </Button>
            </Typography>
          </CardContent>
          <CardMedia
            component="img"
            alt="Login Background"
            image={images.LoginBackground}
            className={classes.cardMedia}
          />
        </Card>
      </Container>
      <Footer />
    </div>
  );
};

export default Login;
