import React, { useState } from "react";
import {
  Paper,
  Typography,
  makeStyles,
  Divider,
  Card,
} from "@material-ui/core";
import { useMediaQuery, Stack } from "@mui/material";
import Footer from "../Footer/Footer2";
import Header from "../Header/Header2";
import LogoHead from "../Header/LogoHead2";
import Title from "../Header/Title2";
import { images } from "../../../assets";
import { colorTags } from "../../../constants/variants";

import { useGetYoutubePlaylistQuery } from "../../../features/api/apiSlice";
import { useTranslation } from "react-i18next";
import { BASE_FILE_URL } from "../../../constants/apiTags";
import MonthlyPublications from "../NewsComponents2/MonthlyPublications";
import Events from "../NewsComponents2/EventsSection";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    boxShadow: "none",
    position: "relative",
    overflow: "hidden",
    width: "100%",
  },
  playlistCardContainer: {
    position: "relative",
    width: "100%",
    padding: theme.spacing(0),
    marginTop: theme.spacing(5),
    paddingRight: "0px",
    height: "620px",
    overflow: "hidden",
    display: "flex",
    borderRadius: "5px",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      height: "auto",
      flexDirection: "column",
    },
  },
  mainVideo: {
    width: "75%",
    marginRight: theme.spacing(2),
    height: "650px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: 0,
      height: "auto",
      marginBottom: theme.spacing(2),
    },
  },
  videoList: {
    width: "25%",
    overflowY: "scroll",
    height: "600px",
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "300px",
    },
  },
  videoItem: {
    display: "flex",
    marginBottom: theme.spacing(2),
    borderRadius: "10px",
    padding: "10px",
    cursor: "pointer",
    boxShadow: "rgba(255, 25, 0, 0.4) 0px 2px 4px 0px inset",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
    },
  },
  currentVideoItem: {
    backgroundColor: "rgba(255, 25, 0, 0.8)",
    boxShadow: "rgba(255, 255,255, 0.9) 0px 2px 4px 0px inset",
    color: "#fff",
  },
  thumbnail: {
    width: "90px",
    height: "60px",
    marginRight: theme.spacing(2),
    borderRadius: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "100px",
      height: "70px",
      borderRadius: "5px",
      marginRight: 0,
      marginBottom: theme.spacing(1),
    },
  },
  videoTitle: {
    fontWeight: "bold",
    textAlign: "left",
    fontSize: "0.9rem",
    color: theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
      marginLeft: "10px",
    },
  },
  videoLength: {
    textAlign: "left",
    fontSize: "0.8rem",
    color: theme.palette.text.secondary,
    position: "absolute",
    bottom: 0,
    right: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.7rem",
      marginLeft: "10px",
    },
  },
  mainVideoTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
    textAlign: "left",
    fontSize: "1.5rem",
    color: "#f00000",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
      marginBottom: theme.spacing(0),
    },
  },
  topTitle: {
    height: "40%",
    position: "relative",
    minHeight: "300px",
    borderRadius: "35px",
    width: "100%",
    backgroundImage: `url(${images.MediaPageCoverPlaceholder6})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    // backgroundImage: images.AdPlaceholderTop,
    // backgroundColor: "red",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100px",
      borderRadius: "15px",
    },
  },
  titleTextContainer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    borderRadius: "0px",
    padding: "10px",
    paddingRight: "13px",
    borderTopRightRadius: "15px",
    // backgroundColor: "#fff",
    backgroundColor: "rgba(255,255,255,0.3)",
    [theme.breakpoints.down("sm")]: {
      padding: "5px",
    },
  },
  titleText: {
    fontSize: "1.5rem",
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  categoryCardContainer: {
    marginTop: theme.spacing(8),
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      //   temporary adjustment till permanent solution is found
      display: "none",
    },
  },
  containerCard: {
    position: "relative",
    width: "200px",
    height: "150px",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  categoryCard: {
    position: "relative",
    width: "180px",
    height: "120px",
    borderRadius: "10px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    // boxShadow:
    //   "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      height: "100px",
      marginBottom: theme.spacing(2),
    },
  },
  categoryTextContainer: {
    position: "absolute",
    bottom: "20px",
    right: "0px",
    // backgroundColor: "rgba(255, 255, 255, 0.7)",
    backgroundColor: `rgba(${colorTags.TERTIARY_RGBA},0.5)`,

    backdropFilter: "blur(10px)",
    padding: "5px 20px",
    borderRadius: "5px",
  },
  categoryText: {
    // color: "#000",
    color: "#fff",

    fontWeight: "bold",
  },
}));

const YouTubePlaylist = () => {
  const classes = useStyles();
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const { t, i18n } = useTranslation();

  const isMobile = useMediaQuery("(max-width: 600px)");
  const {
    data: playlistData,
    isLoading: playlistLoading,
    isError: playlistError,
  } = useGetYoutubePlaylistQuery();

  const videos = [
    {
      id: 1,
      title: "WEEK 229 የመንግስት እና የግሉ ዘርፍ ትብብር በአነስተኛ ኢንተፕራይዞች",
      url: "https://www.youtube.com/embed/Lp_8vBf7as0",
      thumbnail: "https://img.youtube.com/vi/oY59wZdCDo0/0.jpg",
      length: "10:00",
    },
    {
      id: 2,
      title: "WEEK 225 የመንግስት እና የግሉ ዘርፍ ትብብር በአነስተኛ ኢንተፕራይዞች",
      url: "https://www.youtube.com/embed/5qap5aO4i9A",
      thumbnail: "https://img.youtube.com/vi/5qap5aO4i9A/0.jpg",
      length: "2:00:00",
    },
    {
      id: 3,
      title: "WEEK 224 የመንግስት እና የግሉ ዘርፍ ትብብር በአነስተኛ ኢንተፕራይዞች",
      url: "https://www.youtube.com/embed/5qap5aO4i9A",
      thumbnail: "https://img.youtube.com/vi/5qap5aO4i9A/0.jpg",
      length: "3:00:00",
    },
    {
      id: 4,
      title: "WEEK 223 የመንግስት እና የግሉ ዘርፍ ትብብር በአነስተኛ ኢንተፕራይዞች",
      url: "https://www.youtube.com/embed/2OEL4P1Rz04",
      thumbnail: "https://img.youtube.com/vi/2OEL4P1Rz04/0.jpg",
      length: "1:00:00",
    },
    {
      id: 5,
      title: "WEEK 222 የመንግስት እና የግሉ ዘርፍ ትብብር በአነስተኛ ኢንተፕራይዞች",
      url: "https://www.youtube.com/embed/1ZYbU82GVz4",
      thumbnail: "https://img.youtube.com/vi/1ZYbU82GVz4/0.jpg",
      length: "8:00:00",
    },
    {
      id: 6,
      title: "WEEK 221 የመንግስት እና የግሉ ዘርፍ ትብብር በአነስተኛ ኢንተፕራይዞች",
      url: "https://www.youtube.com/embed/oY59wZdCDo0",
      thumbnail: "https://img.youtube.com/vi/oY59wZdCDo0/0.jpg",
      length: "10:00",
    },
    {
      id: 7,
      title: "WEEK 220 የመንግስት እና የግሉ ዘርፍ ትብብር በአነስተኛ ኢንተፕራይዞች",
      url: "https://www.youtube.com/embed/5qap5aO4i9A",
      thumbnail: "https://img.youtube.com/vi/5qap5aO4i9A/0.jpg",
      length: "2:00:00",
    },
    {
      id: 8,
      title: "Deep Focus Music for Studying",
      url: "https://www.youtube.com/embed/5qap5aO4i9A",
      thumbnail: "https://img.youtube.com/vi/5qap5aO4i9A/0.jpg",
      length: "3:00:00",
    },
    {
      id: 9,
      title: "Calm Piano Music for Sleep",
      url: "https://www.youtube.com/embed/2OEL4P1Rz04",
      thumbnail: "https://img.youtube.com/vi/2OEL4P1Rz04/0.jpg",
      length: "1:00:00",
    },
    {
      id: 10,
      title: "Ocean Waves Sounds",
      url: "https://www.youtube.com/embed/1ZYbU82GVz4",
      thumbnail: "https://img.youtube.com/vi/1ZYbU82GVz4/0.jpg",
      length: "8:00:00",
    },
  ];

  const newsCategories = [
    // {
    //   id: 1,
    //   title: "Politics",
    //   imageUrl: images.PoliticsCoverPlaceholder,
    // },
    {
      id: 1,
      title: "Business",
      imageUrl: images.BusinessCoverPlaceholder,
    },
    {
      id: 2,
      title: "Technology",
      imageUrl: images.TechnologyCoverPlaceholder,
    },
    {
      id: 3,
      title: "Sports",
      imageUrl: images.SportsCoverPlaceholder,
    },

    {
      id: 4,
      title: "Entertainment",
      imageUrl: images.EntertainmentCoverPlaceholder,
    },
  ];

  return (
    <div>
      <div>
        <Title />
        <LogoHead />
      </div>
      <Header />
      <Stack
        direction={isMobile ? "column" : "row"}
        spacing={3}
        style={{
          width: "100%",
          padding: isMobile ? "0 5%" : "0% 12%",
          marginTop: isMobile ? "0%" : "0%",
          justifyContent: isMobile ? "flex-start" : "center",
          alignItems: isMobile ? "center" : "flex-start",
        }}>
        <Card className={classes.topTitle}>
          <div className={classes.titleTextContainer}>
            <Typography variant="h6" className={classes.titleText}>
              Addis Chamber Tv
            </Typography>
          </div>
        </Card>
      </Stack>
      <Stack
        direction={isMobile ? "column" : "row"}
        spacing={3}
        style={{
          width: "100%",
          padding: isMobile ? "0 5%" : "0% 7%",
          marginTop: isMobile ? "2%" : "2%",
          justifyContent: isMobile ? "flex-start" : "center",
          alignItems: isMobile ? "center" : "flex-start",
        }}>
        <Paper className={classes.root}>
          <Divider
            style={{
              marginBottom: "10px",
              backgroundColor: "#FFA27F",
            }}
          />
          <Typography variant="h6" className={classes.mainVideoTitle}>
            {t("From Our YouTube Channel")}
          </Typography>
          <div className={classes.playlistCardContainer}>
            <div className={classes.mainVideo}>
              {playlistData && playlistData ? (
                <iframe
                  width="100%"
                  height={isMobile ? "300" : "650"}
                  style={{
                    borderRadius: "5px",
                  }}
                  src={playlistData && playlistData[currentVideoIndex]?.link}
                  title={playlistData && playlistData[currentVideoIndex]?.title}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen></iframe>
              ) : (
                <iframe
                  width="100%"
                  height={isMobile ? "300" : "500"}
                  style={{
                    borderRadius: "5px",
                  }}
                  src={playlistData && playlistData[currentVideoIndex]?.link}
                  title={playlistData && playlistData[currentVideoIndex]?.title}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen></iframe>
              )}
            </div>
            <div className={classes.videoList}>
              {playlistData &&
                playlistData?.map((video, index) => (
                  <div
                    key={video.id}
                    className={`${classes.videoItem} ${
                      index === currentVideoIndex
                        ? classes.currentVideoItem
                        : ""
                    }`}
                    onClick={() => setCurrentVideoIndex(index)}>
                    <img
                      src={BASE_FILE_URL + video.thumbnail}
                      alt={video.title}
                      className={classes.thumbnail}
                    />
                    <div style={{ position: "relative", width: "100%" }}>
                      <Typography
                        className={classes.videoTitle}
                        style={{
                          color: index === currentVideoIndex ? "#fff" : "",
                        }}>
                        {video.title}
                      </Typography>
                      <Typography
                        className={classes.videoLength}
                        style={{
                          color: index === currentVideoIndex ? "#ccc" : "",
                        }}>
                        {video.length}
                      </Typography>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </Paper>
      </Stack>
      {/* <Stack
        direction={isMobile ? "column" : "row"}
        className={classes.categoryCardContainer}
        style={{
          display: isMobile ? "none" : "",
        }}>
        {newsCategories.map((category) => (
          <div className={classes.containerCard}>
            <Card
              key={category.id}
              className={classes.categoryCard}
              style={{ backgroundImage: `url(${category.imageUrl})` }}></Card>
            <div className={classes.categoryTextContainer}>
              <Typography className={classes.categoryText}>
                {category.title}
              </Typography>
            </div>
          </div>
        ))}
      </Stack> */}
      <div style={{ padding: "0% 5%" }}>
        <MonthlyPublications />
        <Events />
      </div>
      <Footer />
    </div>
  );
};

export default YouTubePlaylist;
