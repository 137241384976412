import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import TwitterIcon from "@material-ui/icons/Twitter";
import TelegramIcon from "@material-ui/icons/Telegram";
import FacebookIcon from "@material-ui/icons/Facebook";
import YouTubeIcon from "@material-ui/icons/YouTube";
import { format } from "date-fns";
import {
  WiDaySunny,
  WiCloudy,
  WiRain,
  WiSnow,
  WiThunderstorm,
} from "react-icons/wi";
import { Switch } from "../../../language-switch/Switch";

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    height: "35px",
    backgroundColor: "#000",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),

    [theme.breakpoints.down(600)]: {
      fontSize: "0.8rem",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(2),
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down(370)]: {
      fontSize: "0.8rem",
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(1),
    },
  },
  contactInfo: {
    display: "flex",
    alignItems: "center",
    color: "#efefef",
  },
  mainImage: {
    width: "25px",
    height: "25px",
    color: "#fff",
  },
  icon: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    color: "#fff",
    fontSize: "1rem",
    transition: "color 0.3s",
    "&:hover": {
      color: "#028391",
    },
    [theme.breakpoints.down(600)]: {
      fontSize: "0.8rem",
    },
  },
  iconYoutube: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    color: "#fff",
    fontSize: "1rem",
    transition: "color 0.3s",
    "&:hover": {
      color: "#FF0000",
    },
    [theme.breakpoints.down(600)]: {
      fontSize: "0.8rem",
    },
  },
  mainTitle: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    color: "#efefef",
    marginLeft: "5px",
    [theme.breakpoints.down(600)]: {
      fontSize: "0.67rem",
    },
  },
  mail: {
    display: "flex",
    color: "#efefef",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  socialIcons: {
    display: "flex",
    alignItems: "center",
    color: "#efefef",
  },
  dateWeather: {
    display: "flex",
    alignItems: "center",
    color: "#efefef",
  },
  weatherIcon: {
    marginRight: theme.spacing(1),
    fontSize: "1.5rem",
    [theme.breakpoints.down(600)]: {
      fontSize: "1rem",
    },
  },
}));

const weatherIcons = {
  Clear: <WiDaySunny />,
  Sunny: <WiDaySunny />,
  Cloudy: <WiCloudy />,
  Overcast: <WiCloudy />,
  Rain: <WiRain />,
  Drizzle: <WiRain />,
  Showers: <WiRain />,
  Snow: <WiSnow />,
  Thunderstorm: <WiThunderstorm />,
};

const Title = ({ text }) => {
  const classes = useStyles();
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [date, setDate] = useState(format(new Date(), "EEEE, MMMM d, yyyy"));
  const [weather, setWeather] = useState("");

  const checkScreenSize = () => {
    setIsSmallScreen(window.innerWidth <= 500);
  };

  const fetchWeather = async () => {
    const city = "Addis Ababa";
    const url = `https://wttr.in/${city}?format=%C+%t`;

    try {
      const response = await fetch(url);
      const data = await response.text();
      setWeather(data);
    } catch (error) {
      console.error("Error fetching weather data", error);
      setWeather("Unable to fetch weather data");
    }
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    // Fetch weather data on component mount
    fetchWeather();

    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  const getWeatherIcon = (weatherDescription) => {
    for (const [key, icon] of Object.entries(weatherIcons)) {
      if (weatherDescription.includes(key)) {
        return icon;
      }
    }
    return <WiDaySunny />;
  };

  return (
    <div className={classes.titleContainer}>
      <div className={classes.dateWeather}>
        <Typography
          variant="body1"
          style={{ marginRight: "20px", fontSize: "14px" }}>
          {date}
        </Typography>
        <Typography
          variant="body1"
          style={{ marginRight: "5px", fontSize: "15px", color: "#fff" }}>
          {getWeatherIcon(weather)}
        </Typography>
        <Typography
          variant="body1"
          style={{ marginRight: "20px", fontSize: "14px" }}>
          {weather}
        </Typography>
      </div>
      <div className={classes.socialIcons}>
        <Switch />
        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
          <TwitterIcon className={classes.icon} />
        </a>
        <a
          href="https://telegram.org"
          target="_blank"
          rel="noopener noreferrer">
          <TelegramIcon className={classes.icon} />
        </a>
        <a
          href="https://facebook.com"
          target="_blank"
          rel="noopener noreferrer">
          <FacebookIcon className={classes.icon} />
        </a>
        <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
          <YouTubeIcon className={classes.iconYoutube} />
        </a>
      </div>
    </div>
  );
};

export default Title;
