import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CardContent, Divider, Grid } from "@mui/material";
import { Typography, Card } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "left",
    width: "80%",
    margin: "0 auto",
  },
  categoryTitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),

    fontSize: "2rem",
    fontWeight: "bold",
  },
  mainCard: {
    width: "100%",
    margin: "20px auto",
    height: "auto",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    boxShadow: "none",
  },
  mainMedia: {
    width: "100%",
    height: "300px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
  },
  mainContent: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingLeft: "0px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  mainTitle: {
    fontSize: "2.8rem",
    fontWeight: "500",
    fontFamily: "Times-new-roman",
    marginBottom: theme.spacing(2),
    color: "#222",
  },
  mainIntro: {
    fontSize: "1.1rem",
    lineHeight: 1.4,
    color: "#555",
  },
  overlay: {
    position: "absolute",
    bottom: "0px",
    left: "0px",
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    padding: "5px 10px",
    borderRadius: "0px",
  },
  divider: {
    marginTop: theme.spacing(3),
    width: "100%",
  },
  smallerStory: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    boxShadow: "none",
  },
  smallerMedia: {
    width: "40%",
    // width: "120px",
    height: "100px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
  },
  smallerContent: {
    width: "60%",
    padding: theme.spacing(2),
    paddingTop: "0px",
    paddingLeft: "0px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  smallerTitle: {
    fontSize: "1rem",
    lineHeight: 1,
    fontFamily: "Times-new-roman",
    fontWeight: "bold",
    marginTop: "0px",
    paddingTop: "0px",
    marginBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    color: "#222",
  },
  smallerIntro: {
    fontSize: "0.8rem",
    lineHeight: 1.2,
    color: "#555",
  },
  dateOverlay: {
    position: "absolute",
    bottom: theme.spacing(0),
    left: theme.spacing(0),
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    padding: "5px 10px",
    borderRadius: "0px",
    fontSize: "0.8rem",
  },
}));

const CategoryNewsFull = ({ categoryTitle, articles }) => {
  const classes = useStyles();

  const mainArticle = articles[0];
  const smallerArticles = articles.slice(1);

  return (
    <div className={classes.container}>
      <Typography className={classes.categoryTitle}>{categoryTitle}</Typography>
      <Card className={classes.mainCard} style={{ boxShadow: "none" }}>
        <div
          className={classes.mainMedia}
          style={{ backgroundImage: `url(${mainArticle.image})` }}>
          <div className={classes.overlay}>
            <Typography variant="div">{mainArticle.imageCaption}</Typography>
          </div>
        </div>
        <CardContent
          className={classes.mainContent}
          style={{
            paddingLeft: "0px",
          }}>
          <Typography variant="h3" className={classes.mainTitle}>
            {mainArticle.title}
          </Typography>
          <Typography variant="body1" className={classes.mainIntro}>
            {mainArticle.intro}
          </Typography>
        </CardContent>
      </Card>
      <Divider className={classes.divider} />
      <Grid container spacing={3}>
        {smallerArticles.map((article, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Card className={classes.smallerStory}>
              <div
                className={classes.smallerMedia}
                style={{ backgroundImage: `url(${article.image})` }}>
                {/* <div className={classes.overlay}>
                  <Typography variant="div">{article.imageCaption}</Typography>
                </div> */}
                <div className={classes.dateOverlay}>
                  <Typography variant="body2">{article.date}</Typography>
                </div>
              </div>
              <CardContent
                className={classes.smallerContent}
                style={{
                  paddingTop: "0px",
                }}>
                <Typography
                  variant="h6"
                  className={classes.smallerTitle}
                  style={{
                    marginTop: "0px",
                  }}>
                  {article.title}
                </Typography>
                <Typography variant="body2" className={classes.smallerIntro}>
                  {article.intro}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default CategoryNewsFull;
