import React, { useState, useEffect } from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  Button,
  MenuItem,
  Select,
  Fade,
  Typography,
  Stack,
} from "@mui/material";
import { TextField, makeStyles, Modal, Backdrop, Box } from "@material-ui/core";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { useStyles } from "../../../../App";
import { colorTags } from "../../../../constants/variants";
import { useUpdateCategoryRequestMutation } from "../../../../features/api/apiSlice";

const useEditCategoryStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    backgroundColor: "#fff",
    borderRadius: "10px",
    padding: "2% 4%",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
    maxWidth: "40%",
    maxHeight: "90%",
    overflow: "auto",
    outline: "none",
    minWidth: "500px",
  },
  modalTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
    fontWeight: "medium",
    color: "#03AED2",
  },
  modalBody: {
    marginTop: theme.spacing(2),
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#03AED2",
      },
      "&:hover fieldset": {
        borderColor: "#03AED2",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#03AED2",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#03AED2",
    },
  },
  modalActions: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const EditCategory = ({ open, onClose, title, onSave, categoryData }) => {
  const classes = useEditCategoryStyles();
  const classez = useStyles();
  const [token, setToken] = useState(localStorage.getItem("authToken"));

  // Initialize state with category data
  const [name, setName] = useState("");
  const [nameAm, setNameAm] = useState("");
  const [subCategories, setSubCategories] = useState([]);

  // Error handling
  const [nameError, setNameError] = useState("");
  const [nameAmError, setNameAmError] = useState("");
  const [subCategoriesError, setSubCategoriesError] = useState("");

  const [
    updateCategoryRequest,
    {
      isLoading: updateCategoryLoading,
      isError: isErrorUpdateCategory,
      error: updateCategoryError,
    },
  ] = useUpdateCategoryRequestMutation();

  // Handle input field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      setName(value);
    } else if (name === "nameAm") {
      setNameAm(value);
    }
  };
  useEffect(() => {
    if (categoryData) {
      console.log(categoryData);
      setName(categoryData?.name);
      setNameAm(categoryData?.nameAm);
      setSubCategories(categoryData?.subcategories ?? []);
    }
  }, [categoryData]);

  // Handle subcategory input change
  const handleSubCategoryChange = (e, index) => {
    const { name, value } = e.target;
    const updatedSubCategories = [...subCategories];
    updatedSubCategories[index] = {
      ...updatedSubCategories[index],
      [name]: value,
    };
    setSubCategories(updatedSubCategories);
  };

  // Add a new subcategory field
  const handleAddSubCategory = () => {
    setSubCategories([...subCategories, { name: "", nameAm: "" }]);
  };

  // Save function on button click
  const handleSave = async (e) => {
    e.preventDefault();

    // Clear previous errors
    setNameError("");
    setNameAmError("");
    setSubCategoriesError("");

    // Validation checks
    if (name.length === 0 || name.length > 100) {
      setNameError("Name must be between 1 and 100 characters");
      return;
    }

    if (nameAm.length === 0 || nameAm.length > 100) {
      setNameAmError("Amharic name must be between 1 and 100 characters");
      return;
    }

    // Prepare data object for saving
    const updatedCategoryData = {
      id: categoryData.id,
      name,
      nameAm,
      subcategories: subCategories,
    };

    // Call API to update category data
    await updateCategoryRequest(updatedCategoryData)
      .then((res) => {
        if (res.data && res.data.message !== "Unauthorized") {
          toast.success("Category Updated Successfully");
          onSave(res.data);
        } else if (
          res.data.message === "Unauthorized" ||
          res.data.message === "Forbidden"
        ) {
          toast.error("Error Updating Category");
        }
      })
      .catch((err) => {
        toast.error("Error Updating Category");
      });

    // Close modal on successful save
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setName("");
        setNameAm("");
        setSubCategories([]);
        onClose();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}>
      <Fade in={open}>
        <div className={classes.modalContent}>
          <Typography variant="h6" className={classes.modalTitle}>
            {title}
          </Typography>
          <div className={classes.modalBody}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  label="Category Name"
                  variant="outlined"
                  className={classes.textField}
                  fullWidth
                  required
                  value={name}
                  onChange={handleInputChange}
                  error={!!nameError}
                  helperText={nameError}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="nameAm"
                  label="Amharic Name"
                  variant="outlined"
                  className={classes.textField}
                  fullWidth
                  required
                  value={nameAm}
                  onChange={handleInputChange}
                  error={!!nameAmError}
                  helperText={nameAmError}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" color="textSecondary">
                  Subcategories
                </Typography>
                {subCategories.map((subCategory, index) => (
                  <Grid container spacing={2} key={index}>
                    <Grid item xs={6} style={{ marginBottom: "10px" }}>
                      <TextField
                        name="name"
                        label={`Subcategory ${index + 1} Name`}
                        variant="outlined"
                        className={classes.textField}
                        fullWidth
                        required
                        value={subCategory.name}
                        onChange={(e) => handleSubCategoryChange(e, index)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        name="nameAm"
                        label={`Subcategory ${index + 1} Amharic Name`}
                        variant="outlined"
                        className={classes.textField}
                        fullWidth
                        required
                        value={subCategory.nameAm}
                        onChange={(e) => handleSubCategoryChange(e, index)}
                      />
                    </Grid>
                  </Grid>
                ))}
                {subCategoriesError && (
                  <Typography variant="subtitle2" color="error">
                    {subCategoriesError}
                  </Typography>
                )}
                <Button
                  variant="text"
                  color="primary"
                  onClick={handleAddSubCategory}
                  style={{ marginTop: "10px" }}>
                  Add Subcategory
                </Button>
              </Grid>
            </Grid>
          </div>
          <div className={classes.modalActions}>
            <Stack spacing={2} direction="row">
              <LoadingButton
                variant="contained"
                color="success"
                style={{
                  fontWeight: "bold",
                  color: "#fff",
                  fontWeight: "medium",
                  backgroundColor: colorTags.TERTIARY,
                }}
                onClick={handleSave}
                loading={updateCategoryLoading}>
                Save
              </LoadingButton>
              <Button
                variant="outlined"
                color="error"
                style={{
                  color: "#FF0000",
                  marginLeft: "10px",
                  borderRadius: "15px",
                }}
                onClick={() => {
                  setName("");
                  setNameAm("");
                  setSubCategories([]);
                  onClose();
                }}>
                Cancel
              </Button>
            </Stack>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default EditCategory;
