import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Stack,
  Switch,
  TextField,
  Typography,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { colorTags } from "../../../../constants/variants";
import DeleteDialog from "../../../../components/DeleteDialog";
import {
  useGetUserRolesQuery,
  useGetUserRoleByIdQuery,
  useCreateUserRoleRequestMutation,
  useUpdateUserRoleRequestMutation,
  useDeleteUserRoleRequestMutation,
} from "../../../../features/api/apiSlice";
import AddIcon from "@mui/icons-material/Add";

const dummyUserRoleData2 = [
  {
    id: 1,
    roleName: "Admin",
    isAdmin: true,
    permissions: {
      products: {
        canCreate: true,
        canRead: true,
        canUpdate: true,
        canDelete: true,
      },
      procedures: {
        canCreate: true,
        canRead: true,
        canUpdate: true,
        canDelete: true,
      },
      documents: {
        canCreate: true,
        canRead: true,
        canUpdate: true,
        canDelete: true,
      },
    },
  },
  {
    id: 2,
    roleName: "User",
    isAdmin: false,
    permissions: {
      products: {
        canCreate: false,
        canRead: true,
        canUpdate: false,
        canDelete: false,
      },
      procedures: {
        canCreate: false,
        canRead: true,
        canUpdate: false,
        canDelete: false,
      },
      documents: {
        canCreate: false,
        canRead: true,
        canUpdate: false,
        canDelete: false,
      },
    },
  },
];

const initialPermissions = {
  articles: {
    canCreate: false,
    canRead: true,
    canUpdate: false,
    canDelete: false,
  },
  publications: {
    canCreate: false,
    canRead: true,
    canUpdate: false,
    canDelete: false,
  },
  events: {
    canCreate: false,
    canRead: true,
    canUpdate: false,
    canDelete: false,
  },

  advertisements: {
    canCreate: false,
    canRead: true,
    canUpdate: false,
    canDelete: false,
  },
  socials: {
    canCreate: false,
    canRead: true,
    canUpdate: false,
    canDelete: false,
  },
  users: {
    canCreate: false,
    canRead: true,
    canUpdate: false,
    canDelete: false,
  },
  userRoles: {
    canCreate: false,
    canRead: true,
    canUpdate: false,
    canDelete: false,
  },
  logs: {
    canCreate: false,
    canRead: true,
    canUpdate: false,
    canDelete: false,
  },
};

const UserRoleList = () => {
  const [selectedRole, setSelectedRole] = useState(null);
  const [newRoleName, setNewRoleName] = useState("");
  const [formState, setFormState] = useState([]);
  const [open, setOpen] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [roleToEditID, setRoleToEditID] = useState(null);
  const [roleToEditName, setRoleToEditName] = useState(null);
  const [roleToEditAdmin, setRoleToEditAdmin] = useState(null);
  const [dummyUserRoleData, setDummyUserRoleData] = useState(null);
  const [userRolesData2, setUserRolesData2] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [permissions, setPermissions] = useState({ ...initialPermissions });
  const storedUser = localStorage.getItem("user");
  const [token, setToken] = useState(localStorage.getItem("authToken"));

  // const user = storedUser ? JSON.parse(storedUser) : null;
  const user = storedUser ? storedUser : null;

  const {
    data: userRolesData,
    isLoading: isLoadingUserRolesData,
    isError: isErrorUserRolesData,
    error: errorUserRolesData,
  } = useGetUserRolesQuery();

  useEffect(() => {
    if (userRolesData) {
      setDummyUserRoleData(
        userRolesData.map((item) => ({
          ...item,
          // permissions: JSON.parse(item.permissions),
          permissions: item.permissions,
        }))
      );
      setUserRolesData2(dummyUserRoleData);
    }
  }, [userRolesData]);
  const [
    createUserRoleRequest,
    {
      isLoading: isLoadingCreateUserRoleRequest,
      isError: isErrorCreateUserRoleRequest,
      error: errorCreateUserRoleRequest,
    },
  ] = useCreateUserRoleRequestMutation();

  const [
    updateUserRoleRequest,
    {
      isLoading: isLoadingUpdateUserRoleRequest,
      isError: isErrorUpdateUserRoleRequest,
      error: errorUpdateUserRoleRequest,
    },
  ] = useUpdateUserRoleRequestMutation();

  const handleToggleRole = (role) => {
    if (selectedRole === role) {
      // setDummyUserRoleData(userRolesData2);
      setSelectedRole(null);
      setFormState([]);
    } else {
      setSelectedRole(role);
      setRoleToEditAdmin(role.isAdmin);
      setRoleToEditID(role.id);
      setRoleToEditName(role.roleName);
      setFormState(role.permissions);
      setIsDirty(false);
    }
  };
  // const handleTogglePermission = (category, key) => {
  //   const updatedFormState = { ...formState };
  //   updatedFormState[category][key] = !updatedFormState[category][key];
  //   setFormState(updatedFormState);
  //   setIsDirty(true);
  // };
  const handleTogglePermission = (category, key) => {
    // Create a deep copy of the formState
    const updatedFormState = JSON.parse(JSON.stringify(formState));
    updatedFormState[category][key] = !updatedFormState[category][key];
    setFormState(updatedFormState);
    setIsDirty(true);
  };
  // const handleToggleCategory = (category) => {
  //   const updatedFormState = { ...formState };
  //   const categoryPermissions = formState[category];
  //   const isAllEnabled = Object.values(categoryPermissions).every(
  //     (value) => value === true
  //   );

  //   for (const key in categoryPermissions) {
  //     categoryPermissions[key] = !isAllEnabled;
  //   }

  //   updatedFormState[category] = categoryPermissions;
  //   setFormState(updatedFormState);
  //   setIsDirty(true);
  // };
  const handleToggleCategory = (category) => {
    // Create a deep copy of the formState
    const updatedFormState = JSON.parse(JSON.stringify(formState));
    const categoryPermissions = updatedFormState[category];
    const isAllEnabled = Object.values(categoryPermissions).every(
      (value) => value === true
    );

    for (const key in categoryPermissions) {
      categoryPermissions[key] = !isAllEnabled;
    }

    updatedFormState[category] = categoryPermissions;
    setFormState(updatedFormState);
    setIsDirty(true);
  };
  const handleIsAdminToggle = () => {
    // Toggle the "isAdmin" state
    setIsAdmin((prevIsAdmin) => !prevIsAdmin);

    // If "isAdmin" is now true, enable all permissions
    if (!isAdmin) {
      const updatedPermissions = {};

      for (const category of Object.keys(permissions)) {
        const categoryPermissions = {};
        for (const key of Object.keys(permissions[category])) {
          categoryPermissions[key] = true;
        }
        updatedPermissions[category] = categoryPermissions;
      }

      setPermissions(updatedPermissions);
    }
  };
  const handleToggleIsAdmin = (role) => {
    // Create a copy of the dummyUserRoleData array to avoid mutating state directly
    const updatedDummyUserRoleData = [...dummyUserRoleData];

    // Find the index of the role in the array
    const roleIndex = updatedDummyUserRoleData.findIndex(
      (r) => r.id === role.id
    );

    if (roleIndex !== -1) {
      // Update the isAdmin property of the selected role
      updatedDummyUserRoleData[roleIndex].isAdmin = !role.isAdmin;

      // Update the state with the modified data
      setDummyUserRoleData(updatedDummyUserRoleData);
    }
  };

  const handleCloseAdd = async (e) => {
    setIsAdmin(false);
    setPermissions({ ...initialPermissions });
    setNewRoleName("");
    setOpen(false);
  };

  const handlePermissionChange = (category, key) => {
    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      [category]: {
        ...prevPermissions[category],
        [key]: !prevPermissions[category][key],
      },
    }));
  };
  const handleCreateRole = async (e) => {
    e.preventDefault();
    const newRoleData = {
      roleName: newRoleName,
      isAdmin: isAdmin,
      permissions: permissions,
      userId: user?.user_id,
    };
    await createUserRoleRequest(newRoleData)
      .then((res) => {
        if (res.data) {
          toast.success("Role created successfully!");
          setNewRoleName("");
          setIsAdmin(false);
          setPermissions({ ...initialPermissions });
          setOpen(false);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error creating role!");
      });
  };
  const permissionsCategories = Object.keys(permissions);

  const handleUpdateRole = () => {
    toast.success("Role updated successfully!");
  };

  const handleDeleteRole = () => {
    toast.success("Role deleted successfully!");
    setSelectedRole(null);
    setFormState([]);
  };

  const handleSaveChanges = async (e) => {
    e.preventDefault();
    const data = {
      roleName: roleToEditName,
      isAdmin: roleToEditAdmin,
      id: roleToEditID,
      permissions: { ...formState },
      userId: user?.user_id,
    };

    await updateUserRoleRequest(data)
      .then((res) => {
        if (res) {
          toast.success("Changes saved successfully!");
          setSelectedRole(null);
          setFormState([]);
        }
      })
      .catch((err) => {
        toast.error("Error saving changes!");
        console.log(err);
      });
    setIsDirty(false);
  };

  const handleCancelChanges = async () => {
    await setDummyUserRoleData(
      userRolesData?.map((item) => ({
        ...item,
        // permissions: JSON.parse(item.permissions),
        permissions: item.permissions,
      }))
    );
    setIsDirty(false);
  };

  return (
    <div style={{ marginTop: "5%" }}>
      <Typography
        style={{ marginLeft: "5%", color: colorTags.TERTIARY }}
        variant="h4">
        Roles and Permissions
      </Typography>

      <Grid container style={{ marginTop: "3%", placeContent: "center" }}>
        <Grid item xs={12}>
          <div elevation={3} sx={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "2%",
                paddingRight: "5%",
              }}>
              <Stack sx={{ placeSelf: "flex-end" }}>
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => setOpen(true)}
                  style={{ backgroundColor: colorTags.TERTIARY }}>
                  <AddIcon style={{ marginRight: "5px" }} />
                  Role
                </Button>
              </Stack>
            </div>
            <Grid container spacing={4} p={7} pt={3}>
              <Grid item xs={12}>
                {dummyUserRoleData?.map((role) => (
                  <div key={role.id} style={{ marginBottom: "20px" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Button
                        variant="text"
                        sx={{
                          textTransform: "none",
                          fontSize: "1.2rem",
                          color: colorTags.PRIMARY,
                        }}
                        onClick={() => handleToggleRole(role)}>
                        {role.roleName}{" "}
                      </Button>
                      {selectedRole === role && (
                        <Switch
                          checked={role.isAdmin}
                          onChange={() => handleToggleIsAdmin(role)}
                          name={`isAdmin_${role.id}`}
                          // color="success"
                          sx={{ marginLeft: "10px", color: colorTags.TERTIARY }}
                        />
                      )}{" "}
                      {selectedRole === role && (
                        <Typography sx={{ marginLeft: "5px" }}>
                          (Is Admin?)
                        </Typography>
                      )}{" "}
                    </div>
                    {selectedRole === role && (
                      <div>
                        {isDirty && (
                          <div
                            style={{
                              marginTop: "20px",
                              display: "flex",
                              justifyContent: "flex-start",
                            }}>
                            <LoadingButton
                              variant="contained"
                              loading={isLoadingUpdateUserRoleRequest}
                              color="success"
                              onClick={handleSaveChanges}>
                              Save
                            </LoadingButton>
                            <Button
                              variant="outlined"
                              style={{ color: "#FF0000", marginLeft: "10px" }}
                              onClick={handleCancelChanges}>
                              Cancel
                            </Button>
                          </div>
                        )}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}>
                          <DeleteDialog
                            api={useDeleteUserRoleRequestMutation}
                            dialogLabel="Remove User Role"
                            id={role.id}
                            name={`the ${role.roleName} Role?`}
                          />
                        </div>
                        {Object.keys(role.permissions).map((category) => (
                          <div key={category} style={{ marginBottom: "10px" }}>
                            <Typography
                              variant="h6"
                              sx={{
                                // color: colorTags.SECONDARY,
                                color: colorTags.TERTIARY,
                              }}>
                              {category}
                              <Switch
                                sx={{ ml: 2 }}
                                checked={Object.values(
                                  formState[category]
                                ).every((value) => value === true)}
                                onChange={() => handleToggleCategory(category)}
                              />
                            </Typography>
                            <Grid container columnSpacing={15} sx={{ mt: 1 }}>
                              {Object.keys(role.permissions[category]).map(
                                (key) => (
                                  <Grid
                                    key={key}
                                    item
                                    md={6}
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}>
                                    {key}
                                    <Switch
                                      name={key}
                                      checked={formState[category][key]}
                                      onChange={() =>
                                        handleTogglePermission(category, key)
                                      }
                                    />
                                  </Grid>
                                )
                              )}
                            </Grid>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Dialog maxWidth="sm" fullWidth open={open} onClose={handleCloseAdd}>
          <DialogTitle>
            <Stack direction={"row"} justifyContent="space-between">
              Add User Role{" "}
              <IconButton title="Close" onClick={handleCloseAdd}>
                <CloseIcon />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={5} justifyContent="center">
              <Grid item xs={12}>
                <Paper
                  elevation={3}
                  sx={{
                    width: "100%",
                    p: 2,
                    // boxShadow:
                    //   "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
                    boxShadow:
                      "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                  }}>
                  <Stack spacing={3}>
                    <TextField
                      fullWidth
                      id="roleName"
                      required
                      label="Role Name"
                      color="success"
                      value={newRoleName}
                      onChange={(e) => setNewRoleName(e.target.value)}
                    />

                    <FormGroup style={{ marginTop: "15px" }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={isAdmin}
                            onChange={handleIsAdminToggle}
                            name="isAdmin"
                            color="success"
                            style={{ color: colorTags.TERTIARY }}
                          />
                        }
                        label="Is Admin"
                      />
                    </FormGroup>

                    <Typography
                      variant="h6"
                      sx={{
                        // color: colorTags.SECONDARY,
                        color: colorTags.TERTIARY,

                        marginTop: "15px",
                      }}>
                      Permissions
                    </Typography>
                    {permissionsCategories.map((category) => (
                      <div key={category} style={{ marginBottom: "10px" }}>
                        <Typography
                          variant="h6"
                          sx={{
                            // color: colorTags.SECONDARY,
                            color: colorTags.PRIMARY,
                            // color: "#000",
                          }}>
                          {category}
                        </Typography>
                        <Grid container columnSpacing={15} sx={{ mt: 1 }}>
                          {Object.keys(permissions[category]).map((key) => (
                            <Grid
                              key={key}
                              item
                              md={6}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}>
                              {key}
                              <Switch
                                name={key}
                                checked={permissions[category][key]}
                                onChange={() =>
                                  handlePermissionChange(category, key)
                                }
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </div>
                    ))}
                  </Stack>
                </Paper>
              </Grid>
            </Grid>
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
              }}>
              <LoadingButton
                variant="contained"
                loading={isLoadingCreateUserRoleRequest}
                color="success"
                onClick={handleCreateRole}>
                Create
              </LoadingButton>
            </div>
          </DialogContent>
        </Dialog>
      </Grid>
    </div>
  );
};

export default UserRoleList;
