import React from 'react';
import Header from './Header/Header2';
import Footer from './Footer/Footer2';
import Title from './Header/Title2';
import LogoHead from './Header/LogoHead2';
import { images } from '../../assets';
import { colorTags } from '../../constants/variants';
const NotFoundPage = () => {
  return (
    <>
    <Title/>
    <LogoHead/>
        <Header />
    <div style={{height:"75vh", display:"flex",
      backgroundColor:"rgb(198 202 200)", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
      {/* <h1>404 - Page Not Found</h1> */}
   
      <p style={{color:"#000", fontSize:"1.2rem", textAlign:"center"}}>Sorry, the page you are looking for does not exist.</p>
      <p>
        {/* You can  */}
        <a href="/" style={{color:colorTags.TERTIARY,fontSize:"1.2rem"}}>return to the homepage</a>.</p>
      <img
      src={images.Placeholder404}
      />
    </div>
    <Footer />
    </>
  );
};

export default NotFoundPage;
