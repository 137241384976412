
// export const BASE_URL = "https://localhost:7255/api";
// export const BASE_URL = "https://www.aaexport.somee.com/api";
// export const BASE_FILE_URL = "https://www.aaexport.somee.com/cs/";
// export const FILE_LINK_URL = "https://www.aaexport.somee.com/api/File"
// export const FILE_DOWNLOAD_URL = "https://www.aaexport.somee.com/api/File/download?fileUrl=cs/"

// export const INTERNAL_LINK_URL = "http://localhost:3000/"
// export const INTERNAL_LINK_URL = "https://exportportal.addischamber.com/";
export const INTERNAL_LINK_URL = "https://exportportal.addischamber.com/";
export const INTERNAL_LINK_URL_CPANEL = "https://addischambernews.com/"

export const BASE_URL = "https://exportportal.addischamber.com/newsapi";
// export const BASE_FILE_URL = "http://64.227.144.151:3000/cs/";
// export const BASE_FILE_URL = "https://exportportal.addischamber.com/uploads/";
export const BASE_FILE_URL = "https://exportportal.addischamber.com/newsapi/File/download/";

export const BASE_FILE_URL_HTTP = "http://exportportal.addischamber.com:3000/uploads/";


export const  FILE_DOWNLOAD_URL= "https://exportportal.addischamber.com/newsapi/File/download/";

export const FILE_LINK_URL = "https://exportportal.addischamber.com/newsapi/File";
// export const FILE_DOWNLOAD_URL = "http://64.227.144.151:3000/api/File/download?fileUrl=";


// http://64.227.144.151:3000/api-docs/#/

export const API_TAGS = {
    AUTH: "auth",
    DASHBOARD: "Dashboard",
    TRENDING:"Trending",
    SOCIALS:"Socials",
    SEARCH:"Search",
NEWS: "News",
ARTICLES:"Articles",
PUBLICATION: "Publication",
EVENT: "Event",
PROMOTION: "Promotion",
ADS:"Ads",
USER: "User",
USERROLE: "UserRole",
IMAGE: "Image",
LOG: "Log",
 
NOTIFICATION: "Notification",
 
AUTH: "Auth",
CONTACT_US: "ContactUs",
ANALYTICS: "Analytics",
AUTHORS:"Authors",
LAYOUT:"Layout",
CATEGORIES:"Categories",
LINKS:"Links"
 
}