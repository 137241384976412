import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { useStyles } from "../../../../App";
import {
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Fade,
  Typography,
  Stack,
} from "@mui/material";
import { Container, Modal, Backdrop, Box } from "@material-ui/core";
import { LoadingButton } from "@mui/lab";
import { colorTags } from "../../../../constants/variants";
import { DropzoneArea } from "material-ui-dropzone";
import { FILE_LINK_URL } from "../../../../constants/apiTags";
import { toast } from "react-toastify";
import {
  useUpdateYoutubePlaylistRequestMutation,
  // useGetYoutubePlaylistByIdQuery,
} from "../../../../features/api/apiSlice";

const useEditPlaylistStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    backgroundColor: "#fff",
    borderRadius: "10px",
    padding: "2% 4%",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
    maxWidth: "40%",
    maxHeight: "90%",
    overflow: "auto",
    outline: "none",
    minWidth: "500px",
  },
  modalTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
    fontWeight: "medium",
    color: "#ff0000",
  },
  modalBody: {
    marginTop: theme.spacing(2),
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#03AED2",
      },
      "&:hover fieldset": {
        borderColor: "#03AED2",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#03AED2",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#03AED2",
    },
  },
  modalActions: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
  },
  imagePreview: {
    width: "100%",
    maxWidth: "270px",
    maxHeight: "150px",
    objectFit: "cover",
    border: "2px dashed #ccc",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  imagePreviewPlaceholder: {
    width: "100%",
    maxHeight: "150px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "2px dashed #ccc",
    cursor: "pointer",
    marginBottom: theme.spacing(2),
  },
}));

export default function EditPlaylist({ open, onClose, playlistDetails }) {
  const classes = useEditPlaylistStyles();
  const classez = useStyles();

  const [playlistData, setPlaylistData] = useState({
    title: "",
    link: "",
    length: "",
    createdAt: new Date().toISOString(),
  });
  const [thumbnail, setThumbnail] = useState([]);
  const [thumbnailError, setThumbnailError] = useState("");

  // const { data: playlistDetails } = useGetYoutubePlaylistByIdQuery(playlistId);

  const [
    updatePlaylist,
    { isLoading: isUpdating, isError: isUpdateError, error: updateError },
  ] = useUpdateYoutubePlaylistRequestMutation();

  useEffect(() => {
    if (playlistDetails) {
      setPlaylistData({
        title: playlistDetails.title,
        link: playlistDetails.link,
        length: playlistDetails.length,
        createdAt: playlistDetails.createdAt,
      });
      setThumbnail([{ data: playlistDetails.thumbnail }]);
    }
  }, [playlistDetails]);

  const closeModal = () => {
    setPlaylistData({
      title: "",
      link: "",
      thumbnail: "",
      length: "",
      createdAt: new Date().toISOString(),
    });
    onClose();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPlaylistData((prevData) => ({ ...prevData, [name]: value }));
  };

  const uploadImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("files", file);

      const response = await fetch(FILE_LINK_URL, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        throw new Error("Image upload failed");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  };

  const getYoutubeVideoId = (url) => {
    const regExp =
      /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/;
    const match = url.match(regExp);
    return match ? match[1] : null;
  };

  const videoId = getYoutubeVideoId(playlistData.link);
  const iframeSrc = videoId ? `https://www.youtube.com/embed/${videoId}` : "";

  const handleSave = async (e) => {
    e.preventDefault();

    const updatedData = {
      id: playlistDetails?.id,
      title: playlistData.title,
      link: iframeSrc,
      thumbnail: "",
      length: playlistData.length,
      createdAt: new Date(),
    };

    try {
      const thumbnailResponse = await uploadImage(thumbnail[0]);
      updatedData.thumbnail = thumbnailResponse?.filenames[0] ?? "";
    } catch (error) {
      toast.error("Error uploading thumbnail");
      updatedData.thumbnail = playlistDetails?.thumbnail;
      return;
    }

    await updatePlaylist(updatedData)
      .then((res) => {
        if (res) {
          toast.success("Playlist updated successfully");
        }
      })
      .catch((err) => {
        toast.error("Error updating playlist");
      });

    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}>
      <Fade in={open}>
        <div className={classes.modalContent}>
          <Typography variant="h6" className={classes.modalTitle}>
            Edit Playlist
          </Typography>
          <div className={classes.modalBody}>
            <TextField
              autoFocus
              margin="dense"
              name="title"
              label="Title"
              type="text"
              fullWidth
              variant="outlined"
              value={playlistData.title}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="link"
              label="Link"
              type="text"
              placeholder="https://www.youtube.com/watch?v=... Format"
              fullWidth
              variant="outlined"
              value={playlistData.link}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="length"
              label="Length"
              placeholder="00:00 Format"
              type="text"
              fullWidth
              variant="outlined"
              value={playlistData.length}
              onChange={handleChange}
            />
            <DropzoneArea
              dropzoneClass={classez?.dropZone}
              showAlerts={false}
              useChipsForPreview={true}
              acceptedFiles={[
                "image/jpeg",
                "image/jpg",
                "image/png",
                "image/webp",
              ]}
              filesLimit={1}
              maxFiles={1}
              maxFileSize={5000000}
              dropzoneText={
                "Drop or Click to Upload Thumbnail (JPEG, JPG, PNG, WEBP)"
              }
              onChange={(files) => setThumbnail(files)}
              error={!!thumbnailError}
              helperText={thumbnailError}
            />
          </div>
          <div className={classes.modalActions}>
            <Stack spacing={2} direction="row">
              <LoadingButton
                variant="contained"
                color="success"
                style={{
                  fontWeight: "bold",
                  color: "#fff",
                  backgroundColor: colorTags.TERTIARY,
                }}
                onClick={handleSave}>
                Save
              </LoadingButton>
              <Button
                variant="outlined"
                color="error"
                style={{
                  color: "#FF0000",
                  marginLeft: "10px",
                  borderRadius: "15px",
                }}
                onClick={onClose}>
                Cancel
              </Button>
            </Stack>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
