import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Collapse,
  useMediaQuery,
  useTheme,
  makeStyles,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import HomeIcon from "@material-ui/icons/Home";
import EditIcon from "@material-ui/icons/Edit";
import BusinessIcon from "@material-ui/icons/Business";
import CommentIcon from "@material-ui/icons/Comment";
import ReportIcon from "@material-ui/icons/Report";
import TvIcon from "@material-ui/icons/Tv";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import EventIcon from "@material-ui/icons/Event";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import NatureIcon from "@material-ui/icons/Nature";
import AgricultureIcon from "@material-ui/icons/LocalFlorist";
import IndustryIcon from "@material-ui/icons/Apartment";
import ServiceIcon from "@material-ui/icons/RoomService";
import PhoneIcon from "@material-ui/icons/Phone";
import CategoryIcon from "@material-ui/icons/Category"; // Placeholder icon
import { images } from "../../../assets";
import { useNavigate } from "react-router-dom";
import { colorTags } from "../../../constants/variants";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
    color: "#fff",
    paddingTop: "20px",
    [theme.breakpoints.down(650)]: {
      width: "100%",
    },
  },
  drawerPaper: {
    backgroundColor: colorTags.PRIMARY,
    color: "#fff",
    height: "100%",
  },
  menuButton: {
    marginLeft: theme.spacing(1),
    color: "black",
    borderBottom: "1px solid grey",
  },
  closeIcon: {
    position: "absolute",
    top: theme.spacing(0.2),
    right: theme.spacing(1),
  },
  menuTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(0.5),
  },
  menuItem: {
    paddingLeft: theme.spacing(3),
    borderBottom: "1px solid grey",
  },
  bigItemIcon: {
    marginRight: theme.spacing(1),
  },
  topBar: {
    width: "100%",
    height: 50,
    backgroundColor: `rgba(${colorTags.PRIMARY_RGBA},0.9)`,
    padding: "0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width: "50%",
    maxWidth: 300,
    maxHeight: 100,
    height: "auto",
    objectFit: "contain",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      width: "18%",
    },
  },
}));

const convertToSlug = (text) => {
  return text.toLowerCase().replace(/\s+/g, "-");
};

const constantMenuItems = [
  { id: "home", name: "Home" },
  { id: "events", name: "Events" },
  { id: "addis-chamber-media", name: "Addis Chamber Media" },
  { id: "contact", name: "Contact" },
];

const Sidebar = ({ menuData }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState({});
  const navigate = useNavigate();
  const { t } = useTranslation();

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmenuClick = (menu, event) => {
    event.stopPropagation(); // Prevents sidebar from closing
    setOpenSubmenu({
      ...openSubmenu,
      [menu]: !openSubmenu[menu],
    });
  };

  const handleNavigation = (menuItem) => {
    let path = "";
    switch (menuItem) {
      case "Home":
        path = "/";
        break;
      case "Events":
        path = "/events";
        break;
      case "Addis Chamber Media":
        path = "/addis-chamber-media";
        break;
      case "Contact":
        path = "/contact-us";
        break;
      default:
        path = `/news-category/${convertToSlug(menuItem)}`;
    }
    navigate(path);
    handleClose();
  };

  const renderIcon = (text) => {
    switch (text) {
      case "Home":
        return <HomeIcon className={classes.bigItemIcon} />;
      case "Editorial":
        return <EditIcon className={classes.bigItemIcon} />;
      case "Green Economy":
        return <NatureIcon className={classes.bigItemIcon} />;
      case "Economy":
        return <BusinessIcon className={classes.bigItemIcon} />;
      case "Agriculture":
        return <AgricultureIcon className={classes.bigItemIcon} />;
      case "Industry":
        return <IndustryIcon className={classes.bigItemIcon} />;
      case "Service":
        return <ServiceIcon className={classes.bigItemIcon} />;
      case "Opinions":
        return <CommentIcon className={classes.bigItemIcon} />;
      case "Reports":
        return <ReportIcon className={classes.bigItemIcon} />;
      case "Addis Chamber Media":
        return <TvIcon className={classes.bigItemIcon} />;
      case "Contact":
        return <PhoneIcon className={classes.bigItemIcon} />;
      case "Events":
        return <EventIcon className={classes.bigItemIcon} />;
      default:
        return <CategoryIcon className={classes.bigItemIcon} />;
    }
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
      }}>
      <IconButton
        edge="start"
        className={classes.menuButton}
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(true)}>
        <MenuIcon />
      </IconButton>

      <Drawer
        open={open}
        onClose={toggleDrawer(false)}
        classes={{ paper: classes.drawerPaper }}>
        <div
          className={classes.list}
          role="presentation"
          onKeyDown={toggleDrawer(false)}>
          <IconButton
            className={classes.closeIcon}
            onClick={handleClose}
            color="inherit">
            <CloseIcon />
          </IconButton>
          <div className={classes.topBar}>
            <img
              src={images.AACCSALogoWhite}
              alt="Top Bar"
              onClick={() => handleNavigation("Home")}
              className={classes.image}
            />
          </div>
          <List style={{ marginTop: "25px" }}>
            {constantMenuItems.map((menuItem) => (
              <ListItem
                button
                key={menuItem.id}
                onClick={() => handleNavigation(menuItem.name)}
                style={{
                  borderBottom: "0.7px solid #cccc",
                }}>
                <ListItemIcon>{renderIcon(menuItem.name)}</ListItemIcon>
                <ListItemText primary={t(menuItem.name)} />
              </ListItem>
            ))}
            {menuData.map((menuItem) => (
              <div key={menuItem.id}>
                <ListItem
                  button
                  onClick={() => handleNavigation(menuItem.name)}
                  style={{
                    borderBottom: "0.7px solid #cccc",
                  }}>
                  <ListItemIcon>{renderIcon(menuItem.name)}</ListItemIcon>
                  <ListItemText primary={t(menuItem.name)} />
                  {menuItem.subcategories &&
                    menuItem.subcategories.length > 0 && (
                      <IconButton
                        edge="end"
                        onClick={(event) =>
                          handleSubmenuClick(menuItem.name, event)
                        }>
                        {openSubmenu[menuItem.name] ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </IconButton>
                    )}
                </ListItem>
                {menuItem.subcategories &&
                  menuItem.subcategories.length > 0 && (
                    <Collapse
                      in={openSubmenu[menuItem.name]}
                      timeout="auto"
                      unmountOnExit>
                      {menuItem.subcategories.map((subMenuItem) => (
                        <ListItem
                          button
                          key={subMenuItem.id}
                          className={classes.menuItem}
                          onClick={() => handleNavigation(subMenuItem.name)}
                          style={{
                            borderBottom: "0.3px solid #eeee",
                          }}>
                          <ListItemText primary={t(subMenuItem.name)} />
                        </ListItem>
                      ))}
                    </Collapse>
                  )}
              </div>
            ))}
          </List>
        </div>
      </Drawer>
    </div>
  );
};

const menuData = [
  {
    id: 5,
    name: "Business",
    nameAm: "ቢዝነስ",
    subcategories: [
      {
        id: 3,
        name: "Entrepreneurship",
        nameAm: "ስራ ፈጠራ",
        categoryId: 5,
      },
    ],
  },
  {
    id: 2,
    name: "Editorial",
    nameAm: "ርዕሰ አንቀጽ",
    subcategories: [],
  },
];

const App = () => {
  return <Sidebar menuData={menuData} />;
};

export default App;
