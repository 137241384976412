import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  iframe: {
    border: 0,
    width: "100%",
    height: "600px",
  },
}));

const YoutubeChannelFrame = () => {
  const classes = useStyles();
  const playlistId = "PLC9f5-y-hM3gbKAYuZqcJl6Sbyqv66MuH"; // Example playlist ID
  const videoId = "AW15-400bzY"; // Example video ID

  return (
    <iframe
      className={classes.iframe}
      title="YouTube Playlist"
      src={`https://www.youtube.com/embed/${videoId}?list=${playlistId}&listType=playlist`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );
};

export default YoutubeChannelFrame;
