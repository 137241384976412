export const colorTags = {
    PRIMARY: "#006738",
    
    PRIMARY_RGBA: "0, 103, 56",
  
    SECONDARY : "#E1A411",
    SECONDARY_RGBA: "225, 164, 17",
    TERTIARY:"#009EB0",
    TERTIARY_RGBA: "0, 158, 176",


    ACCENT : "#B1B1B1",

    TERTIATY :"0,158,176"



};
    
    export const primaryColor = "#006738";
    export const secondaryColor = "#E1A411";
    export const tertiaryColor="#009EB0";
    export const accentColor = "#B1B1B1";

    // constants/regex.js

export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const phoneNumberRegex = /^(\+\d{1,3})?(\d{9})$/;

