import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CardContent, Divider } from "@mui/material";
import { Card, Typography, CardMedia } from "@material-ui/core";
import CategoryChip from "../../../../components/ChipGenerator"; // Import CategoryChip component
import { images } from "../../../../assets";
import { colorTags } from "../../../../constants/variants";
import {
  useGetTrendingCategoryArticlesQuery,
  useGetArticlesLatestFiveQuery,
} from "../../../../features/api/apiSlice";
import { useTranslation } from "react-i18next";
import { BASE_FILE_URL } from "../../../../constants/apiTags";

const useStyles = makeStyles((theme) => ({
  mostRead: {
    width: 300,
    border: "0.7px solid lightgrey",
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  header: {
    fontSize: 18,
    fontWeight: "bold",
    color: colorTags.PRIMARY,
  },
  card: {
    backgroundColor: "transparent",
    boxShadow: `rgba(${colorTags.TERTIARY_RGBA}, 0.15) 1.95px 1.95px 2.6px`,
    marginTop: theme.spacing(2),
    // paddingTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  content: {
    flex: 1,
    // paddingLeft: theme.spacing(2),
    textAlign: "left",
  },
  title: {
    fontSize: 20,
    lineHeight: 1.2,
    fontFamily: "Times-new-roman",
    fontWeight: "bold",
  },
  chip: {
    position: "absolute",
    top: theme.spacing(1),
    left: theme.spacing(1),
    zIndex: 1,
  },
  description: {
    fontSize: 14,
    marginTop: theme.spacing(0.5),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    backgroundColor: colorTags.TERTIARY,
  },
  media: {
    width: 50,
    height: 50,
    borderRadius: theme.spacing(1),
  },
  topArticleMedia: {
    height: 140,
  },
  topArticleContent: {
    textAlign: "left",
  },
}));

const MostReadThisMonth = ({ category }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const news = [
    {
      title: "Deep Dive into Quantum Computing",
      description: "Exploring the latest advancements in quantum computing.",
      category: "Technology and Its Impact",
      imageUrl: images.NewsPlaceholder3,
    },
    {
      title: "The Impact of Climate Change on Global Agriculture",
      description: "How climate change is affecting agriculture worldwide.",
      category: "Climate Change",
      imageUrl: images.CategoryNewsFullPlaceholder,
    },
    {
      title: "The Future of Artificial Intelligence",
      description: "AI advancements and their potential impact on society.",
      category: "Technology and Its Impact",
      imageUrl: images.NewsPlaceholder3,
    },
    {
      title: "New Developments in Renewable Energy",
      description: "Innovations in renewable energy sources.",
      category: "Energy",
      imageUrl: images.CategoryNewsFullEconomyPlaceholder,
    },
    {
      title: "Global Economic Trends in 2024",
      description: "An overview of the global economic landscape.",
      category: "Economy",
      imageUrl: images.NewsPlaceholder3,
    },
  ];

  const {
    data: tendingData,
    isLoading: isLoadingTrendingData,
    isError: isErrorTrendingData,
  } = useGetTrendingCategoryArticlesQuery({
    lang: "en",
    title: category,
  });

  const {
    data: tendingDataAm,
    isLoading: isLoadingTrendingDataAm,
    isError: isErrorTrendingDataAm,
  } = useGetTrendingCategoryArticlesQuery({
    lang: "am",
    title: category,
  });

  // Top Five Story Data
  const {
    data: topFiveStoryData,
    isLoading: topFiveStoryLoading,
    isError: topFiveStoryError,
    error: errorTopFiveStory,
  } = useGetArticlesLatestFiveQuery({ lang: "en" });

  const {
    data: topFiveStoryAmData,
    isLoading: topFiveStoryAmLoading,
    isError: topFiveStoryAmError,
    error: errorTopFiveStoryAm,
  } = useGetArticlesLatestFiveQuery({ lang: "am" });
  return (
    <div className={classes.mostRead}>
      <Typography className={classes.header}>{category}</Typography>
      <Divider className={classes.divider} />
      {currentLanguage === "am" ? (
        <Card
          className={classes.card}
          style={{
            display: "flex",
            flexDirection: "column",
          }}>
          <CardMedia
            component="img"
            className={classes.topArticleMedia}
            image={
              topFiveStoryAmData &&
              BASE_FILE_URL + topFiveStoryAmData[0]?.coverImage
            }
            alt={topFiveStoryAmData && topFiveStoryAmData[0]?.title}
          />
          <CardContent
            className={classes.topArticleContent}
            style={{ paddingLeft: "0px" }}>
            <Typography className={classes.title}>
              {topFiveStoryAmData && topFiveStoryAmData[0]?.title}
            </Typography>
          </CardContent>
        </Card>
      ) : (
        <Card
          className={classes.card}
          style={{
            display: "flex",
            flexDirection: "column",
          }}>
          <CardMedia
            component="img"
            className={classes.topArticleMedia}
            image={
              topFiveStoryData &&
              BASE_FILE_URL + topFiveStoryData[0]?.coverImage
            }
            alt={topFiveStoryData && topFiveStoryData[0]?.title}
          />
          <CardContent
            className={classes.topArticleContent}
            style={{ paddingLeft: "0px" }}>
            <Typography className={classes.title}>
              {topFiveStoryData && topFiveStoryData[0]?.title}
            </Typography>
          </CardContent>
        </Card>
      )}
      {currentLanguage === "am"
        ? topFiveStoryAmData?.slice(1).map((item, index) => (
            <Card key={index} className={classes.card}>
              <CardMedia
                component="img"
                className={classes.media}
                // image={item.imageUrl}
                image={BASE_FILE_URL + item?.coverImage}
                alt={item?.title}
              />
              <CardContent className={classes.content}>
                <Typography className={classes.title}>{item?.title}</Typography>
              </CardContent>
              {/* Uncomment if you want to use CategoryChip */}
              {/* <div className={classes.chip}>
            <CategoryChip category={item.category} />
          </div> */}
            </Card>
          ))
        : topFiveStoryData?.slice(1).map((item, index) => (
            <Card key={index} className={classes.card}>
              <CardMedia
                component="img"
                className={classes.media}
                // image={item.imageUrl}
                image={BASE_FILE_URL + item?.coverImage}
                alt={item.title}
              />
              <CardContent className={classes.content}>
                <Typography className={classes.title}>{item?.title}</Typography>
              </CardContent>
              {/* Uncomment if you want to use CategoryChip */}
              {/* <div className={classes.chip}>
            <CategoryChip category={item.category} />
          </div> */}
            </Card>
          ))}
    </div>
  );
};

export default MostReadThisMonth;
