import { useLocation } from "react-router-dom";
import ListItemLink from "../ListItemLink";

export default function SideBarMenuSingle({ menu }) {
  const { pathname } = useLocation();

  if (!menu.permission) return <></>;

  return (
    <ListItemLink
      to={menu.to}
      primary={menu.label}
      bgColor={
        menu.selected?.includes(pathname)
          ? "rgba(255,255,255,0.4)"
          : "rgba(0,0,0,0.5)"
      }
      textColor={menu.selected?.includes(pathname) ? "#fff" : "#EEEEEE"}
      icon={
        <div
          style={{
            color: menu.selected?.includes(pathname) ? "#fff" : "#EEEEEE",
            // fontSize: menu.selected?.includes(pathname) ? "20px" : "15px",
          }}>
          {menu.icon}
        </div>
      }
    />
  );
}
