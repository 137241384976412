import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Card, Divider, Grid } from "@material-ui/core";
import { CardContent } from "@mui/material";
import { backdropClasses } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "70%",
    margin: "0 auto",
  },
  root: {
    margin: "20px auto",
    height: "auto",
    overflow: "hidden",
    display: "flex",
    flexDirection: "row",
    boxShadow: "none",
  },
  media: {
    width: "50%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
  },
  content: {
    width: "50%",
    padding: theme.spacing(4),
    paddingLeft: "0px",
    paddingTop: "0px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  title: {
    fontSize: "2.8rem",
    fontWeight: "500",
    fontFamily: "Times-new-roman",
    marginBottom: theme.spacing(2),
    color: "#222",
  },
  intro: {
    fontSize: "1.1rem",
    lineHeight: 1.4,
    color: "#555",
  },
  overlay: {
    position: "absolute",
    bottom: "0px",
    left: "0px",
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    padding: "5px 10px",
    borderRadius: "0px",
  },
  divider: {
    marginTop: theme.spacing(3),
    width: "100%",
  },
  smallerStory: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    boxShadow: "none",
  },
  smallerMedia: {
    width: "50%",
    height: "150px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
  },
  smallerContent: {
    width: "50%",
    height: "150px",

    padding: theme.spacing(2),
    paddingTop: "0px",
    paddingLeft: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  smallerTitle: {
    fontSize: "1.5rem",
    lineHeight: 1.1,
    fontFamily: "Times-new-roman",
    fontWeight: "bold",
    marginTop: "0px",
    paddingTop: "0px",
    marginBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    color: "#222",
  },
  smallerIntro: {
    fontSize: "0.8rem",
    lineHeight: 1.2,
    color: "#555",
  },
}));

const TopStory = ({ mainArticle, smallerArticles }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <div className={classes.container}>
      <Card className={classes.root} style={{ boxShadow: "none" }}>
        <CardContent
          className={classes.content}
          style={{
            paddingTop: "0px",
          }}>
          <Typography
            variant="h3"
            style={{
              marginTop: "-10px",
            }}
            className={classes.title}
            // onClick={() => navigate("/news-detail")}
          >
            <Link to={"/news-detail"}> {mainArticle.title}</Link>
          </Typography>
          <Typography variant="body1" className={classes.intro}>
            {mainArticle.intro}
          </Typography>
        </CardContent>
        <div
          className={classes.media}
          style={{ backgroundImage: `url(${mainArticle.image})` }}>
          <div className={classes.overlay}>
            <Typography variant="div">{mainArticle.imageCaption}</Typography>
          </div>
        </div>
      </Card>
      <Divider className={classes.divider} />
      <Grid container spacing={6}>
        {smallerArticles.map((article, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Card className={classes.smallerStory}>
              <div
                className={classes.smallerMedia}
                style={{ backgroundImage: `url(${article.image})` }}>
                <div className={classes.overlay}>
                  <Typography variant="div">{article.imageCaption}</Typography>
                </div>
              </div>
              <CardContent
                className={classes.smallerContent}
                style={{
                  paddingTop: "0px",
                }}>
                <Typography
                  variant="h6"
                  style={{ marginTop: "-5px" }}
                  className={classes.smallerTitle}>
                  {article.title}
                </Typography>
                <Typography variant="body2" className={classes.smallerIntro}>
                  {article.intro}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default TopStory;
