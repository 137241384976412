import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Info, OpenInNew, Edit as EditIcon } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { SimpleCard } from "../../../../components";
import GridData from "../../../../components/GridData";
import AddPublication from "./AddPublication";
import DeleteDialog from "../../../../components/DeleteDialog";
import { useNavigate } from "react-router-dom";
import EditPublication from "./EditPublication";
import {
  useGetPublicationsQuery,
  useDeletePublicationRequestMutation,
} from "../../../../features/api/apiSlice";
import { primaryColor, colorTags } from "../../../../constants/variants";

import { BASE_FILE_URL } from "../../../../constants/apiTags";
import { Bars } from "react-loader-spinner";
import AddIcon from "@mui/icons-material/Add";

const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
  },
}));

const samplePublicationsData = [
  {
    id: 1,
    title: "Understanding AI in 2023",
    author: "John Doe",
    datePublished: "2023-05-15",
    documentUrl: "ai_research_2023.pdf",
    lastUpdated: "2023-06-01",
  },
  {
    id: 2,
    title: "Blockchain Technology Overview",
    author: "Jane Smith",
    datePublished: "2023-04-20",
    documentUrl: "blockchain_overview.pdf",
    lastUpdated: "2023-05-25",
  },
];

export default function LawsRegulationsPage() {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const handleEditModalClose = () => setEditModalOpen(false);
  const handleEditModalOpen = () => setEditModalOpen(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("authToken"));

  const {
    data: PublicationsData,
    isLoading: isLoadingPublicationsData,
    isError: isErrorPublicationsData,
    error: errorPublicationsData,
  } = useGetPublicationsQuery();

  // useEffect(() => {
  //   if (PublicationsData) console.log(PublicationsData);
  // }, [PublicationsData]);

  const columns = [
    {
      field: "id",
      headerName: "No.",
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
      flex: 0.3,
    },
    {
      field: "publicationTitle",
      headerName: "Publication Title",
      flex: 1,
    },
    {
      field: "month",
      headerName: "Publication Date",
      type: "date",
      flex: 1,
    },
    {
      field: "coverImage",
      headerName: "Cover Image",
      flex: 1,
      renderCell: (params) => (
        <div>
          <img
            src={BASE_FILE_URL + params?.row?.coverImage}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      ),
    },
    {
      field: "documentUrl",
      headerName: "Document",
      flex: 1,
      renderCell: (params) => (
        <div>
          <IconButton
            href={`${BASE_FILE_URL}${params.row.publicationFile}`}
            target="_blank"
            download>
            <OpenInNew color="primary" />
          </IconButton>
        </div>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      type: "date",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: 1,
      align: "left",
      renderCell: (params) => (
        <div className="flex">
          <IconButton
            onClick={() => {
              setEditModalOpen(true);
              setSelectedRow(params.row);
            }}>
            <EditIcon color="primary" />
          </IconButton>
          <DeleteDialog
            api={useDeletePublicationRequestMutation}
            dialogLabel="Delete Publication"
            id={params.row.id}
            name={params.row.publicationTitle}
          />
        </div>
      ),
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => setModalOpen(false);
  const handleModalOpen = () => setModalOpen(true);

  if (isLoadingPublicationsData)
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Bars
          height="40"
          width="40"
          // color="#4fa94d"
          color={colorTags.TERTIARY}
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          visible={true}
        />
      </div>
    );

  return (
    <div style={{ marginLeft: "5%", marginTop: "5%", width: "90%" }}>
      <Button
        variant="contained"
        style={{
          marginBottom: "1%",
          marginLeft: "88%",
          backgroundColor: colorTags.TERTIARY,
        }}
        onClick={handleModalOpen}>
        <AddIcon style={{ marginRight: "5px" }} />
        Publication
      </Button>
      <AddPublication
        open={modalOpen}
        onClose={handleModalClose}
        title="Add Publication"
        content="This is the modal content."
        actionText="Close"
      />
      <SimpleCard
        title="News Publications List"
        style={{
          marginTop: "2%",
        }}>
        <Box width="100%" overflow="auto">
          <GridData rows={PublicationsData ?? []} columns={columns} />
        </Box>
      </SimpleCard>

      <EditPublication
        data={selectedRow}
        title="Edit Publication"
        open={editModalOpen}
        onClose={handleEditModalClose}
        onSave={handleEditModalClose}
      />
    </div>
  );
}
