import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, API_TAGS } from "../../constants/apiTags";
import { useState } from "react";

export const apiSlice = createApi({
  reducerPath: 'apiSlice',

  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem('authToken');

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
        headers.set('Content-Type', 'application/json');
      }

      return headers;
    },
  }),

  tagTypes: Object.values(API_TAGS),
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => ({
// Dashboard stats
getDashboardStats: builder.query({
      query: () => ({
        url: `GetDashboardStats`,
        method: "GET",
      }),
      providesTags: [API_TAGS.DASHBOARD],
    }),

//  Articles
getArticles: builder.query({
      query: (data) => ({
        // url: `Articles/${data.lang??'en'}`,
        url: `Articles`,

        method: "GET",
      }),
      providesTags: [API_TAGS.ARTICLES],
    }),
    getArticlesLang: builder.query({
      query: (data) => ({
        url: `Articles/${data.lang??'en'}`,
        method: "GET",
      }),
      providesTags: [API_TAGS.ARTICLES],
    }),
  
getArticlesById: builder.query({
  query: (data) => `Articles/${data.id}`,

      providesTags: [API_TAGS.ARTICLES],
    }),

    getArticlesLatestFive: builder.query({
      query:(data)=> `Articles/latest/5/${data.lang??'en'}`,
      providesTags:[API_TAGS.ARTICLES],
    }),

getArticlesCategoryLatest: builder.query({
  query:(data)=> `Articles/categoryLatest/${data.lang??'en'}/${data.title}/${data.amount}`,
  providesTags:[API_TAGS.ARTICLES],
}),
// getArticlesCategory: builder.query({
//   query:(data)=> `Articles/Category/${data.title}/${data.amount}`,
//   providesTags:[API_TAGS.ARTICLES],
// }),

getArticlesTopToday: builder.query({
  query:(data)=> `Articles/topToday/${data.lang??'en'}`,
  providesTags:[API_TAGS.ARTICLES]
}),

getArticlesMostRead: builder.query({
  // query:(data)=> `Articles/mostRead/${data.lang??'en'}`,
  query:(data)=> `matomo/most-viewed-articles/${data.lang??'en'}`,

  providesTags:[API_TAGS.ARTICLES]
}),



createArticlesRequest: builder.mutation({
      query: (data) => ({
        url: `Articles`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [API_TAGS.ARTICLES],
    }),

updateArticlesRequest: builder.mutation({
      query: (data) => ({
        url: `Articles/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [API_TAGS.ARTICLES],
    }),

    deleteArticlesRequest: builder.mutation({
      query: (data) => ({
        url: `Articles/${data.id}`,
        method: "DELETE",
        params: data,
      }),
      invalidatesTags: [API_TAGS.ARTICLES],
    }),

//Publications
getPublications: builder.query({
      query: () => ({
        url: `Publications`,
        method: "GET",
      }),
      providesTags: [API_TAGS.PUBLICATION],
    }),

getPublicationById: builder.query({
  query: (data) =>({ 
    url:`Publications/${data.id}`,
  method: "GET",
}),
      providesTags: [API_TAGS.PUBLICATION],
}),

getPublicationsLatest: builder.query({
  query: (data) =>({ 
    url:`Publications/latest`,
  method: "GET",
}),
      providesTags: [API_TAGS.PUBLICATION],
}),


createPublicationRequest: builder.mutation({
      query: (data) => ({
        url: `Publications`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [API_TAGS.PUBLICATION],
    }),

updatePublicationRequest: builder.mutation({
      query: (data) => ({
        url: `Publications/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [API_TAGS.PUBLICATION],
    }),

    deletePublicationRequest: builder.mutation({
      query: (data) => ({
        url: `Publications/${data.id}`,
        method: "DELETE",
        params: data,
      }),
      invalidatesTags: [API_TAGS.PUBLICATION],
    }),

// Events
getEvents: builder.query({
      query: () => ({
        url: `Events`,
        method: "GET",
      }),
      providesTags: [API_TAGS.EVENT],
    }),

getEventById: builder.query({
  query: (data) => ({
    url:`Events/${data.id}`,
  method: "GET",
}),
      providesTags: [API_TAGS.EVENT],
}),

createEventRequest: builder.mutation({
      query: (data) => ({
        url: `Events`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [API_TAGS.EVENT],
    } ),

updateEventRequest: builder.mutation({
      query: (data) => ({
        url: `Events/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [API_TAGS.EVENT],
    }),

    deleteEventRequest: builder.mutation({
      query: (data) => ({
        url: `Events/${data.id}`,
        method: "DELETE",
        params: data,
      }),
      invalidatesTags: [API_TAGS.EVENT],
    }),


// Advertisement
getAds: builder.query({
      query: () => ({
        url: `Ads`,
        method: "GET",
      }),
      providesTags: [API_TAGS.ADS],
    }),

getAdsById: builder.query({
  query: (data) => `Ads/${data.id}`,
      providesTags: [API_TAGS.ADS],
      method: "GET",

}),

createAdsRequest: builder.mutation({
      query: (data) => ({
        url: `Ads`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [API_TAGS.ADS],
    }),

updateAdsRequest: builder.mutation({
      query: (data) => ({
        url: `Ads/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [API_TAGS.ADS],
    }),

    deleteAdsRequest: builder.mutation({
      query: (data) => ({
        url: `Ads/${data.id}`,
        method: "DELETE",
        params: data,
      }),
      invalidatesTags: [API_TAGS.ADS],
    }),


// Trending
getTrendingArticles: builder.query({
  query:(data)=> ({
    url:`Trending/Today/${data.lang}`,
    // url:`matomo/trending-articles/${data.lang}`,
    // correct-link
    // url:`matomo/trending-articles`,


    method:"GET",
  }),
  providesTags:[API_TAGS.TRENDING]
}),

getTrendingCategoryArticles: builder.query({
  query:(data)=>({
    url: `Trending/category/${data.title}/${data.lang}`,
    method:"GET",
  }),
  providesTags:[API_TAGS.TRENDING]
}),

getMostReadPublications: builder.query({
  query:(data)=>({
    url: `matomo/most-viewed-publications`,
    method:"GET",
  }),
  providesTags:[API_TAGS.TRENDING]
}),



// SOCIALS


createSocialPostRequest: builder.mutation({
  query:(data)=>({
    url: `Socials`,
    method:"POST",
    body:data
  }),
  invalidatesTags: [API_TAGS.LINKS],

}),

getYoutubePlaylist: builder.query({
  query:()=>({
    url:`Socials/youtube/links`,
    method:"GET",

  }),
  providesTags:[API_TAGS.LINKS]
}),

createYoutubePlaylist: builder.mutation({
  mutation:(data)=>({
    url: `Socials/youtube/links`,
    method:"POST",
    body:data
  }),
  invalidatesTags: [API_TAGS.LINKS],

}),
createYoutubePlaylistRequest: builder.mutation({
  query: (data) => ({
    url: `Socials/youtube/links`,
    method: "POST",
    body: data,
  }),
  invalidatesTags: [API_TAGS.LINKS],
}),

// createPublicationRequest: builder.mutation({
//       query: (data) => ({
//         url: `Publications`,
//         method: "POST",
//         body: data,
//       }),
//       invalidatesTags: [API_TAGS.PUBLICATION],
//     }),

updateYoutubePlaylist: builder.mutation({
  mutation:(data)=>({
    url: `Socials/youtube/links/${data.id}`,
    method:"PUT",
    body:data
  }),
  invalidatesTags: [API_TAGS.LINKS],

})
,
updateYoutubePlaylistRequest: builder.mutation({
  query: (data) => ({
    url: `Socials/youtube/links/${data.id}`,
    method: "PUT",
    body: data,
  }),
  invalidatesTags: [API_TAGS.LINKS],
}),

deleteYoutubePlaylistRequest: builder.mutation({
  query: (data) => ({
    url: `Socials/youtube/links/${data.id}`,
    method: "DELETE",
    params: data,
  }),
  invalidatesTags: [API_TAGS.LINKS],
}),





// search
// getSearchResults:builder.query({
//   query:(data)=>({
//     url:`search?filter=${data.query}`,
    
//   }),
//   // invalidatesTags:[API_TAGS.SEARCH]
// }),
getSearchResults: builder.query({
  query:(data)=> `search?filter=${data.query}`,
  providesTags:[API_TAGS.ARTICLES],
}),






// Settings Users
getUsers: builder.query({
      query: () => ({
        url: `Users`,
        method: "GET",
      }),
      providesTags: [API_TAGS.USER],
    }),

getUserById: builder.query({
  query: (data) => ({
    
    url:`Users/${data.id}`,
    method: "GET",
  }),
      providesTags: [API_TAGS.USER],
}),

createUserRequest: builder.mutation({
      query: (data) => ({
        url: `Users`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [API_TAGS.USER],
    }),

updateUserRequest: builder.mutation({
      query: (data) => ({
        url: `Users/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [API_TAGS.USER],
    }),

    deleteUserRequest: builder.mutation({
      query: (data) => ({
        url: `Users/${data.id}`,
        method: "DELETE",
        params: data,
      }),
      invalidatesTags: [API_TAGS.USER],
    }),

// Authors
getAuthors: builder.query({
  query: () => ({
    url: `Authors`,
    method: "GET",
  }),
  providesTags: [API_TAGS.AUTHORS],
}),

getAuthorById: builder.query({
query: (data) => `Authors/${data.id}`,
  providesTags: [API_TAGS.AUTHORS],
}),

createAuthorRequest: builder.mutation({
  query: (data) => ({
    url: `Authors`,
    method: "POST",
    body: data,
  }),
  invalidatesTags: [API_TAGS.AUTHORS],
}),

updateAuthorRequest: builder.mutation({
  query: (data) => ({
    url: `Authors/${data.id}`,
    method: "PUT",
    body: data,
  }),
  invalidatesTags: [API_TAGS.AUTHORS],
}),

deleteAuthorRequest: builder.mutation({
  query: (data) => ({
    url: `Authors/${data.id}`,
    method: "DELETE",
    params: data,
  }),
  invalidatesTags: [API_TAGS.AUTHORS],
}),


// Settings User Roles
getUserRoles: builder.query({
      query: () => ({
        url: `Roles`,
        method: "GET",
      }),
      providesTags: [API_TAGS.USERROLE],
    }),

getUserRoleById: builder.query({
  query: (data) =>  ({
    url: `Roles/${data.id}`,
    method: "GET",
  }),
      providesTags: [API_TAGS.USERROLE],
}),

createUserRoleRequest: builder.mutation({
      query: (data) => ({
        url: `Roles`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [API_TAGS.USERROLE],
    }),

updateUserRoleRequest: builder.mutation({
      query: (data) => ({
        url: `Roles/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [API_TAGS.USERROLE],
    }),

    deleteUserRoleRequest: builder.mutation({
      query: (data) => ({
        url: `Roles/${data.id}`,
        method: "DELETE",
        params: data,
      }),
      invalidatesTags: [API_TAGS.USERROLE],
    }),



// HomePage Layout Config
getLayout: builder.query({
  query: () => ({
    url: `homePageLayouts`,
    method: "GET",
  }),
  providesTags: [API_TAGS.LAYOUT],
}),

getLayoutById: builder.query({
query: (data) => `homePageLayouts/${data.id}`,
  providesTags: [API_TAGS.LAYOUT],
}),

createLayoutRequest: builder.mutation({
  query: (data) => ({
    url: `homePageLayouts`,
    method: "POST",
    body: data,
  }),
  invalidatesTags: [API_TAGS.LAYOUT],
}),

updateLayoutRequest: builder.mutation({
  query: (data) => ({
    url: `homePageLayouts/${data.id}`,
    method: "PUT",
    body: data,
  }),
  invalidatesTags: [API_TAGS.LAYOUT],
}),

deleteLayoutRequest: builder.mutation({
  query: (data) => ({
    url: `homePageLayouts/${data.id}`,
    method: "DELETE",
    params: data,
  }),
  invalidatesTags: [API_TAGS.LAYOUT],
}),


// Categories
getCategories: builder.query({
  query: () => ({
    url: `Categories`,
    method: "GET",
  }),
  providesTags: [API_TAGS.CATEGORIES],
}),

getCategoryById: builder.query({
query: (data) => `Categories/${data.id}`,
  providesTags: [API_TAGS.CATEGORIES],
}),

createCategoryRequest: builder.mutation({
  query: (data) => ({
    url: `Categories`,
    method: "POST",
    body: data,
  }),
  invalidatesTags: [API_TAGS.CATEGORIES],
}),

updateCategoryRequest: builder.mutation({
  query: (data) => ({
    url: `Categories/${data.id}`,
    method: "PUT",
    body: data,
  }),
  invalidatesTags: [API_TAGS.CATEGORIES],
}),

deleteCategoryRequest: builder.mutation({
  query: (data) => ({
    url: `Categories/${data.id}`,
    method: "DELETE",
    params: data,
  }),
  invalidatesTags: [API_TAGS.CATEGORIES],
}),



// Settings Custom Images
getCustomImages: builder.query({
      query: () => ({
        url: `Pages`,
        method: "GET",
      }),
      providesTags: [API_TAGS.IMAGE],
    }),

getCustomImageById: builder.query({
  query: (data) => `Pages/${data.id}`,
      providesTags: [API_TAGS.IMAGE],
}),

createCustomImageRequest: builder.mutation({
      query: (data) => ({
        url: `Pages`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [API_TAGS.IMAGE],
    }),

updateCustomImageRequest: builder.mutation({
      query: (data) => ({
        url: `Pages/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [API_TAGS.IMAGE],
    }),

    deleteCustomImageRequest: builder.mutation({
      query: (data) => ({
        url: `Pages/${data.id}`,
        method: "DELETE",
        params: data,
      }),
      invalidatesTags: [API_TAGS.IMAGE],
    }),



    

// Settings Logs
getLogs: builder.query({
      query: () => ({
        url: `Users/userLogs`,
        method: "GET",
      }),
      providesTags: [API_TAGS.LOG],
    }),


    // NOTIFICATIONS
    getNotifications: builder.query({
      query: () => ({
        url: `Notifications`,
        method: "GET",
      }),
      providesTags: [API_TAGS.NOTIFICATION],
    }),

    getNotificationById: builder.query({
      query: (data) => `Notifications/${data.id}`,
      providesTags: [API_TAGS.NOTIFICATION],
    }),

    createNotificationRequest: builder.mutation({
      query: (data) => ({
        url: `Notifications`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [API_TAGS.NOTIFICATION],
    }),

    updateNotificationRequest: builder.mutation({
      query: (data) => ({
        url: `Notifications/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [API_TAGS.NOTIFICATION],
    }),

    deleteNotificationRequest: builder.mutation({
      query: (data) => ({
        url: `Notifications/${data.id}`,
        method: "DELETE",
        params: data,
      }),
      invalidatesTags: [API_TAGS.NOTIFICATION],
    }),

    markNotificationAsReadRequest: builder.mutation({
      query: (data) => ({
        url: `Notifications/deteleAllNotifications/${data.id}`,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: [API_TAGS.NOTIFICATION],
    }),



   
    // File
    //---------------------------------
    uploadFile: builder.mutation({
      query: (data) => ({
        url: "File",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [API_TAGS.FILE],
    }),
    getFileLink: builder.query({
      query: (data) => `File/${data.fileName}`,
      // responseHandler: (response) => console.log(response.blob()),
    }),
    downloadFile: builder.query({
      query: (data) => ({
        url: `File/download`,
        method: "POST",
        body:{data}
      }),
      providesTags: [API_TAGS.FILE],
    }),





// CONTACT US
//---------------------------------
contactUs: builder.mutation({
  query: (data) => ({
    url: "Contactus",
    method: "POST",
    body: data,
  }),
  invalidatesTags: [API_TAGS.CONTACT_US],
}),


getAnalyticsSocials: builder.query({
  query: () => ({
    url: "Analytics/socials",
    method: "GET",
  }),
  providesTags: [API_TAGS.ANALYTICS],
}),


// ANALYTICS
//---------------------------------
getAnalyticsShares: builder.query({
  query: () => ({
    url: "Analytics/shares",
    method: "GET",
  }),
  providesTags: [API_TAGS.ANALYTICS],
}),

postAnalyticsShares: builder.mutation({
  query: (data) => ({
    url: "Analytics/shares",
    method: "POST",
    body: data,
  }),
  invalidatesTags:[API_TAGS.ANALYTICS]

}),
getAnalyticsDownloads: builder.query({
  query: () => ({
    url: "Analytics/downloads",
    method: "GET",
  }),
  providesTags: [API_TAGS.ANALYTICS],
}),

postAnalyticsDownloads: builder.mutation({
  query: (data) => ({
    url: "Analytics/downloads",
    method: "POST",
    body: data,
  }),
  invalidatesTags:[API_TAGS.ANALYTICS]
}),

getAnalyticsVisits: builder.query({
  query: () => ({
    url: "Analytics/visits",
    method: "GET",
  }),
  providesTags: [API_TAGS.ANALYTICS],
}),

postAnalyticsVisits: builder.mutation({
  query: (data) => ({
    url: "Analytics/visits",
    method: "POST",
    body: data,
  }),
  invalidatesTags:[API_TAGS.ANALYTICS]

}),

getAnalyticsByCategory: builder.query({
    query: () => ({
      url: `Analytics/categoryViews`,
      method: "GET",
    }),
 
    providesTags: [API_TAGS.ANALYTICS],
  }),





  
    // SET PASSWORD
    //---------------------------------
    setPassword: builder.mutation({
      query: (data) => ({
        url: "Jwts/SetPass",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [API_TAGS.AUTH],
    }), 


    // FORGOT PASSWORD
    //---------------------------------
    forgotPassword: builder.mutation({
      query: (data) => ({
        url: `Jwts/SendPasswordResetLink/${data?.email}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [API_TAGS.AUTH],
    }),

  // code-verify
  VerifyCode: builder.mutation({
    query: (data) => ({
      url: "Jwts/ResetPassword",
      method: "POST",
      body: data,
    }),
    invalidatesTags: [API_TAGS.AUTH],

  }),



})

  



});

export const {  
  // set password
  useSetPasswordMutation,
  useForgotPasswordMutation,

  // Contact Us
  useContactUsMutation,

  // Dashboard stats
  useGetDashboardStatsQuery,

  // Posts - Articles
  useGetArticlesQuery,
  useGetArticlesLangQuery,

  useGetArticlesByIdQuery,
  useGetArticlesLatestFiveQuery,
  useCreateArticlesRequestMutation,
  useUpdateArticlesRequestMutation,
  useDeleteArticlesRequestMutation,

  // Articles - Category
  useGetArticlesCategoryLatestQuery,
  // useGetArticlesCategoryQuery,
  useGetArticlesTopTodayQuery,
  useGetArticlesMostReadQuery,

  // Posts - Publications
  useGetPublicationsQuery,
  useGetPublicationByIdQuery,
  useCreatePublicationRequestMutation,
  useUpdatePublicationRequestMutation,
  useDeletePublicationRequestMutation,

  // Posts - Events
  useGetEventsQuery,
  useGetEventByIdQuery,
  useCreateEventRequestMutation,
  useUpdateEventRequestMutation,
  useDeleteEventRequestMutation,


  // Ads

  useGetAdsQuery,
  useGetAdsByIdQuery,
  useCreateAdsRequestMutation,
  useUpdateAdsRequestMutation,
  useDeleteAdsRequestMutation,

  // Trending
  useGetTrendingArticlesQuery,
  useGetTrendingCategoryArticlesQuery,
  useGetMostReadPublicationsQuery,

  // SOCIALS
  useGetYoutubePlaylistQuery,
  useCreateYoutubePlaylistMutation,
  useCreateSocialPostRequestMutation,
  useCreateYoutubePlaylistRequestMutation,

  // createYoutubePlaylistRequest
  useUpdateYoutubePlaylistMutation,
  useUpdateYoutubePlaylistRequestMutation,

  useDeleteYoutubePlaylistRequestMutation,
  

  // search
  useGetSearchResultsQuery,


// Authors
useGetAuthorsQuery,
useGetAuthorByIdQuery,
useCreateAuthorRequestMutation,
useUpdateAuthorRequestMutation,
useDeleteAuthorRequestMutation,

  // Settings Users
  useGetUsersQuery,
  useGetUserByIdQuery,
  useCreateUserRequestMutation,
  useUpdateUserRequestMutation,
  useDeleteUserRequestMutation,

  // Settings User Roles
  useGetCategoriesQuery,
  useGetCategoryByIdQuery,
  useCreateCategoryRequestMutation,
  useUpdateCategoryRequestMutation,
  useDeleteCategoryRequestMutation,

// Settings Categories
useGetUserRolesQuery,
useGetUserRoleByIdQuery,
useCreateUserRoleRequestMutation,
useUpdateUserRoleRequestMutation,
useDeleteUserRoleRequestMutation,


// Settings Homepage Layout
useGetLayoutQuery,
useGetLayoutByIdQuery,
useCreateLayoutRequestMutation,
useUpdateLayoutRequestMutation,
useDeleteLayoutRequestMutation,



  // Settings Custom Images
  useGetCustomImagesQuery,
  useGetCustomImageByIdQuery,
  useCreateCustomImageRequestMutation,
  useUpdateCustomImageRequestMutation,
  useDeleteCustomImageRequestMutation,

  // Settings Logs
  useGetLogsQuery,

  // Notifications
  useGetNotificationsQuery,
  useGetNotificationByIdQuery,
  useCreateNotificationRequestMutation,
  useUpdateNotificationRequestMutation,
  useDeleteNotificationRequestMutation,
  useMarkNotificationAsReadRequestMutation,

  // File
  useUploadFileMutation,
  useGetFileLinkQuery,
  useDownloadFileQuery,

  // Analytics
  useGetAnalyticsSharesQuery,
  useGetAnalyticsSocialsQuery,

  usePostAnalyticsSharesMutation,
  useGetAnalyticsDownloadsQuery,
  usePostAnalyticsDownloadsMutation,
  useGetAnalyticsVisitsQuery,
  usePostAnalyticsVisitsMutation,
  useGetAnalyticsByCategoryQuery,

  // VERIFY CODE
  useVerifyCodeMutation,
} = apiSlice;
