import React, { useState } from "react";
import {
  Stack,
  styled,
  Link,
  Grid,
  Avatar,
  InputAdornment,
  FormControl,
  InputLabel,
  Button,
  MenuItem,
  Select,
  Fade,
  Divider,
  FormLabel,
  FormHelperText,
} from "@mui/material";
import {
  TextField,
  Typography,
  makeStyles,
  Container,
  Modal,
  Backdrop,
  TextareaAutosize,
} from "@material-ui/core";
import { colorTags } from "../../../../constants/variants";
import { useStyles } from "../../../../App";
import { DropzoneArea } from "material-ui-dropzone";
import {
  useUploadFileMutation,
  useCreateAdsRequestMutation,
} from "../../../../features/api/apiSlice";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { FILE_LINK_URL } from "../../../../constants/apiTags";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
const useStyless = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: theme.spacing(2),
    boxShadow:
      "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(255,255,255, 0.25) 0px 50px 100px -20px, rgba(255,255,255, 0.3) 0px 30px 60px -30px",
    maxWidth: "90%",
    maxHeight: "90%",
    overflow: "auto",
    outline: "none",
  },
  modalTitle: {
    textAlign: "left",
    marginBottom: theme.spacing(2),
  },
  modalBody: {
    marginTop: theme.spacing(2),
  },
  inputField: {
    marginBottom: theme.spacing(2),
  },
  modalActions: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#03AED2",
      },
      "&:hover fieldset": {
        borderColor: "#03AED2",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#03AED2",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#03AED2",
    },
  },
}));

const AddSponsor = ({ open, onClose, title, onSave }) => {
  const [token, setToken] = useState(localStorage.getItem("authToken"));

  const classes = useStyless();
  const classez = useStyles();
  const [name, setName] = useState("");
  const [page, setPage] = useState("");
  const [position, setPosition] = useState("");
  const [link, setLink] = useState("");
  const [image, setImage] = useState([]);
  const [nameError, setNameError] = useState("");
  const [imageError, setImageError] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [endDateError, setEndDateError] = useState("");

  // Placeholder arrays for page and position options
  const pages = ["Home", "Media Pages", "Categories", "NewsDetail", "All"];
  const positions = ["Top", "Middle", "Bottom", "All"];

  const [
    createSponsorRequest,
    {
      isLoading: createSponsorLoading,
      isError: isErrorCreateSponsor,
      error: createSponsorError,
    },
  ] = useCreateAdsRequestMutation();

  const [submitFileUpload] = useUploadFileMutation();

  const uploadImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("files", file);

      const response = await fetch(FILE_LINK_URL, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        throw new Error("Image upload failed");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();

    setNameError("");
    setImageError("");

    const data = {
      name: name,
      page: page,
      position: position,
      link: link,
      startDate,
      endDate,
    };

    if (name.length === 0 || name.length > 100) {
      setNameError("Sponsor name must be between 1 and 100 characters");
      return;
    }

    if (image.length === 0) {
      setImageError("Please upload an image");
      return;
    }

    try {
      const imageResponse = await uploadImage(image[0]);
      data.logo = imageResponse?.filenames[0] ?? "";
    } catch (error) {
      toast.error("Error uploading image");
      data.logo = "";
      return;
    }

    await createSponsorRequest(data)
      .then((res) => {
        toast.success("Sponsor Created Successfully");
      })
      .catch((err) => {
        toast.error("Error in creating sponsor");
      });

    setName("");
    setPage("");
    setPosition("");
    setLink("");
    setImage([]);

    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}>
      <Fade in={open}>
        <div className={classes.modalContent}>
          <div className={classes.modalHead}>
            <Typography
              variant="h6"
              className={classes.modalTitle}
              style={{ fontWeight: "medium", color: "#03AED2" }}>
              {title}
            </Typography>
          </div>
          <div className={classes.modalBody}>
            <TextField
              label="Sponsor Name"
              variant="outlined"
              className={classes.textField}
              fullWidth
              required
              style={{ marginTop: "15px" }}
              value={name}
              onChange={(e) => setName(e.target.value)}
              error={!!nameError}
              helperText={nameError}
            />
            <FormControl
              variant="outlined"
              className={classes.textField}
              fullWidth
              style={{ marginTop: "15px" }}>
              <InputLabel>Page</InputLabel>
              <Select
                value={page}
                onChange={(e) => setPage(e.target.value)}
                label="Page">
                {pages.map((page) => (
                  <MenuItem key={page} value={page}>
                    {page}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              className={classes.textField}
              fullWidth
              style={{ marginTop: "15px" }}>
              <InputLabel>Position</InputLabel>
              <Select
                value={position}
                onChange={(e) => setPosition(e.target.value)}
                label="Position">
                {positions.map((position) => (
                  <MenuItem key={position} value={position}>
                    {position}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Link"
              variant="outlined"
              placeholder="https://www.example.com   ...Format"
              className={classes.textField}
              fullWidth
              style={{ marginTop: "15px" }}
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />
            <Stack
              direction="row"
              spacing={3}
              style={{ marginTop: 20, marginBottom: 20, width: "100%" }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={3} sx={{ width: "50%" }}>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}>
                    <FormLabel component="legend">Start Date</FormLabel>
                    <DesktopDatePicker
                      inputFormat="dd/MM/yyyy"
                      color="success"
                      value={startDate}
                      onChange={(date) => setStartDate(date)}
                      renderInput={(params) => (
                        <TextField required variant="outlined" {...params} />
                      )}
                    />
                  </FormControl>
                </Stack>
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={3} sx={{ width: "50%" }}>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}>
                    <FormLabel component="legend">End Date</FormLabel>
                    <DesktopDatePicker
                      inputFormat="dd/MM/yyyy"
                      color="success"
                      value={endDate}
                      minDate={startDate}
                      onChange={(date) => setEndDate(date)}
                      renderInput={(params) => (
                        <TextField required variant="outlined" {...params} />
                      )}
                    />
                    <FormHelperText error={!!endDateError}>
                      {endDateError}
                    </FormHelperText>
                  </FormControl>
                </Stack>
              </LocalizationProvider>
            </Stack>
            <div className={classes.modalBody}>
              <DropzoneArea
                dropzoneClass={classez?.dropZone}
                style={{ marginTop: "15px" }}
                showAlerts={false}
                useChipsForPreview={true}
                acceptedFiles={[
                  "image/jpeg",
                  "image/jpg",
                  "image/png",
                  "image/webp",
                ]}
                filesLimit={1}
                maxFiles={1}
                maxFileSize={5000000}
                dropzoneText={
                  "Drop or Click to Upload Image (JPEG, JPG, PNG, WEBP)"
                }
                onChange={(files) => setImage(files)}
                error={!!imageError}
                helperText={imageError}
              />
            </div>
          </div>
          <div className={classes.modalActions}>
            <Stack spacing={2} direction="row">
              <div className={classes.modalActions}>
                <LoadingButton
                  // loading={createSponsorLoading}
                  variant="contained"
                  color="success"
                  style={{
                    fontWeight: "bold",
                    color: "#fff",
                    borderRadius: "15px",
                  }}
                  onClick={handleSave}>
                  Save
                </LoadingButton>
                <Button
                  variant="outlined"
                  color="error"
                  style={{
                    color: "#FF0000",
                    marginLeft: "10px",
                    borderRadius: "15px",
                  }}
                  onClick={onClose}>
                  Cancel
                </Button>
              </div>
            </Stack>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default AddSponsor;
