import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Paper,
  MenuItem,
  IconButton,
  makeStyles,
  useMediaQuery,
  useTheme,
  InputBase,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import Sidebar from "./Sidebar"; // Importing Sidebar component
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
  },
  title: {
    flexGrow: 1,
  },
  menuCard: {
    position: "absolute",
    top: theme.spacing(5.9),
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(2),
    backgroundColor: "#f5f5f5",
    boxShadow: theme.shadows[0.3],
    zIndex: 10,
    borderTopColor: "#BBBBBB",
    // borderRightColor:"#BBBBBB",
    // borderLeftColor:"#BBBBBB",
  },
  menuSection: {
    marginRight: theme.spacing(4),
  },
  menuTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(0.1),
    fontSize: "13px",
    cursor: "pointer",
  },
  menuItem: {
    fontSize: "12px",
    lineHeight: "0.8rem",
    display: "flex",
    justifyContent: "flex-start",
    cursor: "pointer",
  },
  button: {
    height: "100%",
    backgroundColor: "transparent",
    margin: "0px",
    fontSize: "13px",
    color: "#373A40",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
  highlightedButton: {
    backgroundColor: "#f5f5f5",
    borderTopColor: "#BBBBBB",
    borderRightColor: "#BBBBBB",
    borderLeftColor: "#BBBBBB",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  searchButton: {
    // marginLeft: "auto",
  },
  searchInput: {
    marginLeft: theme.spacing(0),
    transition: theme.transitions.create("width"),
    borderBottom: "1px solid grey",
    width: "200px",
    "&:focus": {
      width: "200px",
    },
  },
}));

const Header = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [hoveredMenu, setHoveredMenu] = useState(null);
  const [isSearchOpen, setSearchOpen] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const menuData = {
    Home: {},
    Editorial: {},
    Economy: {
      "Green Economy": ["Climate Change", "Technology", "Inclusive Economies"],
      "Business News": [
        "Entrepreneurship",
        "Wholesale and Retail Trade",
        "Export-Import",
        "Transport and Logistics",
        "Agribusiness",
        "Manufacturing",
        "Construction",
        "Banking and Finance",
        "Tourism and Hospitality",
        "Companies",
        "Education",
        "Energy",
        "Fisheries and Aquaculture",
        "Health",
        "Insurance",
        "Features and Advocacy",
      ],
    },
    Agriculture: {},
    Industry: {},
    Service: {},
    Opinions: {},
    Reports: {},
    "Addis Chamber TV": {},
    "Addis Chamber Podcast": {},
    Events: {},
  };

  const handleMouseEnter = (menu) => {
    setHoveredMenu(menu);
  };

  const handleMouseLeave = () => {
    setHoveredMenu(null);
  };

  const toggleSearch = () => {
    setSearchOpen(!isSearchOpen);
  };

  const convertToSlug = (text) => {
    return text.toLowerCase().replace(/\s+/g, "-");
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        style={{
          backgroundColor: "#ffff",
          boxShadow: "none",
          borderTop: "1px solid lightgrey",
        }}>
        <Toolbar
          style={{
            backgroundColor: "#ffff",
            boxShadow: "none",
            borderTop: "1px solid lightgrey",
            padding: "0px",
            height: "30",
            display: "flex",
            justifyContent: "center",
          }}>
          {isMobile ? (
            <Sidebar menuData={menuData} />
          ) : (
            <>
              {Object.keys(menuData).map((menu) => (
                <Button
                  key={menu}
                  className={`${classes.button} ${
                    hoveredMenu === menu ? classes.highlightedButton : ""
                  }`}
                  onClick={() =>
                    menu === "Home"
                      ? navigate(`/`)
                      : navigate(`/news-category/${convertToSlug(menu)}`)
                  }
                  onMouseEnter={() => handleMouseEnter(menu)}
                  onMouseLeave={handleMouseLeave}>
                  {t(menu)}
                </Button>
              ))}
              <div className={classes.searchButton}>
                <IconButton onClick={toggleSearch}>
                  <SearchIcon />
                </IconButton>
                {isSearchOpen && (
                  <InputBase
                    placeholder="Search..."
                    className={classes.searchInput}
                  />
                )}
              </div>
            </>
          )}
        </Toolbar>
      </AppBar>
      {hoveredMenu && !isMobile && (
        <Paper
          className={classes.menuCard}
          onMouseEnter={() => setHoveredMenu(hoveredMenu)}
          onMouseLeave={handleMouseLeave}>
          {Object.entries(menuData[hoveredMenu]).map(([section, items]) => (
            <div key={section} className={classes.menuSection}>
              <Typography
                className={classes.menuTitle}
                onClick={() =>
                  navigate(`/news-category/${convertToSlug(section)}`)
                }>
                {t(section)}
              </Typography>
              {items.map((item) => (
                <MenuItem
                  key={item}
                  className={classes.menuItem}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    paddingLeft: "0px",
                  }}
                  onClick={() =>
                    navigate(`/news-category/${convertToSlug(item)}`)
                  }>
                  {t(item)}
                </MenuItem>
              ))}
            </div>
          ))}
        </Paper>
      )}
    </div>
  );
};

export default Header;
