import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Typography, Divider } from "@mui/material";
import { colorTags } from "../../../../constants/variants";
import { useGetTrendingCategoryArticlesQuery } from "../../../../features/api/apiSlice";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  trendingNews: {
    width: 300,
    border: "1px solid lightgrey",
    padding: theme.spacing(2),
    paddingLeft: "0px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  header: {
    fontSize: 18,
    fontWeight: "bold",
    paddingLeft: "10px",
    color: colorTags.PRIMARY,
  },
  card: {
    backgroundColor: "transparent",
    boxShadow: "none",
    marginTop: theme.spacing(2),
    color: colorTags.PRIMARY,
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
  },
  description: {
    fontSize: 14,
    marginTop: theme.spacing(0.5),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    backgroundColor: colorTags.TERTIARY,
  },
}));

const TrendingNews = ({ category }) => {
  const classes = useStyles();
  const news = [
    { title: "News 1", description: "Brief description of news 1." },
    { title: "News 2", description: "Brief description of news 2." },
    { title: "News 3", description: "Brief description of news 3." },
    { title: "News 4", description: "Brief description of news 4." },
    { title: "News 5", description: "Brief description of news 5." },
  ];

  const {
    data: articlesData,
    isLoading: isLoadingArticles,
    isError: isErrorArticles,
    error: errorArticles,
  } = useGetTrendingCategoryArticlesQuery();

  return (
    <div className={classes.trendingNews}>
      <Typography className={classes.header} style={{ fontWeight: "bold" }}>
        Trending in {category}
        {/* News */}
      </Typography>
      <Divider className={classes.divider} />
      {articlesData?.map((item, index) => (
        <Card
          key={index}
          className={classes.card}
          sx={{
            // boxShadow: "none"

            boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 2px 0px",
          }}>
          <CardContent
            style={{
              padding: "10px 10px",
            }}>
            <Link to={`/news-detail/${item && item?.id}`}>
              <Typography
                className={classes.title}
                style={{ fontWeight: "bold", fontFamily: "Times-new-roman" }}>
                {item.title}
              </Typography>
              <Typography
                className={classes.description}
                style={{
                  fontFamily: "Times-new-roman",
                }}>
                {item.description}
              </Typography>
            </Link>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default TrendingNews;
