import React, { useState } from "react";
import {
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  IconButton,
  Box,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import { TextFields, Image, YouTube } from "@mui/icons-material";
import { ArrowForward } from "@mui/icons-material";
import { makeStyles } from "@material-ui/core/styles";
import { images } from "../../../../assets/index";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(3),
  },
  card: {
    position: "relative",
    borderRadius: "15px",
    boxShadow: "rgba(0, 0, 0, 0.25) 0px 25px 50px -12px",
  },
  media: {
    // height: 160,
    // height: "auto",
    height: 0,
    paddingTop: "70%",
  },
  iconContainer: {
    // position: "absolute",
    top: 0,
    width: "100%",
    left: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
  },
  activeIcon: {
    color: "cyan",
    width: "20px",
  },
  inactiveIcon: {
    color: theme.palette.grey[500],
    // fontSize: "10px",
    width: "20px",
  },
  activeIconImg: {
    color: "#F4CE14",
    width: "20px",
  },
  activeIconYtb: {
    color: "#FF0000",
    width: "20px",
  },
}));

const templates = [
  { id: 1, image: "template1.jpg" },
  { id: 2, image: "template2.jpg" },
  { id: 3, image: "template3.jpg" },
];

const AddNews = ({ onClose }) => {
  const classes = useStyles();
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const navigate = useNavigate();

  const handleTemplateSelect = (id) => {
    setSelectedTemplate(id);
  };

  return (
    <Box className={classes.container}>
      <Stack
        style={{ width: "100%" }}
        direction="row"
        spacing={2}
        justifyContent={"space-between"}>
        {" "}
        <Typography variant="h6"> Select a Template</Typography>{" "}
        {selectedTemplate && (
          <Button
            variant="outlined"
            className={classes.button}
            style={{
              marginBottom: "1%",
              marginRight: "5%",
              borderRadius: "18px",
              // backgroundColor: "#03AED2",
              color: "#03AED2",
            }}
            // disabled={selectedTemplate ? false : true}
            // disabled={true}
            onClick={() =>
              navigate(`/dashboard/article-template/${selectedTemplate}`)
            }>
            {" "}
            next
            <ArrowForward />
          </Button>
        )}
      </Stack>
      <Grid container spacing={8} justifyContent={"center"} pl={10} pr={10}>
        <Grid item xs={10} sm={6} md={4} key={1}>
          <Stack spacing={1} direction="column">
            <Box className={classes.iconContainer}>
              <IconButton
                className={
                  selectedTemplate === 1
                    ? classes.activeIcon
                    : classes.inactiveIcon
                }>
                <TextFields />
              </IconButton>
              {/* <IconButton
                className={
                  selectedTemplate === 1
                    ? classes.activeIconImg
                    : classes.inactiveIcon
                }>
                <Image />
              </IconButton>
              <IconButton
                className={
                  selectedTemplate === 1
                    ? classes.activeIconYtb
                    : classes.inactiveIcon
                }>
                <YouTube />
              </IconButton> */}
            </Box>
            <Card
              className={classes.card}
              onClick={() => handleTemplateSelect(1)}
              raised={selectedTemplate === 1}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={images?.ArtcileTemplatePlaceholder1}
                  title={`Template ${1}`}
                />
              </CardActionArea>
            </Card>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={4} key={2}>
          <Stack spacing={1} direction="column">
            <Box className={classes.iconContainer}>
              <IconButton
                className={
                  selectedTemplate === 2
                    ? classes.activeIcon
                    : classes.inactiveIcon
                }>
                <TextFields />
              </IconButton>
              <IconButton
                className={
                  selectedTemplate === 2
                    ? classes.activeIconImg
                    : classes.inactiveIcon
                }>
                <Image />
              </IconButton>
              {/* <IconButton
                className={
                  selectedTemplate === 2
                    ? classes.activeIconYtb
                    : classes.inactiveIcon
                }>
                <YouTube />
              </IconButton> */}
            </Box>
            <Card
              className={classes.card}
              onClick={() => handleTemplateSelect(2)}
              style={{ borderRadius: "15px" }}
              raised={selectedTemplate === 2}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={images?.ArtcileTemplatePlaceholder2}
                  title={`Template ${2}`}
                />
              </CardActionArea>
            </Card>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={4} key={3}>
          <Stack spacing={1} direction="column">
            <Box className={classes.iconContainer}>
              <IconButton
                className={
                  selectedTemplate === 3
                    ? classes.activeIcon
                    : classes.inactiveIcon
                }>
                <TextFields />
              </IconButton>
              <IconButton
                className={
                  selectedTemplate === 3
                    ? classes.activeIconImg
                    : classes.inactiveIcon
                }>
                <Image />
              </IconButton>
              <IconButton
                className={
                  selectedTemplate === 3
                    ? classes.activeIconYtb
                    : classes.inactiveIcon
                }>
                <YouTube />
              </IconButton>
            </Box>
            <Card
              className={classes.card}
              onClick={() => handleTemplateSelect(3)}
              style={{ borderRadius: "15px" }}
              raised={selectedTemplate === 3}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={images?.ArtcileTemplatePlaceholder3}
                  title={`Template ${3}`}
                />
              </CardActionArea>
            </Card>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={4} key={4}>
          <Stack spacing={1} direction="column">
            <Box className={classes.iconContainer}>
              <IconButton
                className={
                  selectedTemplate === 4
                    ? classes.activeIcon
                    : classes.inactiveIcon
                }>
                <TextFields />
              </IconButton>
              {"(2"}
              <IconButton
                className={
                  selectedTemplate === 4
                    ? classes.activeIconImg
                    : classes.inactiveIcon
                }>
                <Image />
              </IconButton>
              {")"}
              {/* <IconButton
                className={
                  selectedTemplate === 4
                    ? classes.activeIconImg
                    : classes.inactiveIcon
                }>
                <Image />
              </IconButton> */}
              {/* <IconButton
                className={
                  selectedTemplate === 2
                    ? classes.activeIconYtb
                    : classes.inactiveIcon
                }>
                <YouTube />
              </IconButton> */}
            </Box>
            <Card
              className={classes.card}
              onClick={() => handleTemplateSelect(4)}
              style={{ borderRadius: "15px" }}
              raised={selectedTemplate === 4}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={images?.ArtcileTemplatePlaceholder4}
                  title={`Template ${4}`}
                />
              </CardActionArea>
            </Card>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={4} key={5}>
          <Stack spacing={1} direction="column">
            <Box className={classes.iconContainer}>
              <IconButton
                className={
                  selectedTemplate === 5
                    ? classes.activeIcon
                    : classes.inactiveIcon
                }>
                <TextFields />
              </IconButton>

              {/* <IconButton
                className={
                  selectedTemplate === 5
                    ? classes.activeIconImg
                    : classes.inactiveIcon
                }>
                <Image />
              </IconButton> */}
              <IconButton
                className={
                  selectedTemplate === 5
                    ? classes.activeIconYtb
                    : classes.inactiveIcon
                }>
                <YouTube />
              </IconButton>
            </Box>
            <Card
              className={classes.card}
              onClick={() => handleTemplateSelect(5)}
              style={{ borderRadius: "15px" }}
              raised={selectedTemplate === 5}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={images?.ArtcileTemplatePlaceholder5}
                  title={`Template ${5}`}
                />
              </CardActionArea>
            </Card>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={4} key={6}>
          <Stack spacing={1} direction="column">
            <Box className={classes.iconContainer}>
              <IconButton
                className={
                  selectedTemplate === 6
                    ? classes.activeIcon
                    : classes.inactiveIcon
                }>
                <TextFields />
              </IconButton>
              {"(2"}
              <IconButton
                className={
                  selectedTemplate === 6
                    ? classes.activeIconImg
                    : classes.inactiveIcon
                }>
                <Image />
              </IconButton>
              {")"}
              {/* <IconButton
                className={
                  selectedTemplate === 6
                    ? classes.activeIconImg
                    : classes.inactiveIcon
                }>
                <Image />
              </IconButton> */}
              <IconButton
                className={
                  selectedTemplate === 6
                    ? classes.activeIconYtb
                    : classes.inactiveIcon
                }>
                <YouTube />
              </IconButton>
            </Box>
            <Card
              className={classes.card}
              onClick={() => handleTemplateSelect(6)}
              raised={selectedTemplate === 6}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={images?.ArtcileTemplatePlaceholder6}
                  title={`Template ${6}`}
                />
              </CardActionArea>
            </Card>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddNews;
